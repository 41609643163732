import { getFileName, getTradeRules } from './propertyDataHelper';
import { getMeterNodes } from './propertyDataManager';

/**
 * Builds the meter data for the json (data pack) download.
 * @param {Array<object>} meterNodes
 * @returns {Array<object>} - meter schema.
 */

export const buildMeterSchema = (node) => {
  if (!node) {
    return null;
  }
  const { dataConsumed, dataGenerated } = node || {};

  const {
    aggregation: consumedAgg, timeRange: consumedRange, timeZone: consumeZone,
    metric: consumedMetric, data: consumedData,
  } = dataConsumed || {};
  const {
    aggregation: generatedAgg, timeRange: generatedRange, timeZone: generatedZone,
    metric: generatedMetric, data: generatedData,
  } = dataGenerated || {};

  const { identifier: consumedIdentifier } = consumedMetric;
  const { identifier: generatedIdentifier } = generatedMetric;

  const meterSchema = {
    [consumedIdentifier]: {
      metric: consumedMetric,
      aggregation: consumedAgg,
      timeRange: consumedRange,
      timeZone: consumeZone,
      data: consumedData,
    },
    [generatedIdentifier]: {
      metric: generatedMetric,
      aggregation: generatedAgg,
      timeRange: generatedRange,
      timeZone: generatedZone,
      data: generatedData,
    },
  };
  return meterSchema;
};

/**
 * Description
 * @param {Array<object>} trades
 * @param {string} interval - trade interval
 * @returns {Array<object>} - trade schema
 */
export const buildTradeSchema = (trades, interval = 'P1D') => {
  if (!trades || trades?.length === 0) {
    return null;
  }
  const tradeSchema = [];
  trades.forEach((trade) => {
    const { data, key } = trade;
    const { ruleId, type } = key;
    data.forEach((datum) => {
      const {
        averagePrice, buyerTradePointIds, directions, range: timeRanga,
        sellerTradePointIds, value: tradeValue, volume: tradeVolume,
      } = datum;
      const buyerTradePointId = buyerTradePointIds && buyerTradePointIds[0];
      const sellerTradePointId = sellerTradePointIds && sellerTradePointIds[0];
      const direction = directions && directions[0];
      const price = { units: averagePrice };
      const volume = { units: tradeVolume };
      const value = { units: tradeValue };
      const tradeObj = {
        buyerTradePointId,
        direction,
        interval,
        price,
        ruleId,
        sellerTradePointId,
        timeRanga,
        type,
        value,
        volume,
      };
      tradeSchema.push(tradeObj);
    });
  });
  return tradeSchema;
};

/**
 * Builds the user data for the json (data pack) download.
 * @param {object} propertyUsers
 * @returns {object} - user schema.
 */
export const buildUserSchema = (propertyUsers) => {
  if (!propertyUsers) {
    return null;
  }
  const userNodes = propertyUsers.edges?.map((item) => item.node.user);
  const users = {};
  userNodes?.forEach((user) => {
    const { id } = user;
    users[id] = user;
  });
  return users;
};

/**
 * Builds the trade and meter data schema for the json (data pack) download.
 * @param {Array<object>} meterNodes
 * @param {string} propertyTitle
 * @returns {object} - json data schema
 */
export const buildDataSchema = (meterNodes, propertyTitle) => {
  if (!meterNodes) {
    return null;
  }
  const tradeData = {};
  const meterData = {};
  let fileName = '';

  // Adding trade rules
  const allRules = getTradeRules(meterNodes);
  const tradeRules = {};
  allRules.forEach((trade) => {
    const { id } = trade;
    tradeRules[id] = trade;
  });

  // Adding the info on the list of available meters
  const meters = [];
  meterNodes.forEach((node) => {
    const {
      dataConsumed, active, externalIdentifier, identifier,
      title, tradeSetSummary: trades, tradePointId,
    } = node || {};
    const { aggregation, timeRange } = dataConsumed || {};

    meters.push({
      active,
      externalIdentifier,
      identifier,
      title,
      tradePointId,
    });

    if (!fileName) {
      fileName = getFileName(propertyTitle, timeRange, '');
    }

    // Adding meter and trade data
    meterData[identifier] = buildMeterSchema(node);
    tradeData[tradePointId] = buildTradeSchema(trades, aggregation);
  });

  return {
    fileName, meterData, meters, tradeData, tradeRules,
  };
};

/**
 * Prepares the schema for json(data pack) download
 * Data schema for data pack - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838743595/JSON+property+data+download
 * @param {object} property
 * @returns {object} - json data schema and file name
 */
export const processDataPack = (property) => {
  if (!property) {
    return null;
  }

  const {
    address, externalIdentifier, meters: meterList, title, propertyUsers,
  } = property;

  const meterNodes = getMeterNodes(meterList);
  const users = buildUserSchema(propertyUsers);
  const {
    fileName, tradeData, tradeRules, meterData, meters,
  } = buildDataSchema(meterNodes, title);

  const data = {
    address, externalIdentifier, title, users, meters, meterData, tradeData, tradeRules,
  };

  return { data, fileName };
};
