import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import StackedBarChart from './StackedBarChart';
import {
  METER, UNIT_ENERGY, UNIT_CARBON, UNIT_CURRENCY,
} from './chartConstants';
import { getUnitProps, showNoDataMsg } from './ChartHelpers';

/**
 * Builds the summary ( for a selected time timestamp) for the tooltip
 * @param {object} data - meter data
 * @param {object} tooltipTimestamp
 * @param {string} unit - energy or carbon
 * @returns {object} - tooltip summary.
 */
const buildTooltipSummary = (data, tooltipTimestamp, unit) => {
  let finalData = null;
  if (data && Object.keys(data).length > 0) {
    Object.keys(data).forEach((timestamp) => {
      const { timestamp: meterTimestamp } = data[timestamp] || {};
      if (meterTimestamp?.ts === tooltipTimestamp?.ts) {
        const {
          value, carbon, flags: flagsData,
        } = data[timestamp];
        const y = unit === UNIT_ENERGY ? value : carbon;
        const flags = unit === UNIT_ENERGY ? flagsData : [];
        finalData = { x: meterTimestamp, value: y, flags };
      }
    });
  }
  return finalData;
};

/**
 * Build and returns the tooltip data
 * @param {object} data - meterData
 * @param {object} tooltipTimestamp
 * @param {any} unit - energy or carbon
 * @param {boolean} isAggregated - whether the view is aggregated or not
 * @param {string} dir - sell or buy
 * @returns {object} - tooltip data.
 */
export const getTooltipData = (data, tooltipTimestamp, unit, isAggregated, dir) => {
  let finalData = null;
  if (tooltipTimestamp) {
    if (isAggregated) {
      const tooltipData = data[dir];
      finalData = buildTooltipSummary(tooltipData, tooltipTimestamp, unit);
      return finalData;
    }
    finalData = {};
    Object.keys(data).forEach((meterId) => {
      const { title, identifier } = data[meterId];
      const tooltipData = data[meterId][dir];
      const resp = buildTooltipSummary(tooltipData, tooltipTimestamp, unit);
      if (resp && Object.keys(resp).length > 0) {
        finalData[meterId] = { ...resp, title, identifier };
      }
    });
  }
  return finalData;
};

/**
 * Build time based meter data
 * @param {object} data - meter data
 * @param {string} unit - energy or carbon
 * @returns {object} - chart meter data.
 */
const buildChartData = (data, unit) => {
  const finalResp = {};
  if (data && Object.keys(data).length > 0) {
    Object.keys(data).forEach((timestamp) => {
      const {
        timestamp: x, value: energy, carbon,
      } = data[timestamp];
      const finalValue = unit === UNIT_ENERGY ? energy : carbon;
      if (x) {
        finalResp[timestamp] = { x, y: finalValue };
      }
    });
  }

  return finalResp;
};

/**
 * Build and returns the data for meter chart
 * @param {object} data - meter data
 * @param {string} unit - energy or carbon
 * @param {boolean} isAggregated - whether the view is aggregated or not
 * @returns {object} - chart data.
 */
export const getChartData = (data, unit, isAggregated) => {
  let dataConsumed = {};
  let dataGenerated = {};
  if (isAggregated) {
    const { buy, sell } = data;
    dataConsumed = { aggregated: buildChartData(buy, unit) };
    dataGenerated = { aggregated: buildChartData(sell, unit) };
  } else {
    Object.keys(data).forEach((meterId) => {
      const { buy, sell } = data[meterId];
      const buyData = buildChartData(buy, unit);
      const sellData = buildChartData(sell, unit);
      if (buyData && Object.keys(buyData).length > 0) {
        dataConsumed = { ...dataConsumed, [meterId]: buyData };
      }
      if (sellData && Object.keys(sellData).length > 0) {
        dataGenerated = { ...dataGenerated, [meterId]: sellData };
      }
    });
  }
  const offset = {};
  Object.keys(dataGenerated).forEach((meterId) => {
    const dataGeneratedByMeter = dataGenerated[meterId];
    if (Object.keys(dataGeneratedByMeter).length > 0) {
      Object.keys(dataGeneratedByMeter).forEach((timestamp) => {
        const { x, y } = dataGenerated[meterId][timestamp];
        offset[x] = { x, y: -y, y0: -y };
        if (dataConsumed[meterId] && !(dataConsumed[meterId][timestamp])) {
          dataConsumed[meterId][timestamp] = { x, y: 0 };
        }
      });
    }
  });

  Object.keys(dataConsumed).forEach((meterId) => {
    const dataConsumedByMeter = dataConsumed[meterId];
    if (Object.keys(dataConsumedByMeter).length > 0) {
      Object.keys(dataConsumedByMeter).forEach((timestamp) => {
        const { x, y } = dataConsumed[meterId][timestamp];
        offset[x] = { x, y: -y, y0: -y };
        if (dataGenerated[meterId] && !(dataGenerated[meterId][timestamp])) {
          dataGenerated[meterId][timestamp] = { x, y: 0 };
        }
      });
    }
  });
  return { dataConsumed, dataGenerated };
};

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - ChartMeter component
 */
export default function ChartMeter(props) {
  const {
    aggregation, tooltipTimestamp,
    tooltipUpdate, handleChartClick, unit, width, data,
    hoverKeys, selectedKeys, isAggregated,
  } = props;
  if (!data || Object.keys(data)?.length === 0) {
    return showNoDataMsg(METER);
  }

  const {
    label: unitLabel, format: yAxisFormat,
    tooltipFormat,
  } = getUnitProps(unit);

  const { dataConsumed, dataGenerated } = getChartData(data, unit, isAggregated);

  const compareX = (a, b) => (a.x - b.x);

  const tooltipConsumed = getTooltipData(data, tooltipTimestamp, unit, isAggregated, 'buy');
  const tooltipGenerated = getTooltipData(data, tooltipTimestamp, unit, isAggregated, 'sell');

  const tooltipDateFormat = aggregation.match(/^PT\d+[HMS]/) ? DateTime.DATETIME_SHORT : DateTime.DATE_SHORT;

  // Logic to frame the chart data :  End

  const toolTipProps = {
    unitLabel,
    tooltipDateFormat,
    tooltipData: { tooltipConsumed, tooltipGenerated },
    tooltipTimestamp,
    tooltipFormat,
    isCarbon: unit === UNIT_CARBON,
    isAggregated,
  };

  const stackBarProps = {
    data: { sell: dataGenerated, buy: dataConsumed },
    compareX,
    tooltipUpdate,
    handleChartClick,
    chartType: METER,
    hoverKeys,
    selectedKeys,
    isAggregated,
  };

  return (
    <StackedBarChart
      stackBarProps={stackBarProps}
      toolTipProps={toolTipProps}
      width={width}
      yAxisFormat={yAxisFormat}
      stepSize={aggregation}
    />
  );
}

ChartMeter.propTypes = {
  aggregation: PropTypes.string.isRequired,
  tooltipTimestamp: PropTypes.instanceOf(DateTime),
  tooltipUpdate: PropTypes.func.isRequired,
  handleChartClick: PropTypes.func.isRequired,
  unit: PropTypes.oneOf([
    UNIT_CURRENCY,
    UNIT_ENERGY,
    UNIT_CARBON,
  ]).isRequired,
  width: PropTypes.number.isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  hoverKeys: PropTypes.instanceOf(Array).isRequired,
  selectedKeys: PropTypes.instanceOf(Array).isRequired,
  isAggregated: PropTypes.bool.isRequired,
};

ChartMeter.defaultProps = {
  tooltipTimestamp: null,
};
