import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

import { AvatarItem } from 'src/enosikit/components/Avatar';
import capitalize from 'src/util/capitalize';
import {
  TradeTypeLabels,
  TRADE_RULE_STATE_PROPOSED, TRADE_RULE_STATE_ACCEPTED, TRADE_RULE_STATE_CANCELLED,
  TRADE_RULE_STATE_REJECTED, TRADE_RULE_STATE_CLOSED,
} from 'src/util/constants';

const Step = styled.li.attrs({
  className: 'd-flex',
})`
  list-style: none;
`;

const Icon = styled.div.attrs((props) => ({
  className: props.isCurrentStep ? 'text-primary' : 'text-secondary',
}))`
  font-size: 1.5rem;
  opacity: 75%;
`;

const Line = styled.div.attrs({
  className: 'h-100 border border-dark',
})`
  opacity: 25%;
`;

const Header = styled.div.attrs({
  className: 'd-flex justify-content-between mt-2',
})``;

const Body = styled.div.attrs({
  className: 'card card-body mb-3 mt-3',
})``;

const List = styled.ul.attrs({
  className: 'mt-0 mx-0 mb-n3 p-0',
})`
  list-style: none;
`;

const Actor = styled.span`
  vertical-align: middle;
`;

/**
 * Description
 * @param {any} props
 * @returns {any} - TradeRuleTimelineStep component
 */
function TradeRuleTimelineStep(props) {
  const {
    ruleId, tradeType, state, timestamp, user, icon, isCurrentStep, hasNextStep, children,
  } = props;

  const type = TradeTypeLabels[tradeType] || 'unspecified';

  let stepType;
  switch (state) {
    case TRADE_RULE_STATE_PROPOSED:
      stepType = 'proposed';
      break;
    case TRADE_RULE_STATE_ACCEPTED:
      stepType = 'accepted';
      break;
    case TRADE_RULE_STATE_CANCELLED:
      stepType = 'cancelled';
      break;
    case TRADE_RULE_STATE_REJECTED:
      stepType = 'rejected';
      break;
    case TRADE_RULE_STATE_CLOSED:
      stepType = 'closed';
      break;
    default:
      stepType = 'unspecified';
  }

  const ts = DateTime.fromSeconds(timestamp);

  let actor;
  if (user) {
    const { email, givenName, familyName } = user;
    let name = [givenName, familyName].filter(Boolean).join(' ');
    if (name === '') {
      name = email;
    }
    actor = (
      <AvatarItem
        primaryText={name}
        secondaryText={email}
        avatarImage={
          <FontAwesomeIcon icon={faUserCircle} size="3x" />
        }
        className="d-inline-flex"
      />
    );
  } else {
    actor = 'System';
  }

  return (
    <Step>
      <div className="d-flex flex-column align-items-center">
        <Icon isCurrentStep={isCurrentStep}>{icon}</Icon>
        {hasNextStep && <Line />}
      </div>
      <div className="ms-3 w-100">
        <Header>
          <div>
            <strong>{capitalize(type)}</strong>
            {' trade rule '}
            <strong>{stepType}</strong>
          </div>
          <div>
            <time id={`timestamp-${ruleId}-${stepType}`} dateTime={ts.toISO()}>
              {ts.toLocaleString(DateTime.DATETIME_FULL)}
            </time>
            <UncontrolledTooltip target={`timestamp-${ruleId}-${stepType}`}>
              {ts.toISO()}
            </UncontrolledTooltip>
          </div>
        </Header>
        <Body>
          <List>
            <li className="mb-3">
              <strong>{capitalize(stepType)}</strong>
              {' by '}
              <Actor>{actor}</Actor>
            </li>
            {children}
          </List>
        </Body>
      </div>
    </Step>
  );
}

TradeRuleTimelineStep.propTypes = {
  ruleId: PropTypes.string.isRequired,
  tradeType: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
  }).isRequired,
  icon: PropTypes.node.isRequired,
  isCurrentStep: PropTypes.bool.isRequired,
  hasNextStep: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

TradeRuleTimelineStep.defaultProps = {
  children: null,
};

export default TradeRuleTimelineStep;
