import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import {
  FormFeedback, FormGroup, Label,
} from 'reactstrap';

import { Days, DayNames } from 'src/util/constants';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - SelectDays component
 */
function SelectDays(props) {
  const {
    idPrefix, daysSelected, valid, handleDaysChange,
  } = props;

  const daysOptions = Days.map((day) => ({ value: day, label: DayNames[day] }));
  const daysValue = daysSelected.map((day) => ({ value: day, label: DayNames[day] }));

  const handleChange = (selected) => {
    handleDaysChange(selected.map((option) => option.value));
  };

  return (
    <FormGroup>
      <Label for={`${idPrefix}DaysOfWeek`}>Days of week</Label>
      <Select
        isMulti
        name="daysOfWeek"
        id={`${idPrefix}DaysOfWeek`}
        options={daysOptions}
        value={daysValue}
        onChange={handleChange}
        className={valid === false ? 'is-invalid' : ''}
      />
      <FormFeedback>Invalid days of week</FormFeedback>
    </FormGroup>
  );
}

SelectDays.propTypes = {
  idPrefix: PropTypes.string.isRequired,
  daysSelected: PropTypes.arrayOf(PropTypes.oneOf(Days)).isRequired,
  valid: PropTypes.bool,
  handleDaysChange: PropTypes.func.isRequired,
};

SelectDays.defaultProps = {
  valid: null,
};

export default SelectDays;
