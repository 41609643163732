/* eslint no-underscore-dangle: ["error", { "allow": ["__id"] }] */
import { context, SpanStatusCode, trace } from '@opentelemetry/api';
import React, { useEffect } from 'react';

import { tracerName } from 'src/tracing/wrappers';
import { tracingProvider } from 'src/tracing/setup';
import { tracingState } from 'src/tracing/state';

/**
 * HOC to takes care of start/stop of tracing
 * @param {any} name
 * @param {any} WrappedComponent
 * @returns {React.ReactComponentElement} - react component
 */
function withTracingHOC(name, WrappedComponent) {
  const tracer = tracingProvider.getProvider().getTracer(tracerName);
  const ctx = context.active();
  // Always set start/end time to avoid issues with sleep/hibernation
  const span = tracer.startSpan(name, { startTime: new Date() }, ctx);
  const tracingCtx = trace.setSpan(ctx, span);
  let shouldClearPropertyID = false;

  // eslint-disable-next-line func-names
  return context.with(tracingCtx, () => function (props) {
    useEffect(() => {
      /**
       *
       */
      async function addTracingState() {
        // Clean up the span and tracing state after this effect
        await tracingState.addToSpan(span);
      }

      addTracingState()
        .then(() => {
          // NOTE: assuming success here!
          // TODO: is there a way to pass the status code in?
          span.setStatus({ code: SpanStatusCode.OK });
          span.end(new Date());
          if (shouldClearPropertyID) {
            tracingState.clearPropertyID();
            shouldClearPropertyID = false;
          }
        });
    });

    // Needed as dynamic function.
    // eslint-disable-next-line react/prop-types
    const { property } = props;
    if (property) {
      // Hacky!!
      // Cannot see a better way without doing it fully manually everywhere the props are known
      // Needed as dynamic function.
      // eslint-disable-next-line react/prop-types
      const propertyID = property.__id;
      if (propertyID && propertyID !== '') {
        tracingState.setPropertyID(propertyID);
        shouldClearPropertyID = false;
      }
    }

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <WrappedComponent {...props} />
    );
  });
}

export {
  // eslint-disable-next-line import/prefer-default-export
  withTracingHOC,
};
