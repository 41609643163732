/**
 * @generated SignedSource<<17a2ff8429dc73c3445932058e7010ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_Root_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewerUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CustomerLayout_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_Root_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewerUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "givenName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "familyName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "proposals",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10
              },
              {
                "kind": "Literal",
                "name": "state",
                "value": "TRADE_RULE_STATE_PROPOSED"
              },
              {
                "kind": "Literal",
                "name": "type",
                "value": "TRADE_TYPE_NOMINATED"
              }
            ],
            "concreteType": "TradeRuleConnection",
            "kind": "LinkedField",
            "name": "rules",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeRuleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TradeRule",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "rules(first:10,state:\"TRADE_RULE_STATE_PROPOSED\",type:\"TRADE_TYPE_NOMINATED\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyConnection",
            "kind": "LinkedField",
            "name": "properties",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PropertyEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Property",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1b86e86905a51ccfe5dfd56482836017",
    "id": null,
    "metadata": {},
    "name": "routes_Root_Query",
    "operationKind": "query",
    "text": "query routes_Root_Query {\n  viewer {\n    id\n    viewerUser {\n      id\n      type\n    }\n    ...CustomerLayout_viewer\n  }\n}\n\nfragment CustomerLayout_viewer on Viewer {\n  id\n  viewerUser {\n    type\n    id\n  }\n  ...CustomerNavigation_viewer\n  properties {\n    ...CustomerNavigation_properties\n  }\n}\n\nfragment CustomerNavigation_properties on PropertyConnection {\n  edges {\n    node {\n      id\n      title\n    }\n  }\n}\n\nfragment CustomerNavigation_viewer on Viewer {\n  id\n  viewerUser {\n    email\n    givenName\n    familyName\n    type\n    id\n  }\n  proposals: rules(first: 10, type: TRADE_TYPE_NOMINATED, state: TRADE_RULE_STATE_PROPOSED) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "ea7d97bb47f5a6725d5ca513fcaca723";

module.exports = node;
