import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'reactstrap';

const colorMapping = {
  USER_TYPE_ADMIN: 'secondary',
  USER_TYPE_CUSTOMER: 'primary',
};

/**
 * Description
 * @param {any} {state}
 * @returns {React.ReactComponentElement} - BadgeUserType component
 */
function BadgeUserType({ type }) {
  if (!type) { return (null); }

  const color = colorMapping[type] || 'mid';
  const title = type.split('_').slice(-1)[0];

  return (
    <Badge color={color} className="badge-outline">{title}</Badge>
  );
}

BadgeUserType.propTypes = {
  type: PropTypes.string.isRequired,
};

export default BadgeUserType;
