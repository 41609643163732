import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Div = styled.div.attrs({
  className: 'align-items-center',
})``;

const TextContainer = styled.div.attrs({
  className: 'ms-2',
})``;

const SecondaryText = styled.div.attrs({
  className: 'small',
})`
  opacity: 0.7;
`;

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - AvatarItem component
 */
function AvatarItem(props) {
  const {
    avatarImage, className, primaryText, secondaryText,
  } = props;

  return (
    <Div className={className}>
      <div>
        {avatarImage}
      </div>
      <TextContainer>
        <div>{primaryText}</div>
        <SecondaryText>{secondaryText}</SecondaryText>
      </TextContainer>
    </Div>
  );
}

AvatarItem.propTypes = {
  avatarImage: PropTypes.element.isRequired,
  className: PropTypes.string,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
};

AvatarItem.defaultProps = {
  className: 'd-inline-flex',
  primaryText: null,
  secondaryText: null,
};

export default AvatarItem;
