import {
  TARIFF_TYPE_ENERGY, TARIFF_TYPE_ENVIRONMENTAL, TARIFF_TYPE_NETWORK, TARIFF_TYPE_OTHER,
} from 'src/util/constants';

/**
 * Tariff type ordering, returning a value for sort prioritisation by type in an
 * opinionated order.
 * @function
 * @param {object} tariff - the tariff as per the GraphQL schema.
 * @param {string} tariff.tariffType – the type of tariff.
 * @returns {number} - tariff order
 */
const tariffTypeOrder = (tariff) => {
  const typesOrder = [
    TARIFF_TYPE_ENERGY, TARIFF_TYPE_ENVIRONMENTAL, TARIFF_TYPE_NETWORK, TARIFF_TYPE_OTHER,
  ];
  const index = typesOrder.indexOf(tariff.tariffType);
  return index === -1 ? typesOrder.length : index;
};

/**
 * Provides for sorting of tariffs
 * @function
 * @param {object} a - a tariff object.
 * @param {string} a.id – the tariff's UUID.
 * @param {string} a.tariffType – the type of tariff.
 * @param {object} b - a tariff object.
 * @param {string} b.id – the tariff's UUID.
 * @param {string} b.tariffType – the type of tariff.
 * @returns {object[]} An array of tariffs now ordered.
 */
const sortTariffs = (a, b) => {
  const orderA = tariffTypeOrder(a);
  const orderB = tariffTypeOrder(b);

  if (orderA !== orderB) {
    return (orderA - orderB);
  }

  return a.id > b.id ? 1 : -1;
};

/**
 * Provides for sorting of tariffs
 * @function
 * @param {object} a - a tariff object.
 * @param {string} a.id – the tariff's UUID.
 * @param {string} a.tariffType – the type of tariff.
 * @param {object} b - a tariff object.
 * @param {string} b.id – the tariff's UUID.
 * @param {string} b.tariffType – the type of tariff.
 * @returns {object[]} An array of tariffs now ordered.
 */
export default sortTariffs;
