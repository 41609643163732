import { ENOSI_USER_AUTH_RESET_PASSWORD_REQUEST } from 'src/util/constants';
import { fetchREST } from 'src/util/environment';
// import { commitMutation, graphql } from 'react-relay';

// const mutation = graphql`
//   mutation PasswordResetRequestMutation($email: String!) {
//     passwordResetRequest(email: $email)
//   }
// `;

const PasswordResetRequestMutation = (email, onCompleted, onError) => {
  const variables = { email };

  // commitMutation(environment, {
  //   mutation,
  //   variables,
  //   onCompleted,
  //   onError,
  //   // updater: (store) => {
  //   //   // Get the current user record from the store
  //   //   const ruleProxy = store.get(id);
  //   //   ruleProxy.setValue("TRADE_RULE_STATE_CANCELLED", "state");
  //   // },
  // });

  fetchREST(
    ENOSI_USER_AUTH_RESET_PASSWORD_REQUEST,
    JSON.stringify(variables),
    onCompleted,
    onError,
  );
};

export default PasswordResetRequestMutation;
