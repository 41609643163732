import { ENOSI_USER_AUTH_EMAIL_CONFIRM } from 'src/util/constants';
import { fetchREST } from 'src/util/environment';
// import { commitMutation, graphql } from 'react-relay';

// const mutation = graphql`
//   mutation EmailConfirmMutationMutation(
//     $email: String!
//     $newEmail: String!
//   ) {
//     passwordResetConfirm(
//       email: $email
//       newEmail: $newEmail
//       verificationCode: $verificationCode
//     )
//   }
// `;

const EmailConfirmMutation = (req, onCompleted, onError) => {
  const { email, newEmail, verificationCode } = req;
  const variables = { email, newEmail, verificationCode };

  // TODO: until we have a unauthenticated endpoint for auth
  // commitMutation(environment, {
  //   mutation,
  //   variables,
  //   onCompleted,
  //   onError,
  // });

  fetchREST(
    ENOSI_USER_AUTH_EMAIL_CONFIRM,
    JSON.stringify(variables),
    onCompleted,
    onError,
  );
};

export default EmailConfirmMutation;
