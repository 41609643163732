/**
 * @generated SignedSource<<b066a470823e32de72fdf6ae9dbc9d43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerPropertyLayout_property",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CustomerPropertyNavigation_property"
    }
  ],
  "type": "Property",
  "abstractKey": null
};

node.hash = "ff802b54b91740bfc3c26d89dcd52b1f";

module.exports = node;
