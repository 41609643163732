import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import Loading from 'src/components/Loading';
import convertEnergyPrice from 'src/util/conversions';
import { timeOfUseConditions, timeOfUseDetails, timeOfUseIsSimple } from 'src/util/timeOfUse';

class AdminMeterTradeRulesItemClause extends React.Component {
  static clauseConditions(clause) {
    const conditions = timeOfUseConditions(clause);
    if (conditions.length === 0) {
      return null;
    }
    return (<div>{`(${conditions.join(', ')})`}</div>);
  }

  static renderSimple(clause) {
    return (
      <div key={clause.id}>
        <strong>{convertEnergyPrice(clause.price).toFixed(3)}</strong>
        {' '}
        <abbr title="cents per kilowatt hour">c/kWh</abbr>
      </div>
    );
  }

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { clause, showConditions } = this.props;
    const { price } = clause;

    if (timeOfUseIsSimple(clause)) {
      return AdminMeterTradeRulesItemClause.renderSimple(clause);
    }

    return (
      <li key={clause.id}>
        <strong>{convertEnergyPrice(price).toFixed(3)}</strong>
        {' '}
        <abbr title="cents per kilowatt hour">c/kWh</abbr>
        {' '}
        {timeOfUseDetails(clause)}
        {showConditions && AdminMeterTradeRulesItemClause.clauseConditions(clause)}
      </li>
    );
  }
}

AdminMeterTradeRulesItemClause.propTypes = {
  clause: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  showConditions: PropTypes.bool,
};

AdminMeterTradeRulesItemClause.defaultProps = {
  showConditions: true,
};

export default createFragmentContainer(
  AdminMeterTradeRulesItemClause,
  {
    clause: graphql`
      fragment AdminMeterTradeRulesItemClause_clause on Clause {
        id
        price
        timezone
        ignorePublicHolidays
        ignoreDaylightSavings
        publicHolidayRegion
        monthsOfYear
        daysOfWeek
        timesOfDay {
          start {
            hours
            minutes
            seconds
          }
          finish {
            hours
            minutes
            seconds
          }
        }
      }
    `,
  },
);
