import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import {
  Button, ButtonGroup, ButtonToolbar, Dropdown, DropdownMenu, DropdownItem, DropdownToggle,
  Label, Input,
} from 'reactstrap';
import Chart from 'src/enosikit/components/Chart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDatabase, faDownload, faGaugeHigh, faTableCells,
} from '@fortawesome/free-solid-svg-icons';
import {
  CSV_FILE_HEADERS, DATA_METER_AGGREGATE, DATA_METER_DISCRETE, DATA_PACK, DOWNLOAD_SELECTOR,
  METER, TRADE, VIEW_SELECTOR, MIME_TYPE_CSV, MIME_TYPE_JSON,
} from 'src/util/constants';
import downloadHandler from './data/downloadHandler';
import { processCSVData, prepareCSVData } from './data/csvDataBuilder';
import { processDataPack } from './data/jsonDataBuilder';

const BtnGroupWrapper = styled(ButtonGroup)`
  {
    z-index: 9999;
  }
`;

const ViewWrapper = styled(DropdownToggle)`
{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
`;

const DownloadWrapper = styled(DropdownToggle)`
{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
`;

/**
 * Initiate csv or json data download
 * @param {Array<object>} data - property, meter or trade data
 * Data schema for meter - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838743569/CSV+meter+data+download
 * Data schema for trade - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838710828/CSV+trade+history+data+download
 * Data schema for data pack - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838743595/JSON+property+data+download
 * @param {string} type - meter, trade or data pack
 */
const downloadManager = (data, type) => {
  if (type === DATA_PACK) {
    const { data: jsonData, fileName } = processDataPack(data, type) || {};
    if (jsonData && JSON.stringify(jsonData)) {
      downloadHandler(JSON.stringify(jsonData), fileName, MIME_TYPE_JSON);
    }
    return;
  }

  const { data: finalCSVData, fileName } = processCSVData(data, type) || {};
  const headers = CSV_FILE_HEADERS[type];
  if (finalCSVData) {
    const csvdata = prepareCSVData(finalCSVData, headers);
    if (csvdata) {
      const finalFileName = `${fileName}.csv`;
      downloadHandler(csvdata, finalFileName, MIME_TYPE_CSV);
    }
  }
};

/**
 * Chart view selector dropdown
 * @param {boolean} dropdownOpen - flag to decide the status(close/open) of the dropdown
 * @param {Function} toggle
 * @param {string} chartView - current view (aggregate/meter) of the chart
 * @param {Function} setChartView
 * @returns {React.ReactElement} - chart view dropdown.
 */
const chartViewSelector = (dropdownOpen, toggle, chartView, setChartView) => {
  const aggregatedView = chartView === DATA_METER_AGGREGATE;
  const meterView = chartView === DATA_METER_DISCRETE;
  return (
    <Dropdown className="chart-view-selector" isOpen={dropdownOpen} toggle={() => toggle(VIEW_SELECTOR)}>
      <ViewWrapper className="btn btn-darken" caret><FontAwesomeIcon icon={faGaugeHigh} size="1x" className="me-2" /></ViewWrapper>
      <DropdownMenu right>
        <DropdownItem header>Aggregation</DropdownItem>
        <DropdownItem onClick={() => setChartView(DATA_METER_AGGREGATE)}>
          <Label>
            <Input className="me-1" checked={aggregatedView} type="radio" name="chartview" />
            Aggregated
          </Label>
        </DropdownItem>
        <DropdownItem onClick={() => setChartView(DATA_METER_DISCRETE)}>
          <Label>
            <Input className="me-1" type="radio" checked={meterView} name="chartview" />
            By meter
          </Label>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

/**
 * File download dropdown
 * @param {boolean} dropdownOpen - flag to decide the status(close/open) of the dropdown
 * @param {Function} toggle
 * @param {object} property
 * @returns {React.ReactElement} - file download dropdown.
 */
const fileDownloadSelector = (dropdownOpen, toggle, property) => {
  const { meters } = property;
  return (
    <Dropdown className="download-type-selector" isOpen={dropdownOpen} toggle={() => toggle(DOWNLOAD_SELECTOR)}>
      <DownloadWrapper className="btn btn-darken" caret><FontAwesomeIcon icon={faDownload} size="1x" className="me-2" /></DownloadWrapper>
      <DropdownMenu right>
        <DropdownItem onClick={() => downloadManager(meters, METER)}>
          <Label>
            <FontAwesomeIcon icon={faTableCells} size="1x" className="me-2" />
            Meter data (.csv)
          </Label>
        </DropdownItem>
        <DropdownItem onClick={() => downloadManager(meters, TRADE)}>
          <Label>
            <FontAwesomeIcon icon={faTableCells} size="1x" className="me-2" />
            Trade data (.csv)
          </Label>
        </DropdownItem>
        <DropdownItem onClick={() => downloadManager(property, DATA_PACK)}>
          <Label>
            <FontAwesomeIcon icon={faDatabase} size="1x" className="me-2" />
            Data pack (.json)
          </Label>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

/**
 *
 * @param {any} root0
 * @param {any} root0.property
 * @param {any} root0.timespan
 * @param {any} root0.timespanUpdateFunc
 * @param {any} root0.controlOptionFunc
 * @param {any} root0.controlSetStateFunc
 * @param {any} root0.viewSelectorOpen
 * @param {any} root0.setChartView
 * @param {any} root0.chartView
 * @param {any} root0.downloadSelectorOpen
 * @param {any} root0.toggle
 * @returns {React.ReactComponentElement} - PropertyShowControl component
 */
function PropertyShowControl({
  property, timespan, timespanUpdateFunc,
  controlOptionFunc, controlSetStateFunc,
  viewSelectorOpen, setChartView, chartView,
  downloadSelectorOpen, toggle,
}) {
  const [start, setStart] = useState(timespan.start);
  const [finish, setFinish] = useState(timespan.finish);
  const [myRef, setMyRef] = useState(false);
  const [apply, setApply] = useState(false);

  const openCalendar = () => {
    const { start: prevStart, finish: prevFinish } = timespan;
    setStart(prevStart);
    setFinish(prevFinish);
    setApply(false);
  };
  const closeCalendar = () => {
    setApply(true);
    myRef.setOpen(false);
  };

  const timeSpanButtonLabel = (s, f) => {
    // If no finish ... erroneous ... return empty
    if (f === null) { return ''; }

    // If it's for a single day, display a single day
    if (s.toSeconds() === f.toSeconds()) { return s.toFormat('DD'); }

    // Otherwise let's make a nice label
    let startFormat = 'DD';
    if (s.month === f.month) {
      startFormat = 'd';
    } else if (s.year === f.year) {
      startFormat = 'MMM d';
    }

    return [s.toFormat(startFormat), f.toFormat('DD')].flat().join(' - ');
  };

  const setTimeSpan = () => {
    if (!apply) { return; }

    const { start: prevStart, finish: prevFinish } = timespan;

    if (start === null) { setStart(prevStart); }
    if (finish === null) { setFinish(start); }

    // No change, nothing to see here...
    if (start.toSeconds() === prevStart.toSeconds()
      && (finish !== null && finish.toSeconds() === prevFinish.toSeconds())) {
      return;
    }

    timespanUpdateFunc(start, (finish === null ? start : finish));
  };

  const updateStartAndFinish = (dates) => {
    const [newStartJS, newFinishJS] = dates;
    const newStart = DateTime.fromJSDate(newStartJS);
    const newFinish = newFinishJS ? DateTime.fromJSDate(newFinishJS) : null;

    const noRanges = !start && !finish;
    const hasStartRange = start && !finish;
    const isRangeFilled = start && finish;
    if (noRanges || isRangeFilled) {
      setStart(newStart);
      setFinish(newFinish);
    } else if (hasStartRange) {
      if (newStart < start) {
        setStart(newStart);
        setFinish(newFinish);
      } else {
        setStart(newStart);
        setFinish(newFinish);
      }
    }
  };

  const renderTimeSpanPicker = (onChangeFunc, onCloseFunc) => (
    <DatePicker
      ref={(r) => { setMyRef(r); }}
      value={timeSpanButtonLabel(timespan.start, timespan.finish)}
      selected={start && !finish ? start.toJSDate() : null}
      startDate={start ? start.toJSDate() : null}
      endDate={finish ? finish.toJSDate() : null}
      maxDate={start && !finish ? start.plus({ days: 30 }).toJSDate() : null}
      onChange={onChangeFunc}
      onCalendarClose={onCloseFunc}
      onCalendarOpen={openCalendar}
      customInput={<TimeSpanButton />}
      monthsShown={2}
      selectsRange
      shouldCloseOnSelect={false}
      popperPlacement="bottom-end"
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: 'viewport',
        },
      }}
    >
      <div
        style={{
          clear: 'both',
          textAlign: 'right',
          borderTop: '1px solid #ccc',
          padding: '1em',
        }}
      >
        <button className="btn btn-primary" type="button" onClick={closeCalendar}>Apply</button>
      </div>
    </DatePicker>
  );

  if (!property || property === undefined) {
    return (
      null
    );
  }

  if (!timespan || timespan === undefined) {
    return (null);
  }

  return (
    <ButtonToolbar>

      <BtnGroupWrapper className="ms-2 mb-2">
        {renderTimeSpanPicker(updateStartAndFinish, setTimeSpan)}
      </BtnGroupWrapper>

      <Chart.ChartControls
        buttons={controlOptionFunc()}
        onButtonClick={(opts) => { controlSetStateFunc(opts); }}
      />
      <ButtonGroup>
        {chartViewSelector(viewSelectorOpen, toggle, chartView, setChartView)}
        {fileDownloadSelector(downloadSelectorOpen, toggle, property)}
      </ButtonGroup>

    </ButtonToolbar>
  );
}

PropertyShowControl.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.string,
    meters: PropTypes.instanceOf(Array),
  }).isRequired,
  timespan: PropTypes.shape({
    start: PropTypes.instanceOf(DateTime),
    finish: PropTypes.instanceOf(DateTime),
  }).isRequired,
  timespanUpdateFunc: PropTypes.func.isRequired,
  controlOptionFunc: PropTypes.func.isRequired,
  controlSetStateFunc: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  setChartView: PropTypes.func.isRequired,
  chartView: PropTypes.string.isRequired,
  viewSelectorOpen: PropTypes.bool.isRequired,
  downloadSelectorOpen: PropTypes.bool.isRequired,
};

// Ref: <https://github.com/Hacker0x01/react-datepicker/issues/862>
//
// eslint-disable-next-line react/prefer-stateless-function
class TimeSpanButton extends React.Component {
  // TODO: smatter output formatting of the range. For example:
  // - Same month: "20 - 25 Jun 2020",
  // - Same year: "20 Jun - 4 Jul 2020",
  // - Otherwise "19 Dec 2019 - 18 Jan 2020")  render() {
  render() {
    const {
      disabled,
      onClick,
      value,
    } = this.props;

    return (
      <Button className="btn btn-darken" onClick={() => (onClick())} disabled={disabled}>
        {value}
      </Button>
    );
  }
}

TimeSpanButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.string,
};
TimeSpanButton.defaultProps = {
  disabled: false,
  onClick: null,
  value: 'loading...',
};

export default PropertyShowControl;

export { TimeSpanButton };
