import { Auth } from 'aws-amplify';

// A 'global' stateful object to hold state to be exported on spans
// TODO: replace? when Open Telemetry allows attach/detach on context
// https://github.com/open-telemetry/opentelemetry-js/discussions/2398
// eslint-disable-next-line func-names
const tracingState = (function () {
  const anonymousUser = 'anonymous';
  const unknownProperty = '';
  const state = {
    userID: '',
    propertyID: '',
  };

  /**
   *
   */
  function clearUserID() {
    state.userID = anonymousUser;
  }

  /**
   * Description
   * @param {any} userID - suser id
   */
  function setUserID(userID) {
    if (!userID || userID === '') {
      clearUserID();
      return;
    }
    state.userID = userID;
  }

  /**
   * Description
   * @returns {string} - user id
   */
  function getUserID() {
    return state.userID;
  }

  /**
   * Description
   * @returns {boolean} - is user an anonymous user
   */
  function isUserUnknown() {
    return getUserID() === anonymousUser;
  }

  /**
   *
   */
  function clearPropertyID() {
    state.propertyID = unknownProperty;
  }

  /**
   *
   * @param {string} propertyID
   */
  function setPropertyID(propertyID) {
    if (!propertyID || propertyID === '') {
      clearPropertyID();
    }
    state.propertyID = propertyID;
  }

  /**
   * Description
   * @returns {string} - property id.
   */
  function getPropertyID() {
    return state.propertyID;
  }

  /**
   * Description
   * @returns {boolean} - is property unknown.
   */
  function isPropertyUnknown() {
    return getPropertyID() === unknownProperty;
  }

  // Used for testing
  let lastSpan;
  /**
   * Description
   * @returns {any} - lastSpan.
   */
  function getLastSpan() {
    return lastSpan;
  }

  /**
   *
   */
  function reset() {
    clearUserID();
    clearPropertyID();
    lastSpan = null;
  }

  /**
   * Description
   * @param {any} span
   * @returns {void}
   */
  function spanSetAttributes(span) {
    const userID = getUserID();
    if (userID) {
      span.setAttribute('syndesi.user_id', userID);
    }
    const propertyID = getPropertyID();
    if (propertyID) {
      span.setAttribute('syndesi.property_id', propertyID);
    }
  }

  /**
   * Description
   * @param {any} span
   * @returns {Promise} - promise
   */
  function addToSpan(span) {
    lastSpan = span;
    return new Promise((resolve) => {
      if (isUserUnknown()) {
        Auth.currentAuthenticatedUser({
          bypassCache: false,
        })
          .then((user) => {
            const { attributes } = user;
            if (attributes) {
              const userID = attributes['custom:id'] || anonymousUser;
              if (userID) {
                setUserID(userID);
                spanSetAttributes(span);
              }
            }
          })
          .catch((err) => {
            span.recordException(err);
          })
          .finally(() => spanSetAttributes(span));
      } else {
        spanSetAttributes(span);
      }
      resolve();
    });
  }

  reset();
  return {
    reset,
    clearUserID,
    setUserID,
    getUserID,
    isUserUnknown,
    clearPropertyID,
    setPropertyID,
    getPropertyID,
    isPropertyUnknown,
    addToSpan,
    getLastSpan,
  };
}());

export {
  // eslint-disable-next-line import/prefer-default-export
  tracingState,
};
