import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import Address from 'src/components/Address';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import { allTariffs } from 'src/util/meterTariff';
import TariffInfo from '../TariffInfo';

class TariffList extends React.Component {
  renderPropertyControls = () => {
    const { property } = this.props;
    return (
      <ButtonToolbar>
        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.id}/settings`} className="btn btn-darken">
            Property Settings
          </Link>
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { property } = this.props;
    const {
      id, address, title, meters,
    } = property;

    const addr = new Address(address);

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Enosi - Property: ${title} - Tariffs`}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: 'Properties', path: '/properties' },
            { name: title, path: `/properties/${id}` },
            { name: 'Tariffs' },
          ]}
        />
        <Heading
          alias={title}
          address={addr.simpleString()}
          context="Tariffs"
          controls={this.renderPropertyControls()}
        />
        <p>Your electricity tariffs are listed here and are GST exclusive.</p>

        <div className="property-all-tariffs-list">
          {meters.edges.map((el) => el.node).map((meter) => (
            <>
              <hr />
              <div key={meter.id}>
                <h3>
                  {meter.title}
                  <br />
                  <small>{meter.identifier}</small>
                </h3>

                {meter.tariffs && meter.tariffs.edges
                  && allTariffs(meter.tariffs.edges).map((tariff) => (
                    <TariffInfo tariff={tariff} key={tariff.id} />
                  ))}
              </div>
            </>
          ))}
        </div>
      </>
    );
  }
}

TariffList.propTypes = {
  property: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default createFragmentContainer(
  TariffList,
  {
    property: graphql`
      fragment TariffList_property on Property {
        id
        title
        address {
          line1
          line2
          city
          state
          country
          postcode
        }
        meters(first:500) {
          edges {
            node {
              id
              identifier
              title
              tariffs(first:500) {
                edges {
                  node {
                    id
                    identifier
                    tariffType
                    title
                    active { start finish }
                    ...TariffInfo_tariff
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
);
