import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import React from 'react';

import AnonymousNavigation from 'src/components/Navigation/AnonymousNavigation';
import Footer from 'src/enosikit/components/Footer';
import Flashes from 'src/components/Flashes';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - HTTP404 component
 */
function HTTP404(props) {
  const { children } = props;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Enosi - Login to get started</title>
      </Helmet>

      <div className="flex flex-column" style={{ minHeight: '80vh' }}>
        <AnonymousNavigation />
        <Flashes />
        <div className="flex-auto">
          <div className="container text-center">
            <h1>Not found</h1>
            <p>We are not sure where we are...</p>
            {children}
          </div>
        </div>
        <Footer versionUI={VERSION} />
      </div>
    </>
  );
}

HTTP404.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

HTTP404.defaultProps = {
  children: null,
};

export default HTTP404;
