/**
 * @generated SignedSource<<c3e3380521ad6f5f2885fb24f0e821ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_CustomerPropertyRoot_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CustomerPropertyLayout_viewer"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Property",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CustomerPropertyLayout_property"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_CustomerPropertyRoot_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewerUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "givenName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "familyName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "proposals",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10
              },
              {
                "kind": "Literal",
                "name": "state",
                "value": "TRADE_RULE_STATE_PROPOSED"
              },
              {
                "kind": "Literal",
                "name": "type",
                "value": "TRADE_TYPE_NOMINATED"
              }
            ],
            "concreteType": "TradeRuleConnection",
            "kind": "LinkedField",
            "name": "rules",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeRuleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TradeRule",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "rules(first:10,state:\"TRADE_RULE_STATE_PROPOSED\",type:\"TRADE_TYPE_NOMINATED\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PropertyConnection",
            "kind": "LinkedField",
            "name": "properties",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PropertyEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Property",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Property",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Community",
            "kind": "LinkedField",
            "name": "community",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b9729e6d4d35717b6d409d94dbdfd711",
    "id": null,
    "metadata": {},
    "name": "routes_CustomerPropertyRoot_Query",
    "operationKind": "query",
    "text": "query routes_CustomerPropertyRoot_Query(\n  $id: ID!\n) {\n  viewer {\n    ...CustomerPropertyLayout_viewer\n    id\n  }\n  property(id: $id) {\n    ...CustomerPropertyLayout_property\n    id\n  }\n}\n\nfragment CustomerPropertyLayout_property on Property {\n  title\n  ...CustomerPropertyNavigation_property\n}\n\nfragment CustomerPropertyLayout_viewer on Viewer {\n  id\n  viewerUser {\n    id\n    type\n  }\n  ...CustomerPropertyNavigation_viewer\n  properties {\n    ...CustomerPropertyNavigation_properties\n  }\n}\n\nfragment CustomerPropertyNavigation_properties on PropertyConnection {\n  edges {\n    node {\n      id\n      title\n    }\n  }\n}\n\nfragment CustomerPropertyNavigation_property on Property {\n  id\n  title\n  community {\n    id\n    title\n  }\n}\n\nfragment CustomerPropertyNavigation_viewer on Viewer {\n  id\n  viewerUser {\n    email\n    givenName\n    familyName\n    type\n    id\n  }\n  proposals: rules(first: 10, type: TRADE_TYPE_NOMINATED, state: TRADE_RULE_STATE_PROPOSED) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "5311e791944b06a7bd6712401aef80e1";

module.exports = node;
