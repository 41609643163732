import React from 'react';
import { DateTime } from 'luxon';
import BadgeTradePointType from 'src/components/Badge/BadgeTradePointType';
import UUID from 'src/components/UUID';
import {
  BUY_LABEL, SELL_LABEL, TRADE_DIRECTION_BUY, TRADE_POINT_TYPE_COMMUNITY,
  TRADE_POINT_TYPE_RESIDUAL, PLATFORM_MODE_REBATE,
} from 'src/util/constants';
import {
  Badge, CardText,
} from 'reactstrap';
import { APIConfig } from 'src/config';
import username from 'src/util/decorators/username';
import convertEnergyPrice from 'src/util/conversions';
import { tradeRulePriceRange } from 'src/util/tradeRule';

/**
 * Delivers a link to a property for a trade point, returning null if no link can be generated.
 * @param {object} tradePoint
 * @param {boolean} isPopover
 * @returns {React.ReactElement} - property link.
 */
export const getPropertyLink = (tradePoint, isPopover) => {
  const property = tradePoint?.meter?.property;
  if (!property) {
    return null;
  }

  const { id, title } = property;
  const label = !title ? id : title;

  if (!label || label === '') {
    return null;
  }

  if (isPopover) {
    return <strong>{label}</strong>;
  }

  if (id) {
    const redirectLink = `/properties/${id}`;

    return (
      <span>
        <a href={redirectLink}>{label}</a>
      </span>
    );
  }

  return <span>{label}</span>;
};

/**
 * Build a trade point label for inline use.
 * @param {object} tradePoint
 * @returns {React.ReactElement} - trade point label.
 */
export const getTradePointInlineLabel = (tradePoint) => {
  if (!tradePoint) { return null; }

  const { id, meter, type } = tradePoint;

  const idLabel = !id ? null : (
    <>
      (
      <UUID uuid={id} />
      )
    </>
  );

  if (type === TRADE_POINT_TYPE_RESIDUAL) {
    return (
      <>
        <span className="trade-point-title">
          Retailer
        </span>
        {' '}
        {idLabel}
      </>
    );
  }

  if (type === TRADE_POINT_TYPE_COMMUNITY) {
    return (
      <>
        <span className="trade-point-title">
          Community
        </span>
        {' '}
        {idLabel}
      </>
    );
  }

  if (!meter) {
    return (
      <>
        <span className="trade-point-title">
          Trade Point
        </span>
        {idLabel && ' '}
        {idLabel}
      </>
    );
  }

  const { title, identifier } = meter;
  const propertyLink = getPropertyLink(tradePoint);

  return (
    <>
      <span className="trade-point-title">{title}</span>
      {` (${identifier}) `}
      {propertyLink && ' at '}
      {propertyLink}
      {idLabel && ' '}
      {idLabel}
    </>
  );
};

/**
 * Build a trade point label for the trade rule table.
 * @param {object} tradePoint
 * @returns {React.ReactElement} - trade point label.
 */
export const getTradePointTradeRuleTableLabel = (tradePoint) => {
  if (!tradePoint) { return null; }

  const { id, meter, type } = tradePoint;
  const idLabel = !id ? null : (
    <>
      (
      <UUID uuid={id} />
      )
    </>
  );

  if (type === TRADE_POINT_TYPE_RESIDUAL) {
    return (
      <>
        <strong>Retailer</strong>
        <br />
        {idLabel}
        <br />
        <BadgeTradePointType type={type} />
      </>
    );
  }

  if (type === TRADE_POINT_TYPE_COMMUNITY) {
    return (
      <>
        <strong>Community</strong>
        <br />
        {idLabel}
        <br />
        <BadgeTradePointType type={type} />
      </>
    );
  }

  if (!meter) {
    return (
      <>
        <strong>Trade Point</strong>
        <br />
        {idLabel}
        <br />
        <BadgeTradePointType type={type} />
      </>
    );
  }

  const { title, identifier } = meter;
  const propertyLink = getPropertyLink(tradePoint);

  return (
    <>
      <strong>{title}</strong>
      {` (${identifier}) `}
      <br />
      {propertyLink}
      <br />
      {idLabel}
      <br />
      <BadgeTradePointType type={type} />
    </>
  );
};

/**
 * Build a label for a trade rule meter.
 * @param {object} trader
 * @param {object} counterparty
 * @param {string} direction
 * @param {boolean} isPopover
 * @returns {React.ReactElement} - trade rule meter label.
 */
export const getTradeRuleMeterLabel = (trader, counterparty, direction, isPopover) => {
  if (!trader) {
    return null;
  }

  const { tradePoint } = trader;
  const { id: tradePointId, meter } = tradePoint || {};
  const { id: counterpartyTradepointId } = counterparty?.tradePoint || {};

  const counterPartyCheck = tradePointId === counterpartyTradepointId;
  const finalCounterparty = counterPartyCheck ? '' : counterparty;

  const { title, identifier } = meter || {};
  const propertyLink = getPropertyLink(tradePoint, isPopover);
  const {
    title: counterpartyTitle,
    identifier: counterpartyIdentifier,
  } = finalCounterparty?.tradePoint?.meter || {};
  const { tradePoint: counterpartyTradePoint } = finalCounterparty || {};
  const counterpartyPropertyLabel = getPropertyLink(counterpartyTradePoint, isPopover);
  const counterpartyMeterExist = counterpartyTitle && counterpartyIdentifier;
  const directionJoinLabel = direction === TRADE_DIRECTION_BUY ? 'from' : 'to';
  const counterpartyPropertyJoinLabel = counterpartyMeterExist ? 'at' : directionJoinLabel;

  return (
    <>
      {title && <span>{title}</span>}
      {identifier && <span>{` (${identifier})`}</span>}
      {propertyLink && ' at '}
      {propertyLink}
      {counterpartyMeterExist && ` ${directionJoinLabel} ${counterpartyTitle} (${counterpartyIdentifier}) `}
      {counterpartyPropertyLabel && `${counterpartyPropertyJoinLabel} `}
      {counterpartyPropertyLabel}
      <br />
    </>
  );
};

/**
 * Provide the action label for trade rule pages.
 * @param {string} direction
 * @returns {string} - trade rule label.
 */
export const getTradeRuleActionLabel = (direction) => {
  const finalLabel = direction === TRADE_DIRECTION_BUY ? BUY_LABEL : SELL_LABEL;
  return finalLabel;
};

/**
 * Build the content that needs to be shown inside the trade popover.
 * @param {string} direction
 * @param {object} tradeRule
 * @returns {React.ReactElement} - trade rule content.
 */
export const getTradeRuleContent = (direction, tradeRule) => {
  if (!direction || !tradeRule) {
    return null;
  }
  const {
    seller, buyer, acceptedAt, acceptedBy, proposedAt,
    proposedBy, id: ruleId,
  } = tradeRule || {};
  const isBuy = direction === TRADE_DIRECTION_BUY;
  const trader = isBuy ? buyer : seller;
  const counterParty = isBuy ? seller : buyer;
  const priceRange = tradeRulePriceRange(tradeRule);
  const { maximum, minimum } = priceRange;

  let priceLabel;
  if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
    priceLabel = 'Discount of: ';
  } else if (isBuy) {
    priceLabel = 'Buy price: ';
  } else {
    priceLabel = 'Sell price: ';
  }

  return (
    <CardText>
      {getTradeRuleActionLabel(direction)}
      {' '}
      {getTradeRuleMeterLabel(trader, counterParty, direction, true)}
      Proposed by
      {' '}
      <strong>{username(proposedBy)}</strong>
      {' '}
      on
      {' '}
      {DateTime.fromSeconds(proposedAt).toFormat('dd LLL yyyy')}
      <br />
      {acceptedAt && (
        <>
          Accepted by
          {' '}
          <strong>{username(acceptedBy)}</strong>
          {' '}
          on
          {' '}
          {DateTime.fromSeconds(acceptedAt).toFormat(
            'dd LLL yyyy',
          )}
        </>
      )}
      <br />
      {priceLabel}
      <strong>
        {convertEnergyPrice(minimum).toFixed(3)}
      </strong>
      {maximum !== minimum && (
        <>
          {' '}
          to
          {' '}
          <strong>
            {convertEnergyPrice(maximum).toFixed(3)}
          </strong>
        </>
      )}
      {' '}
      <abbr title="cents per kilowatt hour">c/kWh</abbr>
      {' '}
      <Badge color="mid">{ruleId}</Badge>
    </CardText>
  );
};

/**
 * Returns a class name when there are no trade rules.
 * Used for targeting components while launching product tours
 * @param {object} tradeRules
 * @param {string} tradeType
 * @returns {string} - class name.
 */
export const emptyTradeRulesClassName = (tradeRules, tradeType) => {
  if (tradeRules && tradeType) {
    const { buy, sell } = tradeRules;
    if (buy?.length === 0 && sell?.length === 0) {
      return ` no-trade-rules-${tradeType}`;
    }
  }
  return '';
};

/**
 * Returns a class name when there are any buy trade rule to prioritize.
 * Used for targeting components while launching product tours
 * @param {object} tradeRules
 * @param {string} tradeType
 * @returns {string} - class name.
 */
export const prioritizeTradesClassName = (tradeRules, tradeType) => {
  if (tradeRules && tradeType) {
    const { buy } = tradeRules;
    if (buy?.length > 0) {
      return ` prioritize-trade-rules-${tradeType}`;
    }
  }
  return '';
};
