import { Link } from 'found';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Alert, Badge, Button, Row, Col,
  Card, CardBody, CardFooter, CardTitle, CardSubtitle, CardText,
  UncontrolledButtonDropdown, DropdownMenu, DropdownToggle,
} from 'reactstrap';

import { APIConfig } from 'src/config';
import CloseNominatedTradeMutation from 'src/mutations/CloseNominatedTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';
import isActive from 'src/util/isActive';
import {
  NOMINATED, PLATFORM_MODE_REBATE, TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL,
  TRADE_TYPE_NOMINATED, TRADE_RULE_CANCEL, TRADE_RULE_CLOSE,
  TRADE_RULE_STATE_PROPOSED, TRADE_RULE_STATE_CANCELLED, TRADE_RULE_STATE_REJECTED,
  TRADE_RULE_STATE_ACCEPTED, TRADE_RULE_STATE_CLOSED,
} from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import { tradeRulePriceRange } from 'src/util/tradeRule';
import username from 'src/util/decorators/username';
import { emptyTradeRulesClassName, getTradeRuleMeterLabel, prioritizeTradesClassName } from 'src/helpers/tradeHelpers';

class TradeRuleActiveNominated extends React.Component {
  static tradeRulesForProperty(property) {
    const { meters } = property;
    const rules = { sell: [], buy: [] };

    meters.edges.forEach((meterEdge) => {
      if (!!meterEdge && !!meterEdge.node && !!meterEdge.node.nominatedRules) {
        meterEdge.node.nominatedRules.edges.forEach((ruleEdge) => {
          const { node: ruleNode } = ruleEdge;
          if (
            ruleNode.state !== TRADE_RULE_STATE_ACCEPTED
            || ruleNode.tradeType !== TRADE_TYPE_NOMINATED
          ) {
            return;
          }

          if (ruleNode.seller.tradePoint.id === meterEdge.node.tradePointId) {
            rules.sell.push({ ...ruleNode, meter: meterEdge.node });
          } else {
            rules.buy.push({ ...ruleNode, meter: meterEdge.node });
          }
        });
      }
    });

    const compareRules = (ruleA, ruleB) => {
      if (ruleA.meter.title !== ruleB.meter.title) {
        return ruleA.meter.title.localeCompare(ruleB.meter.title);
      }
      if (ruleA.priority !== ruleB.priority) {
        return ruleA.priority - ruleB.priority;
      }
      return ruleA.acceptedAt - ruleB.acceptedAt;
    };
    rules.sell.sort(compareRules);
    rules.buy.sort(compareRules);

    return rules;
  }

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleNominatedTradeMutationFailure = (error) => {
    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleCloseNominatedTradeMutationSuccess = (response) => {
    const { id } = response.closeNominatedTrade;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      `Closed peer-to-peer trade rule with id: ${id}.`,
    );
  };

  tradeRuleAction = (rule, action) => {
    const { id } = rule;
    switch (action) {
      case TRADE_RULE_CLOSE:
        CloseNominatedTradeMutation(
          id,
          this.handleCloseNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
        );
        return;
      default:
        FlashesStore.flash(
          FlashesStore.ERROR,
          `Action ${action} is not possible`,
        );
    }
  };

  tradeRuleActionButton = (rule) => {
    const {
      state, meter, buyer, seller,
    } = rule;

    if (
      [
        TRADE_RULE_STATE_PROPOSED,
        TRADE_RULE_STATE_CANCELLED,
        TRADE_RULE_STATE_REJECTED,
        TRADE_RULE_STATE_CLOSED,
      ].includes(state)
    ) {
      return null;
    }

    if (state === TRADE_RULE_STATE_ACCEPTED) {
      return (
        <Button onClick={() => this.tradeRuleAction(rule, TRADE_RULE_CLOSE)}>
          Close agreed trade
        </Button>
      );
    }

    if (
      [buyer.tradePoint.id, seller.tradePoint.id].includes(meter.tradePointId)
    ) {
      return (
        <Button onClick={() => this.tradeRuleAction(rule, TRADE_RULE_CANCEL)}>
          Cancel agreed trade
        </Button>
      );
    }

    return (
      <Alert color="danger">
        {`Unknown trade point: ${meter.id} when buyer is ${buyer.tradePoint.id} and seller is ${seller.tradePoint.id}`}
      </Alert>
    );
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <div>Loading...</div>;
    }

    const { property } = this.props;
    const { id: propertyId } = property;
    const tradeRules = TradeRuleActiveNominated.tradeRulesForProperty(property);
    const noTradeRulesClass = emptyTradeRulesClassName(tradeRules, NOMINATED);
    const prioritizeTradesClass = prioritizeTradesClassName(tradeRules, NOMINATED);

    return (
      <Card className={`mt-4 mb-4 trade-rules-nominated${noTradeRulesClass}`}>
        <CardBody>
          <h2>Peer-to-Peer Trades</h2>
          <p>
            Enosi is best experienced when you trade energy with the people
            you know. We call this type of trade a peer-to-peer trade.
          </p>
          <Row>
            <Col xs="12" sm="12" md="6">
              <h3>Selling</h3>
              <p>
                {'You have '}
                <strong>{tradeRules.sell ? tradeRules.sell.length : 0}</strong>
                {' peer-to-peer trade rules to sell energy.'}
              </p>

              {tradeRules.sell && tradeRules.sell.map((rule) => {
                const {
                  acceptedAt, acceptedBy, buyer, id: ruleId, meter,
                  priority, proposedAt, proposedBy, seller,
                } = rule;
                const { title } = meter;
                const priceRange = tradeRulePriceRange(rule);
                const { maximum, minimum } = priceRange;
                let priceLabel;
                let tradePointTitle;
                if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
                  priceLabel = 'Discount of: ';
                  tradePointTitle = title;
                } else {
                  priceLabel = 'Sell price: ';
                  tradePointTitle = title;
                }

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${ruleId}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        {`Priority ${priority} for ${tradePointTitle}`}
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        Approved
                      </CardSubtitle>
                      <CardText>
                        Selling from
                        {' '}
                        {getTradeRuleMeterLabel(seller, buyer, TRADE_DIRECTION_SELL)}
                        Proposed by
                        {' '}
                        <strong>{username(proposedBy)}</strong>
                        {' '}
                        on
                        {' '}
                        {DateTime.fromSeconds(proposedAt).toFormat('dd LLL yyyy')}
                        <br />
                        {acceptedAt && (
                          <>
                            Accepted by
                            {' '}
                            <strong>{username(acceptedBy)}</strong>
                            {' '}
                            on
                            {' '}
                            {DateTime.fromSeconds(acceptedAt).toFormat(
                              'dd LLL yyyy',
                            )}
                          </>
                        )}
                        <br />
                        {priceLabel}
                        <strong>
                          {convertEnergyPrice(minimum).toFixed(3)}
                        </strong>
                        {maximum !== minimum && (
                          <>
                            {' '}
                            to
                            {' '}
                            <strong>
                              {convertEnergyPrice(maximum).toFixed(3)}
                            </strong>
                          </>
                        )}
                        {' '}
                        <abbr title="cents per kilowatt hour">c/kWh</abbr>
                        {' '}
                        (
                        <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}>further details</Link>
                        )
                        <br />
                        <Link
                          to={`/properties/${propertyId}/trade-rules/${ruleId}`}
                        >
                          <Badge color="mid">{ruleId}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                    <CardFooter>{this.tradeRuleActionButton(rule)}</CardFooter>
                  </Card>
                );
              })}
            </Col>

            <Col xs="12" sm="12" md="6">
              <h3>Buying</h3>
              <p>
                {'You have '}
                <strong>{tradeRules.buy ? tradeRules.buy.length : 0}</strong>
                {' peer-to-peer trade rules to buy energy.'}
              </p>

              {tradeRules.buy && tradeRules.buy.map((rule) => {
                const {
                  acceptedAt, acceptedBy, buyer, id: ruleId, meter,
                  priority, proposedAt, proposedBy, seller,
                } = rule;
                const { title } = meter;
                const priceRange = tradeRulePriceRange(rule);
                const { maximum, minimum } = priceRange;

                let priceLabel;
                let tradePointTitle;
                if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
                  priceLabel = 'Discount of: ';
                  tradePointTitle = title;
                } else {
                  priceLabel = 'Buy price: ';
                  tradePointTitle = title;
                }

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${ruleId}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        {`Priority ${priority} for ${tradePointTitle}`}
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        Approved
                      </CardSubtitle>
                      <CardText>
                        Buying for
                        {' '}
                        {getTradeRuleMeterLabel(buyer, seller, TRADE_DIRECTION_BUY)}
                        Proposed by
                        {' '}
                        <strong>{username(proposedBy)}</strong>
                        {' '}
                        on
                        {' '}
                        {DateTime.fromSeconds(proposedAt).toFormat('dd LLL yyyy')}
                        <br />
                        {acceptedAt && (
                          <>
                            Accepted by
                            {' '}
                            <strong>{username(acceptedBy)}</strong>
                            {' '}
                            on
                            {' '}
                            {DateTime.fromSeconds(acceptedAt).toFormat('dd LLL yyyy')}
                          </>
                        )}
                        <br />
                        {priceLabel}
                        <strong>
                          {convertEnergyPrice(minimum).toFixed(3)}
                        </strong>
                        {maximum !== minimum && (
                          <>
                            {' '}
                            to
                            {' '}
                            <strong>
                              {convertEnergyPrice(maximum).toFixed(3)}
                            </strong>
                          </>
                        )}
                        {' '}
                        <abbr title="cents per kilowatt hour">c/kWh</abbr>
                        {' '}
                        (
                        <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}>further details</Link>
                        )
                        <br />
                        <Link
                          to={`/properties/${propertyId}/trade-rules/${ruleId}`}
                        >
                          <Badge color="mid">{ruleId}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                    <CardFooter>{this.tradeRuleActionButton(rule)}</CardFooter>
                  </Card>
                );
              })}
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Link to={`/properties/${property.id}/trade-rules`}>
            <Button color="primary" className="me-2">
              View all trade rules
            </Button>
          </Link>
          <Link
            to={`/properties/${property.id}/trade-rules/propose/nominated`}
          >
            <Button color="primary" className="me-2">
              Propose new trade
            </Button>
          </Link>
          <UncontrolledButtonDropdown className="me-2">
            <DropdownToggle className={prioritizeTradesClass} caret color="primary">
              Manage priority order
            </DropdownToggle>
            <DropdownMenu>
              {property.meters.edges.map((edge) => edge && edge.node && (
                <Link
                  to={`/properties/${property.id}/meters/${edge.node.id}/trade-rules/nominated/prioritize`}
                  className={`dropdown-item ${!isActive(edge.node.active) ? 'disabled' : ''}`}
                  role="menuitem"
                  key={edge.node.id}
                >
                  {edge.node.title}
                </Link>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </CardFooter>
      </Card>
    );
  }
}

TradeRuleActiveNominated.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TradeRuleActiveNominated.defaultProps = {
  property: null,
};

export default createFragmentContainer(
  TradeRuleActiveNominated,
  {
    property: graphql`
      fragment TradeRuleActiveNominated_property on Property {
        id
        meters {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              active { start finish }
              nominatedRules: rules(first: 500, type: TRADE_TYPE_NOMINATED, state: TRADE_RULE_STATE_ACCEPTED) {
                edges {
                  node {
                    id
                    priority
                    tradeType
                    state
                    buyer {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        id
                        meter {
                          id
                          identifier
                          title
                          property {
                            id
                            title
                          }
                        }
                      }
                    }
                    seller {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        id
                        meter {
                          id
                          identifier
                          title
                          property {
                            id
                            title
                          }
                        }
                      }
                    }
                    clauses {
                      edges {
                        node {
                          price
                        }
                      }
                    }
                    start
                    finish
                    proposedAt
                    proposedBy {
                      id
                      email
                      givenName
                      familyName
                    }
                    acceptedAt
                    acceptedBy {
                      id
                      email
                      givenName
                      familyName
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
);
