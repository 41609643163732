import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import {
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';

import Logo from 'src/enosikit/components/Logo';

import LogoImage from 'src/assets/images/branding/enosi-logo.svg';

export const AUTH_TYPE_EMAIL_CONFIRM = 'confirm-email';
export const AUTH_TYPE_LOGIN = 'login';
export const AUTH_TYPE_LOGOUT = 'login';
export const AUTH_TYPE_FORGOT_PASSWORD = 'forgot-password';
export const AUTH_TYPE_RESET_PASSWORD = 'reset-password';
export const AUTH_TYPE_REGISTER = 'register';

// TODO: figure out how to add style for different widths/points:
// @include devicepoint('767px') {
//   padding: 40px 20px;
// }

// @media only screen and (max-width: 20em) {
//   .login-page {
//     display: block;
//   }

const AuthCanvas = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 20em;
`;

const AuthFrame = styled.div`
  min-width: 20em;
  width: 20em;
`;

const Branding = styled.h1`
  margin-bottom: 1em;
  text-align: center;
`;

const CustomHeader = styled(CardHeader)`
  text-align: center;
`;

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AuthContainer component
 */
function AuthContainer(props) {
  const { children, title, type } = props;

  return (
    <AuthCanvas>
      <AuthFrame>
        <Branding>
          <Logo image={LogoImage} name="Enosi" />
        </Branding>
        <Card>
          <CustomHeader tag="h5">{title}</CustomHeader>
          <CardBody>{children}</CardBody>
          <ListGroup flush>
            {type !== AUTH_TYPE_FORGOT_PASSWORD && (
              <ListGroupItem>
                <Link to="/forgot-password">Reset my password</Link>
              </ListGroupItem>
            )}
            {type !== AUTH_TYPE_LOGIN && (
              <ListGroupItem>
                <Link to="/login">Login</Link>
              </ListGroupItem>
            )}
          </ListGroup>
        </Card>
      </AuthFrame>
    </AuthCanvas>
  );
}

AuthContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    AUTH_TYPE_EMAIL_CONFIRM,
    AUTH_TYPE_LOGIN,
    AUTH_TYPE_LOGOUT,
    AUTH_TYPE_FORGOT_PASSWORD,
    AUTH_TYPE_RESET_PASSWORD,
    AUTH_TYPE_REGISTER,
  ]).isRequired,
};

AuthContainer.defaultProps = {
  children: null,
};

/**
 * Description
 * @param {any} props
 * @returns {any} - AuthLogoutContainer component.
 */
export function AuthLogoutContainer(props) {
  const { children, title } = props;
  return (
    <AuthCanvas>
      <AuthFrame>
        <Branding>
          <Logo image={LogoImage} name="Enosi" />
        </Branding>
        <Card>
          <CustomHeader tag="h5">{title}</CustomHeader>
          <CardBody>{children}</CardBody>
        </Card>
      </AuthFrame>
    </AuthCanvas>
  );
}

AuthLogoutContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string.isRequired,
};

AuthLogoutContainer.defaultProps = {
  children: null,
};

export default AuthContainer;
