import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import Footer from 'src/enosikit/components/Footer';

import AnonymousNavigation from 'src/components/Navigation/AnonymousNavigation';
import Flashes from 'src/components/Flashes';
import Loading from 'src/components/Loading';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - AnonymousLayout component
 */
function AnonymousLayout(props) {
  if (!props) {
    return <Loading />;
  }

  const { children } = props;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Enosi - Login to get started</title>
      </Helmet>

      <div className="flex flex-column" style={{ minHeight: '80vh' }}>
        <AnonymousNavigation />
        <Flashes />
        <div className="flex-auto">{children}</div>
      </div>

      <Footer versionUI={VERSION} />
    </>
  );
}

AnonymousLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

AnonymousLayout.defaultProps = {
  children: <Loading />,
};

export default AnonymousLayout;
