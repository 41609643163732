const sortCards = (cards) => cards.sort((a, b) => {
  const order = ['community', 'residual'];
  const orderB = order.indexOf(b.tradeType);
  const orderA = order.indexOf(a.tradeType);

  if (orderA !== orderB) {
    return orderA - orderB;
  }

  if (a.label > b.label) {
    return 1;
  }

  if (a.label < b.label) {
    return -1;
  }

  return 0;
});

export default sortCards;
