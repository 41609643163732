import { matchShape, routerShape } from 'found/PropTypes';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';

import EmailConfirmMutation from 'src/mutations/EmailConfirmMutation';
import FlashesStore from 'src/stores/FlashesStore';

import AuthContainer, { AUTH_TYPE_EMAIL_CONFIRM } from '../AuthContainer';

class AuthConfirmEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      newEmail: '',
    };
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleNewEmailChange = (e) => {
    this.setState({ newEmail: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, newEmail } = this.state;

    // eslint-disable-next-line react/destructuring-assignment
    const { params } = this.props.match;
    const { token } = params;

    EmailConfirmMutation(
      {
        email,
        newEmail,
        verificationCode: token,
      },
      this.handleMutationSuccess,
      this.handleMutationFailure,
    );
  };

  // eslint-disable-next-line no-unused-vars
  handleMutationSuccess = (_response) => {
    FlashesStore.flash(
      FlashesStore.SUCCESS,
      'Email confirmation was successful. Please confirm by logging in with your updated email and password.',
    );
    const { router } = this.props;
    router.push('/login');
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleMutationFailure = (error) => {
    FlashesStore.flash(FlashesStore.ERROR, error.message);
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Enosi - confirm email change</title>
        </Helmet>

        <AuthContainer type={AUTH_TYPE_EMAIL_CONFIRM} title="Confirm your email change">
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label htmlFor="email">Your account’s previous email</Label>
              <Input
                type="email"
                id="email"
                aria-describedby="email"
                placeholder="Your account's email address"
                onChange={this.handleEmailChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="newEmail">Your new email</Label>
              <Input
                type="email"
                id="newEmail"
                aria-describedby="newEmail"
                placeholder="Your new email"
                onChange={this.handleNewEmailChange}
              />
            </FormGroup>
            <Button type="submit" color="primary">
              Confirm
            </Button>
          </Form>
        </AuthContainer>
      </>
    );
  }
}

AuthConfirmEmail.propTypes = {
  match: matchShape.isRequired,
  router: routerShape.isRequired,
};

export default AuthConfirmEmail;
