import {
  TARIFF_TIME_UNIT_DAY, TARIFF_TIME_UNIT_MONTH, TARIFF_TIME_UNIT_YEAR,
} from '../constants';

const tariffTimeUnit = (key) => {
  switch (key) {
    case TARIFF_TIME_UNIT_DAY:
      return 'day';
    case TARIFF_TIME_UNIT_MONTH:
      return 'month';
    case TARIFF_TIME_UNIT_YEAR:
      return 'year';
    default:
      return null;
  }
};

export default tariffTimeUnit;
