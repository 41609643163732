import { DateTime } from 'luxon';
import {
  BUY, COMMUNITY, CONTRACTED, CSV_DATA_TIMESTAMP_FORMAT, CSV_FILENAME_TIMESTAMP_FORMAT,
  NOMINATED, RESIDUAL, TRADE_TYPE_CONTRACTED, TRADE_TYPE_COMMUNITY, TRADE_DIRECTION_BUY,
  TRADE_DIRECTION_SELL, TRADE_TYPE_NOMINATED, TRADE_DIRECTION_UNSPECIFIED,
  TRADE_TYPE_UNSPECIFIED,
} from 'src/util/constants';
import username from 'src/util/decorators/username';

const counterPartyCheck = (rules, ruleId, type, direction) => rules
  && rules.length > 0 && ruleId && type && direction;

/**
 * Get the entity id, falling to the default value in its absence.
 * @param {object} entity
 * @param {string} def
 * @returns {string} - entity id.
 */
export const getEntityId = (entity, def) => (entity ? entity.id : def) || null;

export const getCounterParty = (rules, ruleId, type, direction) => {
  if (counterPartyCheck(rules, ruleId, type, direction)) {
    const rule = rules.filter((r) => ruleId === r.id)[0];
    if (type === TRADE_TYPE_CONTRACTED || type === TRADE_TYPE_NOMINATED) {
      if (direction === TRADE_DIRECTION_BUY) {
        return rule.seller;
      }
      return rule.buyer;
    }
  }

  return null;
};

export const getTraderProperty = (trader) => (trader?.tradePoint?.meter?.property || null);

export const getDataKey = (type, counterParty, counterPartyProperty, meter, ruleId) => {
  if (!type) {
    return TRADE_TYPE_UNSPECIFIED;
  }

  const { user } = counterParty || {};

  switch (type) {
    case TRADE_TYPE_CONTRACTED:
    case TRADE_TYPE_NOMINATED:
      return [
        getEntityId(user, ruleId),
        (counterPartyProperty ? getEntityId(counterPartyProperty, null) : null),
        (meter ? getEntityId(meter, null) : null),
      ].filter(Boolean).join('-');
    case TRADE_TYPE_COMMUNITY:
      return COMMUNITY;
    default: // TRADE_TYPE_RESIDUAL
      return RESIDUAL;
  }
};

export const getDataObject = (type, counterParty, dataKey) => {
  if (!type) {
    return TRADE_TYPE_UNSPECIFIED;
  }

  const { user } = counterParty || {};
  const property = getTraderProperty(counterParty);

  let label = username(user);
  let subLabel = null;

  if (property) {
    subLabel = label;
    label = property.title;
  }

  switch (type) {
    case TRADE_TYPE_CONTRACTED:
    case TRADE_TYPE_NOMINATED:
      return {
        label, subLabel, key: dataKey, user, property, data: {},
      };
    case TRADE_TYPE_COMMUNITY:
      return {
        label: 'Community', key: dataKey, subLabel: null, user: null, property: null, data: {},
      };
    default: // TRADE_TYPE_RESIDUAL
      return {
        label: 'Retailer default', key: dataKey, subLabel: null, user: null, property: null, data: {},
      };
  }
};

export const getDirection = (direction) => {
  if (!direction) {
    return TRADE_DIRECTION_UNSPECIFIED;
  }
  return direction === BUY
    ? TRADE_DIRECTION_BUY : TRADE_DIRECTION_SELL;
};

export const getTradeType = (type) => {
  if (!type) {
    return TRADE_TYPE_UNSPECIFIED;
  }
  switch (type) {
    case TRADE_TYPE_CONTRACTED:
      return CONTRACTED;
    case TRADE_TYPE_NOMINATED:
      return NOMINATED;
    case TRADE_TYPE_COMMUNITY:
      return COMMUNITY;
    default: // TRADE_TYPE_RESIDUAL
      return RESIDUAL;
  }
};

/**
 * Provides the filename for the downlodable file in property dashboard
 * @param {string} title - property title
 * @param {object} timeRange - time range of the data (meter or trade)
 * @param {string} type - meter or trade
 * @returns {string} - file name (data download).
 */
export const getFileName = (title, timeRange, type) => {
  if (!title && !timeRange) {
    return null;
  }
  const typeLabel = type ? `${type} data - ` : '';
  const { start, finish } = timeRange;
  const startTime = DateTime.fromSeconds(start).toFormat(CSV_FILENAME_TIMESTAMP_FORMAT);
  const finishTime = DateTime.fromSeconds(finish).toFormat(CSV_FILENAME_TIMESTAMP_FORMAT);
  const fileName = `Enosi Powertracer - ${title} - ${typeLabel}${startTime} to ${finishTime}`;
  return fileName;
};

/**
 * Returns the time range, with both start and finish timestamps present, for the csv data.
 * @param {number} timestamp - unit timestamp
 * @param {string} aggregation
 * @returns {object} - time range.
 */
export const getTimeRange = (timestamp, aggregation) => {
  if (!timestamp || !aggregation) {
    return { start: null, finish: null };
  }

  if (aggregation === 'P1D') {
    const finish = DateTime.fromSeconds(timestamp).toFormat(CSV_DATA_TIMESTAMP_FORMAT);
    const start = DateTime.fromSeconds(timestamp).minus({ days: 1 })
      .toFormat(CSV_DATA_TIMESTAMP_FORMAT);
    return { start, finish };
  }
  const finish = DateTime.fromSeconds(timestamp).toFormat(CSV_DATA_TIMESTAMP_FORMAT);
  const start = DateTime.fromSeconds(timestamp).minus({ minutes: 30 })
    .toFormat(CSV_DATA_TIMESTAMP_FORMAT);
  return { start, finish };
};

/**
 * Prepares the user name from the user object
 * concatenates first and last name if available or returns email
 * @param {object} user
 * @returns {string} - user name.
 */
export const getUserName = (user) => {
  if (!user) {
    return null;
  }
  const { familyName, givenName, email } = user;
  let userName = null;
  if (familyName || givenName) {
    userName = `${givenName || ''} ${familyName || ''}`;
  } else {
    userName = email || null;
  }
  return userName?.trim();
};

/**
 * Extract trade rules from the meter data
 * @param {Array} nodes - meter nodes.
 * @returns {Array<object>} - trade rules.
 */
export const getTradeRules = (nodes) => nodes.map(
  (node) => (node?.rules?.edges?.map((el) => (el.node))),
).flat().filter(Boolean);
