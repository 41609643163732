import { Link } from 'found';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Alert, Badge, Button, Row, Col,
  Card, CardBody, CardFooter, CardTitle, CardSubtitle, CardText,
} from 'reactstrap';

import { APIConfig } from 'src/config';
import CancelNominatedTradeMutation from 'src/mutations/CancelNominatedTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';
import {
  PLATFORM_MODE_REBATE, TRADE_RULE_STATE_PROPOSED, TRADE_RULE_CANCEL,
} from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import { tradeRulePriceRange } from 'src/util/tradeRule';
import username from 'src/util/decorators/username';

class TradeRuleActiveProposed extends React.Component {
  static tradeRulesForProperty(property) {
    const { meters } = property;
    const rules = { sell: [], buy: [], count: 0 };

    meters.edges.forEach((meterEdge) => {
      if (!!meterEdge && !!meterEdge.node && !!meterEdge.node.proposedRules) {
        meterEdge.node.proposedRules.edges.forEach((ruleEdge) => {
          const { node: ruleNode } = ruleEdge;
          if (ruleNode.state !== TRADE_RULE_STATE_PROPOSED) return;

          if (ruleNode.seller.tradePoint.id === meterEdge.node.tradePointId) {
            rules.sell.push({ ...ruleNode, meter: meterEdge.node });
          } else {
            rules.buy.push({ ...ruleNode, meter: meterEdge.node });
          }
        });
      }
    });
    rules.count = rules.buy.length + rules.sell.length;

    return rules;
  }

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleNominatedTradeMutationFailure = (error) => {
    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  // eslint-disable-next-line class-methods-use-this -- consistency in handling mutations.
  handleCancelNominatedTradeMutationSuccess = (response) => {
    const { id } = response.cancelNominatedTrade;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      `Cancelled peer-to-peer trade rule proposal with id: ${id}.`,
    );
  };

  tradeRuleAction = (rule, action) => {
    const { id } = rule;
    switch (action) {
      case TRADE_RULE_CANCEL:
        CancelNominatedTradeMutation(
          id,
          this.handleCancelNominatedTradeMutationSuccess,
          this.handleNominatedTradeMutationFailure,
        );
        break;
      default:
        FlashesStore.flash(
          FlashesStore.ERROR,
          `Action ${action} is not possible`,
        );
    }
  };

  tradeRuleActionButton = (rule) => {
    const {
      state, meter, buyer, seller,
    } = rule;

    if (state !== TRADE_RULE_STATE_PROPOSED) {
      return null;
    }

    if (
      [buyer.tradePoint.id, seller.tradePoint.id].includes(meter.tradePointId)
    ) {
      return (
        <Button onClick={() => this.tradeRuleAction(rule, TRADE_RULE_CANCEL)}>
          Cancel proposed trade
        </Button>
      );
    }

    return (
      <Alert color="danger">
        {`Unknown trade point: ${meter.id} when buyer is ${buyer.tradePoint.id} and seller is ${seller.tradePoint.id}`}
      </Alert>
    );
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <div>Loading...</div>;
    }

    const { property } = this.props;
    const tradeRules = TradeRuleActiveProposed.tradeRulesForProperty(property);

    if (tradeRules.count === 0) {
      return null;
    }

    return (
      <Card className="mt-4 mb-4 trade-rules-proposed">
        <CardBody>
          <h2>Proposed Trades</h2>
          <p>We are waiting on your friends to get back in touch.</p>

          <Row>
            <Col xs="12" sm="12" md="6">
              <h3>Selling</h3>
              <p>
                {'You have '}
                <strong>{tradeRules.sell.length}</strong>
                {' proposed trade rules to sell energy.'}
              </p>

              {tradeRules.sell && tradeRules.sell.map((rule) => {
                const priceRange = tradeRulePriceRange(rule);

                let priceLabel;
                let tradePointTitle;
                if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
                  priceLabel = 'Discount of: ';
                  tradePointTitle = rule.meter.title;
                } else {
                  priceLabel = 'Selling at a price of: ';
                  tradePointTitle = rule.meter.title;
                }

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${rule.id}`}>
                    <CardBody>
                      <CardTitle tag="h4">{tradePointTitle}</CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        Proposed
                      </CardSubtitle>
                      <CardText>
                        <strong>{username(rule.seller.user)}</strong>
                        {' has proposed to sell to '}
                        <strong>{username(rule.buyer.user)}</strong>
                        <br />
                        Proposed by
                        {' '}
                        <strong>{username(rule.proposedBy)}</strong>
                        {' '}
                        on
                        {' '}
                        {DateTime.fromSeconds(rule.proposedAt).toFormat('dd LLL yyyy')}
                        <br />
                        {priceLabel}
                        <strong>
                          {convertEnergyPrice(priceRange.minimum).toFixed(3)}
                        </strong>
                        {priceRange.maximum !== priceRange.minimum && (
                          <>
                            {' '}
                            to
                            {' '}
                            <strong>
                              {convertEnergyPrice(priceRange.maximum.toFixed(3))}
                            </strong>
                          </>
                        )}
                        {' '}
                        <abbr title="cents per kilowatt hour">c/kWh</abbr>
                        {' '}
                        (
                        <Link to={`/properties/${property.id}/trade-rules/${rule.id}`}>further details</Link>
                        )
                        <br />
                        <Link
                          to={`/properties/${property.id}/trade-rules/${rule.id}`}
                        >
                          <Badge color="mid">{rule.id}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                    <CardFooter>{this.tradeRuleActionButton(rule)}</CardFooter>
                  </Card>
                );
              })}
            </Col>

            <Col xs="12" sm="12" md="6">
              <h3>Buying</h3>
              <p>
                {'You have '}
                <strong>{tradeRules.buy.length}</strong>
                {' proposed trade rules to buy energy.'}
              </p>

              {tradeRules.buy && tradeRules.buy.map((rule) => {
                const priceRange = tradeRulePriceRange(rule);

                let priceLabel;
                let tradePointTitle;
                if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
                  priceLabel = 'Discount of: ';
                  tradePointTitle = rule.meter.title;
                } else {
                  priceLabel = 'Buying at a price of: ';
                  tradePointTitle = rule.meter.title;
                }

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${rule.id}`}>
                    <CardBody>
                      <CardTitle tag="h4">{tradePointTitle}</CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        Proposed
                      </CardSubtitle>
                      <CardText>
                        <strong>{username(rule.buyer.user)}</strong>
                        {' has proposed to buy from '}
                        <strong>{username(rule.seller.user)}</strong>
                        <br />
                        Proposed by
                        {' '}
                        <strong>{username(rule.proposedBy)}</strong>
                        {' '}
                        on
                        {' '}
                        {DateTime.fromSeconds(rule.proposedAt).toFormat('dd LLL yyyy')}
                        <br />
                        {priceLabel}
                        <strong>
                          {convertEnergyPrice(priceRange.minimum).toFixed(3)}
                        </strong>
                        {priceRange.maximum !== priceRange.minimum && (
                          <>
                            {' '}
                            to
                            {' '}
                            <strong>
                              {convertEnergyPrice(priceRange.maximum).toFixed(3)}
                            </strong>
                          </>
                        )}
                        {' '}
                        <abbr title="cents per kilowatt hour">c/kWh</abbr>
                        {' '}
                        (
                        <Link to={`/properties/${property.id}/trade-rules/${rule.id}`}>further details</Link>
                        )
                        <br />
                        <Link
                          to={`/properties/${property.id}/trade-rules/${rule.id}`}
                        >
                          <Badge color="mid">{rule.id}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                    <CardFooter>{this.tradeRuleActionButton(rule)}</CardFooter>
                  </Card>
                );
              })}
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Link
            to={`/properties/${property.id}/trade-rules?type=TRADE_RULE_TYPE_PROPOSED`}
          >
            <Button color="primary" className="me-2">
              View all trade rules
            </Button>
          </Link>
          <Link
            to={`/properties/${property.id}/trade-rules/propose/nominated`}
          >
            <Button color="primary" className="me-2">
              Propose new trade
            </Button>
          </Link>
        </CardFooter>
      </Card>
    );
  }
}

TradeRuleActiveProposed.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TradeRuleActiveProposed.defaultProps = {
  property: null,
};

export default createFragmentContainer(
  TradeRuleActiveProposed,
  {
    property: graphql`
      fragment TradeRuleActiveProposed_property on Property {
        id
        meters {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              proposedRules: rules(first: 500, state: TRADE_RULE_STATE_PROPOSED) {
                edges {
                  node {
                    id
                    priority
                    tradeType
                    state
                    buyer {
                      userId
                      user { id email givenName familyName }
                      communityId
                      residualId
                      tradePoint { id type }
                    }
                    seller {
                      userId
                      user { id email givenName familyName }
                      communityId
                      residualId
                      tradePoint { id type }
                    }
                    clauses {
                      edges {
                        node {
                          price
                        }
                      }
                    }
                    start
                    finish
                    proposedAt
                    proposedBy { id email givenName familyName }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
);
