import { DEFAULT_CARBON_FREE_EMISSION_FACTOR } from './constants';
import { calculateCarbonEmission, useDefaultCarbonFreeEmissions } from './helpers';

/**
 * Calculate carbon data, given the calculation properties of type, timestamp, energy,
 * direction, types and property region.
 * @param {object} calculationProps
 * @returns {number} - carbon emission.
 */
const calculateCarbonData = (calculationProps) => {
  const {
    dataType, timestamp, energy, direction, types = [], propertyRegion,
  } = calculationProps;

  // For meter data exports and all trade datas except residual imports, carbon emission is 0
  if (useDefaultCarbonFreeEmissions(dataType, direction, types)) {
    return Number(energy.times(DEFAULT_CARBON_FREE_EMISSION_FACTOR));
  }

  return calculateCarbonEmission(energy, timestamp, propertyRegion);
};

export default calculateCarbonData;
