class Address {
  constructor(address) {
    this.state = {
      ...address,
    };
  }

  simpleString() {
    const {
      line1, line2, city, state, country,
    } = this.state;

    const addr = [
      [line1, line2].filter(Boolean).join(', '),
      city,
      state,
      country,
    ].filter(Boolean);

    return addr.slice(0, 3).join(', ');
  }

  string() {
    const {
      line1, line2, city, state, postcode, country,
    } = this.state;

    return [
      [line1, line2, city].filter(Boolean).join(', '),
      state,
      postcode,
      country,
    ]
      .filter(Boolean)
      .join(' ');
  }
}

export default Address;
