/**
 * @generated SignedSource<<d4ab6af1a0897bfb8cdcf316c4ef1f2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "identifier",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalIdentifier",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "TimeRange",
  "kind": "LinkedField",
  "name": "active",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "start",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finish",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AdminTariffShow_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tariff",
        "kind": "LinkedField",
        "name": "tariff",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminTariffShow_tariff"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_AdminTariffShow_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tariff",
        "kind": "LinkedField",
        "name": "tariff",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tariffType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Meter",
            "kind": "LinkedField",
            "name": "meter",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Property",
            "kind": "LinkedField",
            "name": "property",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 500
              }
            ],
            "concreteType": "VolumeChargeConnection",
            "kind": "LinkedField",
            "name": "volumeCharges",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VolumeChargeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VolumeCharge",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "importFlag",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "exportFlag",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ignorePublicHolidays",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "volumeCharges(first:500)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dbcf115c1908ae21fcf0fedff784e796",
    "id": null,
    "metadata": {},
    "name": "routes_AdminTariffShow_Query",
    "operationKind": "query",
    "text": "query routes_AdminTariffShow_Query(\n  $id: ID!\n) {\n  tariff(id: $id) {\n    ...AdminTariffShow_tariff\n    id\n  }\n}\n\nfragment AdminTariffShow_tariff on Tariff {\n  id\n  identifier\n  externalIdentifier\n  title\n  tariffType\n  createdAt\n  updatedAt\n  active {\n    start\n    finish\n  }\n  meter {\n    id\n    identifier\n    active {\n      start\n      finish\n    }\n  }\n  property {\n    id\n    title\n    active {\n      start\n      finish\n    }\n  }\n  volumeCharges(first: 500) {\n    edges {\n      node {\n        id\n        identifier\n        externalIdentifier\n        title\n        rate\n        importFlag\n        exportFlag\n        ignorePublicHolidays\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "feef96b24de008dce8d9f37064a67139";

module.exports = node;
