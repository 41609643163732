import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import {
  FormFeedback, FormGroup, Label,
} from 'reactstrap';

import { Months, MonthNames } from 'src/util/constants';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - SelectTimes component
 */
function SelectMonths(props) {
  const {
    idPrefix, monthsSelected, valid, handleMonthsChange,
  } = props;

  const monthsOptions = Months.map((month) => ({ value: month, label: MonthNames[month] }));
  const monthsValue = monthsSelected.map((month) => ({ value: month, label: MonthNames[month] }));

  const handleChange = (selected) => {
    handleMonthsChange(selected.map((option) => option.value));
  };

  return (
    <FormGroup>
      <Label for={`${idPrefix}MonthsOfYear`}>Months of year</Label>
      <Select
        isMulti
        name="monthsOfYear"
        id={`${idPrefix}MonthsOfYear`}
        options={monthsOptions}
        value={monthsValue}
        onChange={handleChange}
        className={valid === false ? 'is-invalid' : ''}
      />
      <FormFeedback>Invalid months of year</FormFeedback>
    </FormGroup>
  );
}

SelectMonths.propTypes = {
  idPrefix: PropTypes.string.isRequired,
  monthsSelected: PropTypes.arrayOf(PropTypes.oneOf(Months)).isRequired,
  valid: PropTypes.bool,
  handleMonthsChange: PropTypes.func.isRequired,
};

SelectMonths.defaultProps = {
  valid: null,
};

export default SelectMonths;
