import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - AdminMeterTradeRulesItemTimeRange component
 */
function AdminMeterTradeRulesItemTimeRange(props) {
  const { start, finish } = props;

  return (
    <>
      {start ? DateTime.fromSeconds(start).toFormat('HH:mm DD') : '-'}
      {' to '}
      {finish ? DateTime.fromSeconds(finish).toFormat('HH:mm DD') : '-'}
    </>
  );
}

AdminMeterTradeRulesItemTimeRange.propTypes = {
  start: PropTypes.number,
  finish: PropTypes.number,
};

AdminMeterTradeRulesItemTimeRange.defaultProps = {
  start: null,
  finish: null,
};

export default AdminMeterTradeRulesItemTimeRange;
