import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button, ButtonGroup, ButtonToolbar } from 'reactstrap';

import { APIConfig } from 'src/config';
import {
  FEATURE_INHIBIT_CONTRACTED_TRADES, FEATURE_INHIBIT_NOMINATED_TRADES,
  FEATURE_INHIBIT_COMMUNITY_TRADES, LIST_FILTER_ALL, TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL,
  TRADE_TYPE_CONTRACTED, TRADE_TYPE_NOMINATED, TRADE_TYPE_COMMUNITY, TRADE_TYPE_RESIDUAL,
} from 'src/util/constants';

/**
 *
 * @param {any} root0
 * @param {any} root0.date
 * @param {any} root0.dateUpdateFunc
 * @param {any} root0.filter
 * @param {any} root0.filterFunc
 * @returns {React.ReactComponentElement} -TradeListControls component
 */
function TradeListControls({
  date, dateUpdateFunc, filter, filterFunc,
}) {
  const [selectedDate, setSelectedDate] = useState(date);
  const [myRef, setMyRef] = useState(false);

  const closeCalendar = () => {
    myRef.setOpen(false);
  };

  const dateButtonLabel = (d) => (d.toFormat('d MMM yyyy'));

  const setDate = () => {
    dateUpdateFunc(selectedDate);
  };

  const updateDate = (d) => {
    const newDate = DateTime.fromJSDate(d);
    if (newDate.toSeconds() === date.toSeconds()) {
      return;
    }
    setSelectedDate(newDate);
  };

  const renderDatePicker = (onChangeFunc, onCloseFunc) => (
    <DatePicker
      ref={(r) => { setMyRef(r); }}
      value={dateButtonLabel(date)}
      selected={selectedDate ? selectedDate.toJSDate() : null}
      onChange={onChangeFunc}
      onCalendarClose={onCloseFunc}
      customInput={<DateButton />}
      shouldCloseOnSelect={false}
      popperPlacement="bottom-end"
      popperModifiers={{
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: 'viewport',
        },
      }}
    >
      <div
        style={{
          clear: 'both',
          textAlign: 'right',
          borderTop: '1px solid #ccc',
          padding: '1em',
        }}
      >
        <button className="btn btn-primary" type="button" onClick={() => (closeCalendar())}>Apply</button>
      </div>
    </DatePicker>
  );

  const filterIncludes = (key, value) => (filter && filter[key] && filter[key].includes(value));

  return (
    <div className="trade-history-filters">
      <ButtonToolbar>
        <ButtonGroup className="me-2 mb-2">
          <Button size="sm" className="btn btn-darken trades-history-all" active={!filter.direction && !filter.type} onClick={() => filterFunc(null, LIST_FILTER_ALL)}>
            All
          </Button>
        </ButtonGroup>

        <ButtonGroup className="me-2 mb-2">
          <Button size="sm" className="btn btn-darken trades-history-buy" active={filterIncludes('direction', TRADE_DIRECTION_BUY)} onClick={() => filterFunc('direction', TRADE_DIRECTION_BUY)}>
            Buy
          </Button>
          <Button size="sm" className="btn btn-darken trades-history-sell" active={filterIncludes('direction', TRADE_DIRECTION_SELL)} onClick={() => filterFunc('direction', TRADE_DIRECTION_SELL)}>
            Sell
          </Button>
        </ButtonGroup>

        <ButtonGroup className="me-2 mb-2">
          {!APIConfig().feature(FEATURE_INHIBIT_CONTRACTED_TRADES) && (
            <Button size="sm" className="btn btn-darken trades-history-contracted" active={filterIncludes('type', TRADE_TYPE_CONTRACTED)} onClick={() => filterFunc('type', TRADE_TYPE_CONTRACTED)}>
              Contracted
            </Button>
          )}
          {!APIConfig().feature(FEATURE_INHIBIT_NOMINATED_TRADES) && (
            <Button size="sm" className="btn btn-darken trades-history-nominated" active={filterIncludes('type', TRADE_TYPE_NOMINATED)} onClick={() => filterFunc('type', TRADE_TYPE_NOMINATED)}>
              Peer-to-peer
            </Button>
          )}
          {!APIConfig().feature(FEATURE_INHIBIT_COMMUNITY_TRADES) && (
            <Button size="sm" className="btn btn-darken trades-history-community" active={filterIncludes('type', TRADE_TYPE_COMMUNITY)} onClick={() => filterFunc('type', TRADE_TYPE_COMMUNITY)}>
              Community
            </Button>
          )}
          <Button size="sm" className="btn btn-darken trades-history-default" active={filterIncludes('type', TRADE_TYPE_RESIDUAL)} onClick={() => filterFunc('type', TRADE_TYPE_RESIDUAL)}>
            Retailer default
          </Button>
        </ButtonGroup>

        <ButtonGroup className="me-2 mb-2 trades-history-date-picker">
          {renderDatePicker(updateDate, setDate)}
        </ButtonGroup>
      </ButtonToolbar>
    </div>
  );
}

TradeListControls.propTypes = {
  date: PropTypes.instanceOf(DateTime).isRequired,
  dateUpdateFunc: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    direction: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  filterFunc: PropTypes.func.isRequired,
};

export default TradeListControls;

// Ref: <https://github.com/Hacker0x01/react-datepicker/issues/862>
//
// eslint-disable-next-line react/prefer-stateless-function
class DateButton extends React.Component {
  render() {
    const {
      onClick,
      disabled,
      value,
    } = this.props;

    return (
      <Button size="sm" className="btn btn-darken" onClick={() => (onClick())} disabled={disabled}>
        {value}
      </Button>
    );
  }
}

DateButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.string,
};
DateButton.defaultProps = {
  disabled: false,
  onClick: null,
  value: 'loading...',
};

export { DateButton };
