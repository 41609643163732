import { format } from 'd3-format';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import {
  TARIFF_TIME_UNIT_UNSPECIFIED, TariffTimeUnitNames,
  TARIFF_TYPE_ENERGY, TARIFF_TYPE_ENVIRONMENTAL, TARIFF_TYPE_NETWORK, TARIFF_TYPE_OTHER,
} from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import tariffTimeUnit from 'src/util/decorators/units';
import { timeOfUseDetails, timeOfUseConditions } from 'src/util/timeOfUse';

class TariffInfo extends React.Component {
  static activeTimerange(timeRange, timezone) {
    const { start, finish } = timeRange;
    const tsFormat = 'd LLLL yyyy, HH:mm ZZZZZ'; // For example: "1 March 2022, 23:45 Australian Eastern Daylight Time"
    const startTS = DateTime.fromSeconds(start).setZone(timezone).toFormat(tsFormat);
    const finishTS = finish ? DateTime.fromSeconds(finish).setZone(timezone).toFormat(tsFormat) : 'present';

    return `${startTS} to ${finishTS}`;
  }

  static blockTariffInformation(volumeCharge) {
    const { blockUnit, blockMinimum, blockMaximum } = volumeCharge;

    if (blockUnit == null || blockUnit === TARIFF_TIME_UNIT_UNSPECIFIED) {
      return null;
    }

    // Base unit value is Wh, presentation is in kWh.
    const formatKwh = (value) => format('.3~f')(value / 1000);

    let text = '';

    if (blockMinimum != null) {
      if (blockMaximum != null) {
        text = `Between ${formatKwh(blockMinimum)} and ${formatKwh(blockMaximum)} kWh/${TariffTimeUnitNames[blockUnit]}`;
      } else {
        text = `Beyond ${formatKwh(blockMinimum)} kWh/${TariffTimeUnitNames[blockUnit]}`;
      }
    } else if (blockMaximum != null) {
      text = `Up to ${formatKwh(blockMaximum)} kWh/${TariffTimeUnitNames[blockUnit]}`;
    }

    return text !== '' ? <div>{text}</div> : null;
  }

  static chargeDirection(volumeCharge) {
    const { importFlag, exportFlag } = volumeCharge;
    if (importFlag) {
      if (exportFlag) {
        return 'both import and export';
      }
      return 'import only';
    }
    return 'export only';
  }

  static chargeLabel(tariffType) {
    switch (tariffType) {
      case TARIFF_TYPE_ENERGY:
        return 'Energy Charge';
      case TARIFF_TYPE_NETWORK:
        return 'Network Charge';
      case TARIFF_TYPE_ENVIRONMENTAL:
        return 'Environmental Charge';
      case TARIFF_TYPE_OTHER:
        return 'Other Charge';
      default:
        return 'Charge';
    }
  }

  static volumeChargeConditions(volumeCharge) {
    const conditions = timeOfUseConditions(volumeCharge);
    if (conditions.length === 0) {
      return null;
    }

    return (<div>{`(${conditions.join(', ')})`}</div>);
  }

  render() {
    const { tariff } = this.props;

    return (
      <div className="mt-4 mb-4">
        <h4>
          {tariff.title}
          <br />
          <small>{tariff.identifier}</small>
        </h4>

        <div className="card">
          <div className="card-header">
            {`Valid from ${TariffInfo.activeTimerange(tariff.active, tariff.property.timezone)}`}
          </div>
          <table className="table m-0">
            <thead>
              <tr>
                <th scope="col">{TariffInfo.chargeLabel(tariff.tariffType)}</th>
                <th scope="col">Details</th>
              </tr>
            </thead>
            <tbody>
              {tariff.standingCharges && tariff.standingCharges.edges.map((elv) => {
                const { node: charge } = elv;

                return (
                  <tr key={charge.id}>
                    <th scope="row">
                      {charge.title}
                      <br />
                      <small>{charge.identifier}</small>
                    </th>
                    <td>
                      {`$${format('.3f')(charge.rate)}`}
                      <abbr title={`per ${tariffTimeUnit(charge.timeUnit)}`}>
                        {`/${tariffTimeUnit(charge.timeUnit)}`}
                      </abbr>
                    </td>
                  </tr>
                );
              })}

              {// eslint-disable-next-line max-len
                tariff.volumeCharges && tariff.volumeCharges.edges.slice().sort((a, b) => (b.node.rate - a.node.rate)).map((elv) => {
                  const { node: charge } = elv;

                  return (
                    <tr key={charge.id}>
                      <th scope="row">
                        {charge.title}
                        <br />
                        <small>{charge.identifier}</small>
                      </th>
                      <td>
                        <div>
                          {`${format('.3f')(convertEnergyPrice(charge.rate))}`}
                          <abbr title="cents per kilowatt hours">c/kWh</abbr>
                          {` ${TariffInfo.chargeDirection(charge)}`}
                        </div>
                        <div>
                          {timeOfUseDetails(charge)}
                        </div>
                        {TariffInfo.blockTariffInformation(charge)}
                        {TariffInfo.volumeChargeConditions(charge)}
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

TariffInfo.propTypes = {
  tariff: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default createFragmentContainer(
  TariffInfo,
  {
    tariff: graphql`
      fragment TariffInfo_tariff on Tariff {
        id
        identifier
        title
        description
        tariffType
        active { start finish }
        property {
          timezone
        }
        standingCharges(first:500) {
          edges {
            node {
              id
              externalIdentifier
              identifier
              title
              description
              rate
              timeUnit
            }
          }
        }
        volumeCharges(first:500) {
          edges {
            node {
              id
              externalIdentifier
              identifier
              title
              description
              rate
              volumeUnit
              importFlag
              exportFlag
              ignorePublicHolidays
              ignoreDaylightSavings
              timezone
              monthsOfYear
              daysOfWeek
              timesOfDay {
                start { hours minutes seconds }
                finish { hours minutes seconds }
              }
              blockUnit
              blockMinimum
              blockMaximum
            }
          }
        }
      }
    `,
  },
);
