import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'reactstrap';

import { TradeTypeLabels } from 'src/util/constants';

const colorMapping = {};

/**
 * Description
 * @param {any} {state}
 * @returns {React.ReactComponentElement} - BadgeTradeType component
 */
function BadgeTradeType({ type }) {
  if (!type) { return (null); }

  const color = colorMapping[type] || 'mid';
  const title = (TradeTypeLabels[type] || '').toUpperCase();

  return (
    <Badge color={color} className="badge-outline">{title}</Badge>
  );
}

BadgeTradeType.propTypes = {
  type: PropTypes.string.isRequired,
};

export default BadgeTradeType;
