import {
  DEFAULT_CARBON_FREE_EMISSION_FACTOR, EMISSION_FACTORS,
  TRADE, TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL, TRADE_TYPE_RESIDUAL,
} from './constants';

/**
 * Determine if the combination is a residual buy trade.
 * @param {string} direction
 * @param {string} types
 * @returns {boolean} - is the trade a residual buy.
 */
export const isResidualBuy = (direction, types) => {
  const isDirectionBuy = direction.length > 0 && direction.includes(TRADE_DIRECTION_BUY);
  const isResidual = types.length > 0 && types.includes(TRADE_TYPE_RESIDUAL);
  return isDirectionBuy && isResidual;
};

/**
 * Calculate the total carbon emissions from a dataset.
 * @param {Array<object>} dataConsumed
 * @returns {number} - total carbon emission.
 */
export const getTotalCarbonEmission = (dataConsumed) => {
  let totalCarbonEmission = 0;
  Object.keys(dataConsumed).forEach((item) => {
    const { trades } = dataConsumed[item];
    Object.keys(trades).forEach((trade) => {
      totalCarbonEmission += trades[trade].carbon;
    });
  });
  return totalCarbonEmission;
};

/**
 * Determine if the dataset should use zero carbon emissions.
 * @param {string} dataType
 * @param {string} direction
 * @param {string} types
 * @returns {boolean} - zero emission or not.
 */
export const useDefaultCarbonFreeEmissions = (dataType, direction, types) => {
  if (dataType === TRADE) {
    return !isResidualBuy(direction, types);
  }
  // this is meter data. So calculate carbon emission only for the imports
  return direction === TRADE_DIRECTION_SELL;
};

/**
 * For a given set of factors (note, normally this is EMISSION_FACTORS from ./constants.js), return
 * the array of valid factors for the period, sorted by start date ascending.
 * @param {object} factors
 * @param {string} propertyRegion
 * @param {object} atTime
 * @returns {object} - emission factors.
 */
export const emissionFactors = (factors, propertyRegion, atTime) => {
  const validFactors = Object.values(factors).filter((factor) => {
    const { regions, timeRange } = factor;
    const { start, finish } = timeRange;
    const includesPropertyRegion = Object.keys(regions).includes(propertyRegion);

    return includesPropertyRegion && atTime >= start && atTime <= finish;
  });

  return validFactors.sort((a, b) => a.timeRange.start - b.timeRange.start);
};

/**
 * Find the emissions factor region, given the property region and the carbon data,
 * and return the dataset for that region. If not found, returns `undefined`.
 * @param {object} carbonData
 * @param {string} propertyRegion
 * @returns {object|undefined} - emission factor for a given region.
 */
export const emissionFactorForRegion = (carbonData, propertyRegion) => {
  // if the state value is not present, default to countrycode
  // if both are not present, default to AU
  if (propertyRegion) {
    if (carbonData[propertyRegion]) {
      return carbonData[propertyRegion];
    }

    const countryCode = propertyRegion.split('-')[0];
    if (countryCode && carbonData[countryCode]) {
      return carbonData[countryCode];
    }
  }

  return undefined;
};

/**
 * Calculate the carbon emissions, given the energy, timestamp and property region.
 * @param {number} energy
 * @param {object} timestamp
 * @param {string} propertyRegion
 * @returns {number} - carbon emission.
 */
export const calculateCarbonEmission = (energy, timestamp, propertyRegion) => {
  const validEmissionFactors = emissionFactors(EMISSION_FACTORS, propertyRegion, timestamp);

  // If we don't detect any valid valid emission factors for the timestamp, we return zero.
  if (validEmissionFactors.length === 0) {
    return DEFAULT_CARBON_FREE_EMISSION_FACTOR;
  }

  // If no values in carbonDatasets, use the default, which is the most recent.
  const [emissionFactor] = validEmissionFactors;
  // const [emissionFactor] = validEmissionFactors;
  const emissionFactorRegion = emissionFactorForRegion(emissionFactor.regions, propertyRegion);
  const energyInKwh = energy / 1000.0; // emissions factors are in kg/kWh. Target outcome is in kg.

  return emissionFactorRegion * energyInKwh;
};
