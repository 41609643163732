import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import { APIConfig } from 'src/config';
import {
  FEATURE_INHIBIT_COMMUNITY_TRADES,
  FEATURE_INHIBIT_CONTRACTED_TRADES,
  FEATURE_INHIBIT_NOMINATED_TRADES,
} from 'src/util/constants';

import Address from 'src/components/Address';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';

import TradeRuleActiveContracted from './TradeRuleActiveContracted';
import TradeRuleActiveNominated from './TradeRuleActiveNominated';
import TradeRuleActiveCommunity from './TradeRuleActiveCommunity';
import TradeRuleActiveResidual from './TradeRuleActiveResidual';
import TradeRuleActiveProposed from './TradeRuleActiveProposed';

class TradeRuleActive extends React.Component {
  renderPropertyControls = () => {
    const { property } = this.props;
    return (
      <ButtonToolbar>
        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.id}/trade-rules`} className="btn btn-darken">
            All trade rules
          </Link>
        </ButtonGroup>

        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.id}/settings`} className="btn btn-darken">
            Property Settings
          </Link>
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <div>Loading...</div>;
    }

    const { property, router } = this.props;
    const { address, id, title } = property;

    const addr = new Address(address);

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Enosi - Property: ${title}`}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: 'Properties', path: '/properties' },
            { name: title, path: `/properties/${id}` },
            { name: 'Trade Rules' },
          ]}
        />
        <Heading
          alias={title}
          address={addr.simpleString()}
          context="Trade Rules"
          controls={this.renderPropertyControls()}
        />
        {!APIConfig().feature(FEATURE_INHIBIT_CONTRACTED_TRADES) && (
          <TradeRuleActiveContracted property={property} router={router} />
        )}
        {!APIConfig().feature(FEATURE_INHIBIT_NOMINATED_TRADES) && (
          <>
            <TradeRuleActiveProposed property={property} router={router} />
            <TradeRuleActiveNominated property={property} router={router} />
          </>
        )}
        {!APIConfig().feature(FEATURE_INHIBIT_COMMUNITY_TRADES) && (
          <TradeRuleActiveCommunity property={property} router={router} />
        )}
        <TradeRuleActiveResidual property={property} router={router} />
      </>
    );
  }
}

TradeRuleActive.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TradeRuleActive.defaultProps = {
  property: null,
};

export default createFragmentContainer(
  TradeRuleActive,
  {
    property: graphql`
      fragment TradeRuleActive_property on Property {
        id
        title
        address {
          line1
          line2
          city
          state
          postcode
          gps {
            latitude
            longitude
          }
        }
        ...TradeRuleActiveCommunity_property
        ...TradeRuleActiveContracted_property
        ...TradeRuleActiveNominated_property
        ...TradeRuleActiveProposed_property
        ...TradeRuleActiveResidual_property
      }
    `,
  },
);
