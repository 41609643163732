import { DateTime } from 'luxon';

const isActive = ({ start, finish }) => {
  const now = DateTime.local();
  const s = start ? DateTime.fromSeconds(start) : null;
  const f = finish ? DateTime.fromSeconds(finish) : null;

  return !((s && s > now) || (f && f < now));
};

export default isActive;
