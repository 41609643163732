/**
 * @generated SignedSource<<3ca0fe3f9563f71832ace575210e8bac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "meterId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "meterId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "communityId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "residualId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TradePoint",
    "kind": "LinkedField",
    "name": "tradePoint",
    "plural": false,
    "selections": [
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_TradeRuleSetCommunityFlat_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Property",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TradeRuleSetCommunityFlat_property"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Meter",
        "kind": "LinkedField",
        "name": "meter",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TradeRuleSetCommunityFlat_meter"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_TradeRuleSetCommunityFlat_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Property",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Address",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "line1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "line2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postcode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Gps",
                "kind": "LinkedField",
                "name": "gps",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "latitude",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "longitude",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timezone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publicHolidayRegion",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Meter",
        "kind": "LinkedField",
        "name": "meter",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "identifier",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tradePointId",
            "storageKey": null
          },
          {
            "alias": "communityRules",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 500
              },
              {
                "kind": "Literal",
                "name": "state",
                "value": "TRADE_RULE_STATE_ACCEPTED"
              },
              {
                "kind": "Literal",
                "name": "type",
                "value": "TRADE_TYPE_COMMUNITY"
              }
            ],
            "concreteType": "TradeRuleConnection",
            "kind": "LinkedField",
            "name": "rules",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeRuleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TradeRule",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "priority",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tradeType",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Trader",
                        "kind": "LinkedField",
                        "name": "buyer",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Trader",
                        "kind": "LinkedField",
                        "name": "seller",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClauseConnection",
                        "kind": "LinkedField",
                        "name": "clauses",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ClauseEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Clause",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "price",
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "rules(first:500,state:\"TRADE_RULE_STATE_ACCEPTED\",type:\"TRADE_TYPE_COMMUNITY\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eaa3e1cdc5ce82699a6f5dd8a534a570",
    "id": null,
    "metadata": {},
    "name": "routes_TradeRuleSetCommunityFlat_Query",
    "operationKind": "query",
    "text": "query routes_TradeRuleSetCommunityFlat_Query(\n  $id: ID!\n  $meterId: ID!\n) {\n  property(id: $id) {\n    ...TradeRuleSetCommunityFlat_property\n    id\n  }\n  meter(id: $meterId) {\n    ...TradeRuleSetCommunityFlat_meter\n    id\n  }\n}\n\nfragment TradeRuleSetCommunityFlatForm_meter on Meter {\n  id\n  identifier\n  title\n  tradePointId\n  communityRules: rules(first: 500, type: TRADE_TYPE_COMMUNITY, state: TRADE_RULE_STATE_ACCEPTED) {\n    edges {\n      node {\n        id\n        priority\n        tradeType\n        state\n        buyer {\n          userId\n          communityId\n          residualId\n          tradePoint {\n            id\n          }\n        }\n        seller {\n          userId\n          communityId\n          residualId\n          tradePoint {\n            id\n          }\n        }\n        clauses {\n          edges {\n            node {\n              price\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment TradeRuleSetCommunityFlatForm_property on Property {\n  id\n  timezone\n  publicHolidayRegion\n}\n\nfragment TradeRuleSetCommunityFlat_meter on Meter {\n  id\n  identifier\n  title\n  tradePointId\n  ...TradeRuleSetCommunityFlatForm_meter\n}\n\nfragment TradeRuleSetCommunityFlat_property on Property {\n  id\n  title\n  address {\n    line1\n    line2\n    city\n    state\n    postcode\n    gps {\n      latitude\n      longitude\n    }\n    id\n  }\n  ...TradeRuleSetCommunityFlatForm_property\n}\n"
  }
};
})();

node.hash = "f804f343bf01e02ddd5e62de312c9be9";

module.exports = node;
