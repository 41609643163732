import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';

import UserDetailsUpdateMutation from 'src/mutations/UserDetailsUpdateMutation';
import FlashesStore from 'src/stores/FlashesStore';

import ProfileSettingsForm from './ProfileSettingsForm';

class ProfileSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = { processing: false };
  }

  handleUpdate = (input) => {
    this.setState({ processing: true });
    FlashesStore.reset();

    UserDetailsUpdateMutation(
      input,
      this.handleUpdateSuccess,
      this.handleUpdateFailure,
    );
  };

  handleUpdateSuccess = (response, errors) => {
    this.setState({ processing: false });

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: { errors: [error] },
      }));
      return;
    }

    FlashesStore.flash(FlashesStore.SUCCESS, 'Updated your settings');
  };

  handleUpdateFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { viewer } = this.props;
    const { viewerUser } = viewer;
    const { email } = viewerUser;
    const { processing } = this.state;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Enosi - manage my details</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: 'Profile', path: '/profile/settings' },
            { name: 'Settings' },
          ]}
        />
        <Heading
          alias="Profile"
          context="Settings"
          address={email}
        />

        <ProfileSettingsForm
          viewerUser={viewerUser}
          handleUpdate={this.handleUpdate}
          processing={processing}
        />
      </>
    );
  }
}

ProfileSettings.propTypes = {
  viewer: PropTypes.shape({
    id: PropTypes.string,
    viewerUser: PropTypes.shape({
      id: PropTypes.string,
      email: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
      active: PropTypes.shape({
        start: PropTypes.number,
        finish: PropTypes.number,
      }),
      updatedAt: PropTypes.number,
    }),
  }),
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
};

ProfileSettings.defaultProps = {
  viewer: null,
};

export default createFragmentContainer(
  ProfileSettings,
  {
    viewer: graphql`
      fragment ProfileSettings_viewer on Viewer {
        id
        viewerUser {
          id
          email
          givenName
          familyName
          active { start finish }
          updatedAt
          ...ProfileSettingsForm_viewerUser
        }
      }
    `,
  },
);
