import { routerShape } from 'found/PropTypes';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Form, FormFeedback, FormGroup, FormText, Label, Input,
} from 'reactstrap';
import isEmail from 'validator/es/lib/isEmail';

import PasswordResetRequestMutation from 'src/mutations/PasswordResetRequestMutation';
import FlashesStore from 'src/stores/FlashesStore';

import AuthContainer, { AUTH_TYPE_FORGOT_PASSWORD } from '../AuthContainer';

class AuthForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      emailValid: null,
      processing: false,
    };
  }

  handleEmailChange = (e) => {
    const { value: email } = e.target;
    const emailValid = isEmail(email);

    this.setState({ email, emailValid });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, processing } = this.state;

    FlashesStore.reset();

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, 'We are still processing your request...');
      return;
    }

    if (!this.isValid()) {
      FlashesStore.flash(FlashesStore.ERROR, 'Form data not valid. Please see below.');
      return;
    }

    this.setState({ processing: true });

    PasswordResetRequestMutation(
      email,
      this.handleSubmitSuccess,
      this.handleSubmitFailure,
    );
  };

  // eslint-disable-next-line no-unused-vars
  handleSubmitSuccess = (_response) => {
    this.setState({ processing: false });

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      'Password reset was successful. Check your email shortly.',
    );

    const { router } = this.props;
    router.push('/login');
  };

  handleSubmitFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(
      FlashesStore.ERROR,
      `Password reset was NOT successful: ${error.message}.`,
    );
  };

  isValid = () => {
    const { emailValid } = this.state;

    return emailValid;
  };

  render() {
    const { email, emailValid, processing } = this.state;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Enosi - request a password reset email</title>
        </Helmet>

        <AuthContainer type={AUTH_TYPE_FORGOT_PASSWORD} title="Forgot Password">
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label htmlFor="email">Your account’s email address</Label>
              <Input
                type="email"
                id="email"
                aria-describedby="email"
                placeholder="Your account's email address"
                onChange={this.handleEmailChange}
                value={email}
                disabled={processing}
                valid={emailValid}
                invalid={emailValid !== null && !emailValid}
              />
              <FormFeedback>Email is not valid</FormFeedback>
              <FormText>Your email address associated with your Enosi account</FormText>
            </FormGroup>
            <Button type="submit" color="primary" disabled={processing}>
              Send password reset link
            </Button>
          </Form>
        </AuthContainer>
      </>
    );
  }
}

AuthForgotPassword.propTypes = {
  router: routerShape.isRequired,
};

export default AuthForgotPassword;
