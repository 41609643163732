import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Div = styled.div.attrs({
  className: 'd-flex flex-wrap header mt-4 mb-4',
})``;

const Separator = styled.span.attrs({
  className: 'ms-3 me-3',
})`
  font-weight: lighter;
`;

const Controls = styled.div.attrs({
  className: 'ms-auto',
})``;

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - Heading component
 */
function Heading(props) {
  const {
    alias,
    address,
    context,
    separator,
    controls,
  } = props;

  return (
    <Div>
      <div className="mb-2">
        <h1>
          {alias}
          {alias && <Separator>{separator}</Separator>}
          {context}
        </h1>
        <div>
          {address}
        </div>
      </div>
      {controls && (
        <Controls>
          {controls}
        </Controls>
      )}
    </Div>
  );
}

Heading.propTypes = {
  context: PropTypes.string.isRequired,
  alias: PropTypes.string,
  address: PropTypes.string,
  controls: PropTypes.element,
  separator: PropTypes.string,
};

Heading.defaultProps = {
  alias: null,
  address: null,
  controls: null,
  separator: '/',
};

export default Heading;
