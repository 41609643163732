import { format } from 'd3-format';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { APIConfig } from 'src/config';
import { PLATFORM_MODE_REBATE, TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL } from 'src/util/constants';

import {
  Card, CardBody, CardFooter, Progress,
  Popover, PopoverBody,
} from 'reactstrap';
import { getTradeRuleContent } from 'src/helpers/tradeHelpers';

const TradeType = styled.div.attrs({
  className: 'mt-2 py-1',
})`
  text-transform: uppercase;
  color: $primary;
`;

const TradePrimaryText = styled.div.attrs({
  className: 'py-1',
})`
  line-height: 1rem;
  font-size: 1.5rem;
`;

const TradeSecondaryText = styled.div.attrs({
  className: 'py-1',
})``;

const TradeProgress = styled(Progress).attrs({
  className: 'my-2 rounded-pill',
})`
  height: 1.5rem;
`;

const TradeProgressBar = styled(Progress).attrs({
  className: 'rounded-pill',
})``;

const TradeProgressLabel = styled.span`
  color: #ffffff;
  display: block;
  line-height: 200%;
  cursor: help;
`;

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - trade summary component
 */
function TradeSummary(props) {
  const { category, data } = props;
  const [popoverState, setPopoverState] = useState('');

  const resetToggle = () => {
    setPopoverState('');
  };

  const toggle = (ruleId) => {
    setPopoverState(ruleId);
  };

  let title;
  let description;
  let progressColor;
  let direction;
  if (category === 'exports') {
    title = 'Energy Exports';
    description = 'A summary of where the energy you exported to the grid has been sold to.';
    progressColor = 'primary';
    direction = TRADE_DIRECTION_SELL;
  } else {
    title = 'Energy Imports';
    description = 'A summary of where the energy you imported from the grid has been bought from.';
    progressColor = 'secondary';
    direction = TRADE_DIRECTION_BUY;
  }

  let totalVolume = 0;
  Object.keys(data).forEach((type) => {
    totalVolume += data[type].reduce((sum, x) => sum + x.volume, 0);
  });

  const content = ['contracted', 'nominated', 'community', 'residual'].map((type) => {
    if (!data[type] || data[type].length === 0) {
      return null;
    }

    const typeLabels = {
      contracted: 'Contracted',
      nominated: 'Peer-to-peer',
      community: 'Community',
      residual: 'Retailer default',
    };
    const name = `${typeLabels[type]} trades`;
    const typeValue = data[type].reduce((sum, x) => sum + x.value, 0);
    const typeVolume = data[type].reduce((sum, x) => sum + x.volume, 0);
    const progress = (
      <TradeProgress multi>
        {data[type].sort((a, b) => b.volume - a.volume).map((set) => {
          const { rule, volume } = set;
          const { id: ruleId } = rule;
          const percentage = volume / totalVolume;
          const popoverTarget = `trade-rule-${ruleId}-${direction}`;
          return (
            <React.Fragment key={ruleId}>
              <TradeProgressBar bar value={100 * percentage} color={progressColor}>
                <TradeProgressLabel
                  onMouseEnter={() => toggle(ruleId)}
                  onMouseOut={() => resetToggle()}
                  id={popoverTarget}
                >
                  {`${format('.0%')(percentage)}`}
                </TradeProgressLabel>
              </TradeProgressBar>
              <Popover placement="bottom" isOpen={popoverState === ruleId} target={popoverTarget} toggle={toggle}>
                <PopoverBody>
                  {getTradeRuleContent(direction, rule)}
                </PopoverBody>
              </Popover>
            </React.Fragment>
          );
        })}
      </TradeProgress>
    );

    let details;
    if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
      details = (
        <>
          <TradePrimaryText>{`${format('.4s')(typeVolume)}Wh`}</TradePrimaryText>
          {type !== 'residual' && (
            <TradeSecondaryText>
              Discount amount:
              <br />
              {`${format('$.2f')(typeValue)}`}
            </TradeSecondaryText>
          )}
        </>
      );
    } else {
      details = (
        <>
          <TradePrimaryText>{`${format('$.2f')(typeValue)}`}</TradePrimaryText>
          <TradeSecondaryText>{`${format('.4s')(typeVolume)}Wh`}</TradeSecondaryText>
        </>
      );
    }

    return (
      <div key={type}>
        {progress}
        <TradeType>{name}</TradeType>
        {details}
      </div>
    );
  });

  return (
    <div className="col mb-4 mb-sm-0">
      <Card className={`h-100 trade-summary-${category}`}>
        <CardBody>
          <h2>{title}</h2>
          <div>{content}</div>
        </CardBody>
        <CardFooter>
          {description}
        </CardFooter>
      </Card>
    </div>
  );
}

TradeSummary.propTypes = {
  category: PropTypes.oneOf(['imports', 'exports']).isRequired,
  data: PropTypes.shape({
    nominated: PropTypes.arrayOf(PropTypes.shape({
      tradeRuleId: PropTypes.string,
      value: PropTypes.number,
      volume: PropTypes.number,
    })),
    contracted: PropTypes.arrayOf(PropTypes.shape({
      tradeRuleId: PropTypes.string,
      value: PropTypes.number,
      volume: PropTypes.number,
    })),
    community: PropTypes.arrayOf(PropTypes.shape({
      tradeRuleId: PropTypes.string,
      value: PropTypes.number,
      volume: PropTypes.number,
    })),
    residual: PropTypes.arrayOf(PropTypes.shape({
      tradeRuleId: PropTypes.string,
      value: PropTypes.number,
      volume: PropTypes.number,
    })),
  }).isRequired,
};

export default TradeSummary;
