import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import { getTradePointInlineLabel } from 'src/helpers/tradeHelpers';
import { TRADE_RULE_STATE_ACCEPTED } from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import { tradeRulePriceRange } from 'src/util/tradeRule';

import TradeRuleTimelineStep from './TradeRuleTimelineStep';

/**
 * Description
 * @param {any} props
 * @returns {any} - TradeRuleTimelineStepAccepted component
 */
function TradeRuleTimelineStepAccepted(props) {
  const {
    metersMap, ruleId, tradeType, clauses, buyer, seller, timestamp, user,
    isCurrentStep, hasNextStep,
  } = props;

  let description;
  let direction;
  if (buyer.tradePoint.id in metersMap) {
    description = (
      <>
        {'To buy for '}
        {getTradePointInlineLabel(buyer.tradePoint)}
        {' from '}
        {getTradePointInlineLabel(seller.tradePoint)}
      </>
    );
    direction = 'Buying';
  } else {
    description = (
      <>
        {'To sell from '}
        {getTradePointInlineLabel(seller.tradePoint)}
        {' to '}
        {getTradePointInlineLabel(buyer.tradePoint)}
      </>
    );
    direction = 'Selling';
  }

  const priceRange = tradeRulePriceRange({ clauses });

  return (
    <TradeRuleTimelineStep
      ruleId={ruleId}
      tradeType={tradeType}
      state={TRADE_RULE_STATE_ACCEPTED}
      timestamp={timestamp}
      user={user}
      icon={<FontAwesomeIcon icon={faCheckCircle} />}
      isCurrentStep={isCurrentStep}
      hasNextStep={hasNextStep}
    >
      <li className="mb-3">
        {description}
      </li>
      <li className="mb-3">
        <strong>{direction}</strong>
        {' at a price of '}
        <strong>
          {convertEnergyPrice(priceRange.minimum).toFixed(3)}
        </strong>
        {priceRange.maximum !== priceRange.minimum && (
          <>
            {' '}
            to
            {' '}
            <strong>
              {convertEnergyPrice(priceRange.maximum).toFixed(3)}
            </strong>
          </>
        )}
        {' '}
        <abbr title="cents per kilowatt hour">c/kWh</abbr>
      </li>
    </TradeRuleTimelineStep>
  );
}

TradeRuleTimelineStepAccepted.propTypes = {
  metersMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  ruleId: PropTypes.string.isRequired,
  tradeType: PropTypes.string.isRequired,
  buyer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  seller: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  clauses: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.shape({
      node: (PropTypes.shape({
        price: PropTypes.number.isRequired,
      })),
    })),
  }).isRequired,
  timestamp: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
  }).isRequired,
  isCurrentStep: PropTypes.bool.isRequired,
  hasNextStep: PropTypes.bool.isRequired,
};

TradeRuleTimelineStepAccepted.defaultProps = {
};

export default TradeRuleTimelineStepAccepted;
