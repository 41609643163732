import { Auth } from 'aws-amplify';
import { routerShape } from 'found/PropTypes';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Form, FormGroup, Label, Button,
} from 'reactstrap';

import FlashesStore from 'src/stores/FlashesStore';

import { tracingState } from 'src/tracing';
import { shutdownIntercom } from 'src/util/intercom';
import { AuthLogoutContainer } from '../AuthContainer';

/**
 * Description
 * @returns {boolean} - signout result true or false
 */
async function signOut() {
  try {
    await Auth.signOut({ global: true });
    FlashesStore.flash(FlashesStore.SUCCESS, 'Logged out successfully');
    return true;
  } catch (error) {
    FlashesStore.flash(FlashesStore.ERROR, error.message);
    console.log({ message: 'error signing out', error });
    return false;
  }
}

class AuthLogout extends React.Component {
  handleConfirmLogout = async (e) => {
    e.preventDefault();
    const { router } = this.props;
    const response = await signOut();
    if (response) {
      shutdownIntercom();
      router.push('/login');
      tracingState.clearUserID();
      tracingState.clearPropertyID();
    }
  };

  render() {
    const { router } = this.props;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Logout of Enosi</title>
        </Helmet>
        <AuthLogoutContainer title="Confirm logout">
          <Form onSubmit={this.handleConfirmLogout}>
            <FormGroup>
              <Label htmlFor="formConfirm">Are you sure you want to leave?</Label>
            </FormGroup>
            <Button color="" onClick={() => (router.go(-1))}>
              &laquo; Go back
            </Button>
            {' '}
            <Button type="submit" color="primary">
              Log out
            </Button>
          </Form>
        </AuthLogoutContainer>
      </>
    );
  }
}

AuthLogout.propTypes = {
  router: routerShape.isRequired,
};

export default AuthLogout;
