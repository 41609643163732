import PropTypes from 'prop-types';
import React from 'react';
import {
  Button, Card, CardBody,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Days, Months } from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import { timeOfUseDetails } from 'src/util/timeOfUse';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - ClauseShow component
 */
function ClauseShow(props) {
  const {
    clause, showControls, editClause, removeClause,
  } = props;

  return (
    <Card className="mb-2">
      <CardBody className="d-flex">
        <div>
          <strong>{convertEnergyPrice(clause.price).toFixed(3)}</strong>
          {' '}
          <abbr title="cents per kilowatt hour">c/kWh</abbr>
          {' '}
          {timeOfUseDetails(clause)}
        </div>
        {showControls && (
          <div className="ms-auto flex-shrink-0">
            <Button color="" onClick={editClause}>
              <FontAwesomeIcon icon={faPen} />
            </Button>
            <Button color="" onClick={removeClause}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        )}
      </CardBody>
    </Card>
  );
}

const timeOfDayShape = PropTypes.shape({
  hours: PropTypes.number,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  nanos: PropTypes.number,
});

ClauseShow.propTypes = {
  clause: PropTypes.shape({
    price: PropTypes.number,
    monthsOfYear: PropTypes.arrayOf(PropTypes.oneOf(Months)),
    daysOfWeek: PropTypes.arrayOf(PropTypes.oneOf(Days)),
    timesOfDay: PropTypes.arrayOf(PropTypes.shape({
      start: timeOfDayShape,
      finish: timeOfDayShape,
    })),
  }).isRequired,
  showControls: PropTypes.bool.isRequired,
  editClause: PropTypes.func.isRequired,
  removeClause: PropTypes.func.isRequired,
};

export default ClauseShow;
