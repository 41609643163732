import { Auth } from 'aws-amplify';
import { matchShape, routerShape } from 'found/PropTypes';
import { Helmet } from 'react-helmet-async';
import React from 'react';
import {
  Button, Form, FormFeedback, FormGroup, FormText, Input, Label,
} from 'reactstrap';
import isEmail from 'validator/es/lib/isEmail';
import isLength from 'validator/es/lib/isLength';

import FlashesStore from 'src/stores/FlashesStore';

import { withTracingAsync } from 'src/tracing';
import { setLoggedInUserDetails } from 'src/util/intercom';
import AuthContainer, { AUTH_TYPE_LOGIN } from '../AuthContainer';

/**
 * Description
 * @param {any} username
 * @param {any} password
 * @returns {boolean} - sign in true or false
 */
async function signInRaw(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    setLoggedInUserDetails(user?.attributes);
    return true;
  } catch (error) {
    FlashesStore.flash(FlashesStore.ERROR, error.message);
    console.log({ message: 'error signing in', error });
    return false;
  }
}

/**
 * Description
 * @param {any} username
 * @param {any} password
 * @returns {any} - promise
 */
async function signIn(username, password) {
  return withTracingAsync('signin', signInRaw, username, password);
}

class AuthLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailValid: null,
      password: '',
      passwordValid: null,
      processing: false,
    };
  }

  redirect = () => {
    const { match } = this.props;
    const { location } = match;
    const { search } = location;

    const regex = /[?&]redirect(=([^&#]*)|&|#|$)/i;
    const results = regex.exec(search);

    if (!results || !results[2]) {
      return '/';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  handleEmailChange = (e) => {
    const { value: email } = e.target;
    const emailValid = isEmail(email);

    this.setState({ email, emailValid });
  };

  handlePasswordChange = (e) => {
    const { value: password } = e.target;
    const passwordValid = isLength(password, { min: 8 });

    this.setState({ password, passwordValid });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { router } = this.props;
    const { email, password, processing } = this.state;

    FlashesStore.reset();

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, 'We are still processing your request...');
      return;
    }

    if (!this.isValid()) {
      FlashesStore.flash(FlashesStore.ERROR, 'Form data not valid. Please see below.');
      return;
    }

    try {
      this.setState({ processing: true });

      const login = await signIn(email, password);

      this.setState({ processing: false });

      if (login) {
        const redirect = this.redirect();
        console.log('redirect', redirect);
        FlashesStore.flash(FlashesStore.SUCCESS, `Login succeeded, redirecting to ${redirect}`);
        router.push(redirect);
      }
    } catch (error) {
      this.setState({ processing: false });

      FlashesStore.flash(FlashesStore.ERROR, error);
      console.log({ error });
    }
  };

  isValid = () => {
    const { emailValid, passwordValid } = this.state;

    return emailValid && passwordValid;
  };

  render() {
    const {
      email, emailValid, password, passwordValid, processing,
    } = this.state;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Log in to Enosi</title>
        </Helmet>

        <AuthContainer type={AUTH_TYPE_LOGIN} title="Login">
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label htmlFor="email">Email address</Label>
              <Input
                type="email"
                id="email"
                aria-describedby="email"
                placeholder="Enter email"
                onChange={this.handleEmailChange}
                value={email}
                valid={emailValid}
                invalid={emailValid !== null && !emailValid}
                disabled={processing}
              />
              <FormFeedback>Invalid email address</FormFeedback>
              <FormText>Email address associated with your Enosi account</FormText>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">Password</Label>
              <Input
                type="password"
                id="password"
                aria-describedby="password"
                placeholder="Enter password"
                onChange={this.handlePasswordChange}
                value={password}
                valid={passwordValid}
                invalid={passwordValid !== null && !passwordValid}
                disabled={processing}
              />
              <FormFeedback>Password must be at least 8 characters long</FormFeedback>
            </FormGroup>
            <Button type="submit" color="primary" disabled={processing}>
              Log in
            </Button>
          </Form>
        </AuthContainer>
      </>
    );
  }
}

AuthLogin.propTypes = {
  match: matchShape.isRequired,
  router: routerShape.isRequired,
};

export default AuthLogin;
