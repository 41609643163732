import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createPaginationContainer, graphql } from 'react-relay';

import { Button, ButtonGroup, ButtonToolbar } from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import username from 'src/util/decorators/username';

import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminPropertyList component
 */
function AdminPropertyUserList(props) {
  const loadMore = () => {
    const { relay } = props;

    if (!relay.hasMore() || relay.isLoading()) {
      return;
    }

    relay.loadMore(
      50,
      (error) => { console.log(error); },
    );
  };

  if (!props) {
    return <Loading />;
  }

  const { relay, viewer } = props;
  const { propertyUsers } = viewer;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Enosi Admin - Property Users</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Property Users' },
        ]}
      />

      <div className="clearfix">
        <h1 className="float-start">Property Users</h1>
        <div className="float-end mb-4">
          <ButtonToolbar>
            <ButtonGroup className="ms-2">
              <Link
                to="/admin/property-users/new"
                className="btn btn-darken"
              >
                Add Property User
              </Link>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
      </div>

      <div className="mt-4 mb-4 card">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">
                  <abbr title="Identifier">ID</abbr>
                </th>
                <th scope="col">
                  <abbr title="External Identifier">Ext ID</abbr>
                </th>
                <th scope="col">User</th>
                <th scope="col">Property</th>
              </tr>
            </thead>
            <tbody>
              {
                // properties && <pre>{JSON.stringify(properties, null, 2)}</pre>
                propertyUsers && propertyUsers.edges && propertyUsers.edges.map((edge) => {
                  const { node } = edge;
                  const {
                    active, id, externalIdentifier, user, property,
                  } = node;
                  const { start, finish } = active;
                  const {
                    id: userId, active: userActive, email: userEmail,
                  } = user;
                  const {
                    id: propertyId, title: propertyTitle, active: propertyActive,
                  } = property;

                  return (
                    <tr key={`admin-property-user-${id}`}>
                      <td>
                        <BadgeActive start={start} finish={finish} />
                        <br />
                        {DateTime.fromSeconds(start).toISO()}
                        {' to '}
                        {finish ? DateTime.fromSeconds(finish).toISO() : 'the future'}
                      </td>
                      <th scope="row" colSpan={2}>
                        <Link to={`/admin/property-users/${id}`}>
                          <UUID uuid={id} />
                        </Link>
                        <br />
                        {`(${externalIdentifier})`}
                      </th>
                      <td>
                        <Link to={`/admin/users/${userId}`}>
                          {username(user)}
                        </Link>
                        <br />
                        {userEmail}
                        <br />
                        <BadgeActive
                          start={userActive.start}
                          finish={userActive.finish}
                        />
                      </td>
                      <td>
                        <Link to={`/admin/properties/${propertyId}`}>
                          {propertyTitle}
                        </Link>
                        <br />
                        <UUID uuid={propertyId} />
                        <br />
                        <BadgeActive
                          start={propertyActive.start}
                          finish={propertyActive.finish}
                        />
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <div className="card-footer">
          <Button onClick={loadMore} disabled={!relay.hasMore()}>Load more...</Button>
        </div>
      </div>
    </>
  );
}

AdminPropertyUserList.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  viewer: PropTypes.shape({
    id: PropTypes.string,
    viewerUser: PropTypes.shape({
      email: PropTypes.string,
      givenName: PropTypes.string,
      familyName: PropTypes.string,
    }),
    propertyUsers: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            externalIdentifier: PropTypes.string,
            active: PropTypes.shape({
              start: PropTypes.number,
              finish: PropTypes.number,
            }),
            property: PropTypes.shape({
              id: PropTypes.string,
              title: PropTypes.string,
              active: PropTypes.shape({
                start: PropTypes.number,
                finish: PropTypes.number,
              }),
              address: PropTypes.shape({
                line1: PropTypes.string,
                line2: PropTypes.string,
                city: PropTypes.string,
                state: PropTypes.string,
                postcode: PropTypes.string,
              }),
            }),
            user: PropTypes.shape({
              id: PropTypes.string,
              email: PropTypes.string,
              givenName: PropTypes.string,
              familyName: PropTypes.string,
              active: PropTypes.shape({
                start: PropTypes.number,
                finish: PropTypes.number,
              }),
            }),
          }),
        }),
      ),
    }),
  }),
};

AdminPropertyUserList.defaultProps = {
  viewer: null,
};

export default createPaginationContainer(
  AdminPropertyUserList,
  {
    viewer: graphql`
      fragment AdminPropertyUserList_viewer on Viewer {
        id
        viewerUser {
          email
          givenName
          familyName
        }
        propertyUsers(
          first: $count
          after: $cursor
        ) @connection(key: "AdminPropertyUserList_propertyUsers") {
          edges {
            cursor
            node {
              id
              externalIdentifier
              active {
                start
                finish
              }
              property {
                id
                title
                active { start finish }
              }
              user {
                id
                email
                givenName
                familyName
                active { start finish }
              }
            }
          }
          count
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer.propertyUsers;
    },
    // This is also the default implementation of `getFragmentVariables` if it isn't provided.
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
      };
    },
    query: graphql`
    # Pagination query to be fetched upon calling 'loadMore'.
    # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
    query AdminPropertyUserList_Pagination_Query(
      $count: Int!
      $cursor: String
    ) {
      viewer {
        ...AdminPropertyUserList_viewer
      }
    }
  `,
  },
);
