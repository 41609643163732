/* eslint-disable camelcase */
import { DateTime } from 'luxon';
import { APIConfig } from 'src/config';

/**
 * Generates the payload to be passed to intercom.
 * Basic payload will be the current time and platform domain.
 * Will append the logged in userdetails  from the local storage(if available).
 * @returns {object} - payload to intercom
 */
export const getIntercomPayload = () => {
  const loggedInUser = localStorage.getItem('loggedInUser');
  const userDetails = loggedInUser ? JSON.parse(localStorage.getItem('loggedInUser')) : '';
  const basicPayload = {
    last_request_at: Math.floor(DateTime.local().toSeconds()),
    powertracer_platform_domain: window.location.hostname,
  };
  if (!userDetails) {
    return basicPayload;
  }
  return { ...userDetails, ...basicPayload };
};

/**
 * Concatenates the first and last name of the user.
 * @param {string} firstName
 * @param {string} lastName
 * @returns {string} - username
 */
export const getUserName = (firstName, lastName) => `${firstName || ''} ${lastName || ''}`?.trim();

/**
 * Initiate and renders Intercom widget.
 * Updates/pings intercom with the latest info when there is a change (ex: page navigation).
 * @param {string} intercomAppId
 * @returns {void}
 */
export const renderIntercom = (intercomAppId) => {
  // eslint-disable-next-line
  (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = `https://widget.intercom.io/widget/${intercomAppId}`; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();

  window.Intercom('boot', {
    app_id: intercomAppId,
  });

  if (document.querySelector('title')) {
    new MutationObserver(() => {
      window.Intercom('update', getIntercomPayload());
    })?.observe(
      document.querySelector('title'),
      { subtree: true, characterData: true, childList: true },
    );
  }
};

/**
 * After a successful login render intercom widget
 */
export const intercomHanlder = () => {
  const { INTERCOM_APP_ID } = APIConfig();
  if (INTERCOM_APP_ID) {
    renderIntercom(INTERCOM_APP_ID);
  } else {
    console.log('Could not load Intercom widget because app id is missing');
  }
};

/**
 * Set logged in user details in local storage.
 * Logged in User details may contain email, id, first and last name.
 * @param {object} userAttributes
 * @returns {void}
 */
export const setLoggedInUserDetails = (userAttributes) => {
  if (!userAttributes) {
    return;
  }
  const { email, family_name: lastName, given_name: firstName } = userAttributes;
  const loggedInUser = {
    email,
    name: getUserName(firstName, lastName),
    user_id: userAttributes['custom:id'],
  };
  localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
  intercomHanlder();
};

/**
 * Close/shutdown Intercom when user logs out or when a session ends.
 * Clears user information from the local storage.
 * @returns {void}
 */
export const shutdownIntercom = () => {
  if (window.Intercom) {
    window.Intercom('shutdown');
  }
  localStorage.removeItem('loggedInUser');
};
