import { commitMutation, graphql } from 'react-relay';

import environment from 'src/util/environment';

const mutation = graphql`
  mutation PropertySettingsUpdateMutation($id: ID!, $input: PropertySettingsUpdateInput!) {
    propertySettingsUpdate(id: $id, input: $input) {
      id
      externalIdentifier
      title
      active { start finish }
      createdAt
      updatedAt
    }
  }
`;

const PropertySettingsUpdateMutation = (id, input, onCompleted, onError) => {
  const variables = { id, input };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted,
      onError,
    },
  );
};

export default PropertySettingsUpdateMutation;
