import { ENOSI_USER_AUTH_RESET_PASSWORD_CONFIRM } from 'src/util/constants';
import { fetchREST } from 'src/util/environment';
// import { commitMutation, graphql } from 'react-relay';

// const mutation = graphql`
//   mutation PasswordResetConfirmMutationMutation(
//     $email: String!
//     $password: String!
//     $verificationCode: String!
//   ) {
//     passwordResetConfirm(
//       email: $email
//       password: $password
//       verificationCode: $verificationCode
//     )
//   }
// `;

const PasswordResetConfirmMutation = (req, onCompleted, onError) => {
  const { email, password, verificationCode } = req;
  const variables = { email, password, verificationCode };

  // TODO: until we have a unauthenticated endpoint for auth
  // commitMutation(environment, {
  //   mutation,
  //   variables,
  //   onCompleted,
  //   onError,
  // });

  fetchREST(
    ENOSI_USER_AUTH_RESET_PASSWORD_CONFIRM,
    JSON.stringify(variables),
    onCompleted,
    onError,
  );
};

export default PasswordResetConfirmMutation;
