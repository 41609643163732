import { Link, RedirectException, withRouter } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import styled from 'styled-components';

import {
  Collapse, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
  Nav, Navbar, NavbarToggler,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog, faSignOutAlt, faUserCircle, faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

import { AvatarItem } from 'src/enosikit/components/Avatar';

import AnonymousNavigation from 'src/components/Navigation/AnonymousNavigation';
import NavigationLogo from 'src/components/Navigation/NavigationLogo';
import { APIConfig } from 'src/config';

const RightNav = styled(Nav).attrs({
  className: 'ms-auto',
})``;

const LeftNav = styled(Nav).attrs({
  className: 'me-auto',
})``;

class AdminNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adminDropdownOpen: false,
      navbarOpen: false,
      profileDropdownOpen: false,
    };
  }

  toggleNavbar = () => {
    this.setState((prevState) => ({ navbarOpen: !prevState.navbarOpen }));
  };

  toggleAdmin = () => {
    this.setState((prevState) => ({ adminDropdownOpen: !prevState.adminDropdownOpen }));
  };

  toggleProfile = () => {
    this.setState((prevState) => ({ profileDropdownOpen: !prevState.profileDropdownOpen }));
  };

  render() {
    const { viewer } = this.props;
    const { adminDropdownOpen, navbarOpen, profileDropdownOpen } = this.state;

    if (!viewer) {
      const { match } = this.props;
      const { location } = match;
      const { pathname, search } = location;

      throw new RedirectException(`/login?redirect=${pathname}${search}`);
    }

    const { viewerUser } = viewer;
    const { givenName, familyName, email } = viewerUser;
    let name = [givenName, familyName].filter(Boolean).join(' ');
    if (name === '') {
      name = email;
    }

    if (!viewer) {
      return <AnonymousNavigation />;
    }

    const cobrand = APIConfig().cobrand();
    const primaryBrand = APIConfig().primaryBrand();

    return (
      <Navbar light expand="lg">
        <Container className="d-flex flex-wrap justify-content-between px-0 px-sm-3">
          <Link to="/" className="navbar-brand">
            <NavigationLogo cobrand={cobrand} primaryBrand={primaryBrand} />
          </Link>
          <NavbarToggler onClick={this.toggleNavbar} />
          <Collapse isOpen={navbarOpen} navbar>
            <LeftNav navbar>
              <Dropdown nav inNavbar isOpen={adminDropdownOpen} toggle={this.toggleAdmin}>
                <DropdownToggle nav>
                  Admin
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>Common</DropdownItem>
                  <Link to="/admin/users" className="dropdown-item" role="menuitem" onClick={this.toggleAdmin}>
                    Users
                  </Link>
                  <DropdownItem header>Customer</DropdownItem>
                  <Link to="/admin/communities" className="dropdown-item" role="menuitem" onClick={this.toggleAdmin}>
                    Communities
                  </Link>
                  <Link to="/admin/property-users" className="dropdown-item" role="menuitem" onClick={this.toggleAdmin}>
                    PropertyUsers
                  </Link>
                  <Link to="/admin/properties" className="dropdown-item" role="menuitem" onClick={this.toggleAdmin}>
                    Properties
                  </Link>
                  <Link to="/admin/primary-billing-points" className="dropdown-item" role="menuitem" onClick={this.toggleAdmin}>
                    Primary Billing Points
                  </Link>
                  <Link to="/admin/meters" className="dropdown-item" role="menuitem" onClick={this.toggleAdmin}>
                    Meters
                  </Link>
                  <Link to="/admin/standing-charges" className="dropdown-item" role="menuitem" onClick={this.toggleAdmin}>
                    Standing Charges
                  </Link>
                  <Link to="/admin/tariffs" className="dropdown-item" role="menuitem" onClick={this.toggleAdmin}>
                    Tariffs
                  </Link>
                  <Link to="/admin/volume-charges" className="dropdown-item" role="menuitem" onClick={this.toggleAdmin}>
                    Volume Charges
                  </Link>
                  <DropdownItem divider />
                  <DropdownItem header>Historian</DropdownItem>
                  <Link to="/admin/historian/slices" className="dropdown-item" role="menuitem" onClick={this.toggleAdmin}>
                    Slices
                  </Link>
                  <DropdownItem divider />
                  <DropdownItem header>Trades</DropdownItem>
                  <Link to="/admin/trades" className="dropdown-item" role="menuitem" onClick={this.toggleAdmin}>
                    Trades
                  </Link>
                  <DropdownItem divider />
                  <DropdownItem header>Trade Rules</DropdownItem>
                  <Link to="/admin/trade-rules" className="dropdown-item" role="menuitem" onClick={this.toggleAdmin}>
                    Trade Rules
                  </Link>
                </DropdownMenu>
              </Dropdown>
            </LeftNav>
            <RightNav navbar>
              <Dropdown nav inNavbar isOpen={profileDropdownOpen} toggle={this.toggleProfile}>
                <DropdownToggle nav>
                  <AvatarItem
                    primaryText={name}
                    secondaryText={email}
                    avatarImage={<FontAwesomeIcon icon={faUserCircle} size="3x" />}
                    className="d-inline-flex"
                  />
                  <FontAwesomeIcon icon={faCaretDown} size="1x" className="ms-2" />
                </DropdownToggle>
                <DropdownMenu end>
                  <Link to="/profile/settings" activeClassName="active" className="dropdown-item" role="menuitem">
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    My settings
                  </Link>
                  <DropdownItem divider />
                  <Link to="/logout" activeClassName="active" className="dropdown-item" role="menuitem">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                    Log out
                  </Link>
                </DropdownMenu>
              </Dropdown>
            </RightNav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

AdminNavigation.propTypes = {
  match: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  }).isRequired,
  viewer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminNavigation.defaultProps = {
  viewer: null,
};

export default withRouter(createFragmentContainer(AdminNavigation, {
  viewer: graphql`
    fragment AdminNavigation_viewer on Viewer {
      id
      viewerUser {
        email
        givenName
        familyName
      }
    }
  `,
}));
