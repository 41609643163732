import { format } from 'd3-format';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { PLATFORM_MODE_REBATE } from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import { formatCarbonEmission } from './ChartHelpers';
import { UNIT_CARBON_TOOLTIP_FORMAT } from './chartConstants';

const Div = styled.div`
  border-${(props) => props.align}:
    .25rem solid ${(props) => (props.category === 'imports' ? '#e6c644' : '#3bb667')};
    margin-${(props) => props.align}: .5rem;
    padding-${(props) => props.align}: 1rem;
    text-align: ${(props) => props.align};
`;

const Title = styled.h3.attrs({
  className: 'text-uppercase',
})`
  font-size: .8rem;
  color: #adb5bd;
`;

const getCarbonData = (carbonValue) => {
  const finalValues = formatCarbonEmission(carbonValue);
  const { value: finalValue, label } = finalValues;
  return `${format(UNIT_CARBON_TOOLTIP_FORMAT)(finalValue)}${label}`;
};

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - ChartSummary component
 */
function ChartSummary(props) {
  const {
    category, tradedValue, tradedEnergy, untradedEnergy, rebatedEnergy, align, mode, carbonData,
  } = props;

  const title = category === 'imports' ? 'Energy Imports' : 'Energy Exports';

  if (mode === PLATFORM_MODE_REBATE) {
    return (
      <Div category={category} align={align}>
        <Title>{title}</Title>
        <dl>
          <dt>Traded energy</dt>
          <dd>
            {format('.4s')(tradedEnergy === undefined ? 0 : tradedEnergy)}
            Wh
          </dd>
          <dt>Discounted energy</dt>
          <dd>
            {format('.4s')(rebatedEnergy === undefined ? 0 : rebatedEnergy)}
            Wh
          </dd>
          <dt>Discount amount</dt>
          <dd>
            {format('$.2f')(tradedValue === undefined ? 0 : tradedValue)}
          </dd>
          {untradedEnergy > 0 && (
            <>
              <dt>Untraded energy</dt>
              <dd>
                {format('.4s')(untradedEnergy)}
                Wh
              </dd>
            </>
          )}
        </dl>
      </Div>
    );
  }

  let averagePrice = 0;
  if (tradedValue !== undefined && tradedEnergy !== undefined && tradedEnergy !== 0) {
    averagePrice = tradedValue / tradedEnergy;
  }

  return (
    <Div category={category} align={align} className={`summary-${category}`}>
      <Title>{title}</Title>
      <dl>
        <dt>Average Price</dt>
        <dd>
          {format('.3f')(convertEnergyPrice(averagePrice))}
          c/kWh
        </dd>
        <dt>Traded value</dt>
        <dd>
          {format('$.2f')(tradedValue === undefined ? 0 : tradedValue)}
        </dd>
        <dt>Traded energy</dt>
        <dd>
          {format('.4s')(tradedEnergy === undefined ? 0 : tradedEnergy)}
          Wh
        </dd>
        {untradedEnergy > 0 && (
          <div className="summary-untraded-energy">
            <dt>Untraded energy</dt>
            <dd>
              {format('.4s')(untradedEnergy)}
              Wh
            </dd>
          </div>
        )}
        {carbonData > 0 && (
          <>
            <dt>Carbon</dt>
            <dd>
              {getCarbonData(carbonData)}
            </dd>
          </>
        )}
      </dl>
    </Div>
  );
}

ChartSummary.propTypes = {
  category: PropTypes.oneOf(['imports', 'exports']).isRequired,
  tradedValue: PropTypes.number.isRequired,
  tradedEnergy: PropTypes.number.isRequired,
  untradedEnergy: PropTypes.number.isRequired,
  rebatedEnergy: PropTypes.number,
  align: PropTypes.oneOf(['left', 'right']),
  mode: PropTypes.string.isRequired,
  carbonData: PropTypes.number,
};

ChartSummary.defaultProps = {
  align: 'left',
  rebatedEnergy: 0,
  carbonData: 0,
};

export default ChartSummary;
