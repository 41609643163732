/**
 * @generated SignedSource<<5f30667786f7a08cea568658ea704967>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rules"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tradePointId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "rules",
        "variableName": "rules"
      },
      {
        "kind": "Variable",
        "name": "tradePointId",
        "variableName": "tradePointId"
      }
    ],
    "concreteType": "TradeRule",
    "kind": "LinkedField",
    "name": "prioritizeNominatedTrades",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PrioritizeNominatedTradesMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PrioritizeNominatedTradesMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "63c9457e65ff32f7ec338ef2edd7cd9c",
    "id": null,
    "metadata": {},
    "name": "PrioritizeNominatedTradesMutation",
    "operationKind": "mutation",
    "text": "mutation PrioritizeNominatedTradesMutation(\n  $tradePointId: ID!\n  $rules: [RuleWithPriority!]!\n) {\n  prioritizeNominatedTrades(tradePointId: $tradePointId, rules: $rules) {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "c4a6e099002daf2c2af7daf739738caf";

module.exports = node;
