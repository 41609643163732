import { format } from 'd3-format';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Card, CardBody, Row, Col,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { APIConfig } from 'src/config';
import { PLATFORM_MODE_REBATE } from 'src/util/constants';

/**
 * Returns the avatar shown as part of the trade and meter cards
 * @param {object} user
 * @param {string} ring
 * @returns {React.ReactElement} - avatar used in meter and trade cards
 */
export const avatar = (user, ring) => {
  const baseStyle = {
    background: '#fff', borderRadius: '50%', height: '4em', width: '4em',
  };

  let avatarImage;
  if (user) {
    avatarImage = (
      <div className="property-card-user" style={{ ...baseStyle, background: '#fff' }}>
        <FontAwesomeIcon icon={faUserCircle} size="1x" style={{ height: '100%', width: '100%' }} />
      </div>
    );
  } else {
    avatarImage = <div style={{ ...baseStyle, background: '#fff' }} />;
  }
  return (
    <div style={{ borderRadius: '50%', padding: '0.25rem', background: ring }}>
      {avatarImage}
    </div>
  );
};

/**
 * Determines the card opacity based on the user selection
 * @param {string} key - normally tradeid or a combination of trade and meterid
 * @param {Array} hoverKeys
 * @param {Array} selectedKeys
 * @returns {string} - opacity for the cards.
 */
export const opacity = (key, hoverKeys, selectedKeys) => {
  // Default state
  if (hoverKeys.length === 0 && selectedKeys.length === 0) { return '100%'; }
  // Hovering
  if (hoverKeys.includes(key)) { return '100%'; }
  // Selected - with and without hovering
  if (selectedKeys.includes(key)) { return hoverKeys.length === 0 ? '100%' : '40%'; }
  // Not selected
  return '10%';
};

export const ring = (buy = {}, sell = {}) => {
  if (!buy && !sell) {
    return 'none';
  }
  if (buy?.count > 0 && sell?.count > 0) { return 'linear-gradient(to right, #3bb667 50%, #e6c644 50%)'; }
  if (buy?.count > 0) { return '#e6c644'; }
  if (sell?.count > 0) { return '#3bb667'; }
  return 'none';
};

/**
 * Provides the meter identifier for the meter label
 * @param {string} title
 * @param {string} identifier
 * @returns {string} - meter identifier.
 */
export const meterIdentifier = (title, identifier) => {
  if (!identifier) {
    return '';
  }

  return title ? ` (${identifier})` : identifier;
};

/**
 * Enrich the label with the property data if set.
 * @param {string} label - base label.
 * @param {string} subLabel - sub label.
 * @param {object | null} property - if set with id and title for augmenting the label.
 * @param {string} property.id - id of the property.
 * @param {string} property.title - id of the property.
 * @param {string} title - title of the meter.
 * @param {string} identifier - identifier of the meter.
 * @returns {React.ReactElement} - property label container.
 */
const getLabel = (label, subLabel, property, title, identifier) => {
  const meterLabel = `${title}${identifier && meterIdentifier(title, identifier)}`;

  if (!property) {
    return (
      <>
        {meterLabel && <h6 style={{ color: '#adb5bd' }} className="mb-2">{meterLabel}</h6>}
        <div>{label}</div>
      </>
    );
  }

  const { id } = property;

  return (
    <>
      {meterLabel && <h6 style={{ color: '#adb5bd' }} className="mb-2">{meterLabel}</h6>}
      <a href={`/properties/${id}`}>{label}</a>
      <div>{subLabel}</div>
    </>
  );
};

/**
 * Build and return the content for the meter cards.
 * @param {string} title
 * @param {string} identifier
 * @param {number} buyVolume
 * @param {number} sellVolume
 * @returns {React.ReactElement} - meter data container.
 */
export const meterContent = (title, identifier, buyVolume, sellVolume) => (
  <div className="mb-2">
    {title && <h5 style={{ color: '#adb5bd' }} className="mb-2">{title}</h5>}
    {identifier && <h6>{identifier}</h6>}
    <h6>Exports</h6>
    <div className="mb-2">{`${format('.4s')(sellVolume)}Wh`}</div>
    <h6 className="mb-2">Imports</h6>
    <div className="mb-2">{`${format('.4s')(buyVolume)}Wh`}</div>
  </div>
);

const tradeLabel = (dir) => (dir === 'sell' ? 'Exports' : 'Imports');

/**
 * Build and return the content for trade cards.
 * @param {string} label
 * @param {string} subLabel
 * @param {object} property
 * @param {object} party
 * @param {string} title - meter title.
 * @param {string} identifier - meter identifier.
 * @returns {React.ReactElement} - trade data container.
 */
export const tradeContent = (label, subLabel, property, party, title = '', identifier = '') => (
  <>
    <h5>{getLabel(label, subLabel, property, title, identifier)}</h5>
    {['sell', 'buy'].map((k) => {
      if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
        return (
          <React.Fragment key={k}>
            <h6>{tradeLabel(k)}</h6>
            <div className="mb-2">
              {`${format('.4s')(party[k].volume)}Wh`}
            </div>
            {label.toLowerCase() !== 'retailer default' && (
              <>
                <h6>Discount Amount</h6>
                <div className="mb-2">
                  {format('$.2f')(party[k].value)}
                </div>
              </>
            )}
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={k}>
          <h6>{tradeLabel(k)}</h6>
          <div className="mb-2">
            {format('$.2f')(party[k].value)}
          </div>
          <div className="mb-2">
            {`${format('.4s')(party[k].volume)}Wh`}
          </div>
        </React.Fragment>
      );
    })}
  </>
);
/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement } - PropertyShowChartCards component
 */
function PropertyShowChartCards(props) {
  const {
    counterParties, hoverKeys, hoverFunc, selectedKeys, selectedFunc, meterCard,
  } = props;

  if (!counterParties || counterParties.length === 0) {
    return null;
  }

  return (
    <Row className="mt-4">
      {
        counterParties.map((party) => {
          const {
            buy, identifier, key,
            label, property, sell, subLabel,
            title, tradeType, user,
          } = party;

          const cardOpacity = opacity(key, hoverKeys, selectedKeys);
          const cardRing = ring(buy, sell);
          const cardAvatar = avatar(user || null, cardRing);
          const cardId = tradeType ? ` trade-${tradeType}` : '';

          return (
            <Col className={`mb-4 property-cards${cardId}`} sm="6" lg="4" xl="3" key={key}>
              <Card
                className="h-100"
                style={{ opacity: cardOpacity, cursor: 'pointer' }}
                onMouseEnter={() => (hoverFunc(key, true))}
                onMouseLeave={() => (hoverFunc(key, false))}
                onClick={() => (selectedFunc(key))}
              >
                <CardBody>
                  <Row className="flex-nowrap">
                    <Col xs="auto">
                      {cardAvatar}
                    </Col>
                    <Col xs="auto">
                      {meterCard ? meterContent(title, identifier, buy.volume, sell.volume)
                        : tradeContent(label, subLabel, property, party, title, identifier)}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          );
        })
      }
    </Row>
  );
}

PropertyShowChartCards.propTypes = {
  counterParties: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.string,
        givenName: PropTypes.string,
        familyName: PropTypes.string,
        email: PropTypes.string,
      }),
      buy: PropTypes.shape({
        count: PropTypes.number,
        value: PropTypes.number,
        volume: PropTypes.number,
      }),
      sell: PropTypes.shape({
        count: PropTypes.number,
        value: PropTypes.number,
        volume: PropTypes.number,
      }),
    }),
  ),
  hoverKeys: PropTypes.arrayOf(
    PropTypes.string,
  ),
  hoverFunc: PropTypes.func,
  selectedKeys: PropTypes.arrayOf(
    PropTypes.string,
  ),
  selectedFunc: PropTypes.func,
  meterCard: PropTypes.bool,
};

PropertyShowChartCards.defaultProps = {
  counterParties: [],
  hoverKeys: [],
  hoverFunc: null,
  selectedKeys: [],
  selectedFunc: null,
  meterCard: false,
};

export default PropertyShowChartCards;
