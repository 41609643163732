import React from 'react';

import { getPropertyLink } from 'src/helpers/tradeHelpers';
import {
  TRADE_RULE_STATE_PROPOSED, TRADE_RULE_STATE_ACCEPTED, TRADE_POINT_TYPE_KNOWN,
  TRADE_RULE_STATE_CANCELLED, TRADE_RULE_STATE_CLOSED, TRADE_RULE_STATE_REJECTED,
  TRADE_POINT_TYPE_COMMUNITY, TRADE_POINT_TYPE_RESIDUAL,
} from 'src/util/constants';
import UUID from 'src/components/UUID';
import {
  ActionSpinner, ActionSpinnerWrapper, BUYING, SELLING,
  Step, RETAILER_DEFAULT_TRADE_POINT, TRADE_POINT,
} from './tradeRuleConstants';

export const getLastStep = (tradeRule) => {
  if (!tradeRule) {
    return null;
  }
  const {
    acceptedAt, rejectedAt, cancelledAt, closedAt,
  } = tradeRule;

  if (rejectedAt) {
    return TRADE_RULE_STATE_REJECTED;
  }
  if (cancelledAt) {
    return TRADE_RULE_STATE_CANCELLED;
  }
  if (closedAt) {
    return TRADE_RULE_STATE_CLOSED;
  }
  if (acceptedAt) {
    return TRADE_RULE_STATE_ACCEPTED;
  }
  return TRADE_RULE_STATE_PROPOSED;
};

export const getUserName = (userData) => {
  if (!userData) {
    return null;
  }
  const { email, familyName, givenName } = userData;
  const userName = [givenName, familyName].filter(Boolean).join(' ');
  if (userName) {
    return userName;
  }
  if (email) {
    return email;
  }
  return null;
};

export const getTradePointLabel = (trader) => {
  if (!trader) {
    return null;
  }

  const { tradePoint, community } = trader;
  const { id, type, meter } = tradePoint || {};
  let labelTitle = TRADE_POINT;
  if (type === TRADE_POINT_TYPE_KNOWN && !meter && !id) {
    return null;
  }
  if (type === TRADE_POINT_TYPE_KNOWN && meter) {
    labelTitle = getPropertyLink(tradePoint);
  } else if (type === TRADE_POINT_TYPE_COMMUNITY) {
    labelTitle = `${community?.title}`;
  } else if (type === TRADE_POINT_TYPE_RESIDUAL) {
    labelTitle = RETAILER_DEFAULT_TRADE_POINT;
  }

  return (
    <>
      <span className="trade-point-title">
        {labelTitle}
      </span>
      {' '}
      (
      <UUID uuid={id} />
      )

    </>
  );
};

// When the buyer or seller trade point id is available but the
// relevant data is not present in metersmap then
// it is is a proposed trade and we are viewing it from the counter party view
// ( the person who received the proposal)

export const identifyTradeDirection = (buyer, seller, metersMap) => {
  const buyerData = {
    direction: BUYING,
    trader: buyer,
    counterParty: seller,
  };

  const sellerData = {
    direction: SELLING,
    trader: seller,
    counterParty: buyer,
  };

  if (buyer?.tradePoint?.id) {
    if (buyer.tradePoint.id in metersMap) {
      return buyerData;
    }
    return sellerData;
  }

  if (seller?.tradePoint?.id) {
    if (seller.tradePoint.id in metersMap) {
      return sellerData;
    }
    return buyerData;
  }
  return null;
};

export const tradeRuleActions = (buttons) => {
  if (!buttons) {
    return null;
  }
  return (
    <Step className="align-items-center">
      <ActionSpinnerWrapper>
        <ActionSpinner type="grow" />
      </ActionSpinnerWrapper>
      <div className="d-flex ms-3 mt-2 mb-2">
        {buttons}
      </div>
    </Step>
  );
};
