import { Link } from 'found';
import { routerShape } from 'found/PropTypes';
import { Duration } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Form, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { ClauseEdit, ClauseShow } from 'src/components/TimeOfUse';
import { APIConfig } from 'src/config';
import ProposeCommunityTradeMutation from 'src/mutations/ProposeCommunityTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';
import {
  TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL, Days, DAY_PUBLIC_HOLIDAY, Months,
} from 'src/util/constants';
import { timeOfDayToDuration } from 'src/util/timeOfUse';

class TradeRuleSetCommunityTimeOfUseForm extends React.Component {
  static isValidPrice(price) {
    const { MIN_COMMUNITY_PRICE, MAX_COMMUNITY_PRICE } = APIConfig();

    if (MIN_COMMUNITY_PRICE !== undefined && price < MIN_COMMUNITY_PRICE) {
      return false;
    }
    if (MAX_COMMUNITY_PRICE !== undefined && price > MAX_COMMUNITY_PRICE) {
      return false;
    }

    return true;
  }

  static priceHelpText(tradeDirection) {
    let priceHelpText = '';
    if (tradeDirection === TRADE_DIRECTION_BUY) {
      priceHelpText = 'What is the maximum price, in c/kWh, that you will buy your energy from the community?';
    } else if (tradeDirection === TRADE_DIRECTION_SELL) {
      priceHelpText = 'What is the minimum price, in c/kWh, that you will sell your energy to the community?';
    }
    return priceHelpText;
  }

  static priceLabel(tradeDirection) {
    let priceLabel = '';
    if (tradeDirection === TRADE_DIRECTION_BUY) {
      priceLabel = 'Maximum buy price';
    } else if (tradeDirection === TRADE_DIRECTION_SELL) {
      priceLabel = 'Minimum sell price';
    }
    return priceLabel;
  }

  constructor(props) {
    super(props);

    const { meter } = this.props;
    const { tradePointId, communityRules } = meter;

    this.lastClauseId = 0;

    let buyClauses = [];
    const currentBuyRules = communityRules.edges
      .filter((edge) => edge.node.buyer.tradePoint.id === tradePointId);
    if (currentBuyRules.length === 1) {
      buyClauses = currentBuyRules[0].node.clauses.edges.map(this.formatClauseFromEdge);
    }

    let sellClauses = [];
    const currentSellRules = communityRules.edges
      .filter((edge) => edge.node.seller.tradePoint.id === tradePointId);
    if (currentSellRules.length === 1) {
      sellClauses = currentSellRules[0].node.clauses.edges.map(this.formatClauseFromEdge);
    }

    let ignorePublicHolidays = false;
    if (communityRules.edges.length > 0) {
      const rule = communityRules.edges[0].node;
      if (rule.clauses.edges.length > 0) {
        const clause = rule.clauses.edges[0].node;
        if (clause.ignorePublicHolidays) {
          ignorePublicHolidays = true;
        }
      }
    }

    this.state = {
      buyClauses,
      sellClauses,
      ignorePublicHolidays,
      editing: null, // { direction, index }
      processing: false,
      showConfirmCoverage: false,
    };
  }

  generateClauseId = () => {
    this.lastClauseId += 1;
    return this.lastClauseId.toString();
  };

  formatClauseFromEdge = (edge) => {
    const { node } = edge;
    const {
      price, monthsOfYear, daysOfWeek, timesOfDay,
    } = node;

    return {
      price,
      monthsOfYear,
      daysOfWeek,
      timesOfDay,
      id: this.generateClauseId(),
    };
  };

  copyFromResidual = () => {
    const { meter } = this.props;
    const { tradePointId, residualRules } = meter;

    let buyClauses = [];
    const currentBuyRules = residualRules.edges
      .filter((edge) => edge.node.buyer.tradePoint.id === tradePointId);
    if (currentBuyRules.length === 1) {
      buyClauses = currentBuyRules[0].node.clauses.edges.map(this.formatClauseFromEdge);
    }

    let sellClauses = [];
    const currentSellRules = residualRules.edges
      .filter((edge) => edge.node.seller.tradePoint.id === tradePointId);
    if (currentSellRules.length === 1) {
      sellClauses = currentSellRules[0].node.clauses.edges.map(this.formatClauseFromEdge);
    }

    if (buyClauses.length === 0 && sellClauses.length === 0) {
      return null;
    }

    let ignorePublicHolidays = false;
    if (residualRules.edges.length > 0) {
      const rule = residualRules.edges[0].node;
      if (rule.clauses.edges.length > 0) {
        const clause = rule.clauses.edges[0].node;
        if (clause.ignorePublicHolidays) {
          ignorePublicHolidays = true;
        }
      }
    }

    return { buyClauses, sellClauses, ignorePublicHolidays };
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { property, meter } = this.props;
    const { timezone, publicHolidayRegion } = property;
    const { tradePointId } = meter;
    const {
      buyClauses, sellClauses, ignorePublicHolidays, processing, showConfirmCoverage,
    } = this.state;

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, 'We are still processing your request...');
      return;
    }

    const { valid, coverage } = this.validate();

    if (!valid) {
      FlashesStore.flash(FlashesStore.ERROR, 'Form data not valid. Please see below.');
      return;
    }

    if (!showConfirmCoverage && !coverage) {
      this.toggleConfirmCoverage();
      return;
    }

    this.setState({ processing: true });
    FlashesStore.reset();

    const formatClause = (clause) => {
      const {
        price, monthsOfYear, daysOfWeek, timesOfDay,
      } = clause;

      return {
        price,
        timezone,
        publicHolidayRegion,
        ignoreDaylightSavings: false,
        ignorePublicHolidays,
        monthsOfYear,
        daysOfWeek,
        timesOfDay,
      };
    };

    const input = {
      tradePointId,
      buyClauses: buyClauses.map(formatClause),
      sellClauses: sellClauses.map(formatClause),
    };

    ProposeCommunityTradeMutation(
      input,
      this.handleSubmitSuccess,
      this.handleSubmitFailure,
    );
  };

  handleSubmitSuccess = (_response) => {
    const { property, router } = this.props;

    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.SUCCESS, 'Community trade rules have been set.');

    router.push(`/properties/${property.id}/trade-rules/active`);
  };

  handleSubmitFailure = (error) => {
    const { showConfirmCoverage } = this.state;

    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error);

    if (showConfirmCoverage) {
      this.toggleConfirmCoverage();
    }
  };

  isEditing = (direction, index) => {
    const { editing } = this.state;

    if (direction === undefined && index === undefined) {
      return !!editing;
    }

    return editing && editing.direction === direction && editing.index === index;
  };

  editClause = (direction, index) => {
    this.setState({ editing: { direction, index } });
  };

  addClause = (direction) => {
    this.setState({ editing: { direction, index: null } });
  };

  removeClause = (direction, index) => {
    const { buyClauses, sellClauses } = this.state;

    if (direction === TRADE_DIRECTION_BUY) {
      const newBuyClauses = [...buyClauses];
      newBuyClauses.splice(index, 1);
      this.setState({ buyClauses: newBuyClauses });
    }
    if (direction === TRADE_DIRECTION_SELL) {
      const newSellClauses = [...sellClauses];
      newSellClauses.splice(index, 1);
      this.setState({ sellClauses: newSellClauses });
    }
  };

  editClauseSet = (direction, index, newClause) => {
    const { buyClauses, sellClauses } = this.state;

    if (direction === TRADE_DIRECTION_BUY) {
      const newBuyClauses = [...buyClauses];
      newBuyClauses[index] = { ...newClause, id: buyClauses[index].id };
      this.setState({ buyClauses: newBuyClauses, editing: null });
    }
    if (direction === TRADE_DIRECTION_SELL) {
      const newSellClauses = [...sellClauses];
      newSellClauses[index] = { ...newClause, id: sellClauses[index].id };
      this.setState({ sellClauses: newSellClauses, editing: null });
    }
  };

  addClauseSet = (direction, newClause) => {
    const { buyClauses, sellClauses } = this.state;

    if (direction === TRADE_DIRECTION_BUY) {
      const newBuyClauses = [...buyClauses];
      newBuyClauses.push({ ...newClause, id: this.generateClauseId() });
      this.setState({ buyClauses: newBuyClauses, editing: null });
    }
    if (direction === TRADE_DIRECTION_SELL) {
      const newSellClauses = [...sellClauses];
      newSellClauses.push({ ...newClause, id: this.generateClauseId() });
      this.setState({ sellClauses: newSellClauses, editing: null });
    }
  };

  cancelEdit = () => {
    this.setState({ editing: null });
  };

  validate = () => {
    const { buyClauses, sellClauses } = this.state;

    if (buyClauses.length === 0 && sellClauses.length === 0) {
      return { valid: false };
    }

    const { valid: buyValid, coverage: buyCoverage } = this.validateClauses(buyClauses);
    const { valid: sellValid, coverage: sellCoverage } = this.validateClauses(sellClauses);

    if (!buyValid || !sellValid) {
      return { valid: false };
    }
    return { valid: true, coverage: buyCoverage && sellCoverage };
  };

  validateClauses = (clauses) => {
    const { ignorePublicHolidays } = this.state;

    const relevantDays = ignorePublicHolidays
      ? Days.filter((day) => day !== DAY_PUBLIC_HOLIDAY) : Days;

    const combinations = [];
    clauses.forEach(({ monthsOfYear, daysOfWeek, timesOfDay }) => {
      monthsOfYear.forEach((month) => {
        daysOfWeek.forEach((day) => {
          if (ignorePublicHolidays && day === DAY_PUBLIC_HOLIDAY) {
            return;
          }
          timesOfDay.forEach(({ start, finish }) => {
            combinations.push({
              month: Months.indexOf(month),
              day: relevantDays.indexOf(day),
              time: {
                start: timeOfDayToDuration(start).valueOf(),
                finish: timeOfDayToDuration(finish).valueOf(),
              },
            });
          });
        });
      });
    });

    combinations.sort((a, b) => (a.month - b.month || a.day - b.day
      || a.time.start - b.time.start || a.time.finish - b.time.finish));

    if (combinations.length === 0) {
      return { valid: true, coverage: false };
    }

    const endOfDay = Duration.fromObject({ hours: 24 }).valueOf();

    let coverage = true;

    const first = combinations[0];
    if (first.month !== 0 || first.day !== 0 || first.time.start !== 0) {
      coverage = false;
    }

    const last = combinations[combinations.length - 1];
    if (last.month !== Months.length - 1 || last.day !== relevantDays.length - 1
      || last.time.finish !== endOfDay) {
      coverage = false;
    }

    for (let i = 1; i < combinations.length; i += 1) {
      const curr = combinations[i];
      const prev = combinations[i - 1];

      let expectedMonth = prev.month;
      let expectedDay = prev.day;
      let expectedStart = prev.time.finish;
      if (prev.time.finish === endOfDay) {
        expectedStart = 0;
        if (prev.day === relevantDays.length - 1) {
          expectedDay = 0;
          expectedMonth = prev.month + 1;
        } else {
          expectedDay = prev.day + 1;
        }
      }

      const comparison = curr.month - expectedMonth || curr.day - expectedDay
        || curr.time.start - expectedStart;

      if (comparison < 0) {
        return { valid: false };
      }
      if (comparison > 0) {
        coverage = false;
      }
    }

    return { valid: true, coverage };
  };

  toggleConfirmCoverage = () => {
    this.setState(({ showConfirmCoverage }) => ({ showConfirmCoverage: !showConfirmCoverage }));
  };

  showClause = (clause, index, tradeDirection) => {
    if (this.isEditing(tradeDirection, index)) {
      return (
        <ClauseEdit
          clause={clause}
          setClause={
            (newClause) => this.editClauseSet(tradeDirection, index, newClause)
          }
          cancelEdit={this.cancelEdit}
          priceLabel={TradeRuleSetCommunityTimeOfUseForm.priceLabel(tradeDirection)}
          priceHelpText={TradeRuleSetCommunityTimeOfUseForm.priceHelpText(tradeDirection)}
          priceValidation={TradeRuleSetCommunityTimeOfUseForm.isValidPrice}
          key={clause.id}
        />
      );
    }
    return (
      <ClauseShow
        clause={clause}
        showControls={!this.isEditing()}
        editClause={() => this.editClause(tradeDirection, index)}
        removeClause={() => this.removeClause(tradeDirection, index)}
        key={clause.id}
      />
    );
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <div>Loading...</div>;
    }

    const { property, meter, router } = this.props;
    const {
      buyClauses, sellClauses, processing, showConfirmCoverage,
    } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Card>
          <CardHeader className="d-flex flex-wrap">
            <h2 className="mb-0">
              {`Set community trade rules for ${meter.title}`}
            </h2>
            <Link to={`/properties/${property.id}/meters/${meter.id}/trade-rules/community/set`} className="btn btn-darken ms-auto">
              Flat pricing
            </Link>
          </CardHeader>
          <CardBody>
            <div>
              <h3>Buy</h3>
              {buyClauses.map((clause, index) => (
                this.showClause(clause, index, TRADE_DIRECTION_BUY)
              ))}
              {this.isEditing(TRADE_DIRECTION_BUY, null) && (
                <ClauseEdit
                  setClause={(newClause) => this.addClauseSet(TRADE_DIRECTION_BUY, newClause)}
                  cancelEdit={this.cancelEdit}
                  priceLabel={TradeRuleSetCommunityTimeOfUseForm.priceLabel(TRADE_DIRECTION_BUY)}
                  priceHelpText={
                    TradeRuleSetCommunityTimeOfUseForm.priceHelpText(TRADE_DIRECTION_BUY)
                  }
                  priceValidation={TradeRuleSetCommunityTimeOfUseForm.isValidPrice}
                />
              )}
              {!this.isEditing() && (
                <Button color="darken" onClick={() => this.addClause(TRADE_DIRECTION_BUY)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add clause
                </Button>
              )}
            </div>
            <div className="mt-4">
              <h3>Sell</h3>
              {sellClauses.map((clause, index) => (
                this.showClause(clause, index, TRADE_DIRECTION_SELL)
              ))}
              {this.isEditing(TRADE_DIRECTION_SELL, null) && (
                <ClauseEdit
                  setClause={(newClause) => this.addClauseSet(TRADE_DIRECTION_SELL, newClause)}
                  cancelEdit={this.cancelEdit}
                  priceLabel={TradeRuleSetCommunityTimeOfUseForm.priceLabel(TRADE_DIRECTION_SELL)}
                  priceHelpText={
                    TradeRuleSetCommunityTimeOfUseForm.priceHelpText(TRADE_DIRECTION_SELL)
                  }
                  priceValidation={this.priceValidation}
                />
              )}
              {!this.isEditing() && (
                <Button color="darken" onClick={() => this.addClause(TRADE_DIRECTION_SELL)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add clause
                </Button>
              )}
            </div>
          </CardBody>
          <CardFooter className="d-flex">
            <Button color="primary" className="me-2" disabled={processing || this.isEditing()}>
              Set
            </Button>
            <Button color="" onClick={() => (router.go(-1))} disabled={processing || this.isEditing()}>
              Cancel
            </Button>
            <Button
              color="darken"
              className="ms-auto"
              onClick={() => this.setState(this.copyFromResidual())}
              disabled={processing || this.isEditing() || !this.copyFromResidual()}
            >
              <FontAwesomeIcon icon={faClone} className="me-2" />
              Copy from retailer default
            </Button>
          </CardFooter>
        </Card>
        <Modal isOpen={showConfirmCoverage} toggle={this.toggleConfirmCoverage}>
          <ModalHeader toggle={this.toggleConfirmCoverage}>
            No full time coverage
          </ModalHeader>
          <ModalBody>
            Clauses of proposed community trade rule do not cover all possible times.
            Confirm that this is okay.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="me-2" onClick={this.handleSubmit}>
              Confirm
            </Button>
            <Button color="" onClick={this.toggleConfirmCoverage}>
              Continue editing
            </Button>
          </ModalFooter>
        </Modal>
      </Form>
    );
  }
}

TradeRuleSetCommunityTimeOfUseForm.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  meter: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  router: routerShape.isRequired,
};

TradeRuleSetCommunityTimeOfUseForm.defaultProps = {
  property: null,
  meter: null,
};

export default createFragmentContainer(
  TradeRuleSetCommunityTimeOfUseForm,
  {
    property: graphql`
      fragment TradeRuleSetCommunityTimeOfUseForm_property on Property {
        id
        timezone
        publicHolidayRegion
      }
    `,
    meter: graphql`
      fragment TradeRuleSetCommunityTimeOfUseForm_meter on Meter {
        id
        identifier
        title
        tradePointId
        communityRules: rules(first: 500, type: TRADE_TYPE_COMMUNITY, state: TRADE_RULE_STATE_ACCEPTED) {
          edges {
            node {
              id
              priority
              tradeType
              state
              buyer {
                userId
                communityId
                residualId
                tradePoint {
                  id
                }
              }
              seller {
                userId
                communityId
                residualId
                tradePoint {
                  id
                }
              }
              clauses {
                edges {
                  node {
                    price
                    ignorePublicHolidays
                    monthsOfYear
                    daysOfWeek
                    timesOfDay {
                      start  { hours minutes seconds }
                      finish { hours minutes seconds }
                    }
                  }
                }
              }
            }
          }
        }
        residualRules: rules(first: 500, type: TRADE_TYPE_RESIDUAL, state: TRADE_RULE_STATE_ACCEPTED, start: $now, finish: $now) {
          edges {
            node {
              id
              priority
              tradeType
              state
              buyer {
                userId
                communityId
                residualId
                tradePoint {
                  id
                }
              }
              seller {
                userId
                communityId
                residualId
                tradePoint {
                  id
                }
              }
              clauses {
                edges {
                  node {
                    price
                    ignorePublicHolidays
                    monthsOfYear
                    daysOfWeek
                    timesOfDay {
                      start  { hours minutes seconds }
                      finish { hours minutes seconds }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
);
