const username = (user) => {
  if (!user) { return 'System'; }

  const { email, givenName, familyName } = user;
  const name = [givenName, familyName].filter(Boolean).join(' ');

  return (name === '' ? email : name);
};

export default username;
