import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt, faSolarPanel, faWind } from '@fortawesome/free-solid-svg-icons';

const Icon = styled(FontAwesomeIcon).attrs({
  className: 'ms-1 me-1',
})``;

const IconSet = styled.div``;

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - PropertyMetadata component
 */
function PropertyMetadata(props) {
  const { consumer, solar, wind } = props;

  return (
    // TODO: Colour icons
    // - enosi-green for consumer
    // - enosi-yellow for solar
    // - enosi-blue (tba) for wind
    <IconSet>
      {consumer && <Icon icon={faBolt} />}
      {solar && <Icon icon={faSolarPanel} />}
      {wind && <Icon icon={faWind} />}
    </IconSet>
  );
}

PropertyMetadata.propTypes = {
  consumer: PropTypes.bool,
  solar: PropTypes.bool,
  wind: PropTypes.bool,
};

PropertyMetadata.defaultProps = {
  consumer: false,
  solar: false,
  wind: false,
};

export default PropertyMetadata;
