import PropTypes from 'prop-types';
import React from 'react';
import {
  Button, Card, CardBody, CardFooter, CardHeader,
  Form, FormFeedback, FormGroup, FormText, Label, Input,
} from 'reactstrap';
import { createFragmentContainer, graphql } from 'react-relay';
import isEmail from 'validator/es/lib/isEmail';

import FlashesStore from 'src/stores/FlashesStore';

class ProfileSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    let email = '';
    let emailValid = null;
    let familyName = '';
    let givenName = '';

    if (props && props.viewerUser) {
      email = props.viewerUser.email;
      familyName = props.viewerUser.familyName;
      givenName = props.viewerUser.givenName;

      if (email !== null) {
        emailValid = isEmail(email);
      }
    }

    this.state = {
      email,
      emailValid,
      familyName,
      givenName,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { handleUpdate, processing } = this.props;

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, 'We are still processing your request...');
      return;
    }

    if (!this.isValid()) {
      FlashesStore.flash(FlashesStore.ERROR, 'Form data not valid. Please see below.');
      return;
    }

    const { viewerUser } = this.props;
    const { email: oldEmail, familyName: oldFamilyName, givenName: oldGivenName } = viewerUser;
    const { email, familyName, givenName } = this.state;

    if (email === oldEmail && familyName === oldFamilyName && givenName === oldGivenName) {
      FlashesStore.flash(FlashesStore.INFO, 'No update required.');
    }

    const input = { email, familyName, givenName };

    handleUpdate(input);
  };

  handleEmailChange = (event) => {
    const { value: email } = event.target;
    const emailValid = isEmail(email);

    this.setState({ email, emailValid });
  };

  handleFamilyNameChange = (event) => {
    const { value: familyName } = event.target;
    this.setState({ familyName });
  };

  handleGivenNameChange = (event) => {
    const { value: givenName } = event.target;
    this.setState({ givenName });
  };

  isValid = () => {
    const { emailValid } = this.state;

    return emailValid;
  };

  render() {
    const { processing } = this.props;
    const {
      email, emailValid, familyName, givenName,
    } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} disabled={processing}>
        <Card className="mt-4 mb-4">
          <CardHeader tag="h5">Profile</CardHeader>
          <CardBody>
            <p>Update your details.</p>
            <FormGroup>
              <Label for="userEmail">Email</Label>
              <Input type="string" name="userEmail" id="userEmail" placeholder="Your email" value={email} onChange={(ev) => (this.handleEmailChange(ev))} disabled={processing} valid={emailValid} invalid={emailValid !== null && !emailValid} />
              <FormFeedback>Invalid email address</FormFeedback>
              <FormText color="muted">Changing your email will require confirmation. An email will be sent to your new address to allow you to confirm it. Until then you will still log in using your existing email.</FormText>
            </FormGroup>

            <FormGroup>
              <Label for="userGivenName">Given name</Label>
              <Input type="string" name="userGivenName" id="userGivenName" placeholder="Your given name" value={givenName} onChange={(ev) => (this.handleGivenNameChange(ev))} disabled={processing} />
              <FormFeedback>Your given name is optional</FormFeedback>
              <FormText color="muted">Your given name is optional</FormText>
            </FormGroup>

            <FormGroup>
              <Label for="userFamilyName">Family name</Label>
              <Input type="string" name="userFamilyName" id="userFamilyName" placeholder="Your family name" value={familyName} onChange={(ev) => (this.handleFamilyNameChange(ev))} disabled={processing} />
              <FormFeedback>Your family name is optional</FormFeedback>
              <FormText color="muted">Your family name is optional</FormText>
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button disabled={processing}>Update</Button>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

ProfileSettingsForm.propTypes = {
  handleUpdate: PropTypes.func,
  processing: PropTypes.bool,
  viewerUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

ProfileSettingsForm.defaultProps = {
  handleUpdate: null,
  processing: false,
  viewerUser: null,
};

export default createFragmentContainer(
  ProfileSettingsForm,
  {
    viewerUser: graphql`
      fragment ProfileSettingsForm_viewerUser on User {
        id
        email
        familyName
        givenName
      }
    `,
  },
);
