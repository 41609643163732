import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Primary = styled.div.attrs({
  className: 'fw-bold',
})``;

const Secondary = styled.div.attrs({
  className: 'fw-normal',
})`
  font-size: .75em;
`;

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - PropertyTitle component
 */
function PropertyTitle(props) {
  const { title, address } = props;

  return (
    <div>
      <Primary>{title}</Primary>
      <Secondary>{address}</Secondary>
    </div>
  );
}

PropertyTitle.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
};

export default PropertyTitle;
