import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'reactstrap';

const colorMapping = {
  TRADE_RULE_STATE_ACCEPTED: 'success',
  TRADE_RULE_STATE_CANCELLED: 'danger',
  TRADE_RULE_STATE_CLOSED: 'info',
  TRADE_RULE_STATE_PROPOSED: 'warning',
  TRADE_RULE_STATE_REJECTED: 'danger',
};

/**
 * Description
 * @param {any} {state}
 * @returns {React.ReactComponentElement} - BadgeTradeRuleState component
 */
function BadgeTradeRuleState({ state }) {
  if (!state) { return (null); }

  const color = colorMapping[state] || 'mid';
  const title = state.split('_').slice(-1)[0];

  return (
    <Badge color={color} className="badge-outline">{title}</Badge>
  );
}

BadgeTradeRuleState.propTypes = {
  state: PropTypes.string.isRequired,
};

export default BadgeTradeRuleState;
