import { Link } from 'found';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Badge, Col, Row, Button,
  Card, CardBody, CardTitle, CardSubtitle, CardText, CardFooter,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

import { APIConfig } from 'src/config';
import { PLATFORM_MODE_REBATE, RESIDUAL } from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import { tradeRulePriceRange } from 'src/util/tradeRule';
import { emptyTradeRulesClassName } from 'src/helpers/tradeHelpers';

class TradeRuleActiveResidual extends React.Component {
  static tradeRulesForProperty(property) {
    const { meters } = property;
    const rules = { sell: [], buy: [] };

    meters.edges.forEach((meterEdge) => {
      if (!!meterEdge && !!meterEdge.node && !!meterEdge.node.residualRules) {
        meterEdge.node.residualRules.edges.forEach((ruleEdge) => {
          const { node: ruleNode } = ruleEdge;
          if (
            ruleNode.state !== 'TRADE_RULE_STATE_ACCEPTED'
            || ruleNode.tradeType !== 'TRADE_TYPE_RESIDUAL'
          ) {
            return;
          }

          if (ruleNode.seller.tradePoint.id === meterEdge.node.tradePointId) {
            rules.sell.push({ ...ruleNode, meter: meterEdge.node });
          } else {
            rules.buy.push({ ...ruleNode, meter: meterEdge.node });
          }
        });
      }
    });

    return rules;
  }

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <div>Loading...</div>;
    }

    const { property } = this.props;
    const tradeRules = TradeRuleActiveResidual.tradeRulesForProperty(property);
    const noTradeRulesClass = emptyTradeRulesClassName(tradeRules, RESIDUAL);

    return (
      <Card className={`mt-4 mb-4 trade-rules-residual${noTradeRulesClass}`}>
        <CardBody>
          <h2>Retailer Default Trades</h2>
          <p>
            The retailer default trades are at the agreed energy tariffs that you have
            contracted with your retailer.
          </p>
          <Row>
            <Col xs="12" sm="12" md="6">
              <h3>Selling</h3>
              <p>
                {'You have '}
                <strong>{tradeRules.sell.length}</strong>
                {' retailer default trade rules to sell energy.'}
              </p>

              {tradeRules.sell && tradeRules.sell.map((rule) => {
                let price;
                if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
                  price = <em>Agreed energy tariffs apply</em>;
                } else {
                  const priceRange = tradeRulePriceRange(rule);
                  price = (
                    <>
                      Default sell price:
                      {' '}
                      <strong>
                        {convertEnergyPrice(priceRange.minimum).toFixed(3)}
                      </strong>
                      {priceRange.maximum !== priceRange.minimum && (
                        <>
                          {' '}
                          to
                          {' '}
                          <strong>
                            {convertEnergyPrice(priceRange.maximum).toFixed(3)}
                          </strong>
                        </>
                      )}
                      {' '}
                      <abbr title="cents per kilowatt hour">c/kWh</abbr>
                      {' '}
                      (
                      <Link to={`/properties/${property.id}/trade-rules/${rule.id}`}>further details</Link>
                      )
                    </>
                  );
                }

                return (
                  <Card className="mb-4 mt-4" key={`trade-rules-${rule.id}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        {`Selling from ${rule.meter.title}`}
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        Approved
                      </CardSubtitle>
                      <CardText>
                        {price}
                        <br />
                        <Link
                          to={`/properties/${property.id}/trade-rules/${rule.id}`}
                        >
                          <Badge color="mid">{rule.id}</Badge>
                        </Link>
                        <br />
                        {`From ${DateTime.fromSeconds(rule.start).toFormat('dd LLL yyyy')} `}
                        {rule.finish ? ` to ${DateTime.fromSeconds(rule.finish).toFormat('dd LLL yyyy')}.` : ' onwards.'}
                      </CardText>
                    </CardBody>
                  </Card>
                );
              })}
            </Col>
            <Col xs="12" sm="12" md="6">
              <h3>Buying</h3>
              <p>
                {'You have '}
                <strong>{tradeRules.buy.length}</strong>
                {' retailer default trade rules to buy energy.'}
              </p>

              {tradeRules.buy && tradeRules.buy.map((rule) => {
                let price;
                if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
                  price = <em>Agreed energy tariffs apply</em>;
                } else {
                  const priceRange = tradeRulePriceRange(rule);
                  price = (
                    <>
                      Default buy price:
                      {' '}
                      <strong>
                        {convertEnergyPrice(priceRange.minimum).toFixed(3)}
                      </strong>
                      {priceRange.maximum !== priceRange.minimum && (
                        <>
                          {' '}
                          to
                          {' '}
                          <strong>
                            {convertEnergyPrice(priceRange.maximum).toFixed(3)}
                          </strong>
                        </>
                      )}
                      {' '}
                      <abbr title="cents per kilowatt hour">c/kWh</abbr>
                      {' '}
                      (
                      <Link to={`/properties/${property.id}/trade-rules/${rule.id}`}>further details</Link>
                      )
                    </>
                  );
                }

                return (
                  <Card className="mb-4 mt-4" key={`trade-rules-${rule.id}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        {`Buying for ${rule.meter.title}`}
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        <FontAwesomeIcon icon={faCheck} className="me-2" />
                        Approved
                      </CardSubtitle>
                      <CardText>
                        {price}
                        <br />
                        <Link
                          to={`/properties/${property.id}/trade-rules/${rule.id}`}
                        >
                          <Badge color="mid">{rule.id}</Badge>
                        </Link>
                        <br />
                        {`From ${DateTime.fromSeconds(rule.start).toFormat('dd LLL yyyy')} `}
                        {rule.finish ? ` to ${DateTime.fromSeconds(rule.finish).toFormat('dd LLL yyyy')}.` : ' onwards.'}
                      </CardText>
                    </CardBody>
                  </Card>
                );
              })}
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Link to={`/properties/${property.id}/settings#tariffs`}>
            <Button color="primary" className="me-2">
              View agreed energy tariffs
            </Button>
          </Link>
        </CardFooter>
      </Card>
    );
  }
}

TradeRuleActiveResidual.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
};

TradeRuleActiveResidual.defaultProps = {
  property: null,
};

export default createFragmentContainer(
  TradeRuleActiveResidual,
  {
    property: graphql`
      fragment TradeRuleActiveResidual_property on Property {
        id
        meters {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              residualRules: rules(first: 500, type: TRADE_TYPE_RESIDUAL, state: TRADE_RULE_STATE_ACCEPTED) {
                edges {
                  node {
                    id
                    priority
                    tradeType
                    state
                    buyer {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        id
                      }
                    }
                    seller {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        id
                      }
                    }
                    clauses {
                      edges {
                        node {
                          price
                        }
                      }
                    }
                    start
                    finish
                    proposedAt
                    proposedBy {
                      id
                      email
                      givenName
                      familyName
                    }
                    acceptedAt
                    acceptedBy {
                      id
                      email
                      givenName
                      familyName
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
);
