// Axis label font sizes
export const FONT_SIZE_AXIS = 11;

// chart opacity
export const OPACITY_100 = '100%';
export const OPACITY_40 = '40%';
export const OPACITY_10 = '10%';

// colors
export const COLOR_IMPORT = '#3bb667cc';
export const COLOR_EXPORT = '#e6c644cc';
export const COLOR_STROKE = '#e6e6e9';
export const COLOR_LABEL = '#6b6b76';

// other constants
export const BAR_RATIO = 0.9;
export const CHART_HEIGHT = 240;
export const TICK_COUNT = 10;
export const IMPORTS = 'Imports';
export const EXPORTS = 'Exports';
export const METER = 'meter';
export const TRADE = 'trade';
export const BUY = 'buy';
export const SELL = 'sell';
export const UNIT_CURRENCY = 'UNIT_CURRENCY';
export const UNIT_CURRENCY_FORMAT = '$.3f';
export const UNIT_CURRENCY_KEY = 'value';
export const UNIT_CURRENCY_LABEL = '$';
export const UNIT_ENERGY = 'UNIT_ENERGY';
export const UNIT_ENERGY_FORMAT = '~s';
export const UNIT_ENERGY_KEY = 'volume';
export const UNIT_ENERGY_LABEL = 'Wh';
export const Y_AXIS_DOMAIN_MULTIPLIER = 1.05;
export const UNIT_CARBON = 'UNIT_CARBON';
export const UNIT_CARBON_KEY = 'carbon';
export const UNIT_CARBON_DEFAULT_LABEL = 'kg CO2-e';
export const UNIT_CARBON_TOOLTIP_FORMAT = '.3f';

export const KILO_GRAM = 'kg';
export const GRAM = 'g';
export const TONNE = 't';
export const KILO_TONNE = 'kt';
export const MEGA_TONNE = 'mT';
export const CARBON_EMISSION = 'CO2-e';

export const ChartAxisStyle = {
  axis: { stroke: COLOR_STROKE },
  axisLabel: {
    fill: COLOR_LABEL,
    fontFamily: 'inherit',
    fontSize: FONT_SIZE_AXIS,
  },
  ticks: { stroke: COLOR_STROKE, size: 3 },
  tickLabels: {
    fill: COLOR_LABEL,
    fontFamily: 'inherit',
    fontSize: FONT_SIZE_AXIS,
    padding: 2,
  },
};

export const chartPadding = {
  top: 20, bottom: 20, right: 20, left: 50,
};

export const carbonProps = {
  key: UNIT_CARBON_KEY,
  label: UNIT_CARBON_DEFAULT_LABEL,
  format: UNIT_ENERGY_FORMAT,
  tooltipFormat: UNIT_CARBON_TOOLTIP_FORMAT,
};

export const currencyProps = {
  key: UNIT_CURRENCY_KEY,
  label: '', // label comes from the d3 format
  format: UNIT_CURRENCY_FORMAT,
};

export const energyProps = {
  key: UNIT_ENERGY_KEY,
  label: UNIT_ENERGY_LABEL,
  format: UNIT_ENERGY_FORMAT,
};
