import { Link } from 'found';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import BadgeTradeDirection from 'src/components/Badge/BadgeTradeDirection';
import BadgeTradePointType from 'src/components/Badge/BadgeTradePointType';
import BadgeTradeRuleState from 'src/components/Badge/BadgeTradeRuleState';
import BadgeTradeType from 'src/components/Badge/BadgeTradeType';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';

import {
  TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL, TRADE_DIRECTION_UNSPECIFIED,
  TRADE_POINT_TYPE_COMMUNITY, TRADE_POINT_TYPE_KNOWN, TRADE_POINT_TYPE_RESIDUAL,
} from 'src/util/constants';
import username from 'src/util/decorators/username';
import { tradeRuleClauseConditions } from 'src/util/tradeRule';

import AdminMeterShowTradeRuleClause from './AdminMeterShowTradeRuleClause';

class AdminMeterShowTradeRule extends React.Component {
  static clauseConditions = (rule) => {
    const clauseConditions = tradeRuleClauseConditions(rule);

    if (clauseConditions.length === 0) {
      return { element: null, display: false };
    }

    return {
      element: (
        <li>
          All
          {' '}
          {clauseConditions.reduce((prev, curr) => [prev, ', ', curr])}
        </li>
      ),
      display: true,
    };
  };

  counterPartyTrader = (rule) => {
    const direction = this.direction();

    let trader;
    if (direction === TRADE_DIRECTION_BUY) {
      trader = rule.seller;
    } else if (direction === TRADE_DIRECTION_SELL) {
      trader = rule.buyer;
    } else {
      return null;
    }

    if (trader.user) {
      return trader.user;
    }
    if (trader.community) {
      return trader.community;
    }
    if (trader.residual) {
      return trader.residual;
    }

    return null;
  };

  counterPartyTradePoint = (rule) => {
    const direction = this.direction();

    let tradePoint;
    if (direction === TRADE_DIRECTION_BUY) {
      tradePoint = rule.seller.tradePoint;
    } else if (direction === TRADE_DIRECTION_SELL) {
      tradePoint = rule.buyer.tradePoint;
    } else {
      return null;
    }

    if (tradePoint.type === TRADE_POINT_TYPE_KNOWN) {
      return { label: 'Trade Point', tradePoint };
    }
    if (tradePoint.type === TRADE_POINT_TYPE_COMMUNITY) {
      return { label: 'Community', tradePoint };
    }
    if (tradePoint.type === TRADE_POINT_TYPE_RESIDUAL) {
      return { label: 'Retailer', tradePoint };
    }

    return null;
  };

  direction = () => {
    const { rule, tradePointId } = this.props;
    const { buyer, seller } = rule;

    let buyerTradePointId = null;
    let sellerTradePointId = null;

    if (buyer.tradePoint && buyer.tradePoint.id) {
      buyerTradePointId = buyer.tradePoint.id;
    }
    if (seller.tradePoint && seller.tradePoint.id) {
      sellerTradePointId = seller.tradePoint.id;
    }

    if (buyerTradePointId && buyerTradePointId === tradePointId) {
      return TRADE_DIRECTION_BUY;
    }
    if (sellerTradePointId && sellerTradePointId === tradePointId) {
      return TRADE_DIRECTION_SELL;
    }
    return TRADE_DIRECTION_UNSPECIFIED;
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { propertyId, rule } = this.props;

    const {
      id, tradeType, state, start, finish,
    } = rule;

    const clauses = (!rule.clauses || !rule.clauses.edges)
      ? [] : rule.clauses.edges.map((el) => (el.node)).sort((a, b) => (b.price - a.price));

    const clauseConditions = AdminMeterShowTradeRule.clauseConditions(rule);

    const counterPartyTrader = this.counterPartyTrader(rule);
    const counterPartyTradePoint = this.counterPartyTradePoint(rule);

    return (
      <tr key={`trade-rule-${id}`}>
        <th>
          <Link to={`/properties/${propertyId}/trade-rules/${id}`}>
            <UUID uuid={id} />
          </Link>
          <br />
          <BadgeTradeDirection direction={this.direction()} />
          <br />
          <BadgeTradeType type={tradeType} />
          {/* <br />
          <BadgeActive start={start} finish={finish} /> */}
          <br />
          <BadgeTradeRuleState state={state} />
        </th>
        <td>
          {counterPartyTrader && (
            <>
              <strong>{username(counterPartyTrader)}</strong>
              <br />
              <UUID uuid={counterPartyTrader.id} />
            </>
          )}
        </td>
        <td>
          {counterPartyTradePoint && (
            <>
              <strong>{counterPartyTradePoint.label}</strong>
              <br />
              <UUID uuid={counterPartyTradePoint.tradePoint.id} />
              <br />
              <BadgeTradePointType type={counterPartyTradePoint.tradePoint.type} />
            </>
          )}
        </td>
        <td>
          <ul className="ps-2">
            {clauses && clauses.map((clause) => (
              <AdminMeterShowTradeRuleClause
                clause={clause}
                key={clause.id}
                showConditions={false}
              />
            ))}
            {clauseConditions.display && clauseConditions.element}
          </ul>
        </td>
        <td>
          {start ? DateTime.fromSeconds(start).toFormat('HH:mm DD') : '-'}
          {' to '}
          {finish ? DateTime.fromSeconds(finish).toFormat('HH:mm DD') : '-'}
        </td>
      </tr>
    );
  }
}

AdminMeterShowTradeRule.propTypes = {
  propertyId: PropTypes.string.isRequired,
  rule: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  tradePointId: PropTypes.string.isRequired,
};

export default createFragmentContainer(
  AdminMeterShowTradeRule,
  {
    rule: graphql`
      fragment AdminMeterShowTradeRule_rule on TradeRule {
        id
        buyer {
          user { id email givenName familyName }
          community { id title }
          residualId
          tradePoint { id type }
        }
        seller {
          user { id email givenName familyName }
          community { id title }
          residualId
          tradePoint { id type }
        }
        clauses {
          edges {
            node {
              id
              price
              ignoreDaylightSavings
              ignorePublicHolidays
              timezone
              ...AdminMeterShowTradeRuleClause_clause
            }
          }
        }
        tradeType
        state
        start
        finish
        proposedAt
        proposedBy { id email givenName familyName }
        acceptedAt
        acceptedBy { id email givenName familyName }
        rejectedAt
        rejectedBy { id email givenName familyName }
        cancelledAt
        cancelledBy { id email givenName familyName }
        closedAt
        closedBy { id email givenName familyName }
      }
    `,
  },
);
