/**
 * @generated SignedSource<<c8e6cc9c43a26ffb6639d049adc9dba4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TradeRuleShow_rule",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TradeRuleTimeline_rule"
    }
  ],
  "type": "TradeRule",
  "abstractKey": null
};

node.hash = "b801d1b819772c081f5fe7c814d3a8ca";

module.exports = node;
