import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Col, Row, Card, CardBody,
} from 'reactstrap';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import FlashesStore from 'src/stores/FlashesStore';
import { USER_TYPE_CUSTOMER } from 'src/util/constants';
import { getTradeRules, tradeRulesBuy, tradeRulesSell } from './helpers';

class TradeRuleCustomerProposals extends React.Component {
  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { router, viewer } = this.props;
    // eslint-disable-next-line react/prop-types
    const { proposals, viewerUser } = viewer;
    const { id } = viewerUser;

    if (!viewerUser || viewerUser.type !== USER_TYPE_CUSTOMER) {
      FlashesStore.flash(FlashesStore.ERROR, 'only customers can view their proposals');
      router.replace('/properties');
    }

    const tradeRules = getTradeRules(id, proposals);
    const {
      buy = [], counterPartyBuy = [], counterPartySell = [], sell = [],
    } = tradeRules || {};

    const totalBuys = buy.length + counterPartySell.length;
    const totalSells = sell.length + counterPartyBuy.length;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Enosi - Manage my proposals</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: 'Trade Rules', path: '/trade-rules' },
            { name: 'Proposals' },
          ]}
        />
        <Heading
          alias="Trade Rules"
          context="Proposals"
        />

        <Card className="mt-4 mb-4 trade-proposals">
          <CardBody>
            <h2>Proposed Trades</h2>
            <Row>
              <Col xs="12" sm="12" md="6">
                <h3>Selling</h3>
                <p>
                  {'You have '}
                  <strong>{totalSells}</strong>
                  {' proposed trade rules to sell energy.'}
                </p>

                {tradeRulesSell(sell, viewer)}
                {tradeRulesBuy(counterPartyBuy, viewer)}
              </Col>

              <Col xs="12" sm="12" md="6">
                <h3>Buying</h3>
                <p>
                  {'You have '}
                  <strong>{totalBuys}</strong>
                  {' proposed trade rules to buy energy.'}
                </p>
                {tradeRulesBuy(buy, viewer)}
                {tradeRulesSell(counterPartySell, viewer)}
              </Col>

            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

TradeRuleCustomerProposals.propTypes = {
  viewer: PropTypes.shape({
    viewerUser: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    }),
    meters: PropTypes.shape({
      edges: PropTypes.arrayOf({
        node: PropTypes.shape({
          id: PropTypes.string,
          identifier: PropTypes.string,
          tradePointId: PropTypes.string,
          active: PropTypes.shape({
            start: PropTypes.number,
            finish: PropTypes.number,
          }),
        }),
      }),
    }),
    proposals: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
          }),
        }),
      ),
    }),
  }).isRequired, // eslint-disable-line react/forbid-prop-types
  router: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

TradeRuleCustomerProposals.defaultProps = {};

export default createFragmentContainer(
  TradeRuleCustomerProposals,
  {
    viewer: graphql`
      fragment TradeRuleCustomerProposals_viewer on Viewer {
        id
        viewerUser { id type }
        meters(first: 500) {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              active { start finish }
              property {
                id
                title
                active { start finish }
              }
            }
          }
        }
        proposals: rules(first:500, type: TRADE_TYPE_NOMINATED, state: TRADE_RULE_STATE_PROPOSED) {
          edges {
            node {
              id
              clauses {
                edges {
                  node {
                    price
                  }
                }
              }
              tradeType
              state
              buyer {
                user { id email givenName familyName }
                tradePoint { id type }
              }
              seller {
                user { id email givenName familyName }
                tradePoint { id type }
              }
              proposedAt
              proposedBy { id email givenName familyName }
            }
          }
        }
      }
    `,
  },
);
