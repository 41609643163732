import FlashesStore from 'src/stores/FlashesStore';

// Authenticate
const authenticateUser = (viewer, type, router) => {
  if (!viewer || !viewer.id) {
    FlashesStore.flash(FlashesStore.ERROR, 'Please log in to continue');
    router.replace('/login');
    return false;
  }

  if (type === null) {
    return true;
  }

  // Authorises an admin
  const { viewerUser } = viewer;
  if (viewerUser && viewerUser.type === type) {
    return true;
  }

  FlashesStore.flash(FlashesStore.ERROR, 'You are not authorised to be here');
  router.replace('/properties');
  return false;
};

export default authenticateUser;
