import PropTypes from 'prop-types';
import React from 'react';

import styled from 'styled-components';

import { Button, ButtonGroup, ButtonToolbar } from 'reactstrap';

const ToggleButtonToolbar = styled(ButtonToolbar).attrs({
  className: 'ml-n1 mr-n1 justify-content-center ',
})``;

const ToggleButtonGroup = styled(ButtonGroup).attrs({
  className: 'ms-1 me-1 mb-3',
})``;

const ToggleButton = styled(Button).attrs({
  color: 'darken',
})``;

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - ChartControls component
 */
function ChartControls(props) {
  const { buttons, onButtonClick } = props;

  return (
    <ToggleButtonToolbar>
      {buttons.map(({ group, items }) => (
        <ToggleButtonGroup key={group}>
          {items.map(({
            label, value, active, disabled,
          }) => (
            <ToggleButton
              disabled={disabled}
              key={value}
              active={active}
              onClick={() => onButtonClick({ group, value })}
            >
              {label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      ))}
    </ToggleButtonToolbar>
  );
}

ChartControls.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    group: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
    })),
  })).isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

ChartControls.defaultProps = {};

export default ChartControls;
