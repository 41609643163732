import { DateTime } from 'luxon';
import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';

import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactElement} - AdminPropertyShowTariff component
 */
function AdminPropertyShowTariff(props) {
  if (!props) {
    return <Loading />;
  }

  const { tariff } = props;
  const {
    id, identifier, title, active, meter,
  } = tariff;
  const { start, finish } = active;
  const { id: meterId, identifier: meterIdentifier } = meter;

  return (
    <tr>
      <th scope="row" colSpan={3}>
        <Link to={`/admin/tariffs/${id}`}>
          {title}
        </Link>
        <br />
        {identifier}
        <br />
        <UUID uuid={id} />
      </th>
      <td>
        <BadgeActive start={start} finish={finish} />
        <br />
        {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
        -
        {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
      </td>
      <td>
        <Link to={`/admin/meters/${meterId}`}>
          {meterIdentifier}
        </Link>
        <br />
        <UUID uuid={meterId} />
      </td>
    </tr>
  );
}

AdminPropertyShowTariff.propTypes = {
  tariff: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminPropertyShowTariff.defaultProps = {
  tariff: null,
};

export default AdminPropertyShowTariff;
