import { format } from 'd3-format';
import { Link } from 'found';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import BadgeTradeDirection from 'src/components/Badge/BadgeTradeDirection';
import BadgeTradePointType from 'src/components/Badge/BadgeTradePointType';
import BadgeTradeType from 'src/components/Badge/BadgeTradeType';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';

import { APIConfig } from 'src/config';
import { PLATFORM_MODE_REBATE, TRADE_DIRECTION_BUY } from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import username from 'src/util/decorators/username';
import { getPropertyLink } from 'src/helpers/tradeHelpers';

/**
 * Description
 * @param {string} identifier - meter identifier
 * @param {string} title - meter title
 * @returns {string} - meter label
 */
export const getMeterLabel = (identifier, title) => {
  if (!identifier && !title) {
    return null;
  }
  let meterDetails = title || '';
  meterDetails = identifier ? `${title} (${identifier})` : meterDetails;
  return meterDetails?.trim();
};

/**
 * Description
 * @param {object} trader - user data object
 * @returns {React.ReactElement} - trader details (meter, property etc)
 */
export const getTrader = (trader) => {
  if (!trader || trader === undefined) {
    return null;
  }
  if (!trader.tradePoint || trader.tradePoint === undefined) {
    return null;
  }
  const {
    communityId, community, tradePoint, userId, user,
  } = trader;
  const { id, type, meter } = tradePoint || {};
  const { identifier, title } = meter || {};
  const meterLabel = getMeterLabel(identifier, title);
  const propertyLink = getPropertyLink(tradePoint);
  return (
    <dl>
      {user && (
        <>
          <dt>Trader</dt>
          <dd>
            {username(user)}
            <br />
            <UUID uuid={userId} />
          </dd>
        </>
      )}
      {community && (
        <>
          <dt>Trader</dt>
          <dd>
            {community.title}
            <br />
            <UUID uuid={communityId} />
          </dd>
        </>
      )}
      <dt>Trade Point</dt>
      <dd>
        {meterLabel && (
          <>
            <span>{meterLabel}</span>
            <br />
          </>
        )}
        {propertyLink && (
          <>
            {propertyLink}
            <br />
          </>
        )}
        <UUID uuid={id} />
        <br />
        <BadgeTradePointType type={type} />
      </dd>
    </dl>
  );
};
class TradeListTable extends React.Component {
  static tradesForProperty(property) {
    const { meters: meterConn } = property;

    let allTrades = [];

    if (meterConn === undefined || meterConn.edges.length === 0) {
      return allTrades;
    }
    meterConn.edges.forEach((el) => {
      const { node: meter } = el;
      const {
        rules: ruleConn, trades: tradeConn, id: meterId,
        identifier: meterIdentifier, title: meterTitle,
      } = meter;
      let allRules = {};

      if (!tradeConn || tradeConn === undefined) {
        return;
      }
      if (ruleConn && ruleConn !== undefined) {
        allRules = ruleConn.edges.reduce((acc, edge) => {
          acc[edge.node.id] = edge.node;
          return acc;
        }, {});
      }

      allTrades = allTrades.concat(
        tradeConn.edges.map((edge) => {
          const { node: trade } = edge;
          return ({
            ...trade,
            meter: { id: meterId, identifier: meterIdentifier, title: meterTitle },
            rule: allRules[trade.ruleId],
          });
        }),
      );
    });

    return allTrades;
  }

  static tradeSummary(trade) {
    if (!trade || trade === undefined) {
      return null;
    }

    return (
      <div>
        <strong>
          {format('.4s')(trade.volume === undefined ? 0 : trade.volume)}
          <abbr title="watt hour">Wh</abbr>
        </strong>
        {' '}
        @
        {' '}
        <strong>
          {format('.3f')(convertEnergyPrice(trade.price))}
        </strong>
        {' '}
        <abbr title="cents per kilowatt hour">c/kWh</abbr>
        <br />
        {APIConfig().MODE === PLATFORM_MODE_REBATE && 'Discount of '}
        <strong>
          {format('$.2f')(trade.value !== null ? trade.value : 0)}
        </strong>
      </div>
    );
  }

  filteredTradeForProperty = (property) => {
    const { filter } = this.props;
    const { direction, type } = filter;

    return TradeListTable.tradesForProperty(property).filter((trade) => {
      if (direction.length === 0 && type.length === 0) { return true; }
      const hasDirection = direction.length === 0 || direction.includes(trade.direction);
      const hasType = type.length === 0 || type.includes(trade.type);
      return hasDirection && hasType;
    });
  };

  setDate = (event, date) => {
    event.preventDefault();

    const { dateUpdateFunc } = this.props;

    dateUpdateFunc(date);
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { date, property } = this.props;
    const { id: propertyId } = property;
    const trades = this.filteredTradeForProperty(property);

    const after = date.plus({ days: 1 });
    const before = date.plus({ days: -1 });

    return (
      <div className="mt-4 mb-4 card trades-history-list">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Timestamp</th>
                <th scope="col">Details</th>
                <th scope="col">Direction</th>
                <th scope="col">Type</th>
                <th scope="col">Trade Point</th>
                <th scope="col">Counterparty</th>
                <th scope="col">Rule</th>
              </tr>
            </thead>
            <tbody>
              {trades
                && trades.map((trade) => {
                  const {
                    id, direction, interval, meter, type, ruleId, rule,
                  } = trade || {};
                  const { buyer, seller } = rule || {};
                  const trader = direction === TRADE_DIRECTION_BUY ? buyer : seller;
                  console.log('buyer==', buyer);
                  console.log('seller==', seller);
                  // const trader = direction === TRADE_DIRECTION_BUY ? seller : buyer;
                  const { tradePoint } = trader;
                  const propertyLink = getPropertyLink(tradePoint);
                  return (
                    <tr key={`trade-${id}`}>
                      <th scope="row" colSpan={2}>
                        <UUID uuid={id} />
                        <div style={{ whiteSpace: 'nowrap', fontWeight: 'normal' }}>
                          {DateTime.fromSeconds(
                            interval.timestamp,
                          ).toFormat('HH:mm DD')}
                          <br />
                          <small>
                            {`(${interval.length / 60}min)`}
                          </small>
                        </div>

                      </th>

                      <td style={{ whiteSpace: 'nowrap' }}>
                        {TradeListTable.tradeSummary(trade)}
                      </td>
                      <td colSpan={2}>
                        <BadgeTradeDirection direction={direction} />
                        <br />
                        <BadgeTradeType type={type} />
                      </td>
                      <td>
                        <strong>
                          {meter.title}
                        </strong>
                        <br />
                        {meter.identifier}
                        {propertyLink && <br />}
                        {propertyLink}
                      </td>
                      <td>
                        {direction === TRADE_DIRECTION_BUY
                          ? getTrader(seller)
                          : getTrader(buyer)}
                      </td>
                      <td>
                        <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}>
                          <UUID uuid={ruleId} />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            <tfoot className="border-top-0">
              <tr>
                <th scope="row" colSpan={2}>Total Buy</th>
                <td colSpan={6}>
                  {trades && trades.reduce((acc, trade) => {
                    if (trade.direction !== 'TRADE_DIRECTION_BUY') {
                      return acc;
                    }
                    acc[0].value += trade.value;
                    acc[0].volume += trade.volume;
                    return acc;
                  }, [{ value: 0, volume: 0 }]).map((summary) => (
                    <div key="total-buy">
                      <strong>
                        {format('.4s')(summary.volume === undefined ? 0 : summary.volume)}
                        <abbr title="watt hour">Wh</abbr>
                      </strong>
                      <br />
                      <strong>
                        {format('$.2f')(summary.value !== null ? summary.value : 0)}
                      </strong>
                      {summary.volume && summary.volume > 0 && (
                        <>
                          <br />
                          <strong>
                            {format('.2f')(convertEnergyPrice(summary.value / summary.volume))}
                          </strong>
                          {' '}
                          <abbr title="cents per kilowatt hour">c/kWh</abbr>
                        </>
                      )}
                    </div>
                  ))}
                </td>
              </tr>
              <tr>
                <th className="border-bottom-0" scope="row" colSpan={2}>Total Sell</th>
                <td className="border-bottom-0" colSpan={6}>
                  {trades && trades.reduce((acc, trade) => {
                    if (trade.direction !== 'TRADE_DIRECTION_SELL') {
                      return acc;
                    }
                    acc[0].value += trade.value;
                    acc[0].volume += trade.volume;
                    return acc;
                  }, [{ value: 0, volume: 0 }]).map((summary) => (
                    <div key="total-sell">
                      <strong>
                        {format('.4s')(summary.volume === undefined ? 0 : summary.volume)}
                        <abbr title="watt hour">Wh</abbr>
                      </strong>
                      <br />
                      <strong>
                        {format('$.2f')(summary.value !== null ? summary.value : 0)}
                      </strong>
                      {summary.volume && summary.volume > 0 && (
                        <>
                          <br />
                          <strong>
                            {format('.2f')(convertEnergyPrice(summary.value / summary.volume))}
                          </strong>
                          {' '}
                          <abbr title="cents per kilowatt hour">c/kWh</abbr>
                        </>
                      )}
                    </div>
                  ))}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="card-footer">
          <div className="trade-history-pagination">
            <nav aria-label="Trade history navigation">
              <ul className="pagination">
                <li className="page-item">
                  <Link
                    className="page-link trades-previous-day"
                    to={`/properties/${property.id}/trades/history/?date=${before.toISODate()}`}
                    onClick={(event) => (this.setDate(event, before))}
                  >
                    Previous day
                  </Link>
                </li>
                <li className="page-item">
                  <Link
                    className="page-link trades-next-day"
                    to={`/properties/${property.id}/trades/history/?date=${after.toISODate()}`}
                    onClick={(event) => (this.setDate(event, after))}
                  >
                    Next day
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

TradeListTable.propTypes = {
  date: PropTypes.instanceOf(DateTime).isRequired,
  dateUpdateFunc: PropTypes.func.isRequired,
  property: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  filter: PropTypes.shape({
    direction: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default createFragmentContainer(
  TradeListTable,
  {
    property: graphql`
      fragment TradeListTable_property on Property
        @argumentDefinitions(
          start: { type: "Timestamp!" }
          finish: { type: "Timestamp!" }
        ) {
        id
        meters(first: 500) {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              rules(first: 500) {
                edges {
                  node {
                    id
                    buyer {
                      tradePoint {
                        id
                        type
                        meter {
                          id
                          identifier
                          title
                          property {
                            id
                            title
                          }
                        }
                      }
                      userId
                      user {
                        email
                        givenName
                        familyName
                      }
                      communityId
                      residualId
                    }
                    seller {
                      tradePoint {
                        id
                        type
                        meter {
                          id
                          identifier
                          title
                          property {
                            id
                            title
                          }
                        }
                      }
                      userId
                      user {
                        email
                        givenName
                        familyName
                      }
                      communityId
                      residualId
                    }
                  }
                }
              }
              trades(start: $start, finish: $finish, pageSize: 999999999) {
                edges {
                  node {
                    id
                    ruleId
                    buyerTradePointId
                    sellerTradePointId
                    price
                    value
                    volume
                    direction
                    interval {
                      timestamp
                      length
                    }
                    type
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
);
