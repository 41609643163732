import { format } from 'd3-format';
import { Link } from 'found';
import { routerShape } from 'found/PropTypes';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Badge, Button, Row, Col, Card, CardBody, CardFooter, CardTitle, CardSubtitle, CardText,
} from 'reactstrap';
import styled from 'styled-components';

import { APIConfig } from 'src/config';
import PrioritizeNominatedTradesMutation from 'src/mutations/PrioritizeNominatedTradesMutation';
import FlashesStore from 'src/stores/FlashesStore';
import { PLATFORM_MODE_REBATE, TRADE_DIRECTION_BUY } from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import { tradeRulePriceRange } from 'src/util/tradeRule';
import username from 'src/util/decorators/username';
import { getTradeRuleMeterLabel } from 'src/helpers/tradeHelpers';

const DraggableCard = styled.div.attrs({
  className: 'card mb-4',
})`
  box-sizing: content-box;
  padding-right: 3rem;
`;

const DraggableCardHandle = styled.div`
  position: absolute;
  right: 0;
  vertical-align: middle;
  width: 3rem;
  background: #eee;
  height: 100%;
`;

const DraggableCardHandleIcon = styled.div`
  width: 100%;
  margin-top: 5rem;

  &:before {
    content: '.';
    font-size: 1rem;
    line-height: 1rem;
    text-shadow: 0 5px #666, 0 10px #666, 5px 0 #666, 5px 5px #666, 5px 10px #666, 10px 0 #666, 10px 5px #666, 10px 10px #666;
    color: #666;
    padding-left: 1rem;
    text-align: center;
  }
`;

class TradeRulePrioritizeNominatedList extends React.Component {
  constructor(props) {
    super(props);

    const { meter } = props;

    const rules = {};
    if (meter && meter.nominatedRules && meter.nominatedRules.edges) {
      meter.nominatedRules.edges.forEach((edge) => {
        const { node } = edge;
        if (node.buyer.tradePoint.id === meter.tradePointId) {
          rules[node.id] = node;
        }
      });
    }

    const ruleIds = Object.values(rules)
      .sort((a, b) => a.priority - b.priority)
      .map((rule) => rule.id);

    this.state = {
      rules,
      ruleIds,
      processing: false,
    };
  }

  handleConfirm = () => {
    const { meter } = this.props;
    const { ruleIds, processing } = this.state;

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, 'We are still processing your request...');
      return;
    }

    this.setState({ processing: true });
    FlashesStore.reset();

    PrioritizeNominatedTradesMutation(
      meter.tradePointId,
      ruleIds.map((id, index) => ({ id, priority: index + 1 })),
      this.handleConfirmSuccess,
      this.handleConfirmFailure,
    );
  };

  handleConfirmSuccess = (_response) => {
    const { property, router } = this.props;

    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.SUCCESS, 'Updated peer-to-peer trades priority order.');

    router.push(`/properties/${property.id}/trade-rules/active`);
  };

  handleConfirmFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    const { ruleIds } = this.state;

    if (!destination) {
      return;
    }

    const newRuleIds = [...ruleIds];
    newRuleIds.splice(source.index, 1);
    newRuleIds.splice(destination.index, 0, draggableId);

    this.setState({ ruleIds: newRuleIds });
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <div>Loading...</div>;
    }

    const { property, meter, router } = this.props;
    const { rules, ruleIds, processing } = this.state;
    const tradeRules = ruleIds.map((ruleId) => rules[ruleId]);

    return (
      <Card className="mt-4 mb-4 trade-rules-priority-list">
        <CardBody>
          <h2>{`Peer-to-Peer Trades for ${meter?.title}`}</h2>
          <Row>
            <Col xs="12" sm="12">
              <h3>Change buying priority order</h3>
              <p>
                {'Reorder the priority of your '}
                <strong>{tradeRules.length}</strong>
                {' peer-to-peer trade rules to buy energy.'}
              </p>

              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {((droppableProvided) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                      {tradeRules.map((rule, index) => {
                        const {
                          acceptedAt, acceptedBy, buyer, id,
                          priority, proposedAt, proposedBy, seller,
                        } = rule;
                        const newPriority = index + 1;
                        let priceLabel;
                        if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
                          priceLabel = 'Discount of: ';
                        } else {
                          priceLabel = 'Sell price: ';
                        }
                        const priceRange = tradeRulePriceRange(rule);

                        return (
                          <Draggable draggableId={id} index={index} key={`trade-rules-${id}`}>
                            {(draggableProvided) => (
                              <DraggableCard
                                ref={draggableProvided.innerRef}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...draggableProvided.draggableProps}
                              >
                                <CardBody>
                                  <CardTitle tag="h4">
                                    {`Priority ${newPriority}`}
                                    {newPriority !== priority && (
                                      <>
                                        {' ('}
                                        <s>{priority}</s>
                                        )
                                      </>
                                    )}
                                  </CardTitle>
                                  <CardSubtitle tag="h5" className="mb-2">
                                    Approved
                                  </CardSubtitle>
                                  <CardText>
                                    Buying for
                                    {' '}
                                    {getTradeRuleMeterLabel(buyer, seller, TRADE_DIRECTION_BUY)}
                                    Proposed by
                                    {' '}
                                    <strong>{username(proposedBy)}</strong>
                                    {' '}
                                    on
                                    {' '}
                                    {DateTime.fromSeconds(proposedAt).toFormat(
                                      'dd LLL yyyy',
                                    )}
                                    <br />
                                    {acceptedAt && (
                                      <>
                                        Accepted by
                                        {' '}
                                        <strong>{username(acceptedBy)}</strong>
                                        {' '}
                                        on
                                        {' '}
                                        {DateTime.fromSeconds(acceptedAt).toFormat(
                                          'dd LLL yyyy',
                                        )}
                                      </>
                                    )}
                                    <br />
                                    {priceLabel}
                                    <strong>
                                      {format('.3f')(convertEnergyPrice(priceRange.minimum))}
                                    </strong>
                                    {priceRange.maximum !== priceRange.minimum && (
                                      <>
                                        {' '}
                                        to
                                        {' '}
                                        <strong>
                                          {format('.3f')(convertEnergyPrice(priceRange.maximum))}
                                        </strong>
                                      </>
                                    )}
                                    {' '}
                                    <abbr title="cents per kilowatt hour">c/kWh</abbr>
                                    {' '}
                                    (
                                    <Link to={`/properties/${property.id}/trade-rules/${id}`}>further details</Link>
                                    )
                                    <br />
                                    <Link
                                      to={`/properties/${property.id}/trade-rules/${id}`}
                                    >
                                      <Badge color="mid">{id}</Badge>
                                    </Link>
                                  </CardText>
                                </CardBody>

                                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                                <DraggableCardHandle {...draggableProvided.dragHandleProps} className="trade-rules-priority-handler">
                                  <DraggableCardHandleIcon />
                                </DraggableCardHandle>
                              </DraggableCard>
                            )}
                          </Draggable>
                        );
                      })}
                      {droppableProvided.placeholder}
                    </div>
                  ))}
                </Droppable>
              </DragDropContext>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button color="primary" className="me-2" onClick={this.handleConfirm} disabled={processing}>
            Confirm order
          </Button>
          <Button color="" onClick={() => (router.go(-1))} disabled={processing}>
            Cancel changes
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

TradeRulePrioritizeNominatedList.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  meter: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  router: routerShape.isRequired,
};

TradeRulePrioritizeNominatedList.defaultProps = {
  property: null,
  meter: null,
};

export default createFragmentContainer(
  TradeRulePrioritizeNominatedList,
  {
    property: graphql`
      fragment TradeRulePrioritizeNominatedList_property on Property {
        id
      }
    `,
    meter: graphql`
      fragment TradeRulePrioritizeNominatedList_meter on Meter {
        id
        identifier
        title
        tradePointId
        nominatedRules: rules(first: 500, type: TRADE_TYPE_NOMINATED, state: TRADE_RULE_STATE_ACCEPTED) {
          edges {
            node {
              id
              priority
              tradeType
              state
              buyer {
                userId
                communityId
                residualId
                tradePoint {
                  id
                  meter {
                    id
                    identifier
                    title
                    property {
                      id
                      title
                    }
                  }
                }
              }
              seller {
                userId
                communityId
                residualId
                tradePoint {
                  id
                  meter {
                    id
                    identifier
                    title
                    property {
                      id
                      title
                    }
                  }
                }
              }
              clauses {
                edges {
                  node {
                    price
                  }
                }
              }
              start
              finish
              proposedAt
              proposedBy {
                id
                email
                givenName
                familyName
              }
              acceptedAt
              acceptedBy {
                id
                email
                givenName
                familyName
              }
            }
          }
        }
      }
    `,
  },
);
