import isActive from 'src/util/isActive';
import sortTariffs from 'src/util/sortTariffs';

/**
 * Filter the active tariffs and apply sorting (based on the display list in sortTariffs.js)
 * isActive(util/isActive.js) function takes care of filtering out the active tariffs
 * sortTariffs(util/sortTariffs) function takes care of sorting the tariffs
 * @param {Array<object>} tariffEdges - list of meter tariff
 * @returns {Array<object>} sorted list of active tariffs
 */
export const activeTariffs = (tariffEdges) => {
  if (!tariffEdges) {
    return null;
  }

  return tariffEdges.map((el) => el.node)
    .filter((tariff) => isActive(tariff.active))
    .sort(sortTariffs);
};

/**
 * Filter the inactive tariffs and apply sorting (based on the display list in sortTariffs.js)
 * isActive(util/isActive.js) function takes care of filtering out the active tariffs
 * sortTariffs(util/sortTariffs) function takes care of sorting the tariffs
 * @param {Array<object>} tariffEdges - list of meter tariff
 * @returns {Array<object>} sorted list of inactive tariffs
 */
export const inactiveTariffs = (tariffEdges) => {
  if (!tariffEdges) {
    return null;
  }

  return tariffEdges.map((el) => el.node)
    .filter((tariff) => !isActive(tariff.active))
    .sort(sortTariffs);
};

/**
 * Filter tariffs and apply sorting (based on the display list in sortTariffs.js)
 * @param {Array<object>} tariffEdges  - list of meter tariff
 * @returns {Array<object>} sorted list of tariffs
 */
export const allTariffs = (tariffEdges) => {
  if (!tariffEdges) {
    return null;
  }

  const allTariffList = [...activeTariffs(tariffEdges), ...inactiveTariffs(tariffEdges)];
  return allTariffList;
};
