import { DateTime } from 'luxon';

/**
 * Emissions factors, for each year type, in kg CO2•e/kWh.
 */
export const EMISSION_FACTORS = {
  cal2016: {
    timeRange: {
      start: DateTime.fromISO('2016-01-01'),
      finish: DateTime.fromISO('2016-12-31'),
    },
    regions: {
      SG: 0.4237,
    },
  },
  cal2017: {
    timeRange: {
      start: DateTime.fromISO('2017-01-01'),
      finish: DateTime.fromISO('2017-12-31'),
    },
    regions: {
      SG: 0.421,
    },
  },
  cal2018: {
    timeRange: {
      start: DateTime.fromISO('2018-01-01'),
      finish: DateTime.fromISO('2018-12-31'),
    },
    regions: {
      SG: 0.4206,
    },
  },
  cal2019: {
    timeRange: {
      start: DateTime.fromISO('2019-01-01'),
      finish: DateTime.fromISO('2019-12-31'),
    },
    regions: {
      SG: 0.4085,
    },
  },
  cal2020: {
    timeRange: {
      start: DateTime.fromISO('2020-01-01'),
      finish: DateTime.fromISO('2020-12-31'),
    },
    regions: {
      SG: 0.4074,
    },
  },
  cal2021: {
    timeRange: {
      start: DateTime.fromISO('2021-01-01'),
      finish: DateTime.fromISO('2099-12-31'),
      // finish: DateTime.fromISO('2021-12-31'),
    },
    regions: {
      SG: 0.4057,
    },
  },
  fy13: {
    timeRange: {
      start: DateTime.fromISO('2012-07-01'),
      finish: DateTime.fromISO('2013-06-30'),
    },
    regions: {
      'AU-NSW': 0.86,
      'AU-ACT': 0.86,
      'AU-NT': 0.67,
      'AU-QLD': 0.80,
      'AU-SA': 0.58,
      'AU-TAS': 0.19,
      'AU-VIC': 1.16,
      'AU-WA': 0.76,
      AU: 0.85,
    },
  },
  fy14: {
    timeRange: {
      start: DateTime.fromISO('2013-07-01'),
      finish: DateTime.fromISO('2014-06-30'),
    },
    regions: {
      'AU-NSW': 0.84,
      'AU-ACT': 0.84,
      'AU-NT': 0.67,
      'AU-QLD': 0.79,
      'AU-SA': 0.55,
      'AU-TAS': 0.14,
      'AU-VIC': 1.11,
      'AU-WA': 0.74,
      AU: 0.82,
    },
  },
  fy15: {
    timeRange: {
      start: DateTime.fromISO('2014-07-01'),
      finish: DateTime.fromISO('2015-06-30'),
    },
    regions: {
      'AU-NSW': 0.83,
      'AU-ACT': 0.83,
      'AU-NT': 0.66,
      'AU-QLD': 0.78,
      'AU-SA': 0.53,
      'AU-TAS': 0.15,
      'AU-VIC': 1.07,
      'AU-WA': 0.71,
      AU: 0.81,
    },
  },
  fy16: {
    timeRange: {
      start: DateTime.fromISO('2015-07-01'),
      finish: DateTime.fromISO('2016-06-30'),
    },
    regions: {
      'AU-NSW': 0.83,
      'AU-ACT': 0.83,
      'AU-NT': 0.65,
      'AU-QLD': 0.79,
      'AU-SA': 0.53,
      'AU-TAS': 0.19,
      'AU-VIC': 1.07,
      'AU-WA': 0.70,
      AU: 0.80,
    },
  },
  fy17: {
    timeRange: {
      start: DateTime.fromISO('2016-07-01'),
      finish: DateTime.fromISO('2017-06-30'),
    },
    regions: {
      'AU-NSW': 0.82,
      'AU-ACT': 0.82,
      'AU-NT': 0.64,
      'AU-QLD': 0.80,
      'AU-SA': 0.51,
      'AU-TAS': 0.23,
      'AU-VIC': 1.07,
      'AU-WA': 0.70,
      AU: 0.80,
    },
  },
  fy18: {
    timeRange: {
      start: DateTime.fromISO('2017-07-01'),
      finish: DateTime.fromISO('2018-06-30'),
    },
    regions: {
      'AU-NSW': 0.81,
      'AU-ACT': 0.81,
      'AU-NT': 0.63,
      'AU-QLD': 0.81,
      'AU-SA': 0.44,
      'AU-TAS': 0.19,
      'AU-VIC': 1.02,
      'AU-WA': 0.69,
      AU: 0.79,
    },
  },
  fy19: {
    timeRange: {
      start: DateTime.fromISO('2018-07-01'),
      finish: DateTime.fromISO('2019-06-30'),
    },
    regions: {
      'AU-NSW': 0.79,
      'AU-ACT': 0.79,
      'AU-NT': 0.57,
      'AU-QLD': 0.80,
      'AU-SA': 0.35,
      'AU-TAS': 0.16,
      'AU-VIC': 0.96,
      'AU-WA': 0.68,
      AU: 0.76,
    },
  },
  fy20: {
    timeRange: {
      start: DateTime.fromISO('2019-07-01'),
      finish: DateTime.fromISO('2020-06-30'),
    },
    regions: {
      'AU-NSW': 0.78,
      'AU-ACT': 0.78,
      'AU-NT': 0.54,
      'AU-QLD': 0.80,
      'AU-SA': 0.30,
      'AU-TAS': 0.14,
      'AU-VIC': 0.91,
      'AU-WA': 0.67,
      AU: 0.73,
    },
  },
  fy21: {
    timeRange: {
      start: DateTime.fromISO('2020-07-01'),
      finish: DateTime.fromISO('2099-12-31'),
    },
    regions: {
      'AU-NSW': 0.73,
      'AU-ACT': 0.73,
      'AU-NT': 0.54,
      'AU-QLD': 0.73,
      'AU-SA': 0.25,
      'AU-TAS': 0.17,
      'AU-VIC': 0.85,
      'AU-WA': 0.545,
      AU: 0.68,
    },
  },
};

/**
 *  ISO based country codes.
 */
export const COUNTRY_CODES = {
  default: 'AU',
  australia: 'AU',
  singapore: 'SG',
};

/**
 * Default carbon free emission factor to be applied to non carbon values.
 */
export const DEFAULT_CARBON_FREE_EMISSION_FACTOR = 0;

/**
 * The constants below are also present in `src/util/constants`. These are duplicated
 * here because we want `lib/carbondata` to be independent of any external dependencies.
 */

/**
 * Trade direction is "buy".
 */
export const TRADE_DIRECTION_BUY = 'TRADE_DIRECTION_BUY';

/**
 * Trade direction is "sell".
 */
export const TRADE_DIRECTION_SELL = 'TRADE_DIRECTION_SELL';

/**
 * Trade type is "residual" (also referred to as "retailer default").
 */
export const TRADE_TYPE_RESIDUAL = 'TRADE_TYPE_RESIDUAL';

/**
 * Trade label.
 */
export const TRADE = 'TRADE';

/**
 * Meter label.
 */
export const METER = 'METER';
