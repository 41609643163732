import PropTypes from 'prop-types';
import React from 'react';
import {
  Card, CardBody, CardHeader, CardFooter,
  Button, Form, FormFeedback, FormGroup, FormText, Input, Label,
} from 'reactstrap';
import isEmail from 'validator/es/lib/isEmail';

import FlashesStore from 'src/stores/FlashesStore';

class AdminUserInviteCustomerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      givenName: '',
      familyName: '',
      emailValid: null,
    };
  }

  isValid = () => {
    const { emailValid } = this.state;

    return emailValid !== null && emailValid;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { handleInvite, processing } = this.props;

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, 'We are still processing your request...');
      return;
    }

    if (!this.isValid()) {
      FlashesStore.flash(FlashesStore.ERROR, 'Form data not valid. Please see below.');
      return;
    }

    const {
      email, familyName, givenName,
    } = this.state;

    const input = {
      email, familyName, givenName,
    };

    handleInvite(input);
  };

  handleEmailChange = (event) => {
    const { value: email } = event.target;
    const emailValid = isEmail(email);

    this.setState({ email, emailValid });
  };

  handleFamilyNameChange = (event) => {
    const { value } = event.target;

    this.setState({ familyName: value });
  };

  handleGivenNameChange = (event) => {
    const { value } = event.target;

    this.setState({ givenName: value });
  };

  render() {
    const { processing } = this.props;

    const {
      email, familyName, givenName, emailValid,
    } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} disabled={processing}>
        <Card>
          <CardHeader tag="h2">
            Invite a new customer to the Enosi platform
          </CardHeader>
          <CardBody>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input type="email" name="email" id="email" value={email} onChange={this.handleEmailChange} disabled={processing} valid={emailValid} invalid={emailValid !== null && !emailValid} />
              <FormFeedback>Not a valid email</FormFeedback>
              <FormText>
                This email address will be used by the admin user to log onto the Enosi platform
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="givenName">Given name</Label>
              <Input type="text" name="givenName" id="givenName" value={givenName} onChange={this.handleGivenNameChange} disabled={processing} />
            </FormGroup>
            <FormGroup>
              <Label for="familyName">Family name</Label>
              <Input type="text" name="familyName" id="familyName" value={familyName} onChange={this.handleFamilyNameChange} disabled={processing} />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button disabled={processing}>Send invitation</Button>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

AdminUserInviteCustomerForm.propTypes = {
  handleInvite: PropTypes.func,
  processing: PropTypes.bool,
};

AdminUserInviteCustomerForm.defaultProps = {
  handleInvite: null,
  processing: false,
};

export default AdminUserInviteCustomerForm;
