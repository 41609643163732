import { Link } from 'found';
import { routerShape } from 'found/PropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import {
  Card, CardBody, CardHeader, CardFooter,
  Button, Form, FormFeedback, FormGroup, FormText, Input, Label,
  Col, Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import isEmail from 'validator/es/lib/isEmail';
import isIn from 'validator/es/lib/isIn';
// import isNumeric from 'validator/es/lib/isNumeric';
import isUUID from 'validator/es/lib/isUUID';

import { ClauseEdit, ClauseShow } from 'src/components/TimeOfUse';
import FlashesStore from 'src/stores/FlashesStore';
import isActive from 'src/util/isActive';
import {
  TRADE_DIRECTION_UNSPECIFIED, TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL,
  Days, DAY_PUBLIC_HOLIDAY, Months,
} from 'src/util/constants';
import { timeOfDayToDuration } from 'src/util/timeOfUse';

const TRADE_POINT_LABEL_BUY = 'Choose the Trade Point you are buying for';
const TRADE_POINT_LABEL_SELL = 'Choose the Trade Point you are selling from';

class TradeRuleProposeNominatedTimeOfUseForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Values
      direction: TRADE_DIRECTION_UNSPECIFIED,
      proposerTradePointId: null,
      recipientUserEmail: '',
      clauses: [],
      ignoreDaylightSavings: false,
      ignorePublicHolidays: false,

      // Validation
      directionValid: null,
      proposerTradePointIdValid: null,
      recipientUserEmailValid: null,

      editing: null, // { index }
    };
    this.lastClauseId = 0;
  }

  generateClauseId = () => {
    this.lastClauseId += 1;
    return this.lastClauseId.toString();
  };

  formatClauseFromEdge = (edge) => {
    const { node } = edge;
    const {
      price, monthsOfYear, daysOfWeek, timesOfDay,
    } = node;

    return {
      price,
      monthsOfYear,
      daysOfWeek,
      timesOfDay,
      id: this.generateClauseId(),
    };
  };

  copyFromResidual = () => {
    const { property } = this.props;
    const { meters } = property;

    const {
      direction, proposerTradePointId, directionValid, proposerTradePointIdValid,
    } = this.state;

    if (!directionValid || !proposerTradePointIdValid) {
      return null;
    }

    const meterEdge = meters.edges
      .filter((edge) => edge.node.tradePointId === proposerTradePointId)[0];

    if (meterEdge === undefined) {
      return null;
    }

    const { tradePointId, residualRules } = meterEdge.node;

    let clauses = [];
    const currentRules = residualRules.edges.filter((edge) => {
      if (direction === TRADE_DIRECTION_BUY) {
        return edge.node.buyer.tradePoint.id === tradePointId;
      }
      if (direction === TRADE_DIRECTION_SELL) {
        return edge.node.seller.tradePoint.id === tradePointId;
      }
      return false;
    });
    if (currentRules.length === 1) {
      clauses = currentRules[0].node.clauses.edges.map(this.formatClauseFromEdge);
    }

    if (clauses.length === 0) {
      return null;
    }

    let ignorePublicHolidays = false;
    if (residualRules.edges.length > 0) {
      const rule = residualRules.edges[0].node;
      if (rule.clauses.edges.length > 0) {
        const clause = rule.clauses.edges[0].node;
        if (clause.ignorePublicHolidays) {
          ignorePublicHolidays = true;
        }
      }
    }

    return { clauses, ignorePublicHolidays };
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { handleTradeRuleProposeNominated, processing, property } = this.props;
    const { timezone, publicHolidayRegion } = property;

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, 'We are still processing your request...');
      return;
    }

    if (!this.isValid()) {
      FlashesStore.flash(FlashesStore.ERROR, 'Form data not valid. Please see below.');
      return;
    }

    const {
      direction, proposerTradePointId, recipientUserEmail, clauses,
      ignoreDaylightSavings, ignorePublicHolidays,
    } = this.state;

    const formatClause = (clause) => {
      const {
        price, monthsOfYear, daysOfWeek, timesOfDay,
      } = clause;

      return {
        price,
        timezone,
        publicHolidayRegion,
        ignoreDaylightSavings,
        ignorePublicHolidays,
        monthsOfYear,
        daysOfWeek,
        timesOfDay,
      };
    };

    const input = {
      direction,
      clauses: clauses.map(formatClause),
      proposerTradePointId,
      recipientUserEmail,
    };

    handleTradeRuleProposeNominated(input);
  };

  handleDirectionChange = (event) => {
    const { value: direction } = event.target;
    const directionValid = isIn(direction, [TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL]);

    this.setState({ direction, directionValid });
  };

  handleRecipientUserEmailChange = (event) => {
    const { value: recipientUserEmail } = event.target;
    const recipientUserEmailValid = isEmail(recipientUserEmail);

    this.setState({ recipientUserEmail, recipientUserEmailValid });
  };

  handleProposerTradePointIdChange = (event) => {
    const { value: proposerTradePointId } = event.target;
    const proposerTradePointIdValid = isUUID(proposerTradePointId);

    this.setState({ proposerTradePointId, proposerTradePointIdValid });
  };

  handleIgnoreDaylightSavingsChange = (event) => {
    const { checked: ignoreDaylightSavings } = event.target;

    this.setState({ ignoreDaylightSavings });
  };

  handleIgnorePublicHolidaysChange = (event) => {
    const { checked: ignorePublicHolidays } = event.target;

    this.setState({ ignorePublicHolidays });
  };

  isEditing = (index) => {
    const { editing } = this.state;

    if (index === undefined) {
      return !!editing;
    }

    return editing && editing.index === index;
  };

  editClause = (index) => {
    this.setState({ editing: { index } });
  };

  addClause = () => {
    this.setState({ editing: { index: null } });
  };

  removeClause = (index) => {
    const { clauses } = this.state;

    const newClauses = [...clauses];
    newClauses.splice(index, 1);
    this.setState({ clauses: newClauses });
  };

  editClauseSet = (index, newClause) => {
    const { clauses } = this.state;

    const newClauses = [...clauses];
    newClauses[index] = { ...newClause, id: clauses[index].id };
    this.setState({ clauses: newClauses, editing: null });
  };

  addClauseSet = (newClause) => {
    const { clauses } = this.state;

    const newClauses = [...clauses];
    newClauses.push({ ...newClause, id: this.generateClauseId() });
    this.setState({ clauses: newClauses, editing: null });
  };

  cancelEdit = () => {
    this.setState({ editing: null });
  };

  isValid = () => {
    const {
      clauses,
      directionValid,
      proposerTradePointIdValid,
      recipientUserEmailValid,
    } = this.state;

    return directionValid !== null && directionValid
      && proposerTradePointIdValid !== null && proposerTradePointIdValid
      && recipientUserEmailValid !== null && recipientUserEmailValid
      && this.validClauses(clauses);
  };

  validClauses = (clauses) => {
    const { ignorePublicHolidays } = this.state;

    if (clauses.length === 0) {
      return false;
    }

    const relevantDays = ignorePublicHolidays
      ? Days.filter((day) => day !== DAY_PUBLIC_HOLIDAY) : Days;

    const combinations = [];
    clauses.forEach(({ monthsOfYear, daysOfWeek, timesOfDay }) => {
      monthsOfYear.forEach((month) => {
        daysOfWeek.forEach((day) => {
          if (ignorePublicHolidays && day === DAY_PUBLIC_HOLIDAY) {
            return;
          }
          timesOfDay.forEach(({ start, finish }) => {
            combinations.push({
              month: Months.indexOf(month),
              day: relevantDays.indexOf(day),
              time: {
                start: timeOfDayToDuration(start).valueOf(),
                finish: timeOfDayToDuration(finish).valueOf(),
              },
            });
          });
        });
      });
    });

    combinations.sort((a, b) => (a.month - b.month || a.day - b.day
      || a.time.start - b.time.start || a.time.finish - b.time.finish));

    for (let i = 1; i < combinations.length; i += 1) {
      const curr = combinations[i];
      const prev = combinations[i - 1];

      const comparison = curr.month - prev.month || curr.day - prev.day
        || curr.time.start - prev.time.finish;

      if (comparison < 0) {
        return false;
      }
    }

    return true;
  };

  render() {
    const { property, processing, router } = this.props;
    const { meters } = property;
    const meterNodes = meters && meters.edges.map((edge) => (edge.node));

    const {
      direction, proposerTradePointId, recipientUserEmail, clauses,
      ignoreDaylightSavings, ignorePublicHolidays,
      directionValid, proposerTradePointIdValid, recipientUserEmailValid,
    } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} disabled={processing}>
        <Card>
          <CardHeader className="d-flex flex-wrap">
            <h2 className="mb-0">
              Propose a peer-to-peer trade
            </h2>
            <Link to={`/properties/${property.id}/trade-rules/propose/nominated`} className="btn btn-darken ms-auto">
              Flat pricing
            </Link>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <Label for="direction">Are you buying or selling?</Label>
              <Input type="select" name="direction" id="direction" defaultValue={direction} onChange={this.handleDirectionChange} disabled={processing} valid={directionValid} invalid={directionValid !== null && !directionValid}>
                <option value={null}>
                  Select &lsquo;Buy&lsquo; or &lsquo;Sell&lsquo;
                </option>
                <option value={TRADE_DIRECTION_BUY}>
                  Buy
                </option>
                <option value={TRADE_DIRECTION_SELL}>
                  Sell
                </option>
              </Input>
              <FormFeedback>Invalid direction</FormFeedback>
              <FormText>
                Are you proposing to sell your energy, or buy energy from a friend?
              </FormText>
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="proposerTradePointId">{direction === TRADE_DIRECTION_BUY ? TRADE_POINT_LABEL_BUY : TRADE_POINT_LABEL_SELL}</Label>
                  <Input type="select" name="select" id="proposerTradePointId" defaultValue={proposerTradePointId} onChange={this.handleProposerTradePointIdChange} disabled={processing} valid={proposerTradePointIdValid} invalid={proposerTradePointIdValid !== null && !proposerTradePointIdValid}>
                    <option key="meter-id-null">
                      Select a Trade Point
                    </option>
                    {meterNodes && meterNodes.map((meter) => (
                      <option
                        value={meter.tradePointId}
                        disabled={!isActive(meter.active)}
                        key={meter.id}
                      >
                        {`${meter.title} (${meter.identifier})`}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>Invalid trade point selection</FormFeedback>
                  <FormText>
                    Which trade point are you using?
                  </FormText>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="recipientUserEmail">Recipient email</Label>
                  <Input type="email" name="recipientUserEmail" id="recipientUserEmail" value={recipientUserEmail} onChange={this.handleRecipientUserEmailChange} disabled={processing} valid={recipientUserEmailValid} invalid={recipientUserEmailValid !== null && !recipientUserEmailValid} />
                  <FormFeedback>Invalid email</FormFeedback>
                  <FormText>
                    Who do you want to propose to trade with?
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              {clauses.map((clause, index) => {
                if (this.isEditing(index)) {
                  return (
                    <ClauseEdit
                      clause={clause}
                      setClause={
                        (newClause) => this.editClauseSet(index, newClause)
                      }
                      cancelEdit={this.cancelEdit}
                      priceHelpText="What price, in c/kWh, do you want to propose?"
                      key={clause.id}
                    />
                  );
                }
                return (
                  <ClauseShow
                    clause={clause}
                    showControls={!this.isEditing()}
                    editClause={() => this.editClause(index)}
                    removeClause={() => this.removeClause(index)}
                    key={clause.id}
                  />
                );
              })}
              {this.isEditing(null) && (
                <ClauseEdit
                  setClause={(newClause) => this.addClauseSet(newClause)}
                  cancelEdit={this.cancelEdit}
                  priceHelpText="What price, in c/kWh, do you want to propose?"
                />
              )}
              {!this.isEditing() && (
                <Button color="darken" onClick={() => this.addClause()}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add clause
                </Button>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="ignoreDaylightSavings">
                Ignore daylight savings
                <Input
                  type="checkbox"
                  name="ignoreDaylightSavings"
                  id="ignoreDaylightSavings"
                  label="Ignore daylight savings"
                  className="mb-2"
                  value={`${ignoreDaylightSavings}`}
                  onChange={this.handleIgnoreDaylightSavingsChange}
                  disabled={processing}
                />
              </Label>
              <Label for="ignorePublicHolidays">
                Ignore public holidays
                <Input
                  type="checkbox"
                  name="ignorePublicHolidays"
                  id="ignorePublicHolidays"
                  label="Ignore public holidays"
                  className="mb-2"
                  value={`${ignorePublicHolidays}`}
                  onChange={this.handleIgnorePublicHolidaysChange}
                  disabled={processing}
                />
              </Label>
            </FormGroup>
          </CardBody>
          <CardFooter className="d-flex">
            <Button disabled={processing || this.isEditing()}>
              Propose
            </Button>
            <Button color="" onClick={() => (router.go(-1))} disabled={processing || this.isEditing()}>
              Cancel
            </Button>
            <Button
              color="darken"
              className="ms-auto"
              onClick={() => this.setState(this.copyFromResidual())}
              disabled={processing || this.isEditing() || !this.copyFromResidual()}
            >
              <FontAwesomeIcon icon={faClone} className="me-2" />
              Copy from retailer default
            </Button>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

TradeRuleProposeNominatedTimeOfUseForm.propTypes = {
  handleTradeRuleProposeNominated: PropTypes.func,
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  processing: PropTypes.bool,
  router: routerShape.isRequired,
};

TradeRuleProposeNominatedTimeOfUseForm.defaultProps = {
  handleTradeRuleProposeNominated: null,
  property: null,
  processing: false,
};

export default createFragmentContainer(
  TradeRuleProposeNominatedTimeOfUseForm,
  {
    property: graphql`
      fragment TradeRuleProposeNominatedTimeOfUseForm_property on Property {
        id
        title
        timezone
        publicHolidayRegion
        meters {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              active { start finish }
              residualRules: rules(first: 500, type: TRADE_TYPE_RESIDUAL, state: TRADE_RULE_STATE_ACCEPTED, start: $now, finish: $now) {
                edges {
                  node {
                    id
                    priority
                    tradeType
                    state
                    buyer {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        id
                      }
                    }
                    seller {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        id
                      }
                    }
                    clauses {
                      edges {
                        node {
                          price
                          ignorePublicHolidays
                          monthsOfYear
                          daysOfWeek
                          timesOfDay {
                            start  { hours minutes seconds }
                            finish { hours minutes seconds }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
);
