import { matchShape, routerShape } from 'found/PropTypes';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Button,
  Form, FormFeedback, FormGroup, FormText, Label, Input,
} from 'reactstrap';
import isEmail from 'validator/es/lib/isEmail';

import PasswordResetConfirmMutation from 'src/mutations/PasswordResetConfirmMutation';
import FlashesStore from 'src/stores/FlashesStore';
import isPassword from 'src/validators/isPassword';

import AuthContainer, { AUTH_TYPE_RESET_PASSWORD } from '../AuthContainer';

class AuthResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailValid: null,
      password: '',
      passwordValid: null,
      confirmPassword: '',
      confirmPasswordValid: null,
      processing: false,
    };
  }

  handleEmailChange = (e) => {
    const { value: email } = e.target;
    const emailValid = isEmail(email);

    this.setState({ email, emailValid });
  };

  handlePasswordChange = (e) => {
    const { value: password } = e.target;
    const passwordValid = isPassword(password);

    this.setState({ password, passwordValid });
  };

  handleConfirmPasswordChange = (e) => {
    const { value: confirmPassword } = e.target;
    const { password } = this.state;
    const confirmPasswordValid = confirmPassword === password;

    this.setState({ confirmPassword, confirmPasswordValid });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password, processing } = this.state;

    FlashesStore.reset();

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, 'We are still processing your request...');
      return;
    }

    if (!this.isValid()) {
      FlashesStore.flash(FlashesStore.ERROR, 'Form data not valid. Please see below.');
      return;
    }

    this.setState({ processing: true });

    const { match } = this.props;
    const { params } = match;
    const { token } = params;

    PasswordResetConfirmMutation(
      {
        email,
        password,
        verificationCode: token,
      },
      this.handleSubmitSuccess,
      this.handleSubmitFailure,
    );
  };

  // eslint-disable-next-line no-unused-vars
  handleSubmitSuccess = (_response) => {
    this.setState({ processing: false });

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      'Password reset was successful. Please confirm by logging in with your email and password.',
    );

    const { router } = this.props;
    router.push('/login');
  };

  handleSubmitFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error.message);
  };

  isValid = () => {
    const { emailValid, passwordValid, confirmPasswordValid } = this.state;

    return emailValid && passwordValid && confirmPasswordValid;
  };

  render() {
    const {
      email, emailValid, password, passwordValid,
      confirmPassword, confirmPasswordValid, processing,
    } = this.state;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Enosi - set up a new password</title>
        </Helmet>

        <AuthContainer type={AUTH_TYPE_RESET_PASSWORD} title="Set a new password">
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label htmlFor="email">Your account’s email address</Label>
              <Input
                type="email"
                id="email"
                aria-describedby="email"
                placeholder="Your account's email address"
                onChange={this.handleEmailChange}
                value={email}
                disabled={processing}
                valid={emailValid}
                invalid={emailValid !== null && !emailValid}
              />
              <FormFeedback>Invalid email address</FormFeedback>
              <FormText>Email address associated with your Enosi account</FormText>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="formPassword">Password</Label>
              <Input
                type="password"
                id="formPassword"
                aria-describedby="password"
                placeholder="Enter password"
                onChange={this.handlePasswordChange}
                value={password}
                valid={passwordValid}
                invalid={passwordValid !== null && !passwordValid}
                disabled={processing}
              />
              <FormFeedback>
                Password must be at least 8 characters long, with uppercase, lowercase,
                number and one of:
                <br />
                <code className="code">
                  {'^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , < > \' : ; | _ ~ `'}
                </code>
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="formPasswordConfirmation">
                Password confirmation
              </Label>
              <Input
                type="password"
                id="formPasswordConfirmation"
                aria-describedby="password"
                placeholder="Confirm password"
                onChange={this.handleConfirmPasswordChange}
                value={confirmPassword}
                valid={confirmPasswordValid}
                invalid={confirmPasswordValid !== null && !confirmPasswordValid}
                disabled={processing}
              />
              <FormFeedback>Passwords must match</FormFeedback>
              <FormText>Confirm the password you are setting</FormText>
            </FormGroup>
            <Button type="submit" color="primary">
              Update
            </Button>
          </Form>
        </AuthContainer>
      </>
    );
  }
}

AuthResetPassword.propTypes = {
  match: matchShape.isRequired,
  router: routerShape.isRequired,
};

export default AuthResetPassword;
