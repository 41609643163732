// rangesFor returns an array of ranges (with values start and finish) of the values
// given all values. If wrapValues is true, the values can "wrap" around the edge
const rangesFor = (values, allValues, wrapValues) => {
  if (allValues.every((v) => values.includes(v))) {
    return [{ start: allValues[0], finish: allValues[allValues.length - 1] }];
  }

  const ranges = [];
  allValues.forEach((v, i) => {
    if (!values.includes(v)) { return; }

    const prevValue = allValues[(allValues.length + i - 1) % allValues.length];
    const nextValue = allValues[(allValues.length + i + 1) % allValues.length];

    // No ranges, add new range
    if (ranges.length === 0) {
      ranges.push({ start: v, finish: v });
      return;
    }
    // Range continues existing moving forward
    if (ranges[ranges.length - 1].finish === prevValue) {
      ranges[ranges.length - 1].finish = v;
    } else {
      // Not continuing forward, not wrapping around, we simply add a new range
      ranges.push({ start: v, finish: v });
    }
    // Return *unless* this is the last element or not supporting wrapping
    if (allValues.length - 1 !== i || !wrapValues) { return; }

    // Check if range continues existing wrapping around
    if (wrapValues && ranges[0].start === nextValue) {
      const lastRange = ranges.pop();
      ranges[0].start = lastRange.start;
    }
  });

  return ranges;
};

export default rangesFor;
