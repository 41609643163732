import { commitMutation, graphql } from 'react-relay';
import environment from 'src/util/environment';

const mutation = graphql`
  mutation ProposeCommunityTradeMutation($input: CommunityTradeProposal!) {
    proposeCommunityTrade(input: $input) {
      id
    }
  }
`;

const ProposeCommunityTradeMutation = (input, onCompleted, onError) => {
  const variables = { input };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted,
      onError,
    },
  );
};

export default ProposeCommunityTradeMutation;
