/**
 * @generated SignedSource<<9fdd15b476a12e8ab8cac90282589a1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finish",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "TimeRange",
  "kind": "LinkedField",
  "name": "active",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "givenName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "familyName",
    "storageKey": null
  },
  (v5/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TradePoint",
    "kind": "LinkedField",
    "name": "tradePoint",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Community",
    "kind": "LinkedField",
    "name": "community",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AdminTradeRuleShow_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TradeRule",
        "kind": "LinkedField",
        "name": "rule",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminTradeRuleShow_rule"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_AdminTradeRuleShow_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TradeRule",
        "kind": "LinkedField",
        "name": "rule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tradeType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClauseConnection",
            "kind": "LinkedField",
            "name": "clauses",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClauseEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Clause",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "price",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "priority",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sequenceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Trader",
            "kind": "LinkedField",
            "name": "buyer",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Trader",
            "kind": "LinkedField",
            "name": "seller",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "proposedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "proposedBy",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cancelledAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "cancelledBy",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "acceptedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "acceptedBy",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rejectedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "rejectedBy",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "closedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "closedBy",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7210bedeea860fc92d6f04ce95e6b450",
    "id": null,
    "metadata": {},
    "name": "routes_AdminTradeRuleShow_Query",
    "operationKind": "query",
    "text": "query routes_AdminTradeRuleShow_Query(\n  $id: ID!\n) {\n  rule(id: $id) {\n    ...AdminTradeRuleShow_rule\n    id\n  }\n}\n\nfragment AdminTradeRuleShow_rule on TradeRule {\n  id\n  tradeType\n  clauses {\n    edges {\n      node {\n        price\n        id\n      }\n    }\n  }\n  start\n  finish\n  state\n  priority\n  sequenceId\n  buyer {\n    tradePoint {\n      id\n      type\n    }\n    community {\n      id\n      title\n      active {\n        start\n        finish\n      }\n    }\n    user {\n      id\n      email\n      givenName\n      familyName\n      active {\n        start\n        finish\n      }\n    }\n  }\n  seller {\n    tradePoint {\n      id\n      type\n    }\n    community {\n      id\n      title\n      active {\n        start\n        finish\n      }\n    }\n    user {\n      id\n      email\n      givenName\n      familyName\n      active {\n        start\n        finish\n      }\n    }\n  }\n  proposedAt\n  proposedBy {\n    id\n    email\n    givenName\n    familyName\n    active {\n      start\n      finish\n    }\n  }\n  cancelledAt\n  cancelledBy {\n    id\n    email\n    givenName\n    familyName\n    active {\n      start\n      finish\n    }\n  }\n  acceptedAt\n  acceptedBy {\n    id\n    email\n    givenName\n    familyName\n    active {\n      start\n      finish\n    }\n  }\n  rejectedAt\n  rejectedBy {\n    id\n    email\n    givenName\n    familyName\n    active {\n      start\n      finish\n    }\n  }\n  closedAt\n  closedBy {\n    id\n    email\n    givenName\n    familyName\n    active {\n      start\n      finish\n    }\n  }\n}\n"
  }
};
})();

node.hash = "9984912454319428eff07d6cdcb4ac5f";

module.exports = node;
