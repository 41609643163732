/**
 * @generated SignedSource<<60ba364868e25ed4c372088e84de9bbd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 500
},
v2 = {
  "kind": "Literal",
  "name": "state",
  "value": "TRADE_RULE_STATE_ACCEPTED"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "communityId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "residualId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TradePoint",
    "kind": "LinkedField",
    "name": "tradePoint",
    "plural": false,
    "selections": [
      (v0/*: any*/)
    ],
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "hours",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "minutes",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "seconds",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TradeRuleEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TradeRule",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "priority",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tradeType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Trader",
            "kind": "LinkedField",
            "name": "buyer",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Trader",
            "kind": "LinkedField",
            "name": "seller",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ClauseConnection",
            "kind": "LinkedField",
            "name": "clauses",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClauseEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Clause",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "price",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ignorePublicHolidays",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "monthsOfYear",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "daysOfWeek",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TimeSpan",
                        "kind": "LinkedField",
                        "name": "timesOfDay",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TimeOfDayType",
                            "kind": "LinkedField",
                            "name": "start",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TimeOfDayType",
                            "kind": "LinkedField",
                            "name": "finish",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "now"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TradeRuleSetCommunityTimeOfUseForm_meter",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tradePointId",
      "storageKey": null
    },
    {
      "alias": "communityRules",
      "args": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "Literal",
          "name": "type",
          "value": "TRADE_TYPE_COMMUNITY"
        }
      ],
      "concreteType": "TradeRuleConnection",
      "kind": "LinkedField",
      "name": "rules",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": "rules(first:500,state:\"TRADE_RULE_STATE_ACCEPTED\",type:\"TRADE_TYPE_COMMUNITY\")"
    },
    {
      "alias": "residualRules",
      "args": [
        {
          "kind": "Variable",
          "name": "finish",
          "variableName": "now"
        },
        (v1/*: any*/),
        {
          "kind": "Variable",
          "name": "start",
          "variableName": "now"
        },
        (v2/*: any*/),
        {
          "kind": "Literal",
          "name": "type",
          "value": "TRADE_TYPE_RESIDUAL"
        }
      ],
      "concreteType": "TradeRuleConnection",
      "kind": "LinkedField",
      "name": "rules",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Meter",
  "abstractKey": null
};
})();

node.hash = "15ab42e1cacaab0ee79a711d8fc26ab0";

module.exports = node;
