/**
 * @generated SignedSource<<447ebb2ca5314a9036d5fcf6a88373fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AdminRoot_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminLayout_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_AdminRoot_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewerUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "givenName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "familyName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9fac44b8df3e3f3517d444cdbf3dde27",
    "id": null,
    "metadata": {},
    "name": "routes_AdminRoot_Query",
    "operationKind": "query",
    "text": "query routes_AdminRoot_Query {\n  viewer {\n    ...AdminLayout_viewer\n    id\n  }\n}\n\nfragment AdminLayout_viewer on Viewer {\n  id\n  viewerUser {\n    id\n    type\n  }\n  ...AdminNavigation_viewer\n}\n\nfragment AdminNavigation_viewer on Viewer {\n  id\n  viewerUser {\n    email\n    givenName\n    familyName\n    id\n  }\n}\n"
  }
};
})();

node.hash = "f3578d6ef6bbbeb910980463af88069e";

module.exports = node;
