import isLength from 'validator/es/lib/isLength';
import matches from 'validator/es/lib/matches';

const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/;

const isPassword = (password) => (
  isLength(password, { min: 8 }) && matches(password, regex)
);

export default isPassword;
