import ChartControls from './components/ChartControls';
import ChartMeter from './components/ChartMeter';
import ChartSummary from './components/ChartSummary';
import ChartTrade from './components/ChartTrade';

const Chart = {
  ChartControls,
  ChartMeter,
  ChartSummary,
  ChartTrade,
};

export default Chart;
export { default as ChartControls } from './components/ChartControls';
export { default as ChartMeter } from './components/ChartMeter';
export { default as ChartSummary } from './components/ChartSummary';
export { default as ChartTrade } from './components/ChartTrade';
