import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';

import { APIConfig } from 'src/config';
import { getTradePointInlineLabel } from 'src/helpers/tradeHelpers';
import {
  PLATFORM_MODE_REBATE, TRADE_RULE_STATE_PROPOSED,
} from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import { timeOfUseConditions, timeOfUseDetails, timeOfUseIsSimple } from 'src/util/timeOfUse';
import { tradeRulePriceRange } from 'src/util/tradeRule';

import TradeRuleTimelineStep from './TradeRuleTimelineStep';
import { getUserName, identifyTradeDirection } from '../TradeRuleHelpers';
import {
  BUYING, CPKWH, DISCOUNT, PRICE, FROM, TO,
} from '../tradeRuleConstants';

/**
 * Description
 * @param {any} props
 * @returns {any} - TradeRuleTimelineStepProposed component
 */
function TradeRuleTimelineStepProposed(props) {
  const clauseConditionDetails = (clauseNodes) => {
    if (clauseNodes.length <= 1) {
      return { element: null, display: false };
    }

    const clauseConditionsAll = clauseNodes.map((el) => {
      const { ignoreDaylightSavings, ignorePublicHolidays, timezone } = el;
      return { ignoreDaylightSavings, ignorePublicHolidays, timezone };
    });

    const ccs = [
      ...new Set(clauseConditionsAll.map((el) => (JSON.stringify(el)))),
    ].map((el) => JSON.parse(el));

    if (ccs.length !== 1) {
      return { element: null, display: false };
    }

    return { element: <>{timeOfUseConditions(ccs[0])}</>, display: true };
  };

  const clauseRender = (clause, showConditions) => {
    const { price } = clause;

    if (timeOfUseIsSimple(clause)) {
      return (
        <>
          {convertEnergyPrice(price).toFixed(3)}
          {' '}
          <abbr title={CPKWH}>c/kWh</abbr>
        </>
      );
    }

    return (
      <li key={clause.id}>
        <strong>{convertEnergyPrice(price).toFixed(3)}</strong>
        {' '}
        <abbr title={CPKWH}>c/kWh</abbr>
        {' '}
        {timeOfUseDetails(clause)}
        {showConditions && timeOfUseConditions(clause)}
      </li>
    );
  };

  const {
    metersMap, ruleId, tradeType, clauses, buyer, seller, timestamp, user,
    isCurrentStep, hasNextStep,
  } = props;

  const {
    direction, trader, counterParty,
  } = identifyTradeDirection(buyer, seller, metersMap) || {};

  let priceLabel = PRICE;

  if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
    priceLabel = DISCOUNT;
  }

  const clauseNodes = (!clauses || !clauses.edges)
    ? [] : clauses.edges.map((el) => (el.node)).sort((a, b) => (b.price - a.price));
  const ccd = clauseConditionDetails(clauseNodes);

  const priceRange = tradeRulePriceRange({ clauses });

  const { user: counterPartyUser } = counterParty || {};

  const tradePointLabel = getTradePointInlineLabel(trader.tradePoint);
  const directionLabel = direction === BUYING ? FROM : TO;
  const userName = getUserName(counterPartyUser);

  return (
    <TradeRuleTimelineStep
      ruleId={ruleId}
      tradeType={tradeType}
      state={TRADE_RULE_STATE_PROPOSED}
      timestamp={timestamp}
      user={user}
      icon={<FontAwesomeIcon icon={faPlayCircle} />}
      isCurrentStep={isCurrentStep}
      hasNextStep={hasNextStep}
    >
      {tradePointLabel && (
        <li className="mb-3">
          {`${direction} for `}
          {tradePointLabel}
          <span>
            {userName && ` ${directionLabel} ${userName}`}
          </span>
        </li>
      )}
      <li className="mb-3">
        <strong>{direction}</strong>
        {` at a ${priceLabel} of `}
        <strong>
          {convertEnergyPrice(priceRange.minimum).toFixed(3)}
        </strong>
        {priceRange.maximum !== priceRange.minimum && (
          <>
            {' to '}
            <strong>
              {convertEnergyPrice(priceRange.maximum).toFixed(3)}
            </strong>
          </>
        )}
        {' '}
        <abbr title={CPKWH}>c/kWh</abbr>
        {!tradePointLabel && <span>{` ${directionLabel} ${userName}`}</span>}
      </li>
      {clauseNodes.length > 1 && (
        <li className="mb-3">
          <ul className="ps-3">
            {clauseNodes && clauseNodes.map((clause) => (clauseRender(clause, !ccd.display)))}
            {ccd.display && ccd.element}
          </ul>
        </li>
      )}
    </TradeRuleTimelineStep>
  );
}

TradeRuleTimelineStepProposed.propTypes = {
  metersMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  ruleId: PropTypes.string.isRequired,
  tradeType: PropTypes.string.isRequired,
  buyer: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  seller: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  clauses: PropTypes.shape({
    edges: PropTypes.arrayOf(PropTypes.shape({
      node: (PropTypes.shape({
        ignoreDaylightSavings: PropTypes.bool.isRequired,
        ignorePublicHolidays: PropTypes.bool.isRequired,
        price: PropTypes.number.isRequired,
        timezone: PropTypes.string.isRequired,
      })),
    })),
  }).isRequired,
  timestamp: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
  }).isRequired,
  isCurrentStep: PropTypes.bool.isRequired,
  hasNextStep: PropTypes.bool.isRequired,
};

TradeRuleTimelineStepProposed.defaultProps = {
};

export default TradeRuleTimelineStepProposed;
