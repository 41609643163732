import Link from 'found/Link';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createPaginationContainer, graphql } from 'react-relay';
import { Button } from 'reactstrap';

import BadgeActive from 'src/components/Badge/BadgeActive';
import UUID from 'src/components/UUID';

import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - AdminPrimaryBillingPointList component
 */
function AdminPrimaryBillingPointList(props) {
  const loadMore = () => {
    const { relay } = props;

    if (!relay.hasMore() || relay.isLoading()) {
      return;
    }

    relay.loadMore(
      50,
      (error) => { console.log(error); },
    );
  };

  if (!props) {
    return <div>Loading...</div>;
  }

  const { relay, viewer } = props;
  const { primaryBillingPoints } = viewer;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Enosi Admin - Primary Billing Points</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Primary Billing Points' },
        ]}
      />

      <h1>Primary Billing Points</h1>

      <div className="mt-4 mb-4 card">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col" colSpan={4} className="text-center">Primary Billing Point</th>
                <th scope="col" colSpan={3} className="text-center">Property</th>
              </tr>
              <tr>
                <th scope="col">Active</th>
                <th scope="col">Identifier</th>
                <th scope="col">ID</th>
                <th scope="col">
                  <abbr title="External identifier">Ext ID</abbr>
                </th>
                <th scope="col">Title</th>
                <th scope="col">
                  <abbr title="External identifier">Ext ID</abbr>
                </th>
                <th scope="col">Active</th>
              </tr>
            </thead>
            <tbody>
              {
                primaryBillingPoints
                && primaryBillingPoints.edges
                && primaryBillingPoints.edges.map((edge) => {
                  const { node } = edge;
                  const {
                    id, externalIdentifier, identifier, property, active,
                  } = node;
                  const { start, finish } = active;
                  // const {
                  //   id: propertyId, title: propertyTitle, address: propertyAddress,
                  //   active: propertyActive,
                  // } = property;
                  // const { start: propertyStart, finish: propertyFinish } = propertyActive;

                  return (
                    <tr key={`admin-primaryBillingPoint-${id}`}>
                      <td>
                        <BadgeActive start={start} finish={finish} />
                        <br />
                        {DateTime.fromSeconds(start).toISO()}
                        {' to '}
                        {finish ? DateTime.fromSeconds(finish).toISO() : 'the future'}
                      </td>
                      <th scope="row" colSpan={3}>
                        <Link to={`/admin/primary-billing-points/${id}`}>
                          {identifier}
                        </Link>
                        <br />
                        <UUID uuid={id} />
                        <br />
                        {externalIdentifier}
                      </th>
                      {property && (
                        <>
                          <td colSpan={2}>
                            <Link to={`/admin/properties/${property.id}`}>
                              {property.title}
                            </Link>
                            <br />
                            {property.externalIdentifier}
                            <br />
                            <UUID uuid={property.id} />
                          </td>
                          <td>
                            <BadgeActive
                              start={property.active.start}
                              finish={property.active.finish}
                            />
                            <br />
                            {DateTime.fromSeconds(property.active.start).toISO()}
                            {' to '}
                            {property.active.finish
                              ? DateTime.fromSeconds(property.active.finish).toISO()
                              : 'the future'}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        <div className="card-footer">
          <Button onClick={loadMore} disabled={!relay.hasMore()}>Load more...</Button>
        </div>
      </div>
    </>
  );
}

AdminPrimaryBillingPointList.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  viewer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminPrimaryBillingPointList.defaultProps = {
  viewer: null,
};

export default createPaginationContainer(
  AdminPrimaryBillingPointList,
  {
    viewer: graphql`
      fragment AdminPrimaryBillingPointList_viewer on Viewer {
        id
        viewerUser {
          email
          givenName
          familyName
        }
        primaryBillingPoints(
          first: $count
          after: $cursor
        ) @connection(key: "AdminPrimaryBillingPointList_primaryBillingPoints") {
          edges {
            cursor
            node {
              id
              externalIdentifier
              identifier
              active {
                start
                finish
              }
              property {
                id
                externalIdentifier
                title
                active {
                  start
                  finish
                }
              }
            }
          }
          count
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.viewer.primaryBillingPoints;
    },
    // This is also the default implementation of `getFragmentVariables` if it isn't provided.
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor,
      };
    },
    query: graphql`
    # Pagination query to be fetched upon calling 'loadMore'.
    # Notice that we re-use our fragment, and the shape of this query matches our fragment spec.
    query AdminPrimaryBillingPointList_Pagination_Query(
      $count: Int!
      $cursor: String
    ) {
      viewer {
        ...AdminPrimaryBillingPointList_viewer
      }
    }
  `,
  },
);
