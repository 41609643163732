import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { graphql, createFragmentContainer } from 'react-relay';

import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - AdminHome component
 */
function AdminHome(props) {
  if (!props) {
    return <div>Loading...</div>;
  }

  const { viewer } = props;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Enosi Admin - Home</title>
      </Helmet>

      <Breadcrumbs breadcrumbs={[{ name: 'Home' }]} />

      <>
        <h1>Admin Home</h1>
        <pre>{JSON.stringify(viewer, null, 2)}</pre>
      </>
    </>
  );
}

AdminHome.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  viewer: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminHome.defaultProps = {
  viewer: null,
};

export default createFragmentContainer(
  AdminHome,
  {
    viewer: graphql`
      fragment AdminHome_viewer on Viewer {
        id
        viewerUser {
          email
          givenName
          familyName
        }
      }
    `,
  },
);
