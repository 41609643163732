import { commitMutation, graphql } from 'react-relay';

import environment from 'src/util/environment';

const mutation = graphql`
  mutation InviteAdminUserMutation($input: InviteAdminUserInput!) {
    inviteAdminUser(input: $input) {
      id
      externalIdentifier
      type
      email
      givenName
      familyName
      active { start finish }
      createdAt
      updatedAt
    }
  }
`;

const InviteAdminUserMutation = (input, onCompleted, onError) => {
  const variables = { input };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted,
      onError,
    },
  );
};

export default InviteAdminUserMutation;
