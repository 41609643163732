import MapboxSDK from '@mapbox/mapbox-sdk';
import MapboxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import PropTypes from 'prop-types';
import React from 'react';

import { APIConfig } from 'src/config';

// PropertyListMap controls are hidden via global stylesheet on the mapbox component
class PropertyListMap extends React.Component {
  static addressString(address) {
    const {
      line1, line2, city, state, postcode, country,
    } = address;

    const addressString = [
      [line1, line2].join(' '),
      city,
      state,
      postcode,
      country,
    ].join(', ');

    return addressString;
  }

  constructor(props) {
    super(props);

    this.state = {
      render: false,
      gps: {
        latitude: null,
        longitude: null,
        zoom: null,
      },
    };
  }

  componentDidMount() {
    this.focus();
  }

  componentWillUnmount() {
    if (this.map) { this.map.remove(); }
  }

  focus = () => {
    const { address, zoom } = this.props;
    const { gps } = address;

    if (gps) {
      const { latitude, longitude } = gps;

      this.setState((prevState) => ({
        ...prevState,
        render: true,
        gps: {
          latitude,
          longitude,
          zoom,
        },
      }));

      return;
    }

    const query = PropertyListMap.addressString(address);

    const mbxClient = MapboxSDK({ accessToken: APIConfig().MAPBOX_TOKEN });
    const geocodingClient = MapboxGeocoding(mbxClient);

    geocodingClient.forwardGeocode({ query, limit: 1 })
      .send()
      .then((response) => {
        if (response && response.body && response.body.features && response.body.features.length) {
          const feature = response.body.features[0];

          this.setState((prevState) => ({
            ...prevState,
            render: true,
            gps: {
              latitude: feature.center[1],
              longitude: feature.center[0],
              zoom,
            },
          }));
        }
      });
  };

  image = () => {
    const { gps, render } = this.state;
    if (!render) { return null; }

    const { latitude, longitude, zoom } = gps;
    const { address, height, width } = this.props;

    const alt = PropertyListMap.addressString(address);

    const src = `https://api.mapbox.com/styles/v1/joel-enosi/cl79xxw5z000014qx5eyk1b6m/static/pin-l+3bb667(${longitude},${latitude})/${longitude},${latitude},${zoom},0/${width}x${height}?access_token=${APIConfig().MAPBOX_TOKEN}`;

    return (
      <img
        src={src}
        alt={`Map of ${alt}`}
        width={width}
        height={height}
        style={{ width: 'auto', maxWidth: '100%', objectFit: 'cover' }}
      />
    );
  };

  render() {
    return this.image();
  }
}

PropertyListMap.propTypes = {
  address: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string,
    gps: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }),
  }),
  zoom: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
};

PropertyListMap.defaultProps = {
  address: null,
  height: '200',
  width: '400',
  zoom: 12,
};

export default PropertyListMap;
