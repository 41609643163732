import Big from 'big.js';

const DOLLARS_PER_WATT_HOUR_TO_CENTS_PER_KILOWATT_HOUR = 100000;

/**
 * Convert energy prices from dollars per wh to cents per kwh
 * @param {number} price
 * @returns {number} - price in c/kwh
 */
const convertEnergyPrice = (price) => {
  if (!price || Number.isNaN(parseFloat(price))) {
    return 0;
  }
  return (Big(price).times(DOLLARS_PER_WATT_HOUR_TO_CENTS_PER_KILOWATT_HOUR));
};

export default convertEnergyPrice;
