import { Link } from 'found';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Badge, Row, Col,
  Card, CardBody, CardTitle, CardSubtitle, CardText,
} from 'reactstrap';

import { APIConfig } from 'src/config';
import {
  CONTRACTED, PLATFORM_MODE_REBATE, TRADE_TYPE_CONTRACTED, TRADE_RULE_STATE_ACCEPTED,
} from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import { tradeRulePriceRange } from 'src/util/tradeRule';
import username from 'src/util/decorators/username';
import { emptyTradeRulesClassName, getTradeRuleMeterLabel } from 'src/helpers/tradeHelpers';

class TradeRuleActiveContracted extends React.Component {
  static tradeRulesForProperty(property) {
    const { meters } = property;
    const rules = { sell: [], buy: [] };

    meters.edges.forEach((meterEdge) => {
      if (!!meterEdge && !!meterEdge.node && !!meterEdge.node.contractedRules) {
        meterEdge.node.contractedRules.edges.forEach((ruleEdge) => {
          const { node: ruleNode } = ruleEdge;
          if (
            ruleNode.state !== TRADE_RULE_STATE_ACCEPTED
            || ruleNode.tradeType !== TRADE_TYPE_CONTRACTED
          ) {
            return;
          }

          if (ruleNode.seller.tradePoint.id === meterEdge.node.tradePointId) {
            rules.sell.push({ ...ruleNode, meter: meterEdge.node });
          } else {
            rules.buy.push({ ...ruleNode, meter: meterEdge.node });
          }
        });
      }
    });

    return rules;
  }

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <div>Loading...</div>;
    }

    const { property } = this.props;
    const { id: propertyId } = property;
    const tradeRules = TradeRuleActiveContracted.tradeRulesForProperty(property);
    const noTradeRulesClass = emptyTradeRulesClassName(tradeRules, CONTRACTED);

    return (
      <Card className={`mt-4 mb-4 trade-rules-contracted${noTradeRulesClass}`}>
        <CardBody>
          <h2>Contracted Trades</h2>
          <p>
            You have agreed to buy your energy from or sell your energy to one
            or more suppliers directly first as a contracted trade.
          </p>

          <Row>
            <Col xs="12" sm="12" md="6">
              <h3>Selling</h3>
              <p>
                {'You have '}
                <strong>{tradeRules.sell ? tradeRules.sell.length : 0}</strong>
                {' contracted trade rules to sell energy.'}
              </p>
              {tradeRules.sell && tradeRules.sell.length > 0 && tradeRules.sell.map((rule) => {
                const {
                  acceptedAt, acceptedBy, id: ruleId, meter, proposedAt, proposedBy, seller,
                } = rule;
                const { title } = meter;
                const priceRange = tradeRulePriceRange(rule);
                const { maximum, minimum } = priceRange;
                let priceLabel;
                let tradePointTitle;
                if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
                  priceLabel = 'Discount of: ';
                  tradePointTitle = title;
                } else {
                  priceLabel = 'Sell price: ';
                  tradePointTitle = title;
                }

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${ruleId}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        {tradePointTitle}
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        Approved
                      </CardSubtitle>
                      <CardText>
                        Selling from
                        {' '}
                        {getTradeRuleMeterLabel(seller)}
                        Proposed by
                        {' '}
                        <strong>{username(proposedBy)}</strong>
                        {' '}
                        on
                        {' '}
                        {DateTime.fromSeconds(proposedAt).toFormat('dd LLL yyyy')}
                        <br />
                        {acceptedAt && (
                          <>
                            Accepted by
                            {' '}
                            <strong>{username(acceptedBy)}</strong>
                            {' '}
                            on
                            {' '}
                            {DateTime.fromSeconds(acceptedAt).toFormat('dd LLL yyyy')}
                          </>
                        )}
                        <br />
                        {priceLabel}
                        <strong>
                          {convertEnergyPrice(minimum).toFixed(3)}
                        </strong>
                        {maximum !== minimum && (
                          <>
                            {' '}
                            to
                            {' '}
                            <strong>
                              {convertEnergyPrice(maximum).toFixed(3)}
                            </strong>
                          </>
                        )}
                        {' '}
                        <abbr title="cents per kilowatt hour">c/kWh</abbr>
                        {' '}
                        (
                        <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}>further details</Link>
                        )
                        <br />
                        <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}>
                          <Badge color="mid">{ruleId}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                  </Card>
                );
              })}
            </Col>

            <Col xs="12" sm="12" md="6">
              <h3>Buying</h3>
              <p>
                {'You have '}
                <strong>{tradeRules.buy.length}</strong>
                {' contracted trade rules to buy energy.'}
              </p>

              {tradeRules.buy && tradeRules.buy.length > 0 && tradeRules.buy.map((rule) => {
                const {
                  acceptedAt, acceptedBy, buyer, id: ruleId, meter, proposedAt, proposedBy,
                } = rule;
                const { title } = meter;
                const priceRange = tradeRulePriceRange(rule);
                const { maximum, minimum } = priceRange;
                let priceLabel;
                let tradePointTitle;
                if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
                  priceLabel = 'Discount of: ';
                  tradePointTitle = title;
                } else {
                  priceLabel = 'Buy price: ';
                  tradePointTitle = title;
                }

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${ruleId}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        {tradePointTitle}
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        Approved
                      </CardSubtitle>
                      <CardText>
                        Buying for
                        {' '}
                        {getTradeRuleMeterLabel(buyer)}
                        Proposed by
                        {' '}
                        <strong>{username(proposedBy)}</strong>
                        {' '}
                        on
                        {' '}
                        {DateTime.fromSeconds(proposedAt).toFormat('dd LLL yyyy')}
                        <br />
                        {acceptedAt && (
                          <>
                            Accepted by
                            {' '}
                            <strong>{username(acceptedBy)}</strong>
                            {' '}
                            on
                            {' '}
                            {DateTime.fromSeconds(acceptedAt).toFormat('dd LLL yyyy')}
                          </>
                        )}
                        <br />
                        {priceLabel}
                        <strong>
                          {convertEnergyPrice(minimum)}
                        </strong>
                        {maximum !== minimum && (
                          <>
                            {' '}
                            to
                            {' '}
                            <strong>
                              {convertEnergyPrice(maximum).toFixed(3)}
                            </strong>
                          </>
                        )}
                        {' '}
                        <abbr title="cents per kilowatt hour">c/kWh</abbr>
                        {' '}
                        (
                        <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}>further details</Link>
                        )
                        <br />
                        <Link
                          to={`/properties/${propertyId}/trade-rules/${ruleId}`}
                        >
                          <Badge color="mid">{ruleId}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                  </Card>
                );
              })}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

TradeRuleActiveContracted.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TradeRuleActiveContracted.defaultProps = {
  property: null,
};

export default createFragmentContainer(
  TradeRuleActiveContracted,
  {
    property: graphql`
      fragment TradeRuleActiveContracted_property on Property {
        id
        meters {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              contractedRules: rules(first: 500, type: TRADE_TYPE_CONTRACTED, state: TRADE_RULE_STATE_ACCEPTED) {
                edges {
                  node {
                    id
                    priority
                    tradeType
                    state
                    buyer {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        id
                        meter {
                          id
                          identifier
                          title
                          property {
                            id
                            title
                          }
                        }
                      }
                    }
                    seller {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        id
                        meter {
                          id
                          identifier
                          title
                          property {
                            id
                            title
                          }
                        }
                      }
                    }
                    clauses {
                      edges {
                        node {
                          price
                        }
                      }
                    }
                    start
                    finish
                    proposedAt
                    proposedBy {
                      id
                      email
                      givenName
                      familyName
                    }
                    acceptedAt
                    acceptedBy {
                      id
                      email
                      givenName
                      familyName
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
);
