import React from 'react';

/**
 * Description
 * @returns {React.ReactComponentElement} - Loading component
 */
function Loading() {
  return (
    <div
      className="container center text-center"
      style={{
        marginTop: 40,
        paddingTop: 30,
      }}
    >
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default Loading;
