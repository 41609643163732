import { commitMutation, graphql } from 'react-relay';
import environment from 'src/util/environment';

const mutation = graphql`
  mutation AcceptNominatedTradeMutation($id: ID!, $tradePointId: String!) {
    acceptNominatedTrade(id: $id, tradePointId: $tradePointId) {
      id
    }
  }
`;

const AcceptNominatedTradeMutation = (id, tradePointId, onCompleted, onError) => {
  const variables = { id, tradePointId };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted,
      onError,
      updater: (store) => {
        // Get the current user record from the store
        const ruleProxy = store.get(id);
        ruleProxy.setValue('TRADE_RULE_STATE_ACCEPTED', 'state');
      },
    },
  );
};

export default AcceptNominatedTradeMutation;
