import PropTypes from 'prop-types';
import React from 'react';
import PropertyShowChart from './PropertyShowChart';
import PropertyShowTradeSummary from './PropertyShowTradeSummary';

class PropertyShowTradeEnergy extends React.Component {
  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <div>Loading...</div>;
    }

    const {
      property, timespan, historianAggregation, tradeAggregation,
      handleTimespanUpdate, source, controlSetStateFunc, unit, chartView,
    } = this.props;

    return (
      <>
        <PropertyShowChart
          historianAggregation={historianAggregation}
          property={property}
          timespan={timespan}
          tradeAggregation={tradeAggregation}
          handleTimespanUpdate={handleTimespanUpdate}
          unit={unit}
          source={source}
          controlSetStateFunc={controlSetStateFunc}
          chartView={chartView}
        />
        <PropertyShowTradeSummary property={property} timespan={timespan} />
      </>
    );
  }
}

PropertyShowTradeEnergy.propTypes = {
  historianAggregation: PropTypes.string.isRequired,
  property: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  timespan: PropTypes.shape({
    start: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    finish: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  tradeAggregation: PropTypes.string.isRequired,
  handleTimespanUpdate: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  controlSetStateFunc: PropTypes.func.isRequired,
  chartView: PropTypes.string.isRequired,
};

export default PropertyShowTradeEnergy;
