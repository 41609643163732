/* eslint no-underscore-dangle: ["error", { "allow": ["_origSend", "_headers", "_useXHR"] }] */
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { propagation } from '@opentelemetry/api';
import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { MultiSpanProcessor } from '@opentelemetry/sdk-trace-base/build/src/MultiSpanProcessor';
import {
  BatchSpanProcessor,
} from '@opentelemetry/sdk-trace-base/build/src/platform/node/export/BatchSpanProcessor';
import { APIConfig } from 'src/config';
import { tracingState } from 'src/tracing/state';
import { Auth } from 'aws-amplify';

// TODO: reinstate when bug fix for BatchSpanProcessor is released
// export class CustomBatchSpanProcessor extends BatchSpanProcessor {
//   onStart (span) {
//     // Pass any baggage along as attributes
//     const baggage = propagation.getBaggage(parentContext)
//     if (baggage === undefined) return
//     span.setAttributes(baggage.getAllEntries().reduce((agg, entry) => {
//       agg[entry[0]] = entry[1].value
//       return agg
//     }, {}))
//   }
// }

// Temporarily use the multi-span until the BatchSpanProcessor bug fix is released
class CustomMultiSpanProcessor extends MultiSpanProcessor {
  // eslint-disable-next-line class-methods-use-this
  onStart(span, parentContext) {
    // Pass any baggage along as attributes
    const baggage = propagation.getBaggage(parentContext);
    if (baggage === undefined) return;
    span.setAttributes(baggage.getAllEntries().reduce((agg, entry) => {
      // eslint-disable-next-line no-param-reassign
      agg[entry[0]] = entry[1].value;
      return agg;
    }, {}));
  }
}

// eslint-disable-next-line func-names
const tracingProvider = (function () {
  let deregisterInstrumentationFn = null;

  /**
   * Helper to construct an authorization header
   * @param {any} token
   * @returns {object} - authorization header
   */
  function createAuthHeader(token) {
    return { Authorization: `Bearer ${token}` };
  }

  /**
   * Helper to create a provider with the specified auth token
   * @param {any} bearerToken
   * @returns {void}
   */
  function createProvider(bearerToken) {
    // Clean up any existing provider instrumentation
    if (deregisterInstrumentationFn != null) {
      deregisterInstrumentationFn();
      deregisterInstrumentationFn = null;
    }

    let oltpTracesURL;
    let devEnv;
    try {
      oltpTracesURL = APIConfig().OLTP_TRACES_URL;
      devEnv = APIConfig().DEPLOYMENT_ENV;
    } catch (e) {
      if (process.env.NODE_ENV !== 'test') {
        console.log('No APIConfig! Defaulting to local OLTP URL. You probably don\'t want this!');
      }
      oltpTracesURL = 'http://0.0.0.0:4318/v1/traces';
      devEnv = 'corporate-sandpit';
    }

    const exporter = new OTLPTraceExporter({
      url: oltpTracesURL,
      headers: { ...createAuthHeader(bearerToken) },
    });

    // HACK! wrap the original send with valid auth, do not send otherwise
    // NOTE: we are using 'protected' members _headers & _useXHR
    //       THIS MAY BREAK!!
    //       But it is the only way to do anything dynamic at present
    exporter._origSend = exporter.send;
    // eslint-disable-next-line func-names
    exporter.send = function (items, onSuccess, onError) {
      // Attempt to ensure we *always* have a valid auth token before sending spans
      new Promise((resolve, reject) => {
        Auth.currentSession()
          .then((sess) => {
            if (sess) {
              const token = sess.getAccessToken().getJwtToken();
              if (token) {
                exporter._headers = Object.assign(exporter._headers, createAuthHeader(token));
                exporter._useXHR = true;
              }
            }
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      })
        .then(() => {
          exporter._origSend(items, onSuccess, onError);
        })
        .catch(() => {
          console.log('No auth available to send traces');
        });
    };

    let tracerProvider;
    if (process.env.NODE_ENV !== 'test') {
      tracerProvider = new WebTracerProvider({
        resource: new Resource({
          [SemanticResourceAttributes.SERVICE_NAME]: 'frontend',
          [SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT]: devEnv,
        }),
      });

      tracerProvider.addSpanProcessor(
        new CustomMultiSpanProcessor([
          new BatchSpanProcessor(exporter),
        ]),
      );

      // Importing this when testing results in conflict between it, react-testing and react, sigh
      // eslint-disable-next-line global-require
      const { ZoneContextManager } = require('@opentelemetry/context-zone');
      tracerProvider.register({
        // Zone is required to keep async calls in the same trace
        contextManager: new ZoneContextManager(),
      });
    } else {
      // Need a valid tracer provider for testing (not NoopTracerProvider)
      // Also need to override the default no-op context manager, so we can inspect active spans
      tracerProvider = new WebTracerProvider();
      // eslint-disable-next-line global-require
      const { StackContextManager } = require('@opentelemetry/sdk-trace-web/build/esm/StackContextManager');
      tracerProvider.register({
        contextManager: new StackContextManager(),
      });
    }

    if (process.env.NODE_ENV !== 'test') {
      deregisterInstrumentationFn = registerInstrumentations({
        tracerProvider,
        instrumentations: [
          // NOTE: the DocumentLoadInstrumentation does not support any callback for adding
          //        custom attributes and is therefore not really worth tracking
          // new DocumentLoadInstrumentation(),
          new FetchInstrumentation({
            propagateTraceHeaderCorsUrls: [
              /.*enosi\.energy.+/g, // Regex matching backend urls
            ],
            applyCustomAttributesOnSpan: (span) => {
              tracingState.addToSpan(span)
                .catch((e) => console.error('error adding to span', e));
            },
          }),
          new UserInteractionInstrumentation({
            shouldPreventSpanCreation: (eventType, element, span) => {
              tracingState.addToSpan(span)
                .catch((e) => console.error('error adding to span', e));
              return false;
            },
          }),
          new XMLHttpRequestInstrumentation({
            ignoreUrls: [
              /.+?sockjs-node/g, // Ignore the websocket (should be dev-only)
            ],
            propagateTraceHeaderCorsUrls: [
              /.*enosi\.energy.+/g, // Regex matching backend urls
            ],
            applyCustomAttributesOnSpan: (span) => {
              tracingState.addToSpan(span)
                .catch((e) => console.error('error adding to span', e));
            },
          }),
        ],
      });
    }

    return tracerProvider;
  }

  // Create a provider with a fake bearer token (to ensure it sets itself up correctly)
  const provider = createProvider('dummy');

  /**
   * Give access to the provider
   * @returns {object} - provider
   */
  function getProvider() {
    return provider;
  }

  return {
    getProvider,
  };
}());

export {
  // eslint-disable-next-line import/prefer-default-export
  tracingProvider,
};
