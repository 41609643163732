import React from 'react';
import { Link } from 'found';
import { DateTime } from 'luxon';
import FlashesStore from 'src/stores/FlashesStore';
import {
  Badge, Button, ButtonGroup, Card, CardBody, CardTitle, CardSubtitle, CardText, CardFooter,
  DropdownMenu, DropdownItem, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';
import convertEnergyPrice from 'src/util/conversions';
import { tradeRulePriceRange } from 'src/util/tradeRule';
import username from 'src/util/decorators/username';
import {
  TRADE_RULE_CANCEL, TRADE_RULE_REJECT, TRADE_RULE_ACCEPT,
  TRADE_RULE_STATE_PROPOSED,
} from 'src/util/constants';
import isActive from 'src/util/isActive';
import AcceptNominatedTradeMutation from 'src/mutations/AcceptNominatedTradeMutation';
import CancelNominatedTradeMutation from 'src/mutations/CancelNominatedTradeMutation';
import RejectNominatedTradeMutation from 'src/mutations/RejectNominatedTradeMutation';

/**
 * Error notification when a trade proposal action fails.
 * @param {string} error
 */
export const handleNominatedTradeMutationFailure = (error) => {
  FlashesStore.flash(FlashesStore.ERROR, error);
};

/**
 *  Success notification when a trade proposal is cancelled.
 * @param {object} response
 */
export const handleCancelNominatedTradeMutationSuccess = (response) => {
  const { id } = response.cancelNominatedTrade;
  FlashesStore.flash(
    FlashesStore.SUCCESS,
    `Cancelled peer-to-peer trade rule proposal with id: ${id}.`,
  );
};

/**
 * Success notification when a trade proposal is rejected.
 * @param {object} response
 */
export const handleRejectNominatedTradeMutationSuccess = (response) => {
  const { id } = response.rejectNominatedTrade;

  FlashesStore.flash(
    FlashesStore.SUCCESS,
    `Rejected peer-to-peer trade rule proposal with id: ${id}.`,
  );
};

/**
 * Success notification when a trade proposal is accepted.
 * @param {object} response
 */
export const handleAcceptNominatedTradeMutationSuccess = (response) => {
  const { id } = response.acceptNominatedTrade;

  FlashesStore.flash(
    FlashesStore.SUCCESS,
    `Accepted peer-to-peer trade rule proposal with id: ${id}.`,
  );
};

/**
 * Segregates trade proposals in to buy and sell with more context.
 * @param {string} id - logged user
 * @param {object} proposals
 * @returns {object} - trade rules (segregated).
 */
export const getTradeRules = (id, proposals) => {
  const rules = {
    buy: [], sell: [], counterPartySell: [], counterPartyBuy: [], count: 0,
  };

  const displayList = [TRADE_RULE_ACCEPT, TRADE_RULE_REJECT, TRADE_RULE_CANCEL];
  if (!id || !proposals || !proposals.edges.length === 0) { return rules; }

  proposals.edges.forEach((ruleEdge) => {
    const { node: ruleNode } = ruleEdge;
    if (ruleNode.state !== TRADE_RULE_STATE_PROPOSED) return;

    const actions = [];
    if (ruleNode.buyer.user.id === id) {
      if (ruleNode.buyer.tradePoint.id === '') {
        actions.push(TRADE_RULE_REJECT);
        actions.push(TRADE_RULE_ACCEPT);
      } else {
        actions.push(TRADE_RULE_CANCEL);
      }
    }
    if (ruleNode.seller.user.id === id) {
      if (ruleNode.seller.tradePoint.id === '') {
        actions.push(TRADE_RULE_REJECT);
        actions.push(TRADE_RULE_ACCEPT);
      } else {
        actions.push(TRADE_RULE_CANCEL);
      }
    }
    actions.sort((a, b) => displayList.indexOf(a) - displayList.indexOf(b));
    // If the seller is the proposer...

    if (ruleNode.seller.user.id === ruleNode.proposedBy.id) {
      if (id === ruleNode.seller.user.id && ruleNode.seller.tradePoint.id) {
        rules.sell.push({ ...ruleNode, actions });
      } else {
        rules.counterPartySell.push({ ...ruleNode, actions });
      }
    } else if (ruleNode.buyer.user.id === ruleNode.proposedBy.id) {
      if (id === ruleNode.buyer.user.id && ruleNode.buyer.tradePoint.id) {
        rules.buy.push({ ...ruleNode, actions });
      } else {
        rules.counterPartyBuy.push({ ...ruleNode, actions });
      }
    }
  });

  return rules;
};

/**
 * Trade rule action handler
 * @param {object} rule
 * @param {string} action
 * @param {object} opts
 */
export const tradeRuleAction = (rule, action, opts = {}) => {
  const { id } = rule;
  switch (action) {
    case TRADE_RULE_CANCEL:
      CancelNominatedTradeMutation(
        id,
        handleCancelNominatedTradeMutationSuccess,
        handleNominatedTradeMutationFailure,
      );
      break;
    case TRADE_RULE_REJECT:
      RejectNominatedTradeMutation(
        id,
        handleRejectNominatedTradeMutationSuccess,
        handleNominatedTradeMutationFailure,
      );
      break;
    case TRADE_RULE_ACCEPT:
      AcceptNominatedTradeMutation(
        id,
        opts.tradePointId,
        handleAcceptNominatedTradeMutationSuccess,
        handleNominatedTradeMutationFailure,
      );
      break;
    default:
      FlashesStore.flash(
        FlashesStore.ERROR,
        `Action ${action} is not possible`,
      );
  }
};

/**
 * Prepares the list of available action buttons for a trade proposal.
 * @param {object} rule
 * @param {object} viewer
 * @returns {React.ReactElement} - Action buttons for a trade proposal.
 */
export const tradeRuleActionButton = (rule, viewer) => {
  if (!rule || !viewer) {
    return null;
  }
  const {
    id, state, buyer, seller,
  } = rule;

  const counterPartyTradePointId = [buyer.tradePoint.id, seller.tradePoint.id].filter(Boolean)[0];

  if (state !== TRADE_RULE_STATE_PROPOSED) {
    return null;
  }

  const { meters: meterConn } = viewer;
  const meterNodes = (!meterConn || !meterConn.edges)
    ? [] : meterConn.edges.map((edge) => (edge.node));

  const options = meterNodes.reduce((acc, el) => {
    const {
      identifier, title, tradePointId, active,
    } = el;
    if (tradePointId === counterPartyTradePointId) { return acc; }
    if (!el.property || !el.property.id) { return acc; }
    acc[el.property.id] = acc[el.property.id] || { property: el.property, tradePoints: [] };
    acc[el.property.id].tradePoints.push({
      identifier, title, tradePointId, active,
    });
    return acc;
  }, {});

  const buttons = [];

  rule.actions.forEach((action) => {
    if (action === TRADE_RULE_ACCEPT) {
      buttons.push(
        <UncontrolledButtonDropdown key={`${id}-${action}`}>
          <DropdownToggle caret>
            Accept
          </DropdownToggle>
          <DropdownMenu>
            {Object.values(options).map((opts) => {
              const { property, tradePoints } = opts;
              return (
                <>
                  <DropdownItem header key={`${id}-${action}-${property.id}`}>
                    {property.title}
                  </DropdownItem>
                  {tradePoints.map((tp) => (
                    <DropdownItem
                      onClick={() => (
                        tradeRuleAction(rule, action, { tradePointId: tp.tradePointId })
                      )}
                      disabled={!isActive(tp.active)}
                      key={`${id}-${action}-${tp.tradePointId}`}
                    >
                      {`${tp.title} (${tp.identifier})`}
                    </DropdownItem>
                  ))}
                </>
              );
            })}
          </DropdownMenu>
        </UncontrolledButtonDropdown>,
      );
      return;
    }
    const title = action.toLowerCase().split('_').splice(-1)[0];
    buttons.push(
      <Button onClick={() => tradeRuleAction(rule, action)} key={`${id}-${action}`}>
        {title.charAt(0).toUpperCase() + title.slice(1)}
      </Button>,
    );
  });

  return (
    <ButtonGroup>
      {buttons}
    </ButtonGroup>
  );
};

/**
 * Prepares the list of buy proposal cards.
 * @param {Array<object>} tradeRules
 * @param {object} viewer
 * @returns {React.ReactElement} - - List of buy proposal cards.
 */
export const tradeRulesBuy = (tradeRules, viewer) => {
  if (!tradeRules) {
    return null;
  }

  return tradeRules.map((rule) => {
    const priceRange = tradeRulePriceRange(rule);

    return (
      <Card className="mt-4 mb-4" key={`trade-rules-${rule.id}`}>
        <CardBody>
          <CardTitle tag="h4">{rule.id}</CardTitle>
          <CardSubtitle tag="h5" className="mb-2">
            Proposed
          </CardSubtitle>
          <CardText>
            <strong>{username(rule.buyer.user)}</strong>
            {' has proposed to buy from '}
            <strong>{username(rule.seller.user)}</strong>
            <br />
            Proposed by
            {' '}
            <strong>{username(rule.proposedBy)}</strong>
            {' '}
            on
            {' '}
            {DateTime.fromSeconds(rule.proposedAt).toFormat(
              'dd LLL yyyy',
            )}
            <br />
            Buy price:
            {' '}
            <strong>
              {convertEnergyPrice(priceRange.minimum).toFixed(3)}
            </strong>
            {priceRange.maximum !== priceRange.minimum && (
              <>
                {' '}
                to
                {' '}
                <strong>
                  {convertEnergyPrice(priceRange.maximum).toFixed(3)}
                </strong>
              </>
            )}
            {' '}
            <abbr title="cents per kilowatt hour">c/kWh</abbr>
            <br />
            <Link
              to={`/trade-rules/${rule.id}`}
            >
              <Badge color="mid">{rule.id}</Badge>
            </Link>
          </CardText>
        </CardBody>
        <CardFooter>{tradeRuleActionButton(rule, viewer)}</CardFooter>
      </Card>
    );
  });
};

/**
 * Prepares the list of sell proposal cards.
 * @param {Array<object>} tradeRules
 * @param {object} viewer
 * @returns {React.ReactElement} - List of sell proposal cards.
 */
export const tradeRulesSell = (tradeRules, viewer) => {
  if (!tradeRules) {
    return null;
  }
  return tradeRules.map((rule) => {
    const priceRange = tradeRulePriceRange(rule);
    return (
      <Card className="mt-4 mb-4" key={`trade-rules-${rule.id}`}>
        <CardBody>
          <CardTitle tag="h4">{rule.id}</CardTitle>
          <CardSubtitle tag="h5" className="mb-2">
            Proposed
          </CardSubtitle>
          <CardText>
            <strong>{username(rule.seller.user)}</strong>
            {' has proposed to sell to '}
            <strong>{username(rule.buyer.user)}</strong>
            <br />
            Proposed by
            {' '}
            <strong>{username(rule.proposedBy)}</strong>
            {' '}
            on
            {' '}
            {DateTime.fromSeconds(rule.proposedAt).toFormat(
              'dd LLL yyyy',
            )}
            <br />
            Sell price:
            {' '}
            <strong>
              {convertEnergyPrice(priceRange.minimum).toFixed(3)}
            </strong>
            {priceRange.maximum !== priceRange.minimum && (
              <>
                {' '}
                to
                {' '}
                <strong>
                  {convertEnergyPrice(priceRange.maximum).toFixed(3)}
                </strong>
              </>
            )}
            {' '}
            <abbr title="cents per kilowatt hour">c/kWh</abbr>
            <br />
            <Link
              to={`/trade-rules/${rule.id}`}
            >
              <Badge color="mid">{rule.id}</Badge>
            </Link>
          </CardText>
        </CardBody>
        <CardFooter>{tradeRuleActionButton(rule, viewer)}</CardFooter>
      </Card>
    );
  });
};
