import { Amplify } from 'aws-amplify';
import queryMiddleware from 'farce/queryMiddleware';
import { Resolver } from 'found-relay';
import createFarceRouter from 'found/createFarceRouter';
import createRender from 'found/createRender';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Button } from 'reactstrap';

import environment from 'src/util/environment';
import browserProtocol from 'src/util/history';
import routes from 'src/routes';

import { AmplifyConfig } from 'src/config';
import ErrorBoundary from 'src/components/ErrorBoundary';
import HTTP404 from 'src/components/HTTP404';
import 'src/scss/index.scss';
import { intercomHanlder } from 'src/util/intercom';

const Router = createFarceRouter({
  historyProtocol: browserProtocol,
  historyMiddlewares: [queryMiddleware],
  routeConfig: routes,

  /* eslint-disable react/prop-types */
  render: createRender({
    renderError: ({ error }) => {
      console.log({ error });
      return error.status === 404 ? <HTTP404 /> : <div>{`Error: ${error}`}</div>;
    },
  }),
  /* eslint-enable react/prop-types */
});

Amplify.configure(AmplifyConfig());

const rootContainer = createRoot(document.getElementById('container'));

rootContainer.render(
  (
    <HelmetProvider>
      <ErrorBoundary
        fallback={(retry) => (
          <Button onClick={retry} type="button">
            Retry
          </Button>
        )}
      >
        <Router resolver={new Resolver(environment)} />
      </ErrorBoundary>
    </HelmetProvider>
  ),
);

intercomHanlder();
