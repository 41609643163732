import { Link } from 'found';
import { routerShape } from 'found/PropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import Address from 'src/components/Address';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';

import TradeRuleSetCommunityFlatForm from './TradeRuleSetCommunityFlatForm';

class TradeRuleSetCommunityFlat extends React.Component {
  renderPropertyControls = () => {
    const { property } = this.props;
    return (
      <ButtonToolbar>
        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.id}/trade-rules`} className="btn btn-darken">
            All trade rules
          </Link>
        </ButtonGroup>

        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.id}/settings`} className="btn btn-darken">
            Property Settings
          </Link>
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <div>Loading...</div>;
    }

    const { property, meter, router } = this.props;
    const { address, id, title } = property;

    const addr = new Address(address);

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Enosi - Property: ${title} - Set community trade rules for ${meter.title}`}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: 'Properties', path: '/properties' },
            { name: title, path: `/properties/${id}` },
            { name: 'Trade Rules', path: `/properties/${id}/trade-rules/active` },
            { name: `Set community trade rules for ${meter.title}` },
          ]}
        />
        <Heading
          alias={title}
          address={addr.simpleString()}
          context="Set community trade rules"
          controls={this.renderPropertyControls()}
        />
        <TradeRuleSetCommunityFlatForm property={property} meter={meter} router={router} />
      </>
    );
  }
}

TradeRuleSetCommunityFlat.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  meter: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: routerShape.isRequired,
};

TradeRuleSetCommunityFlat.defaultProps = {
  property: null,
  meter: null,
};

export default createFragmentContainer(
  TradeRuleSetCommunityFlat,
  {
    property: graphql`
      fragment TradeRuleSetCommunityFlat_property on Property {
        id
        title
        address {
          line1
          line2
          city
          state
          postcode
          gps {
            latitude
            longitude
          }
        }
        ...TradeRuleSetCommunityFlatForm_property
      }
    `,
    meter: graphql`
      fragment TradeRuleSetCommunityFlat_meter on Meter {
        id
        identifier
        title
        tradePointId
        ...TradeRuleSetCommunityFlatForm_meter
      }
    `,
  },
);
