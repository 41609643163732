// import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
// import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';
import TradeRuleTimeline from '../TradeRuleTimeline';
// import FlashesStore from 'src/stores/FlashesStore';

class TradeRuleShow extends React.Component {
  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { property, rule } = this.props;
    const { id, title } = property;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Enosi - Trade Rule ${rule.id} for Property ${title}`}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: 'Properties', path: '/properties' },
            { name: title, path: `/properties/${id}` },
            { name: 'Trade Rules', path: `/properties/${id}/trade-rules` },
            { name: rule.id },
          ]}
        />
        <Heading alias="Trade Rule" context="Show" />
        <TradeRuleTimeline property={property} rule={rule} />
      </>
    );
  }
}

TradeRuleShow.propTypes = {
  property: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  router: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  rule: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

TradeRuleShow.defaultProps = {};

export default createFragmentContainer(
  TradeRuleShow,
  {
    property: graphql`
      fragment TradeRuleShow_property on Property {
        id
        title
        ...TradeRuleTimeline_property
      }
    `,
    rule: graphql`
      fragment TradeRuleShow_rule on TradeRule {
        id
        ...TradeRuleTimeline_rule
      }
    `,
  },
);
