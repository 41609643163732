import { DateTime } from 'luxon';
import Link from 'found/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Col, Row, Table, Card, CardHeader, CardFooter, Button,
} from 'reactstrap';

import { APIConfig } from 'src/config';
import { PLATFORM_MODE_REBATE } from 'src/util/constants';
import BadgeActive from 'src/components/Badge/BadgeActive';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';

import AdminMeterShowTariff from './AdminMeterShowTariff';
import AdminMeterShowTradeRule from './AdminMeterShowTradeRule';

/**
 * Description
 * @param {object} props
 * @returns {React.ReactComponentElement} - AdminMeterShow component
 */
function AdminMeterShow(props) {
  if (!props) {
    return <Loading />;
  }

  const { meter } = props;
  const {
    id, externalIdentifier, createdAt, updatedAt, identifier, tradePointId,
    active, property, primaryBillingPoint, tariffs, rules,
  } = meter;
  const { start, finish } = active;
  const {
    id: propertyId, title: propertyTitle, active: propertyActive,
  } = property;
  const {
    id: primaryBillingPointId, identifier: primaryBillingPointIdentifier,
    active: primaryBillingPointActive,
  } = primaryBillingPoint;

  const priceLabel = (
    <>
      {APIConfig().MODE === PLATFORM_MODE_REBATE ? 'Discount' : 'Price'}
      {' '}
      (
      <abbr title="cents per kilowatt hour">c/kWh</abbr>
      )
    </>
  );

  const trades = (!rules || !rules.edges)
    ? [] : rules.edges.map((el) => (el.node));

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Enosi Admin - Meter - ${identifier}`}</title>
      </Helmet>

      <Breadcrumbs
        breadcrumbs={[
          { name: 'Admin', path: '/admin' },
          { name: 'Meters', path: '/admin/meters' },
          { name: `Meter: ${identifier}` },
        ]}
      />

      <h1>
        {identifier}
      </h1>

      <Row className="clear-both">
        <Col xs={12} sm={6} md={4}>
          <Card>
            <CardHeader>
              Meter details
            </CardHeader>
            <Table responsive>
              <tbody>
                <tr>
                  <th scope="row" className="text-right">Active status</th>
                  <td>
                    <BadgeActive start={start} finish={finish} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">ID</th>
                  <td>
                    <UUID uuid={id} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">
                    <abbr title="External identifier">Ext ID</abbr>
                  </th>
                  <td>{externalIdentifier}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Start</th>
                  <td>
                    {start && DateTime.fromSeconds(start).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Finish</th>
                  <td>
                    {finish && DateTime.fromSeconds(finish).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Identifier</th>
                  <td>{identifier}</td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Trade Point ID</th>
                  <td>
                    <UUID uuid={tradePointId} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Property</th>
                  <td>
                    <Link to={`/admin/properties/${propertyId}`}>
                      <strong>{propertyTitle}</strong>
                    </Link>
                    <br />
                    <UUID uuid={propertyId} />
                    <br />
                    <BadgeActive start={propertyActive.start} finish={propertyActive.finish} />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Primary Billing Point</th>
                  <td>
                    <Link to={`/admin/primary-billing-points/${primaryBillingPointId}`}>
                      <strong>{primaryBillingPointIdentifier}</strong>
                    </Link>
                    <br />
                    <UUID uuid={primaryBillingPointId} />
                    <br />
                    <BadgeActive
                      start={primaryBillingPointActive.start}
                      finish={primaryBillingPointActive.finish}
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Created at</th>
                  <td>
                    {createdAt
                      && DateTime.fromSeconds(createdAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-right">Updated at</th>
                  <td>
                    {updatedAt
                      && DateTime.fromSeconds(updatedAt).toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>

        <Col xs={12} sm={6} md={8}>
          <Card>
            <CardHeader>
              Tariffs
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Identifier</th>
                  <th scope="col">Active</th>
                  <th scope="col">Meter</th>
                </tr>
              </thead>
              <tbody>
                {tariffs && tariffs.edges.map((el) => (
                  <AdminMeterShowTariff tariff={el.node} />
                ))}
              </tbody>
            </Table>
          </Card>

          <Card className="mt-4">
            <CardHeader>
              Active Trade Rules
            </CardHeader>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">
                    <abbr title="identifier">ID</abbr>
                    {' '}
                    / Direction / Type / State
                  </th>
                  <th scope="col">Counterparty Trader</th>
                  <th scope="col">Counterparty Trade Point</th>
                  <th scope="col">{priceLabel}</th>
                  <th scope="col">Validity</th>
                </tr>
              </thead>
              <tbody>
                {trades
                  && trades.map((tradeRule) => (
                    <AdminMeterShowTradeRule
                      tradePointId={tradePointId}
                      rule={tradeRule}
                      propertyId={propertyId}
                      key={tradeRule.id}
                    />
                  ))}
              </tbody>
            </Table>
            <CardFooter>
              <Link to={`/admin/meters/${id}/trade-rules`}>
                <Button color="primary">
                  View all trade rules
                </Button>
              </Link>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
}

AdminMeterShow.propTypes = {
  relay: PropTypes.shape({
    hasMore: PropTypes.func,
    isLoading: PropTypes.func,
    loadMore: PropTypes.func,
  }).isRequired,
  meter: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

AdminMeterShow.defaultProps = {
  meter: null,
};

export default createFragmentContainer(
  AdminMeterShow,
  {
    meter: graphql`
      fragment AdminMeterShow_meter on Meter {
        id
        externalIdentifier
        createdAt
        updatedAt
        identifier
        tradePointId
        active { start finish }
        property {
          id
          title
          active { start finish }
        }
        primaryBillingPoint {
          id
          identifier
          active { start finish }
        }
        tariffs(first: 500) {
          edges {
            node {
              id
              identifier
              active { start finish }
              meter {id}
            }
          }
        }
        rules(
          first: 10
          state: TRADE_RULE_STATE_ACCEPTED
        ) {
          edges {
            cursor
            node {
              id
              ...AdminMeterShowTradeRule_rule
            }
          }
        }
      }
    `,
  },
);
