import {
  TRADE_TYPE_CONTRACTED, TRADE_TYPE_NOMINATED, TRADE_TYPE_COMMUNITY,
  TRADE_DIRECTION_BUY,
} from 'src/util/constants';
import Big from 'big.js';

/**
 * Normalisation of trade data from a meter node for trade summary card.
 */

const getDir = (direction) => (direction === TRADE_DIRECTION_BUY ? 'buy' : 'sell');

const getTradeType = (type) => {
  switch (type) {
    case TRADE_TYPE_CONTRACTED:
      return 'contracted';
    case TRADE_TYPE_NOMINATED:
      return 'nominated';
    case TRADE_TYPE_COMMUNITY:
      return 'community';
    default: // TRADE_TYPE_RESIDUAL
      return 'residual';
  }
};

const getRules = (nodes) => nodes.map(
  (node) => (node
    && node.rules
    && node.rules.edges ? node.rules.edges.map((el) => (el.node)) : null),
).flat().filter(Boolean);

/**
 * Normalises the trade data, from an array of graphql meter nodes.
 * @param {Array} nodes - The meter nodes.
 * @returns {object} an opinionated data structure for use in the trade summary on
 *                  the property show page.
 */
const normaliseTradeData = (nodes) => {
  const resp = {
    buy: {
      contracted: [],
      nominated: [],
      community: [],
      residual: [],
    },
    sell: {
      contracted: [],
      nominated: [],
      community: [],
      residual: [],
    },
  };

  const rules = getRules(nodes);

  nodes.forEach(({ tradeSetSummary }) => {
    if (tradeSetSummary === null) {
      return;
    }

    tradeSetSummary.forEach(({ key, data }) => {
      const {
        ruleId, direction, type,
      } = key;

      const rule = rules.filter((r) => r.id === ruleId)[0];
      const typeKey = getTradeType(type);
      const dirKey = getDir(direction);

      resp[dirKey][typeKey].push({ rule, value: 0, volume: 0 });
      const set = resp[dirKey][typeKey][resp[dirKey][typeKey].length - 1];

      data.forEach((tradeSetSummaryValue) => {
        const { value, volume } = tradeSetSummaryValue;

        set.value = Number(Big(set.value).plus(value));
        set.volume = Number(Big(set.volume).plus(volume));
      });
    });
  });

  return resp;
};

export default normaliseTradeData;
