import { DateTime } from 'luxon';
import Big from 'big.js';
import {
  DIRECTIONS, PLATFORM_MODE_REBATE, SOURCE_TRADES, TRADE_TYPE_RESIDUAL,
} from 'src/util/constants';
import { APIConfig } from 'src/config';
import { getTotalCarbonEmission } from 'src/lib/carbondata/helpers';
import sortCards from '../sortCards';
import { getPrimaryData } from './primaryDataBuilder';
import { buildTradeData } from './tradeDataBuilder';

/**
 * Extract and returns trade rules across meters.
 * @param {Array} meterNodes
 * @returns {Array} - trade rules.
 */
export const getAllTradeRules = (meterNodes) => meterNodes.map(
  (node) => (node
    && node.rules
    && node.rules.edges ? node.rules.edges.map((el) => (el.node)) : null),
).flat().filter(Boolean);

const allTradeTypes = ['contracted', 'nominated', 'community', 'residual'];

/**
 * Form data for the counterparty cards shown below the trade chart.
 * @param {object} tradeData
 * @param {object} meter - contains title and identifier of the meter.
 * @returns {object} -counterparty data.
 */
export const getCounterPartyCard = (tradeData, meter) => {
  const counterParties = {};
  DIRECTIONS.forEach((dir) => {
    allTradeTypes.forEach((type) => {
      Object.values(tradeData[dir][type]).forEach((series) => {
        const {
          key, label, subLabel, user, data, property,
        } = series;
        const { title = '', identifier = '' } = meter || {};
        if (!key) { return; }
        if (!(key in counterParties)) {
          counterParties[key] = {
            key,
            tradeType: type,
            label,
            subLabel,
            property,
            user,
            title,
            identifier,
            buy: { value: 0, volume: 0, count: 0 },
            sell: { value: 0, volume: 0, count: 0 },
          };
        }
        Object.values(data).forEach((datum) => {
          const { value, volume } = datum;
          counterParties[key][dir].count += 1;
          counterParties[key][dir].value = Number(Big(counterParties[key][dir].value).plus(value));
          counterParties[key][dir].volume = Number(Big(counterParties[key][dir].volume)
            .plus(volume));
        });
      });
    });
  });
  return counterParties;
};

/**
 * Form the data for trade cards ( shown below the trade chart).
 * @param {object} trades - trades data.
 * @param {boolean} isAggregated - view of the chart (aggregated and meter).
 * @param {object} sourceData - chart primary source data.
 * @returns {object} - trade cards data.
 */
export const getChartCardData = (trades, isAggregated, sourceData) => {
  if (isAggregated) {
    const counterParties = getCounterPartyCard(trades);
    return sortCards(Object.values(counterParties));
  }
  let counterParties = [];
  Object.keys(trades).forEach((meterId) => {
    const tradesByMeterId = trades[meterId];
    const { title, identifier } = sourceData[meterId];
    const meterData = { title, identifier, id: meterId };
    const chartCards = getCounterPartyCard(tradesByMeterId, meterData);
    counterParties = [...counterParties, ...Object.values(chartCards)];
  });
  return sortCards(Object.values(counterParties));
};

/**
 * Build data for meter cards - shown in meter view below the meter chart.
 * @param {object} meterData
 * @returns {object} - meter cards data.
 */
export const getChartMeterCardData = (meterData) => {
  if (!meterData) {
    return null;
  }
  const finalResp = [];
  Object.keys(meterData).forEach((meterId) => {
    const {
      buy, sell, title, identifier,
    } = meterData[meterId];
    const buyData = { count: 0, volume: 0 };
    const sellData = { count: 0, volume: 0 };
    Object.keys(buy).forEach((timestamp) => {
      const { meter } = buy[timestamp] || {};
      const { value } = meter || {};
      buyData.volume += value;
      buyData.count += 1;
    });

    Object.keys(sell).forEach((timestamp) => {
      const { meter } = sell[timestamp] || {};
      const { value } = meter || {};
      sellData.volume += value;
      sellData.count += 1;
    });
    const cardData = {
      key: meterId, buy: buyData, sell: sellData, title, identifier,
    };
    finalResp.push(cardData);
  });

  return finalResp;
};

/**
 * Conslidate all the trades.
 * @param {object} tradeData
 * @returns {object} - consolidated trades.
 */
export const consolidateTrades = (tradeData) => {
  const { buy: tradeBuy, sell: tradeSell } = tradeData || {};
  const resp = {
    buy: [
      ...Object.values(tradeBuy.contracted),
      ...Object.values(tradeBuy.nominated),
      ...Object.values(tradeBuy.community),
      ...Object.values(tradeBuy.residual),
    ],
    sell: [
      ...Object.values(tradeSell.contracted),
      ...Object.values(tradeSell.nominated),
      ...Object.values(tradeSell.community),
      ...Object.values(tradeSell.residual),
    ],
  };
  return resp;
};
/**
 * Build and returns trade data for the chart.
 * @param {object} tradeDataNormalised
 * @param {boolean} isAggregated - view of the chart (aggregated and meter).
 * @returns {object} - trade data for the chart.
 */
export const getChartTradeData = (tradeDataNormalised, isAggregated) => {
  if (!tradeDataNormalised || Object.keys(tradeDataNormalised).length === 0) {
    return {};
  }
  if (isAggregated) {
    return consolidateTrades(tradeDataNormalised);
  }
  const finalData = { buy: [], sell: [] };
  Object.keys(tradeDataNormalised).forEach((meterId) => {
    const tradeData = tradeDataNormalised[meterId] || {};
    const resp = consolidateTrades(tradeData);
    finalData.buy = [...finalData.buy, ...resp.buy];
    finalData.sell = [...finalData.sell, ...resp.sell];
  });
  return finalData;
};

const IS_MODE_REBATE = APIConfig().MODE === PLATFORM_MODE_REBATE;

export const getTradeSummary = (masterData) => {
  const finalResp = {
    buy: {},
    sell: {},
  };

  ['sell', 'buy'].forEach((dir) => {
    Object.keys(masterData[dir]).forEach((timestamp) => {
      const { meter, trades } = masterData[dir][timestamp];
      const { value: meterValue } = meter;
      const energy = Big(meterValue);
      let tradedEnergy = Big(0);
      let tradedValue = Big(0);
      let totalResidual = Big(0);
      let rebatedEnergy = Big(0);

      Object.keys(trades).forEach((tradeId) => {
        const { value, volume, types } = trades[tradeId];
        tradedEnergy = tradedEnergy.plus(volume);
        tradedValue = tradedValue.plus(value);

        if (IS_MODE_REBATE && types.includes(TRADE_TYPE_RESIDUAL)) {
          totalResidual = totalResidual.plus(volume);
        }
      });

      if (IS_MODE_REBATE) {
        rebatedEnergy = tradedEnergy.minus(totalResidual);
      }

      const untradedEnergy = energy.minus(tradedEnergy);

      finalResp[dir][timestamp] = {
        energy: Number(energy),
        tradedEnergy: Number(tradedEnergy),
        untradedEnergy: Number(untradedEnergy),
        tradedValue: Number(tradedValue),
        rebatedEnergy: Number(rebatedEnergy),
      };
    });
  });
  return finalResp;
};

export const getChartSummaryData = (masterData) => {
  const tradeSummary = getTradeSummary(masterData);
  const finalResp = { buy: {}, sell: {} };

  Object.keys(tradeSummary).forEach((dir) => {
    finalResp[dir].energy = Big(0);
    finalResp[dir].tradedEnergy = Big(0);
    finalResp[dir].untradedEnergy = Big(0);
    finalResp[dir].tradedValue = Big(0);
    finalResp[dir].rebatedEnergy = Big(0);

    Object.keys(tradeSummary[dir]).forEach((timestamp, index) => {
      const {
        energy, rebatedEnergy, tradedEnergy, untradedEnergy, tradedValue,
      } = tradeSummary[dir][timestamp];

      const finalIndex = index === Object.keys(tradeSummary[dir]).length - 1;
      const aggregatedEnergy = finalResp[dir].energy.plus(energy);
      const aggregatedTradedEnergy = finalResp[dir].tradedEnergy.plus(tradedEnergy);
      const aggregatedUntradedEnergy = finalResp[dir].untradedEnergy.plus(untradedEnergy);
      const aggregatedRebatedEnergy = finalResp[dir].rebatedEnergy.plus(rebatedEnergy);
      const aggregatedTradedValue = finalResp[dir].tradedValue.plus(tradedValue);

      finalResp[dir].energy = finalIndex ? Number(aggregatedEnergy) : aggregatedEnergy;
      finalResp[dir].tradedEnergy = finalIndex ? Number(aggregatedTradedEnergy)
        : aggregatedTradedEnergy;
      finalResp[dir].untradedEnergy = finalIndex ? Number(aggregatedUntradedEnergy)
        : aggregatedUntradedEnergy;
      finalResp[dir].rebatedEnergy = finalIndex ? Number(aggregatedRebatedEnergy)
        : aggregatedRebatedEnergy;
      finalResp[dir].tradedValue = finalIndex ? Number(aggregatedTradedValue)
        : aggregatedTradedValue;
    });
  });
  return finalResp;
};

/**
 * Aggregate data by timestamp.
 * @param {object} data - meter data.
 * @returns {object} - aggregated meter data.
 */
const aggregateByTimestamp = (data) => {
  const map = {};
  data.forEach((el) => {
    const ts = DateTime.fromSeconds(el.timestamp);
    // Invalid timestamp so return and process the next timestamp
    if (ts.invalidReason) {
      return;
    }
    map[el.timestamp] = map[el.timestamp] || { value: Big(0), flags: [] };
    map[el.timestamp].value = map[el.timestamp].value.plus(el.value);
    el.flags.forEach((flag) => {
      if (map[el.timestamp].flags.map((f) => f.identifier).indexOf(flag.identifier) === -1) {
        map[el.timestamp].flags.push(flag);
      }
    });
  });

  return Object.keys(map).sort().map((el) => {
    const ts = DateTime.fromSeconds(1 * el);
    const { flags, meterId, value } = map[el] || {};
    // Invalid timestamp so return and process the next timestamp
    if (ts.invalidReason) {
      return null;
    }
    return {
      timestamp: ts, value, flags, meterId,
    };
  });
};

/**
 * Append meter id to the meter data.
 * @param {object} data - meter data.
 * @param {string} meterId
 * @returns {object} - meter data ( meterId appended).
 */
const addMeterId = (data, meterId) => {
  const finalData = data.map((datum) => ({ ...datum, meterId }));
  return finalData;
};

/**
 * Aggregate all the meter data by timestamp across meters.
 * @param {Array} nodes - meter nodes.
 * @returns {object} - aggregated (by timestamp) meter data.
 */
export const aggregateMeterData = (nodes) => {
  const buyCombined = nodes.map(
    (node) => (node?.dataConsumed?.data && addMeterId(node.dataConsumed.data, node.id)),
  ).flat().filter(Boolean);
  const sellCombined = nodes.map(
    (node) => (node?.dataGenerated?.data && addMeterId(node.dataGenerated.data, node.id)),
  ).flat().filter(Boolean);

  return {
    buy: aggregateByTimestamp(buyCombined),
    sell: aggregateByTimestamp(sellCombined),
  };
};

/**
 * Build and returns the base meter data seggregated by meter id.
 * @param {Array} nodes - meter nodes.
 * @returns {object} - seggregated (by timestamp) meter data.
 */
export const segregateMeterData = (nodes) => {
  const seggregatedData = {};
  nodes.forEach((node) => {
    const {
      id, dataConsumed, dataGenerated, title, identifier,
    } = node || {};
    const { data: buy } = dataConsumed || {};
    const { data: sell } = dataGenerated || {};
    seggregatedData[id] = {
      buy, sell, title, identifier,
    };
  });

  return seggregatedData;
};

/**
 *
 * A sub function to build meter data - forms time based meter data.
 * @param {object} primaryData - chart primary data.
 * @returns {object} - meter data (timestamp based).
 */
const formTimeBasedMeterData = (primaryData) => {
  const finalResp = {};

  DIRECTIONS.forEach((dir) => {
    finalResp[dir] = {};
    if (primaryData[dir]) {
      Object.keys(primaryData[dir]).forEach((timestamp) => {
        const { meter } = primaryData[dir][timestamp];
        finalResp[dir][timestamp] = meter;
      });
    }
  });

  return finalResp;
};

/**
 * Build and returns the chart meter data.
 * @param {object} primaryData - chart primary data.
 * @param {boolean} isAggregated - whether the chart view is aggregated or not.
 * @returns {object} - chart meter data.
 */
export const getChartMeterData = (primaryData, isAggregated) => {
  let finalResp = {};
  if (primaryData) {
    if (isAggregated) {
      finalResp = formTimeBasedMeterData(primaryData, isAggregated);
    } else {
      Object.keys(primaryData).forEach((meterId) => {
        let meterResp = {};
        const { title, identifier } = primaryData[meterId] || {};
        meterResp = formTimeBasedMeterData(primaryData[meterId], isAggregated);
        meterResp = { ...meterResp, title, identifier };
        finalResp[meterId] = meterResp;
      });
    }
  }

  return finalResp;
};

/**
 * Returns the meter nodes of the property
 * @param {object} meters
 * @returns {Array<object>} - meter nodes.
 */
export const getMeterNodes = (meters) => meters?.edges?.map((edge) => edge.node);

/**
 * All the data processing functions for the component propertyshowchart are handled here.
 * @param {object} meters
 * @param {string} tradeAggregation - interval of the chart (day or half an hr interval)
 * @param {any} propertyRegion - ISO 3166 country or country-region code. For example: "AU-VIC" (ref: <https://en.wikipedia.org/wiki/ISO_3166>.
 * @param {any} isAggregated - whether the chart view is aggregated or not
 * @param {string} source
 * @returns {object} - data source for the property chart.
 */
export const chartDataHandler = (
  meters,
  tradeAggregation,
  propertyRegion,
  isAggregated,
  source,
) => {
  const meterNodes = getMeterNodes(meters);
  const multipleMeter = meters?.edges?.length > 1;
  const aggregatedMeterData = aggregateMeterData(meterNodes);

  // primary data has the trade, meter and carbon data grouped by timestamp
  const primaryData = getPrimaryData(
    aggregatedMeterData,
    tradeAggregation,
    meterNodes,
    propertyRegion,
    true,
  );

  const allRules = getAllTradeRules(meterNodes);
  const chartSummaryData = getChartSummaryData(primaryData);
  const totalCarbonEmission = getTotalCarbonEmission(primaryData.buy);

  let tradeCardsData = [];
  let meterCardsData = [];
  let chartTradeData = {};
  let chartMeterData = {};

  if (isAggregated) {
    if (source === SOURCE_TRADES) {
      const tradeData = buildTradeData(primaryData, allRules, tradeAggregation, isAggregated);
      chartTradeData = getChartTradeData(tradeData, isAggregated);
      tradeCardsData = getChartCardData(tradeData, isAggregated);
    } else {
      chartMeterData = getChartMeterData(primaryData, true);
    }
  } else {
    const segregatedMeterData = segregateMeterData(meterNodes);
    const primaryDataSegregated = getPrimaryData(
      segregatedMeterData,
      tradeAggregation,
      meterNodes,
      propertyRegion,
      false,
    );
    if (source === SOURCE_TRADES) {
      const tradeData = buildTradeData(
        primaryDataSegregated,
        allRules,
        tradeAggregation,
        false,
      );
      chartTradeData = getChartTradeData(tradeData, isAggregated);
      tradeCardsData = getChartCardData(
        tradeData,
        isAggregated,
        primaryDataSegregated,
      );
    } else {
      chartMeterData = getChartMeterData(primaryDataSegregated, false);
      meterCardsData = getChartMeterCardData(primaryDataSegregated);
    }
  }

  const finalResp = {
    chartMeterData,
    chartTradeData,
    tradeCardsData,
    meterCardsData,
    chartSummaryData,
    totalCarbonEmission,
    multipleMeter,
  };
  return finalResp;
};
