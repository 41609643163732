import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { Nav, NavItem, NavLink } from 'reactstrap';

import { config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook, faInstagram, faLinkedin, faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import { APIConfig } from 'src/config';

config.autoAddCss = true;

const SocialFontAwesomeIcon = styled(FontAwesomeIcon)`
  fontSize: 1.5rem;
  lineHeight: 1rem;
  marginTop: -.25rem;
`;
/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - Footer component
 */
function Footer(props) {
  const { versionUI } = props;

  return (
    <footer>
      <div className="container">
        <hr style={{ marginTop: '2em' }} />
        <Nav>
          <NavItem className="social">
            <NavLink href="https://facebook.com/enosi.energy">
              <SocialFontAwesomeIcon icon={faFacebook} />
            </NavLink>
          </NavItem>
          <NavItem className="social">
            <NavLink href="https://twitter.com/enosienergy">
              <SocialFontAwesomeIcon icon={faTwitter} />
            </NavLink>
          </NavItem>
          <NavItem className="social">
            <NavLink href="https://www.instagram.com/enosi.energy">
              <SocialFontAwesomeIcon icon={faInstagram} />
            </NavLink>
          </NavItem>
          <NavItem className="social">
            <NavLink href="https://www.linkedin.com/company/enosienergy">
              <SocialFontAwesomeIcon icon={faLinkedin} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://enosi.energy/">Enosi Australia Pty Ltd</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href={APIConfig().SUPPORT_URL}>Help desk</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://enosi.energy/legal/terms">Terms</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://enosi.energy/legal/privacy">Privacy</NavLink>
          </NavItem>
        </Nav>
        <p>
          Powertracer provides you with direct access to your friends and family&apos;s
          solar energy combined with large-scale renewable sources.
        </p>
        <p>
          {`UI version: ${versionUI}`}
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  versionUI: PropTypes.string,
};

Footer.defaultProps = {
  versionUI: 'v0.0.0-alpha',
};

export default Footer;
