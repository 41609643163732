import { DateTime } from 'luxon';
import {
  BUY, CSV_DATA_TIMESTAMP_FORMAT, DIRECTIONS, METER,
} from 'src/util/constants';
import { getFileName, getTimeRange, getUserName } from './propertyDataHelper';
import { getMeterNodes } from './propertyDataManager';

/**
 * Prepares the trade parties (buyer and seller) info for a given trade rule id.
 * @param {string} ruleId - trade rule id.
 * @param {Array<object>} rules - trade rules.
 * @returns {object} - trade parties data
 */
export const getTradePartiesData = (ruleId, rules) => {
  if (!ruleId || !rules) {
    return null;
  }
  let finalData = null;
  rules.forEach((rule) => {
    const { id } = rule?.node || {};
    if (id && ruleId === id) {
      const { buyer, seller } = rule.node;
      const { tradePoint: buyerTradePoint, user: userBuyer } = buyer || {};
      const { meter: buyerMeter } = buyerTradePoint || {};
      const { tradePoint: sellerTradePoint, user: userSeller } = seller || {};
      const { meter: sellerMeter } = sellerTradePoint || {};
      finalData = {
        buyer: { buyerMeter, buyerName: getUserName(userBuyer) },
        seller: { sellerMeter, sellerName: getUserName(userSeller) },
      };
    }
  });
  return finalData;
};

/**
 * Prepares the meter data for download
 * @param {object} node - meter node
 * @param {string} fileName - download file name
 * @returns {object} - meter data
 */
export const getMeterDataForDownload = (node, fileName) => {
  const finalData = [];
  let name = fileName;
  const {
    dataConsumed, dataGenerated, identifier, title,
    primaryBillingPoint: billingPoint, property,
  } = node;
  const { identifier: billingPointIdentifier } = billingPoint;
  const { title: propertyTitle } = property;
  DIRECTIONS.forEach((dir) => {
    const dataSource = dir === BUY ? dataConsumed : dataGenerated;
    const {
      data, metric, aggregation, timeRange,
    } = dataSource;

    if (!name) {
      name = getFileName(propertyTitle, timeRange, 'meter');
    }
    data?.forEach((datum) => {
      const { timestamp, flags, value } = datum;
      const { start, finish } = getTimeRange(timestamp, aggregation);
      const dataObj = {
        propertyTitle,
        billingPointIdentifier,
        title,
        identifier,
        metric: metric.identifier,
        start,
        finish,
        value,
        units: 'Wh',
        flags: flags[0].identifier,
      };
      finalData.push(dataObj);
    });
  });
  return { data: finalData, name };
};

/**
 * Prepares the trade data for download
 * @param {object} node - meter node
 * @param {string} fileName - download file name
 * @returns {object} - trade data
 */
export const getTradeDataForDownload = (node, fileName) => {
  const {
    dataConsumed, property, rules, tradeSetSummary: trades,
  } = node;
  const { timeRange } = dataConsumed;
  const finalData = [];
  const { title: propertyTitle } = property;
  let name = fileName;
  if (!name) {
    name = getFileName(propertyTitle, timeRange, 'trade');
  }
  trades.forEach((trade) => {
    const { data, key } = trade;
    const { ruleId, type } = key;
    const { buyer, seller } = getTradePartiesData(ruleId, rules?.edges);
    const { buyerMeter, buyerName } = buyer || {};
    const { sellerMeter, sellerName } = seller || {};
    const {
      identifier: buyerIdentifier, title: buyerTitle,
      property: buyerProperty,
    } = buyerMeter || {};

    const {
      identifier: sellerIdentifier, title: sellerTitle,
      property: sellerProperty,
    } = sellerMeter || {};

    data.forEach((datum) => {
      const {
        averagePrice: price, value, volume, range,
      } = datum;
      const { start, finish } = range;
      const dataObj = {
        ruleId,
        type,
        buyerName,
        buyerPropertyTitle: buyerProperty?.title || null,
        buyerTitle: buyerTitle || null,
        buyerIdentifier: buyerIdentifier || null,
        sellerName,
        sellerPropertyTitle: sellerProperty?.title || null,
        sellerTitle: sellerTitle || null,
        sellerIdentifier: sellerIdentifier || null,
        start: DateTime.fromSeconds(start).toFormat(CSV_DATA_TIMESTAMP_FORMAT),
        finish: DateTime.fromSeconds(finish).toFormat(CSV_DATA_TIMESTAMP_FORMAT),
        price,
        volume,
        value,
      };
      finalData.push(dataObj);
    });
  });
  return { data: finalData, name };
};

/**
 * Prepares the data object that gets feed in to the csv for download
 * Data schema for meter - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838743569/CSV+meter+data+download
 * Data schema for trade - https://enosi.atlassian.net/wiki/spaces/PT/pages/1838710828/CSV+trade+history+data+download
 * @param {object} rawData - meterdata of the property
 * @param {string} dataType - meter or trade
 * @returns {object} - data for the csv and file names
 */
export const processCSVData = (rawData, dataType) => {
  if (!rawData || !dataType) {
    return null;
  }
  let fileName = '';
  let finalData = [];
  const meterNodes = getMeterNodes(rawData);

  meterNodes.forEach((node) => {
    if (dataType === METER) {
      const { data, name } = getMeterDataForDownload(node, fileName);
      finalData = [...finalData, ...data];
      fileName = name;
    } else {
      const { data, name } = getTradeDataForDownload(node, fileName);
      finalData = [...finalData, ...data];
      fileName = name;
    }
  });
  return { data: finalData, fileName };
};

/**
 * Prepares CSV data for download
 * @param {Array<object>} data - raw meter or trade data
 * @param {Array<string>} headers - csv file headers
 * @returns {string} - csv data
 */
export const prepareCSVData = (data, headers) => {
  const csvRows = [];
  csvRows.push(headers.join(','));

  data.forEach((item) => {
    const values = Object.values(item).join(',');
    csvRows.push(values);
  });

  return csvRows.join('\n');
};
