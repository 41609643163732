import { Duration } from 'luxon';

import rangesFor from 'src/util/array';
import {
  DAY_PUBLIC_HOLIDAY, DayNames, MonthNames, AllTimesOfDay, AllDaysOfWeek, AllMonthsOfYear,
} from 'src/util/constants';

// Given a VolumeCharge or Clause, return an array of conditions
export const timeOfUseConditions = (object) => {
  const { ignoreDaylightSavings, ignorePublicHolidays, timezone } = object;
  const conditions = [];
  if (ignoreDaylightSavings) {
    conditions.push('ignoring daylight savings time');
  }
  if (ignorePublicHolidays) {
    conditions.push('public holidays treated as calendar days');
  }
  if (timezone) {
    conditions.push(`using explicit timezone '${timezone}'`);
  }
  return conditions;
};

// Given a VolumeCharge or Clause, return a text representation of the time of use
export const timeOfUseDetails = (object) => {
  let display = '';

  const { monthsOfYear, daysOfWeek, timesOfDay } = object;

  const monthsOfYearRanges = rangesFor(monthsOfYear, AllMonthsOfYear, true);
  const daysOfWeekRanges = rangesFor(daysOfWeek, AllDaysOfWeek.slice(0, 7), true);

  const fmtRange = (range, labels) => {
    const { start, finish } = range;

    const label = (key) => (labels[key] || '');

    if (start === finish) {
      return label(start);
    }
    return `${label(start)} to ${label(finish)}`;
  };

  if (AllMonthsOfYear.every((month) => monthsOfYear.includes(month))
    && AllDaysOfWeek.every((day) => daysOfWeek.includes(day))
    && JSON.stringify(timesOfDay) === JSON.stringify([AllTimesOfDay])) {
    display += 'At all times';
  } else {
    display = [
      ...monthsOfYearRanges.map((range) => fmtRange(range, MonthNames)),
      ...daysOfWeekRanges.map((range) => fmtRange(range, DayNames)),
    ].join(', ');

    if (daysOfWeek.includes(DAY_PUBLIC_HOLIDAY)) {
      display += `${daysOfWeek.length > 1 ? ' &' : ','} Public Holidays`;
    }

    const fmtNum = (number) => number.toString().padStart(2, '0');
    const times = timesOfDay.map(({ start, finish }) => (
      `${fmtNum(start.hours)}:${fmtNum(start.minutes)}-${fmtNum(finish.hours)}:${fmtNum(finish.minutes)}`
    ));
    display += `, between ${times.slice(0, times.length - 1).join(', ')}${times.length > 1 ? ' and ' : ''}${times[times.length - 1]}`;
  }

  return display;
};

// Given an object with monthsOfYear, daysOfWeek, timesOfDay, ignorePublicHolidays
// checks to see if it always applies
export const timeOfUseIsSimple = (object) => {
  const {
    monthsOfYear, daysOfWeek, timesOfDay, ignorePublicHolidays,
  } = object;

  const appliesAllMonths = JSON.stringify(AllMonthsOfYear) === JSON.stringify(monthsOfYear);
  const appliesAllDays = JSON.stringify(AllDaysOfWeek) === JSON.stringify(daysOfWeek)
    || (
      ignorePublicHolidays
      && JSON.stringify(AllDaysOfWeek) === JSON.stringify(daysOfWeek.concat(DAY_PUBLIC_HOLIDAY))
    );
  const appliesAllHours = JSON.stringify(timesOfDay) === JSON.stringify([AllTimesOfDay]);

  return appliesAllMonths && appliesAllDays && appliesAllHours;
};

// Convert a time of day object to a Duration
export const timeOfDayToDuration = (timeOfDay) => Duration.fromObject({
  hours: timeOfDay.hours,
  minutes: timeOfDay.minutes,
  seconds: timeOfDay.seconds,
  milliseconds: timeOfDay.nanos && (timeOfDay.nanos / 1000000),
});
