import PropTypes from 'prop-types';
import React from 'react';
import {
  Card, CardBody, CardHeader, CardFooter,
  Button, Form, FormGroup, Label,
} from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import Address from 'src/components/Address';
import FlashesStore from 'src/stores/FlashesStore';
import username from 'src/util/decorators/username';

class AdminPropertyUserAddForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      property: null,
      propertyValid: null,
      user: null,
      userValid: null,
    };
  }

  isValid = () => {
    const { property, user } = this.state;

    return (
      (property && property.id)
        || (user && user.id)
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const { handleAdd, processing } = this.props;

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, 'We are still processing your request...');
      return;
    }

    if (!this.isValid()) {
      FlashesStore.flash(FlashesStore.ERROR, 'Form data not valid. Please see below.');
      return;
    }

    const { property, user } = this.state;
    const { id: propertyId } = property;
    const { id: userId } = user;

    handleAdd(propertyId, userId);
  };

  handlePropertyChange = (selected) => {
    let property = null;
    let propertyValid = false;

    if (selected.length > 0) {
      [property] = selected;
      propertyValid = true;
    }
    this.setState({ property, propertyValid });
  };

  handleUserChange = (selected) => {
    let user = null;
    let userValid = false;

    if (selected.length > 0) {
      [user] = selected;
      userValid = true;
    }
    this.setState({ user, userValid });
  };

  propertyOptions = () => {
    const { properties } = this.props;
    return properties.map((el) => {
      const { id, title, address } = el;
      if (!address) {
        return { id, label: title };
      }

      const addr = new Address(address);
      const label = `${title} (${addr.string()})`;

      return { id, label };
    });
  };

  userOptions = () => {
    const { users } = this.props;
    return users.map((el) => ({ id: el.id, label: username(el) }));
  };

  render() {
    const { processing } = this.props;
    const {
      property, propertyValid, user, userValid,
    } = this.state;

    const propertyOptions = this.propertyOptions();
    const userOptions = this.userOptions();

    return (
      <Form onSubmit={this.handleSubmit} disabled={processing}>
        <Card>
          <CardHeader tag="h2">
            Add Property User
          </CardHeader>
          <CardBody>
            <FormGroup>
              <Label for="property">Property</Label>
              <Typeahead
                id="property"
                selected={property ? [property] : []}
                onChange={this.handlePropertyChange}
                disabled={processing}
                options={propertyOptions}
                isValid={propertyValid}
                isInvalid={propertyValid !== null && !propertyValid}
              />
            </FormGroup>
            <FormGroup>
              <Label for="user">User</Label>
              <Typeahead
                id="user"
                selected={user ? [user] : []}
                onChange={this.handleUserChange}
                disabled={processing}
                options={userOptions}
                isValid={userValid}
                isInvalid={userValid !== null && !userValid}
              />
            </FormGroup>
          </CardBody>
          <CardFooter>
            <Button disabled={processing}>Add</Button>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

AdminPropertyUserAddForm.propTypes = {
  handleAdd: PropTypes.func,
  processing: PropTypes.bool,
  properties: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    address: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      postcode: PropTypes.string,
    }),
  })),
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    email: PropTypes.string,
  })),
};

AdminPropertyUserAddForm.defaultProps = {
  handleAdd: null,
  processing: false,
  properties: [],
  users: [],
};

export default AdminPropertyUserAddForm;
