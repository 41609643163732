import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'reactstrap';

import { TradePointTypeLabels } from 'src/util/constants';

const colorMapping = {};

/**
 * Description
 * @param {any} {type}
 * @returns {React.ReactComponentElement} - BadgeTradePointType component
 */
function BadgeTradePointType({ type }) {
  if (!type) { return (null); }

  const color = colorMapping[type] || 'mid';
  const title = (TradePointTypeLabels[type] || '').toUpperCase();

  return (
    <Badge color={color} className="badge-outline">{title}</Badge>
  );
}

BadgeTradePointType.propTypes = {
  type: PropTypes.string.isRequired,
};

export default BadgeTradePointType;
