/**
 * Downloads the csv data (meter or trade) file.
 * @param {Array<object>} data - data that goes in to the file
 * @param {any} name - file name
 * @param {string} type - file typpe
 */
const downloadHandler = (data, name, type) => {
  const blob = new Blob([data], { type });

  const downloadURL = window.URL.createObjectURL(blob);

  const downloader = document.createElement('a');
  downloader.setAttribute('href', downloadURL);

  const fileName = name || 'Enosi Powertracer.csv';
  downloader.setAttribute('download', fileName);

  downloader.click();
};

export default downloadHandler;
