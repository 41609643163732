import React from 'react';
import PropTypes from 'prop-types';
import { format as d3Format } from 'd3-format';
import { Row, Col } from 'reactstrap';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import {
  EXPORTS, IMPORTS, BUY, SELL,
} from './chartConstants';
import { formatCarbonEmission, getTooltipPosition } from './ChartHelpers';
// Styles
const base = 2.5;

const TooltipContainer = styled.div`
width: max-content;
fontSize: ${base * 0.25}rem;
fontFamily: inherit;
color: #212529;
padding: ${base * 0.3}rem;
background-color: #fff;
border: ${base * 0.1}rem solid #f5f7fa;
border-radius: ${base * 0.1}rem;
`;

const MainHeader = styled.h5`
font-size: ${base * 0.4}rem;
margin-bottom: ${base * 0.1}rem;
`;

const SectionWrapper = styled.div`
font-size: ${base * 0.3}rem;
`;

const SectionHeader = styled.h6`
font-size: ${base * 0.3}rem;
margin-bottom: ${base * 0.1}rem;
`;

const SectionContainer = styled.div`
max-width: 200px;
margin-bottom: ${base * 0.2}rem;
`;

// End of styles

/**
 * Format the values(carbon/energy) in the tooltip
 * @param {boolean} isCarbon - flag to indicate carbon.
 * @param {string} format - the tooltip format.
 * @param {string} yAxisFormat
 * @param {number} value
 * @param {string} unitLabel
 * @returns {string} - formatted tooltip data.
 */
const formatTooltipData = (isCarbon, format, yAxisFormat, value, unitLabel) => {
  if (isCarbon) {
    const finalValues = formatCarbonEmission(value);
    if (finalValues) {
      const { value: finalValue, label } = finalValues;
      return `${d3Format(format)(finalValue)}${label}`;
    }
  }
  return `${d3Format(yAxisFormat)(value)}${unitLabel}`;
};

/**
 * Forms the meter label for the tooltip
 * @param {string} dir - import or export
 * @param {string} title - meter title
 * @param {string} identifier - meter identifier
 * @returns {string} - meter label.
 */
const getMeterLabel = (dir, title, identifier) => {
  let meterDetails = title || '';
  meterDetails = identifier && `${title} (${identifier})`;
  return `${dir} ${meterDetails ? `- ${meterDetails}` : ''}`;
};

// child components rendering

/**
 * Renders the tooltip content for trade chart
 * @param {boolean} isCarbon
 * @param {object} data - the tooltip datapack.
 * @param {string} yAxisFormat
 * @param {string} unitLabel
 * @param {string} format - the tooltip format.
 * @param {boolean} isAggregated - view of the trade chart.
 * @param {boolean} multipleMeter
 * @returns {React.ReactElement} - tarde tootip.
 */
export const tradeChartTooltip = (
  isCarbon,
  data,
  yAxisFormat,
  unitLabel,
  format,
  isAggregated,
  multipleMeter,
) => {
  if (!data) {
    return null;
  }
  const tradeTooltip = [BUY, SELL].map((direction) => {
    if (!data[direction] || Object.keys(data[direction]).length === 0) {
      return null;
    }
    const tooltipLabel = direction === BUY ? IMPORTS : EXPORTS;
    return (
      <SectionWrapper key={direction}>
        {isAggregated && (
          <SectionHeader>
            {tooltipLabel}
          </SectionHeader>
        )}

        {Object.keys(data[direction]).map((key) => {
          const d = data[direction][key];
          if (!d) {
            return null;
          }
          const {
            label, subLabel, meter,
          } = d;

          const { title, identifier } = meter || {};
          const meterLabel = getMeterLabel(tooltipLabel, title, identifier);
          return (
            <>
              {(!isAggregated && multipleMeter)
                && (
                  <SectionHeader>
                    {meterLabel}
                  </SectionHeader>
                )}
              <Row key={key} className="mb-1">
                <Col className="pe-0">
                  <span>{label}</span>
                  {subLabel && (
                    <>
                      <br />
                      <small>
                        {`(${subLabel})`}
                      </small>
                    </>
                  )}
                </Col>
                <Col xs="auto">
                  {formatTooltipData(isCarbon, format, yAxisFormat, d.value, unitLabel)}
                </Col>
              </Row>

            </>
          );
        })}
      </SectionWrapper>
    );
  });
  return tradeTooltip;
};

/**
 * Forms the content for the meter tooltip
 * @param {object} tooltipData
 * @param {string} label
 * @param {boolean} isCarbon
 * @param {string} tooltipFormat
 * @param {string} yAxisFormat
 * @param {string} unitLabel
 * @returns {React.ReactElement} - meter tooltip content.
 */
const meterContent = (tooltipData, label, isCarbon, tooltipFormat, yAxisFormat, unitLabel) => {
  const {
    flags, value, title, identifier,
  } = tooltipData || {};
  return (
    <SectionWrapper>
      <SectionHeader>
        {getMeterLabel(label, title, identifier)}
      </SectionHeader>
      <div>
        {formatTooltipData(isCarbon, tooltipFormat, yAxisFormat, value, unitLabel)}
      </div>
      {flags?.length > 0 && (
        <SectionContainer>
          {flags.map((el) => el.identifier).join(', ')}
        </SectionContainer>
      )}
    </SectionWrapper>
  );
};

const renderMeterDetails = (
  isCarbon,
  tooltipData,
  label,
  unitLabel,
  yAxisFormat,
  tooltipFormat,
  isAggregated,
) => {
  if (isAggregated) {
    return meterContent(tooltipData, label, isCarbon, tooltipFormat, yAxisFormat, unitLabel);
  }

  const meterDetails = Object.keys(tooltipData).map((meterId) => (
    meterContent(tooltipData[meterId], label, isCarbon, tooltipFormat, yAxisFormat, unitLabel)
  ));
  return meterDetails;
};

export const meterChartTooltip = (
  isCarbon,
  tooltipData,
  yAxisFormat,
  unitLabel,
  tooltipFormat,
  isAggregated,
) => {
  if (!tooltipData) {
    return null;
  }
  const { tooltipConsumed, tooltipGenerated } = tooltipData;
  if (!tooltipConsumed && !tooltipGenerated) {
    return null;
  }
  return (
    <>
      {
        tooltipConsumed
        && (renderMeterDetails(
          isCarbon,
          tooltipConsumed,
          IMPORTS,
          unitLabel,
          yAxisFormat,
          tooltipFormat,
          isAggregated,
        ))
      }
      {
        tooltipGenerated
        && (renderMeterDetails(
          isCarbon,
          tooltipGenerated,
          EXPORTS,
          unitLabel,
          yAxisFormat,
          tooltipFormat,
          isAggregated,
        ))
      }
    </>
  );
};

// End of child components rendering

/**
 * Description
 * @param {any} props
 * @returns {React.ReactElement} - ChartTooltip component
 */
function ChartTooltip(props) {
  if (!props) {
    return null;
  }
  const {
    chartType, chartWidth, datum, tooltipData, tooltipDateFormat,
    tooltipTimestamp, tooltipUpdate, unitLabel, yAxisFormat, x,
    tooltipFormat, isCarbon, isAggregated, multipleMeter,
  } = props;
  if (!tooltipTimestamp) {
    return null;
  }

  const { ts: tooltipTimestampValue } = tooltipTimestamp; // Is required property.
  const { fullDateObj: datumTimestamp } = datum; // Is required property.

  if (datumTimestamp) {
    const { ts: datumTimestampValue } = datumTimestamp;

    // This happens when the tooltip is trigerred from the whitespace above the bars.
    // We need to update the parent component with the correct timestamp to get the tooltip data
    if (datumTimestampValue && datumTimestampValue !== tooltipTimestampValue) {
      tooltipUpdate(datumTimestamp);
    }
  }

  return (
    <g>
      <foreignObject x={getTooltipPosition(x, chartWidth)} width="1" height="100%" className="overflow-visible">
        <TooltipContainer className={`${chartType}-tooltip`}>
          <MainHeader>{tooltipTimestamp.toLocaleString(tooltipDateFormat)}</MainHeader>
          {chartType === 'trade' ? tradeChartTooltip(isCarbon, tooltipData, yAxisFormat, unitLabel, tooltipFormat, isAggregated, multipleMeter)
            : meterChartTooltip(
              isCarbon,
              tooltipData,
              yAxisFormat,
              unitLabel,
              tooltipFormat,
              isAggregated,
            )}
        </TooltipContainer>
      </foreignObject>
    </g>
  );
}

export default ChartTooltip;

ChartTooltip.propTypes = {
  chartType: PropTypes.string.isRequired,
  chartWidth: PropTypes.number.isRequired,
  datum: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isAggregated: PropTypes.bool.isRequired,
  multipleMeter: PropTypes.bool.isRequired,
  isCarbon: PropTypes.bool.isRequired,
  tooltipData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  tooltipDateFormat: PropTypes.string.isRequired,
  tooltipFormat: PropTypes.string,
  tooltipTimestamp: PropTypes.instanceOf(DateTime).isRequired,
  tooltipUpdate: PropTypes.func.isRequired,
  unitLabel: PropTypes.string.isRequired,
  yAxisFormat: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
};
ChartTooltip.defaultProps = {
  tooltipFormat: null,
};
