import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'reactstrap';

const ACTIVE = 'ACTIVE';
const INACTIVE = 'INACTIVE';

const colorMapping = {
  ACTIVE: 'success',
  INACTIVE: 'danger',
};

/**
 *
 * @param {any} root0
 * @param {any} root0.start
 * @param {any} root0.finish
 * @returns {any} - BadgeActive component
 */
function BadgeActive({ start, finish }) {
  const now = DateTime.local();
  const s = start ? DateTime.fromSeconds(start) : null;
  const f = finish ? DateTime.fromSeconds(finish) : null;

  let state = ACTIVE;
  if ((s && s > now) || (f && f < now)) {
    state = INACTIVE;
  }

  const color = colorMapping[state] || 'mid';

  return (
    <Badge color={color} className="badge-outline">{state}</Badge>
  );
}

BadgeActive.propTypes = {
  finish: PropTypes.number,
  start: PropTypes.number,
};

BadgeActive.defaultProps = {
  finish: null,
  start: null,
};

export default BadgeActive;
