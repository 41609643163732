import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from 'reactstrap';

const colorMapping = {
  TRADE_DIRECTION_BUY: 'secondary',
  TRADE_DIRECTION_SELL: 'primary',
};

/**
 * Description
 * @param {any} direction
 * @returns {React.ReactComponentElement} - BadgeTradeDirection component
 */
function BadgeTradeDirection({ direction }) {
  if (!direction) { return (null); }

  const color = colorMapping[direction] || 'mid';
  const title = direction.split('_').slice(-1)[0];

  return (
    <Badge color={color} className="badge-outline">{title}</Badge>
  );
}

BadgeTradeDirection.propTypes = {
  direction: PropTypes.string.isRequired,
};

export default BadgeTradeDirection;
