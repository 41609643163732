import React from 'react';
import FlashesStore from 'src/stores/FlashesStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

/**
     @constant
    @type {number}
    @default
 */
export const WARNING_TIMEOUT = 5000;

/**
     @constant
    @type {string}
    @default
 */
const TRADE_RULE_WARNING = 'Hi! Are you trying to view your trade rules? Create and manage them by selecting Trade Rules in the menu above.';

const warningDialog = (
  <>
    <FontAwesomeIcon className="me-1" size="lg" icon={faTriangleExclamation} />
    {TRADE_RULE_WARNING}
  </>
);

/**
 * Creates the warning dailog when the user in dashboard trades view
 * clicks on trades toggle button more than once in 5s
 * @returns {void}
 */
export const showTradeRuleMsg = () => {
  clearTimeout();
  const tradeRulesNav = document.querySelectorAll('nav.navbar .nav-item.nav-trade-rules');
  if (tradeRulesNav && tradeRulesNav[0]) {
    FlashesStore.flash(
      FlashesStore.WARNING,
      warningDialog,
    );
    tradeRulesNav[0].classList?.add('trade-rules-highlight');
    tradeRulesNav[0].focus();
    setTimeout(() => {
      tradeRulesNav[0].classList?.remove('trade-rules-highlight');
      FlashesStore.reset();
    }, WARNING_TIMEOUT);
  }
};
