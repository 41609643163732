import { Link } from 'found';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import {
  Badge, Row, Col, Card, CardFooter, CardBody, CardTitle, CardSubtitle, CardText,
  DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import { APIConfig } from 'src/config';
import isActive from 'src/util/isActive';
import {
  COMMUNITY, PLATFORM_MODE_REBATE, TRADE_TYPE_COMMUNITY, TRADE_RULE_STATE_ACCEPTED,
} from 'src/util/constants';
import convertEnergyPrice from 'src/util/conversions';
import { tradeRulePriceRange } from 'src/util/tradeRule';
import username from 'src/util/decorators/username';
import { emptyTradeRulesClassName, getTradeRuleMeterLabel } from 'src/helpers/tradeHelpers';

class TradeRuleActiveCommunity extends React.Component {
  static tradeRulesForProperty(property) {
    const { meters } = property;
    const rules = { sell: [], buy: [] };

    meters.edges.forEach((meterEdge) => {
      if (!!meterEdge && !!meterEdge.node && !!meterEdge.node.communityRules) {
        meterEdge.node.communityRules.edges.forEach((ruleEdge) => {
          const { node: ruleNode } = ruleEdge;
          if (
            ruleNode.state !== TRADE_RULE_STATE_ACCEPTED
            || ruleNode.tradeType !== TRADE_TYPE_COMMUNITY
          ) {
            return;
          }

          if (ruleNode.seller.tradePoint.id === meterEdge.node.tradePointId) {
            rules.sell.push({ ...ruleNode, meter: meterEdge.node });
          } else {
            rules.buy.push({ ...ruleNode, meter: meterEdge.node });
          }
        });
      }
    });

    return rules;
  }

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <div>Loading...</div>;
    }

    const { property } = this.props;
    const { id: propertyId } = property;
    const tradeRules = TradeRuleActiveCommunity.tradeRulesForProperty(property);
    const noTradeRulesClass = emptyTradeRulesClassName(tradeRules, COMMUNITY);

    return (
      <Card className={`mt-4 mb-4 trade-rules-community${noTradeRulesClass}`}>
        <CardBody>
          <h2>Community Trades</h2>
          <p>
            Community trades allow you to buy and sell anonymously with other
            members of your community. The price paid for energy is based on
            the available supply and the sell and buy price of everyone else
            in the community.
          </p>

          <Row>
            <Col xs="12" sm="12" md="6">
              <h3>Selling</h3>
              <p>
                {'You have '}
                <strong>{tradeRules.sell.length}</strong>
                {' community trade rules to sell energy.'}
              </p>

              {tradeRules.sell && tradeRules.sell.length > 0 && tradeRules.sell.map((rule) => {
                const {
                  acceptedAt, acceptedBy, id: ruleId, meter, proposedAt, proposedBy, seller,
                } = rule;
                const { title } = meter;
                let priceLabel;
                let tradePointTitle;
                if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
                  priceLabel = 'Discount of: ';
                  tradePointTitle = title;
                } else {
                  priceLabel = 'Sell price: ';
                  tradePointTitle = title;
                }
                const priceRange = tradeRulePriceRange(rule);
                const { maximum, minimum } = priceRange;

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${ruleId}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        {tradePointTitle}
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        {/* <FontAwesomeIcon icon={faCheck} className="me-2" /> */}
                        Approved
                      </CardSubtitle>
                      <CardText>
                        Selling from
                        {' '}
                        {getTradeRuleMeterLabel(seller)}
                        Proposed by
                        {' '}
                        <strong>{username(proposedBy)}</strong>
                        {' '}
                        on
                        {' '}
                        {DateTime.fromSeconds(proposedAt).toFormat('dd LLL yyyy')}
                        <br />
                        {acceptedAt && (
                          <>
                            Accepted by
                            {' '}
                            <strong>{username(acceptedBy)}</strong>
                            {' '}
                            on
                            {' '}
                            {DateTime.fromSeconds(acceptedAt).toFormat(
                              'dd LLL yyyy',
                            )}
                          </>
                        )}
                        <br />
                        {priceLabel}
                        <strong>
                          {convertEnergyPrice(minimum).toFixed(3)}
                        </strong>
                        {maximum !== minimum && (
                          <>
                            {' '}
                            to
                            {' '}
                            <strong>
                              {convertEnergyPrice(maximum).toFixed(3)}
                            </strong>
                          </>
                        )}
                        {' '}
                        <abbr title="cents per kilowatt hour">c/kWh</abbr>
                        {' '}
                        (
                        <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}>further details</Link>
                        )
                        <br />
                        <Link
                          to={`/properties/${propertyId}/trade-rules/${ruleId}`}
                        >
                          <Badge color="mid">{rule.id}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                  </Card>
                );
              })}
            </Col>

            <Col xs="12" sm="12" md="6">
              <h3>Buying</h3>
              <p>
                {'You have '}
                <strong>{tradeRules.buy.length}</strong>
                {' community trade rules to buy energy.'}
              </p>

              {tradeRules.buy && tradeRules.buy.length > 0 && tradeRules.buy.map((rule) => {
                const {
                  acceptedAt, acceptedBy, id: ruleId, meter, proposedAt, proposedBy, buyer,
                } = rule;
                const { title } = meter;
                let priceLabel;
                let tradePointTitle;
                if (APIConfig().MODE === PLATFORM_MODE_REBATE) {
                  priceLabel = 'Discount of: ';
                  tradePointTitle = title;
                } else {
                  priceLabel = 'Buy price: ';
                  tradePointTitle = title;
                }
                const priceRange = tradeRulePriceRange(rule);
                const { maximum, minimum } = priceRange;

                return (
                  <Card className="mt-4 mb-4" key={`trade-rules-${ruleId}`}>
                    <CardBody>
                      <CardTitle tag="h4">
                        {tradePointTitle}
                      </CardTitle>
                      <CardSubtitle tag="h5" className="mb-2">
                        {/* <FontAwesomeIcon icon={faCheck} className="me-2" /> */}
                        Approved
                      </CardSubtitle>
                      <CardText>
                        Buying for
                        {' '}
                        {getTradeRuleMeterLabel(buyer)}
                        Proposed by
                        {' '}
                        <strong>{username(proposedBy)}</strong>
                        {' '}
                        on
                        {' '}
                        {DateTime.fromSeconds(proposedAt).toFormat('dd LLL yyyy')}
                        <br />
                        {acceptedAt && (
                          <>
                            Accepted by
                            {' '}
                            <strong>{username(acceptedBy)}</strong>
                            {' '}
                            on
                            {' '}
                            {DateTime.fromSeconds(acceptedAt).toFormat(
                              'dd LLL yyyy',
                            )}
                          </>
                        )}
                        <br />
                        {priceLabel}
                        <strong>
                          {convertEnergyPrice(minimum).toFixed(3)}
                        </strong>
                        {maximum !== minimum && (
                          <>
                            {' '}
                            to
                            {' '}
                            <strong>
                              {convertEnergyPrice(maximum).toFixed(3)}
                            </strong>
                          </>
                        )}
                        {' '}
                        <abbr title="cents per kilowatt hour">c/kWh</abbr>
                        {' '}
                        (
                        <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}>further details</Link>
                        )
                        <br />
                        <Link to={`/properties/${propertyId}/trade-rules/${ruleId}`}>
                          <Badge color="mid">{rule.id}</Badge>
                        </Link>
                      </CardText>
                    </CardBody>
                  </Card>
                );
              })}
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <UncontrolledButtonDropdown className="me-2">
            <DropdownToggle caret color="primary">
              Set community trade rules
            </DropdownToggle>
            <DropdownMenu>
              {property.meters.edges.map((edge) => edge && edge.node && (
                <Link
                  to={`/properties/${property.id}/meters/${edge.node.id}/trade-rules/community/set`}
                  className={`dropdown-item ${!isActive(edge.node.active) ? 'disabled' : ''}`}
                  role="menuitem"
                  key={edge.node.id}
                >
                  {edge.node.title}
                </Link>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </CardFooter>
      </Card>
    );
  }
}

TradeRuleActiveCommunity.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TradeRuleActiveCommunity.defaultProps = {
  property: null,
};

export default createFragmentContainer(
  TradeRuleActiveCommunity,
  {
    property: graphql`
      fragment TradeRuleActiveCommunity_property on Property {
        id
        meters {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              active { start finish }
              communityRules: rules(first: 500, type: TRADE_TYPE_COMMUNITY, state: TRADE_RULE_STATE_ACCEPTED) {
                edges {
                  node {
                    id
                    priority
                    tradeType
                    state
                    buyer {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        id
                        meter {
                          id
                          identifier
                          title
                          property {
                            id
                            title
                          }
                        }
                      }
                    }
                    seller {
                      userId
                      communityId
                      residualId
                      tradePoint {
                        id
                        meter {
                          id
                          identifier
                          title
                          property {
                            id
                            title
                          }
                        }
                      }
                    }
                    clauses {
                      edges {
                        node {
                          price
                        }
                      }
                    }
                    start
                    finish
                    proposedAt
                    proposedBy {
                      id
                      email
                      givenName
                      familyName
                    }
                    acceptedAt
                    acceptedBy {
                      id
                      email
                      givenName
                      familyName
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
);
