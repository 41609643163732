import { Link, routerShape } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import Address from 'src/components/Address';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';

import PropertySettingsUpdateMutation from 'src/mutations/PropertySettingsUpdateMutation';
import FlashesStore from 'src/stores/FlashesStore';

import PropertySettingsForm from './PropertySettingsForm';
import PropertySettingsMeterTariff from './PropertySettingsMeterTariff';
import PropertySettingsUsers from './PropertySettingsUsers';

class PropertySettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = { processing: false };
  }

  handlePropertySettingsUpdate = (id, input) => {
    this.setState({ processing: true });
    FlashesStore.reset();

    PropertySettingsUpdateMutation(
      id,
      input,
      this.handlePropertySettingsUpdateSuccess,
      this.handlePropertySettingsUpdateFailure,
    );
  };

  handlePropertySettingsUpdateSuccess = (response, errors) => {
    this.setState({ processing: false });

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: { errors: [error] },
      }));
      return;
    }

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      'Updated property settings.',
    );

    const { router, property } = this.props;
    router.push(`/properties/${property.id}`);
  };

  handlePropertySettingsUpdateFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  renderPropertyControls = () => {
    const { property } = this.props;

    return (
      <ButtonToolbar>
        <ButtonGroup className="ms-2">
          <Link to={`/properties/${property.id}/settings`} className="btn btn-darken">
            Property Settings
          </Link>
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { property } = this.props;
    const { address, id, title } = property;
    const { processing } = this.state;

    const addr = new Address(address);

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Enosi - Property: ${title}`}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: 'Properties', path: '/properties' },
            { name: title, path: `/properties/${id}` },
            { name: 'Settings' },
          ]}
        />
        <Heading
          alias={title}
          address={addr.simpleString()}
          context="Overview"
          controls={this.renderPropertyControls()}
        />

        <h2>General Settings</h2>
        <p>Here you can add an alias to your property.</p>

        <PropertySettingsForm
          property={property}
          handlePropertySettingsUpdate={this.handlePropertySettingsUpdate}
          processing={processing}
        />

        <div className="d-flex flex-wrap">
          <h2 id="tariffs">Active Tariffs</h2>
          <div className="ms-auto">
            <Link to={`/properties/${property.id}/tariffs`} className="btn btn-darken property-all-tariffs">
              All tariffs
            </Link>
          </div>
        </div>
        <p>Your electricity tariffs are listed here and are GST exclusive.</p>

        <PropertySettingsMeterTariff property={property} />

        <h2>Users</h2>
        <p>These are the users who can manage this property.</p>

        <PropertySettingsUsers property={property} />
      </>
    );
  }
}

PropertySettings.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: routerShape.isRequired,
};

PropertySettings.defaultProps = {
  property: null,
};

export default createFragmentContainer(
  PropertySettings,
  {
    property: graphql`
      fragment PropertySettings_property on Property {
        id
        title
        address {
          line1
          line2
          city
          state
          country
          postcode
        }
        ...PropertySettingsForm_property
        ...PropertySettingsMeterTariff_property
        ...PropertySettingsUsers_property
      }
    `,
  },
);
