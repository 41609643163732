import { Link } from 'found';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { createFragmentContainer, graphql } from 'react-relay';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';

import Address from 'src/components/Address';
import Loading from 'src/components/Loading';
import Breadcrumbs from 'src/enosikit/components/Breadcrumbs';
import Heading from 'src/enosikit/components/Heading';

import ProposeNominatedTradeMutation from 'src/mutations/ProposeNominatedTradeMutation';
import FlashesStore from 'src/stores/FlashesStore';

import TradeRuleProposeNominatedFlatForm from './TradeRuleProposeNominatedFlatForm';

class TradeRuleProposeNominatedFlat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
    };
  }

  handleTradeRuleProposeNominated = (input) => {
    this.setState({ processing: true });
    FlashesStore.reset();

    ProposeNominatedTradeMutation(
      input,
      this.handleTradeRuleProposeNominatedSuccess,
      this.handleTradeRuleProposeNominatedFailure,
    );
  };

  handleTradeRuleProposeNominatedSuccess = (response, errors) => {
    this.setState({ processing: false });

    if (errors && errors.length > 0) {
      errors.forEach((error) => FlashesStore.flash(FlashesStore.ERROR, {
        message: FlashesStore.ERROR,
        source: {
          errors: [error],
        },
      }));
      return;
    }

    const { id } = response.proposeNominatedTrade;

    FlashesStore.flash(
      FlashesStore.SUCCESS,
      `A new peer-to-peer trade rule proposal has been sent with id ${id}.`,
    );

    const { router, property } = this.props;
    router.push(`/properties/${property.id}/trade-rules/active`);
  };

  handleTradeRuleProposeNominatedFailure = (error) => {
    this.setState({ processing: false });

    FlashesStore.flash(FlashesStore.ERROR, error);
  };

  renderPropertyControls = () => {
    const { property } = this.props;
    return (
      <ButtonToolbar>
        <ButtonGroup className="ms-2">
          <Link
            to={`/properties/${property.id}/trade-rules`}
            className="btn btn-darken"
          >
            All trade rules
          </Link>
        </ButtonGroup>

        <ButtonGroup className="ms-2">
          <Link
            to={`/properties/${property.id}/settings`}
            className="btn btn-darken"
          >
            Property Settings
          </Link>
        </ButtonGroup>
      </ButtonToolbar>
    );
  };

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { property, router } = this.props;
    const { address, id, title } = property;
    const addr = new Address(address);

    const { processing } = this.state;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Enosi - Property: ${title} - Propose peer-to-peer trade rule`}</title>
        </Helmet>

        <Breadcrumbs
          breadcrumbs={[
            { name: 'Properties', path: '/properties' },
            { name: title, path: `/properties/${id}` },
            { name: 'Trade Rules', path: `/properties/${id}/trade-rules/active` },
            { name: 'Propose peer-to-peer trade rule' },
          ]}
        />
        <Heading
          alias={title}
          address={addr.simpleString()}
          context="Trade Rule - Propose Peer-to-Peer"
          controls={this.renderPropertyControls()}
        />
        <TradeRuleProposeNominatedFlatForm
          property={property}
          handleTradeRuleProposeNominated={this.handleTradeRuleProposeNominated}
          processing={processing}
          router={router}
        />
      </>
    );
  }
}

TradeRuleProposeNominatedFlat.propTypes = {
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  relay: PropTypes.shape({
    refetch: PropTypes.func,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

TradeRuleProposeNominatedFlat.defaultProps = {
  property: null,
};

export default createFragmentContainer(
  TradeRuleProposeNominatedFlat,
  {
    property: graphql`
      fragment TradeRuleProposeNominatedFlat_property on Property {
        id
        title
        address {
          line1
          line2
          city
          state
          postcode
          gps {
            latitude
            longitude
          }
        }
        ...TradeRuleProposeNominatedFlatForm_property
      }
    `,
  },
);
