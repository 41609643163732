import { RedirectException, Route } from 'found';
import React from 'react';

// Loading
import Loading from 'src/components/Loading';

class AuthenticatedRoute extends Route {
  render({ Component, props }) {
    if (!props) { return <Loading />; }

    if (props && !props.viewer) {
      console.log('no viewer', props);
      const { match } = props;
      const { location } = match;
      const { pathname, search } = location;

      throw new RedirectException(`/login?redirect=${pathname}${search}`);
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return (<Component {...props} />);
  }
}

export default AuthenticatedRoute;
