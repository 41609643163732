import { commitMutation, graphql } from 'react-relay';
import environment from 'src/util/environment';

const mutation = graphql`
  mutation ProposeNominatedTradeMutation($input: NominatedTradeRuleProposal!) {
    proposeNominatedTrade(input: $input) {
      id
    }
  }
`;

const ProposeNominatedTradeMutation = (input, onCompleted, onError) => {
  const variables = { input };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted,
      onError,
    },
  );
};

export default ProposeNominatedTradeMutation;
