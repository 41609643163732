/**
 * @generated SignedSource<<1b1c7af1a563434d561818188fb7bf20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TradeRulePrioritizeNominatedList_property",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Property",
  "abstractKey": null
};

node.hash = "ab51fcac13c6620038aefe369e2b10ff";

module.exports = node;
