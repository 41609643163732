import { Link } from 'found';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

// import BadgeActive from 'src/components/Badge/BadgeActive';
import BadgeTradeDirection from 'src/components/Badge/BadgeTradeDirection';
import BadgeTradeRuleState from 'src/components/Badge/BadgeTradeRuleState';
import BadgeTradeType from 'src/components/Badge/BadgeTradeType';
import Loading from 'src/components/Loading';
import UUID from 'src/components/UUID';

import {
  getTradePointTradeRuleTableLabel,
} from 'src/helpers/tradeHelpers';
import {
  TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL, TRADE_DIRECTION_UNSPECIFIED,
} from 'src/util/constants';
import username from 'src/util/decorators/username';
import { tradeRuleClauseConditions } from 'src/util/tradeRule';

import TradeRuleListTableItemClause from './TradeRuleListTableItemClause';

class TradeRuleListTableItem extends React.Component {
  static clauseConditions(rule) {
    const conditions = tradeRuleClauseConditions(rule);
    if (conditions.length === 0) {
      return { element: null, display: false };
    }

    return {
      element: (
        <li>
          All
          {' '}
          {conditions.reduce((prev, curr) => [prev, ', ', curr])}
        </li>
      ),
      display: true,
    };
  }

  static counterPartyTrader(tradePointId, rule) {
    const direction = this.direction(tradePointId, rule);

    let trader;
    if (direction === TRADE_DIRECTION_BUY) {
      trader = rule.seller;
    } else if (direction === TRADE_DIRECTION_SELL) {
      trader = rule.buyer;
    } else {
      return null;
    }

    if (trader.user) {
      return trader.user;
    }
    if (trader.community) {
      return trader.community;
    }
    if (trader.residual) {
      return trader.residual;
    }

    return null;
  }

  static counterPartyTradePoint(tradePointId, rule) {
    const direction = this.direction(tradePointId, rule);

    let tradePoint;
    if (direction === TRADE_DIRECTION_BUY) {
      tradePoint = rule.seller.tradePoint;
    } else if (direction === TRADE_DIRECTION_SELL) {
      tradePoint = rule.buyer.tradePoint;
    } else {
      return null;
    }

    return getTradePointTradeRuleTableLabel(tradePoint);
  }

  static direction(tradePointId, rule) {
    const { buyer, seller } = rule;

    let buyerTradePointId = null;
    let sellerTradePointId = null;

    if (buyer.tradePoint && buyer.tradePoint.id) {
      buyerTradePointId = buyer.tradePoint.id;
    }
    if (seller.tradePoint && seller.tradePoint.id) {
      sellerTradePointId = seller.tradePoint.id;
    }

    if (buyerTradePointId && buyerTradePointId === tradePointId) {
      return TRADE_DIRECTION_BUY;
    }
    if (sellerTradePointId && sellerTradePointId === tradePointId) {
      return TRADE_DIRECTION_SELL;
    }
    return TRADE_DIRECTION_UNSPECIFIED;
  }

  render() {
    if (this.error) {
      return <div>Error!</div>;
    }
    if (!this.props) {
      return <Loading />;
    }

    const { propertyId, rule, tradePointId } = this.props;

    const {
      id, tradeType, state, start, finish,
    } = rule;

    const clauses = (!rule.clauses || !rule.clauses.edges)
      ? [] : rule.clauses.edges.map((el) => (el.node)).sort((a, b) => (b.price - a.price));

    const clauseConditions = TradeRuleListTableItem.clauseConditions(rule);

    // eslint-disable-next-line max-len -- conflict between max-len and function-paren-newline.
    const counterPartyTradePoint = TradeRuleListTableItem.counterPartyTradePoint(tradePointId, rule);
    const counterPartyTrader = TradeRuleListTableItem.counterPartyTrader(tradePointId, rule);
    const direction = TradeRuleListTableItem.direction(tradePointId, rule);

    return (
      <tr key={`trade-rule-${id}`}>
        <th>
          <Link to={`/properties/${propertyId}/trade-rules/${id}`}>
            <UUID uuid={id} />
          </Link>
        </th>
        <td>
          <BadgeTradeType type={tradeType} />
          {/* <br />
            <BadgeActive start={start} finish={finish} /> */}
        </td>
        <td>
          <BadgeTradeRuleState state={state} />
        </td>
        <td>
          <BadgeTradeDirection direction={direction} />
        </td>
        <td>
          {counterPartyTrader && (
          <>
            <strong>{username(counterPartyTrader)}</strong>
            <br />
            <UUID uuid={counterPartyTrader.id} />
          </>
          )}
        </td>
        <td>
          {counterPartyTradePoint}
        </td>
        <td>
          <ul className="ps-2">
            {clauses && clauses.map((clause) => (
              <TradeRuleListTableItemClause
                clause={clause}
                key={clause.id}
                showConditions={false}
              />
            ))}
            {clauseConditions.display && clauseConditions.element}
          </ul>
        </td>
        <td>
          {start ? DateTime.fromSeconds(start).toFormat('HH:mm DD') : '-'}
          {' to '}
          {finish ? DateTime.fromSeconds(finish).toFormat('HH:mm DD') : '-'}
        </td>
      </tr>
    );
  }
}

TradeRuleListTableItem.propTypes = {
  propertyId: PropTypes.string.isRequired,
  rule: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  tradePointId: PropTypes.string.isRequired,
};

export default createFragmentContainer(
  TradeRuleListTableItem,
  {
    rule: graphql`
      fragment TradeRuleListTableItem_rule on TradeRule {
        id
        buyer {
          user { id email givenName familyName }
          community { id title }
          residualId
          tradePoint {
            id
            type
            meter {
              id
              identifier
              title
              property {
                id
                title
              }
            }
          }
        }
        seller {
          user { id email givenName familyName }
          community { id title }
          residualId
          tradePoint {
            id
            type
            meter {
              id
              identifier
              title
              property {
                id
                title
              }
            }
          }
        }
        clauses {
          edges {
            node {
              id
              price
              ignoreDaylightSavings
              ignorePublicHolidays
              timezone
              ...TradeRuleListTableItemClause_clause
            }
          }
        }
        tradeType
        state
        start
        finish
        proposedAt
        proposedBy { id email givenName familyName }
        acceptedAt
        acceptedBy { id email givenName familyName }
        rejectedAt
        rejectedBy { id email givenName familyName }
        cancelledAt
        cancelledBy { id email givenName familyName }
        closedAt
        closedBy { id email givenName familyName }
      }
    `,
  },
);
