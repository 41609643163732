import { Link } from 'found';
import { routerShape } from 'found/PropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import {
  Card, CardBody, CardHeader, CardFooter,
  Button, Form, FormFeedback, FormGroup, FormText, Input, Label,
  Col, Row,
} from 'reactstrap';
import isEmail from 'validator/es/lib/isEmail';
import isIn from 'validator/es/lib/isIn';
// import isNumeric from 'validator/es/lib/isNumeric';
import isUUID from 'validator/es/lib/isUUID';

import FlashesStore from 'src/stores/FlashesStore';
import isActive from 'src/util/isActive';
import {
  AllMonthsOfYear, AllDaysOfWeek, AllTimesOfDay,
  TRADE_DIRECTION_UNSPECIFIED, TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL,
} from 'src/util/constants';

const TRADE_POINT_LABEL_BUY = 'Choose the Trade Point you are buying for';
const TRADE_POINT_LABEL_SELL = 'Choose the Trade Point you are selling from';

class TradeRuleProposeNominatedFlatForm extends React.Component {
  static isValidPrice(price) {
    return price !== '' && parseFloat(price) >= 0;
  }

  constructor(props) {
    super(props);

    this.state = {
      // Values
      direction: TRADE_DIRECTION_UNSPECIFIED,
      price: '',
      proposerTradePointId: null,
      recipientUserEmail: '',
      // Validation
      directionValid: null,
      priceValid: null,
      proposerTradePointIdValid: null,
      recipientUserEmailValid: null,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { handleTradeRuleProposeNominated, processing, property } = this.props;
    const { timezone, publicHolidayRegion } = property;

    if (processing) {
      FlashesStore.flash(FlashesStore.INFO, 'We are still processing your request...');
      return;
    }

    if (!this.isValid()) {
      FlashesStore.flash(FlashesStore.ERROR, 'Form data not valid. Please see below.');
      return;
    }

    const {
      direction, price, proposerTradePointId, recipientUserEmail,
    } = this.state;

    const input = {
      direction,
      clauses: [
        {
          price: parseFloat(price) / 100000, // do this once here due to floating point errors
          timezone,
          publicHolidayRegion,
          ignoreDaylightSavings: false,
          ignorePublicHolidays: false,
          monthsOfYear: AllMonthsOfYear,
          daysOfWeek: AllDaysOfWeek,
          timesOfDay: [AllTimesOfDay],
        },
      ],
      proposerTradePointId,
      recipientUserEmail,
    };

    handleTradeRuleProposeNominated(input);
  };

  handleDirectionChange = (event) => {
    const { value: direction } = event.target;
    const directionValid = isIn(direction, [TRADE_DIRECTION_BUY, TRADE_DIRECTION_SELL]);

    this.setState({ direction, directionValid });
  };

  handlePriceChange = (event) => {
    const { value: price } = event.target;
    const priceValid = TradeRuleProposeNominatedFlatForm.isValidPrice(price);

    this.setState({ price, priceValid });
  };

  handleRecipientUserEmailChange = (event) => {
    const { value: recipientUserEmail } = event.target;
    const recipientUserEmailValid = isEmail(recipientUserEmail);

    this.setState({ recipientUserEmail, recipientUserEmailValid });
  };

  handleProposerTradePointIdChange = (event) => {
    const { value: proposerTradePointId } = event.target;
    const proposerTradePointIdValid = isUUID(proposerTradePointId);

    this.setState({ proposerTradePointId, proposerTradePointIdValid });
  };

  isValid = () => {
    const {
      directionValid,
      priceValid,
      proposerTradePointIdValid,
      recipientUserEmailValid,
    } = this.state;

    return priceValid !== null && priceValid
      && directionValid !== null && directionValid
      && proposerTradePointIdValid !== null && proposerTradePointIdValid
      && recipientUserEmailValid !== null && recipientUserEmailValid;
  };

  render() {
    const { property, processing, router } = this.props;
    const { meters } = property;
    const meterNodes = meters && meters.edges.map((edge) => (edge.node));

    const {
      direction, price, proposerTradePointId, recipientUserEmail,
      directionValid, proposerTradePointIdValid, recipientUserEmailValid,
    } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} disabled={processing}>
        <Card>
          <CardHeader className="d-flex flex-wrap">
            <h2 className="mb-0">
              Propose a peer-to-peer trade
            </h2>
            <Link to={`/properties/${property.id}/trade-rules/propose/nominated/time-of-use`} className="btn btn-darken ms-auto">
              Time-of-use pricing
            </Link>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <Label for="direction">Are you buying or selling?</Label>
              <Input type="select" name="direction" id="direction" defaultValue={direction} onChange={this.handleDirectionChange} disabled={processing} valid={directionValid} invalid={directionValid !== null && !directionValid}>
                <option value={null}>
                  Select &lsquo;Buy&lsquo; or &lsquo;Sell&lsquo;
                </option>
                <option value={TRADE_DIRECTION_BUY}>
                  Buy
                </option>
                <option value={TRADE_DIRECTION_SELL}>
                  Sell
                </option>
              </Input>
              <FormFeedback>Invalid direction</FormFeedback>
              <FormText>
                Are you proposing to sell your energy, or buy energy from a friend?
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="price">Price</Label>
              <Input type="number" name="price" id="price" value={price} onChange={this.handlePriceChange} disabled={processing} />
              <FormFeedback>Invalid price</FormFeedback>
              <FormText>
                What price, in c/kWh, do you want to propose?
              </FormText>
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="proposerTradePointId">{direction === TRADE_DIRECTION_BUY ? TRADE_POINT_LABEL_BUY : TRADE_POINT_LABEL_SELL}</Label>
                  <Input type="select" name="select" id="proposerTradePointId" defaultValue={proposerTradePointId} onChange={this.handleProposerTradePointIdChange} disabled={processing} valid={proposerTradePointIdValid} invalid={proposerTradePointIdValid !== null && !proposerTradePointIdValid}>
                    <option key="meter-id-null">
                      Select a Trade Point
                    </option>
                    {meterNodes && meterNodes.map((meter) => (
                      <option
                        value={meter.tradePointId}
                        disabled={!isActive(meter.active)}
                        key={meter.id}
                      >
                        {`${meter.title} (${meter.identifier})`}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>Invalid trade point selection</FormFeedback>
                  <FormText>
                    Which trade point are you using?
                  </FormText>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="recipientUserEmail">Recipient email</Label>
                  <Input type="email" name="recipientUserEmail" id="recipientUserEmail" value={recipientUserEmail} onChange={this.handleRecipientUserEmailChange} disabled={processing} valid={recipientUserEmailValid} invalid={recipientUserEmailValid !== null && !recipientUserEmailValid} />
                  <FormFeedback>Invalid email</FormFeedback>
                  <FormText>
                    Who do you want to propose to trade with?
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button disabled={processing}>
              Propose
            </Button>
            <Button color="" onClick={() => (router.go(-1))} disabled={processing}>
              Cancel
            </Button>
          </CardFooter>
        </Card>
      </Form>
    );
  }
}

TradeRuleProposeNominatedFlatForm.propTypes = {
  handleTradeRuleProposeNominated: PropTypes.func,
  property: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  processing: PropTypes.bool,
  router: routerShape.isRequired,
};

TradeRuleProposeNominatedFlatForm.defaultProps = {
  handleTradeRuleProposeNominated: null,
  property: null,
  processing: false,
};

export default createFragmentContainer(
  TradeRuleProposeNominatedFlatForm,
  {
    property: graphql`
      fragment TradeRuleProposeNominatedFlatForm_property on Property {
        id
        title
        timezone
        publicHolidayRegion
        meters {
          edges {
            node {
              id
              identifier
              title
              tradePointId
              active { start finish }
            }
          }
        }
      }
    `,
  },
);
