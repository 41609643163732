// type FlashType = "error" | "success" | "info";

// type StringMessage = string;
// type GraphqlExceptionMessage = {
//   message: StringMessage,
//   source: {
//     type: string,
//     errors: Array<{message: StringMessage}>
//   }
// };
// type Message = GraphqlExceptionMessage | StringMessage;

// export type FlashItem = {
//   id: number | 'FLASH_CONN_ERROR',
//   type: FlashType,
//   message: StringMessage | React.Node
// };

import EventEmitter from 'eventemitter3';

class FlashesStore extends EventEmitter {
  constructor(...args) {
    super(args);
    this.preloaded = [];
    this.ERROR = 'error';
    this.INFO = 'info';
    this.SUCCESS = 'success';
    this.WARNING = 'warning';
  }

  preload(flashes) {
    flashes.forEach((flash) => {
      this.preloaded.push({ id: (new Date()).valueOf(), ...flash });
    });
  }

  flash(type, message) {
    let returnMessage = message;

    const { message: content, source } = message;

    // See if the message is actually a GraphQL exception
    if (content && source) {
      const { errors, type: sourceType } = source;
      // $FlowExpectError
      if (errors.length && errors[0] && errors[0].message) {
        if (sourceType === 'unknown_error') {
          returnMessage = 'Sorry, there’s been an unexpected error and our engineers have been notified';
        } else {
          returnMessage = errors[0].message;
          if (errors[0].path) {
            const { path } = errors[0];
            returnMessage = `${path[path.length - 1]}: ${returnMessage}`;
          }
        }
      } else {
        returnMessage = 'An unknown error occured';
      }
    }

    this.emit('flash', { id: (new Date()).valueOf(), type, message: returnMessage });
  }

  reset() {
    this.emit('reset');
  }
}

export default new FlashesStore();
