/**
 * @generated SignedSource<<2dca40cf98070ba456f67220a4e0229e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TradeRuleSetCommunityFlatForm_property",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicHolidayRegion",
      "storageKey": null
    }
  ],
  "type": "Property",
  "abstractKey": null
};

node.hash = "395e1b4fd3960eb6028891d83db284b1";

module.exports = node;
