import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { ListGroup, ListGroupItem } from 'reactstrap';

import username from 'src/util/decorators/username';
import isActive from 'src/util/isActive';

/**
 * Description
 * @param {any} props
 * @returns {React.ReactComponentElement} - PropertySettingsUsers component
 */
function PropertySettingsUsers(props) {
  const { property } = props;
  const { propertyUsers } = property;

  return (
    <ListGroup className="property-users">
      {propertyUsers && propertyUsers.edges && propertyUsers.edges.map((edge) => {
        const { node } = edge;
        const { id, active, user } = node;
        const { start, finish } = active;
        const { email } = user;

        if (!isActive(active)) {
          return null;
        }

        let activeString = `Active from ${DateTime.fromSeconds(start).toLocaleString(DateTime.DATE_SHORT)}`;
        if (finish) {
          activeString += ` to ${DateTime.fromSeconds(finish).toLocaleString(DateTime.DATE_SHORT)}`;
        }

        return (
          <ListGroupItem key={id}>
            <div>
              <strong>{username(user)}</strong>
            </div>
            <div>
              {email}
            </div>
            <div>
              {activeString}
            </div>
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
}

PropertySettingsUsers.propTypes = {
  property: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default createFragmentContainer(
  PropertySettingsUsers,
  {
    property: graphql`
      fragment PropertySettingsUsers_property on Property {
        propertyUsers(first: 500) {
          edges {
            node {
              id
              externalIdentifier
              active { start finish }
              user {
                id
                externalIdentifier
                email
                givenName
                familyName
                active { start finish }
              }
            }
          }
        }
      }
    `,
  },
);
