import { commitMutation, graphql } from 'react-relay';
import environment from 'src/util/environment';

const mutation = graphql`
  mutation CancelNominatedTradeMutation($id: ID!) {
    cancelNominatedTrade(id: $id) {
      id
    }
  }
`;

const CancelNominatedTradeMutation = (id, onCompleted, onError) => {
  const variables = { id };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      onCompleted,
      onError,
      updater: (store) => {
        // Get the current user record from the store
        const ruleProxy = store.get(id);
        ruleProxy.setValue('TRADE_RULE_STATE_CANCELLED', 'state');
      },
    },
  );
};

export default CancelNominatedTradeMutation;
