/**
 * @generated SignedSource<<5f2dec49cba8a82e123dedbdbd3ab95f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "propertyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "propertyId",
        "variableName": "propertyId"
      },
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "PropertyUser",
    "kind": "LinkedField",
    "name": "propertyAddUser",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "externalIdentifier",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TimeRange",
        "kind": "LinkedField",
        "name": "active",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "start",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "finish",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Property",
        "kind": "LinkedField",
        "name": "property",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertyAddUserMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PropertyAddUserMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "a415873d832d55fdb6fc25eb5383aa34",
    "id": null,
    "metadata": {},
    "name": "PropertyAddUserMutation",
    "operationKind": "mutation",
    "text": "mutation PropertyAddUserMutation(\n  $propertyId: ID!\n  $userId: ID!\n) {\n  propertyAddUser(propertyId: $propertyId, userId: $userId) {\n    id\n    externalIdentifier\n    active {\n      start\n      finish\n    }\n    createdAt\n    updatedAt\n    user {\n      id\n    }\n    property {\n      id\n    }\n  }\n}\n"
  }
};
})();

node.hash = "874ce282d71fc6060a9421867da5a4b9";

module.exports = node;
